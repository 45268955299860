export class Rule {
  constructor(data) {
    this._data = data || {};
  }
  
  getId() {
    return this._data.id;
  }

  getConfigs() {
    return this._data.configs;
  }  

  getRawData() {
    return this._data;
  }

  getRawMeta() {
    return this._meta;
  }
  
  isNew() {
    return !this._data.id;
  }

  getName() {
    return this._data.name;
  }  

  getHosts() {
    return [this._data.name || ''];
  }

  getMainHost() {
    return this.getHosts()[0];
  }

  getStatus() {
    return false;
  }

  hasCert() {
    return false;
  }

  hasAutoGenerateCert() {
    return false;
  }
  
  getProtocols() {
    if (this._data.configs && this._data.configs.protocols) {
      return this._data.configs.protocols;
    }
    return [];
  }

  isForceHttps() {
    return false;
  }

  getShadows() {
    return [];
  }

  setShadows(newShadows) {
    this._data.hosts = [this.getMainHost(), ...newShadows]
  }

  getServiceId() {
    return null;
  }
  
  getServiceHost() {
    return this._data.name;
  }
  
  getServiceTags() {
    if (this._data.configs && this._data.configs.tags) {
      return this._data.configs.tags;
    }
    
    return [];
  }
  
  getUpstreamTargets() {
    if (this._data.configs && this._data.configs.upstream) {
      return this._data.configs.upstream.targets;
    }
    
    return [];
  }
  
  getServiceProtocol() {
    if (this._data.configs && this._data.configs?.service.protocol) {
      return this._data.configs.service.protocol;
    }
    return [];
  }
  
  getDescription() {
    return this._data.description || '';
  }
  
  getPlugins() {    
    if (this._data.configs && this._data.configs.plugins) {
      return this._data.configs.plugins;
    }
    
    return [];
  }
}
