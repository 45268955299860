import {DOMAIN_TRAFFIC_ACTIONS, MIXED_VALUE, PLUGIN_DEFAULTS, PLUGIN_NAMES, TAG_NAMES} from '@/utilities/constants';
import {isEmpty, isEqual, omit, startsWith, every} from 'lodash';
import {getPlugin} from '@/utilities/plugin-actions';

export class GroupedRoutes {
  constructor(routes) {
    this._routes = routes || [];
  }

  hasSameDomainAction() {
    let initialComparison;

    for (let r of this._routes) {
      const routeDomainAction = this.getRouteDomainAction(r);
      if (!initialComparison) {
        initialComparison = routeDomainAction;
        continue;
      }

      if (initialComparison !== routeDomainAction) {
        return [false, null];
      }
    }

    return [true, initialComparison];
  }

  hasTheSameUpstreamTargets() {
    let initialComparison;

    function targetsToSet(targets) {
      const s = new Set();
      for (let t of targets) {
        s.add(t.target);
      }
      return s;
    }

    for (let domain of this._routes) {
      if (!initialComparison) {
        initialComparison = targetsToSet(domain.getUpstreamTargets());
        continue;
      }

      const compare = targetsToSet(domain.getUpstreamTargets());
      const areTheSame = isEqual(initialComparison, compare);

      if (!areTheSame) {
        return false;
      }
    }

    return true;
  }

  hasSameServiceProtocol() {
    const initialComparison = this._routes[0].getServiceProtocol();
    
    const isSame = every(this._routes, (v) => {
      return isEqual(v.getServiceProtocol(), initialComparison);
    });
    
    if (isSame) {
      return [true, initialComparison];
    }
    
    return [false, null];
  }

  getRouteDomainAction(route) {
    const plugins = route.getPlugins();

    for (let p of plugins) {
      if (p.name === PLUGIN_NAMES.GAIUS_REDIRECT) {
        return DOMAIN_TRAFFIC_ACTIONS.REDIRECT;
      }
    }

    return DOMAIN_TRAFFIC_ACTIONS.UPSTREAM;
  }

  getCommonAutoGenerateCertificateValue() {
    let initialComparison;

    for (let domain of this._routes) {
      let hasAutoGenerateCertificate = domain.hasAutoGenerateCertificate();

        if (initialComparison === undefined) {
            initialComparison = hasAutoGenerateCertificate;
        } else if (initialComparison !== hasAutoGenerateCertificate) {
            return false;
        }
    }

    return initialComparison;
  }

  hasCommonPlugin(pluginName) {
    let initialComparison = 'x';

    for (let domain of this._routes) {
      const plugins = domain.getPlugins();
      const plugin = getPlugin(plugins, pluginName);
      const compareSafe = omit(plugin, ['id']);

      if (initialComparison === 'x') {
        initialComparison = compareSafe;
        continue;
      }

      if (!isEqual(initialComparison, compareSafe)) {
        return [false, null];
      }
    }

    return [true, initialComparison];
  }

  getOwnerInformationServiceTags() {
    const s = new Set();

    for (let domain of this._routes) {
      const serviceTags = domain.getServiceTags();

      for (const st of serviceTags) {
        if (startsWith(st, 'owner') || startsWith(st, 'cname'))
          s.add(st);
      }
    }

    return Array.from(s);
  }

  /* This does not return the tags related to owner and cname, to get those use `getOwnerInformationServiceTags` instead. */
  getServiceTags() {
    const s = new Set();
    const inverseOfNoChallengeTag = 'no_js_challenge=0';

    for (let domain of this._routes) {
      const serviceTags = domain.getServiceTags();
      let hasANoJsChallengeTag = false;

      for (const st of serviceTags) {
        if (st !== TAG_NAMES.HAS_AUTO_GENERATE_CERTIFICATE)
          s.add(st);

        if (st === TAG_NAMES.NO_JS_CHALLENGE) {
          hasANoJsChallengeTag = true;
        }
      }

      // if no no_js_challenge=1 was detected then we temporarily add a no_js_challenge=0 
      // to identify that the domain does not have no_js_challenge=1
      if (!hasANoJsChallengeTag) {
        s.add(inverseOfNoChallengeTag);
      }
    }

    // this is where we detect if the selection has mixed values for the no_js_challenge tag
    const hasANoJsChallengeTag = s.has(TAG_NAMES.NO_JS_CHALLENGE);
    const hasInverse = s.has(inverseOfNoChallengeTag);
    if (hasANoJsChallengeTag && hasInverse) {
      s.delete(TAG_NAMES.NO_JS_CHALLENGE);
      s.delete(inverseOfNoChallengeTag);

      // remove the =1 and =0 and replace with =mixed
      s.add(TAG_NAMES.NO_JS_CHALLENGE_MIXED);
    } else {
      // if the domain selection has ALL =1 or ALL =0 we remove =0 as it is no a 
      // valid backend value
      s.delete(inverseOfNoChallengeTag);
    }

    const hasAutoGenerateCertificate = this.getCommonAutoGenerateCertificateValue();
    if (hasAutoGenerateCertificate)
    s.add(TAG_NAMES.HAS_AUTO_GENERATE_CERTIFICATE);

    // remove owner information
    const ownerInfoRemoved = Array.from(s).filter((t) => !startsWith(t, 'cname') && !startsWith(t, 'owner'));
    // remove paused=1 because if we add it here the payload would contain it, which would
    // cause all selected domains being edited to be deactivated
    const removedPaused = ownerInfoRemoved.filter((t) => !startsWith(t, 'paused'));
    
    return removedPaused;
  }

  hasSameServicePath() {
    const initialComparison = this._routes[0].getServicePath();
    
    const isSame = every(this._routes, (v) => {
      return isEqual(v.getServicePath(), initialComparison);
    });
    
    if (isSame) {
      return [true, initialComparison];
    }
    
    return [false, null];
  }
  
  getPlugins() {
    const pluginsListToIterate = [
      PLUGIN_NAMES.GAIUS_REDIRECT,
      // advanced configuration plugins
      PLUGIN_NAMES.GAIUS_CUSTOM_HEADER,
      PLUGIN_NAMES.RESPONSE_TRANSFORMER,
      PLUGIN_NAMES.GAIUS_PROXY_CACHE,
      PLUGIN_NAMES.GAIUS_ADVANCE_PROXY_CACHE,
      PLUGIN_NAMES.GAIUS_BAN,
      PLUGIN_NAMES.GAIUS_UPSTREAM_HOST,
      // security settings (GuardConfiguration) plugins
      PLUGIN_NAMES.GAIUS_CHALLENGE,
      PLUGIN_NAMES.GAIUS_REQUEST_LIMIT,
      PLUGIN_NAMES.GAIUS_CROWDSEC_CAPTCHA,
      PLUGIN_NAMES.GAIUS_BLACKRULES,
      PLUGIN_NAMES.GAIUS_SECLINK,
      PLUGIN_NAMES.GAIUS_GEOIP,
      PLUGIN_NAMES.GAIUS_IP_RESTRICTION,
      PLUGIN_NAMES.GAIUS_CUSTOM_ERROR_PAGE,
    ];
    const plugins = [];

    for (let p of pluginsListToIterate) {
      const [isCommon, commonPluginData] = this.hasCommonPlugin(p);
      // only populate if its common and the plugin is not empty. the plugin would be empty if the plugin does not exist
      // for the domain selection. e.g. if domain 1 and domain 2 does not have a plugin, if both plugin does not exist
      // for both plugins then they are both common. so we only populate if its COMMON and the plugin exist.
      if (isCommon && !isEmpty(commonPluginData)) {
        plugins.push({...PLUGIN_DEFAULTS[p], id: MIXED_VALUE, ...commonPluginData});
      } else if (!isCommon) {
        plugins.push({...PLUGIN_DEFAULTS[p], id: MIXED_VALUE, config: MIXED_VALUE});
      }
    }

    return plugins;
  }
}
