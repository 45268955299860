<template>
  <div>
    <a-modal :visible="isVisibleDetail"
             v-if="certDetail"
             id="sslModal"
             :title="$t('domain.DetailSSL')"
             :closable="false"
             :maskClosable="false"
             @cancel="certDetail = null;"
             @ok="certDetail=null"
             width="45%">
      <dl class="row">
        <dt class="col-sm-4">{{ $t('Name') }}</dt><dd class="col-sm-8">{{ certDetail.name }}</dd>
        <dt class="col-sm-4">{{ $t('Type') }}</dt><dd class="col-sm-8">{{ certDetail.type }}</dd>
        <dt class="col-sm-4">{{ $t('SNIS') }}</dt>
        <dd class="col-sm-8">
          <template v-for="sni in certDetail.snis">
            <a-badge :count="sni"
                     :number-style="{
                        backgroundColor: '#fff',
                        color: '#999',
                        boxShadow: '0 0 0 1px #d9d9d9 inset',
                      }"/>
          </template>
        </dd>
        <dt class="col-sm-4" v-if="certDetail.issuer_name.length">{{ $t('SSLIssuer') }}</dt>
          <dd class="col-sm-8" v-if="certDetail.issuer_name.length">{{ certDetail.issuer_name }}</dd>
        <dt class="col-sm-4" v-if="!certDetail.issuer_name.length">{{ $t('SSLIssuer') }}</dt>
          <dd class="col-sm-8" v-if="!certDetail.issuer_name.length" > - </dd>
        <dt class="col-sm-4">{{ $t('IssueAt') }}</dt><dd class="col-sm-8">{{ certDetail.issue_at }}</dd>
        <dt class="col-sm-4">{{ $t('ExpiredAt') }}</dt><dd class="col-sm-8">{{ certDetail.expired_at }}</dd>
      </dl>
    </a-modal>
    <a-modal :visible="isVisible"
             :title="$t('domain.ConfigureSSL')"
             :closable="false"
             :maskClosable="false"
             @cancel="$emit('hide')"
             width="50%">
      <div>
        <div>
          <h5 class="has-icon ml-2 mb-1">
            <a-icon type="lock"/>
            {{ $t('message.SSLConfigurationFor', [domain.getMainHost()]) }}
          </h5>
          <p class="ml-2">{{ $t('message.ConfigureSSLForDomain') }}</p>

          <a-table :columns="domainColumns"
                   :data-source="domainData"
                   :loading="isSslConfigLoading"
                   size="small"
                   :pagination="false"
                   rowKey="name">
            <span slot="status" slot-scope="text, record">
              <span class="has-icon" v-bind:class="{ 'text-danger': record.isErrorState }">
                <a-tooltip v-if="record.isErrorState" :title="$t('message.CertificateNotFoundDescription')">
                  <a-icon type="exclamation-circle" class="mr-1"/>
                  <span>{{ text }}</span>
                </a-tooltip>
                <span v-else>{{ text }}</span>
              </span>
            </span>
            <span slot="action" slot-scope="text, record">
              <a-button @click="generateSshKey(domain.getMainHost())"
                        v-if="record.isErrorState"
                        :loading="sslConfigGeneratingKeyDomains.includes(domain.getMainHost())"
                        :disabled="sslConfigGeneratingKeyDomains.includes(domain.getMainHost()) || isBypassACME"
                        size="small"
                        type="link"
                        class="ml-n2">
                <a-icon v-if="!sslConfigGeneratingKeyDomains.includes(domain.getMainHost())" type="reload"/>
                {{ $t('Generate HTTP') }}
              </a-button>
              <br />
              
              <a-button @click="generateDnsKey(domain.getMainHost())"
                        v-if="record.isErrorState"
                        :loading="dnsConfigGeneratingKeyDomains.includes(domain.getMainHost())"
                        :disabled="dnsConfigGeneratingKeyDomains.includes(domain.getMainHost()) || isBypassACME"
                        size="small"
                        type="link"
                        class="ml-n2"
                        v-c-tooltip="{content: getDNSAlias(domain.getMainHost()), placement: 'top'}">
                <a-icon v-if="!dnsConfigGeneratingKeyDomains.includes(domain.getMainHost())" type="reload"/>
                {{ $t('Generate DNS') }}
              </a-button>             
              <br />
              <a-button v-if="shouldShowCertificateUploadButton(domain.getMainHost())"
                        :loading="sslConfigUploadCertDomains.includes(domain.getMainHost())"
                        :disabled="sslConfigUploadCertDomains.includes(domain.getMainHost())"
                        @click="showUploadCertModal(domain.getMainHost())"
                        size="small"
                        type="link"
                        class="ml-n2">
                <a-icon type="upload"/>
                {{ $t('Upload') }}
              </a-button>
              <a-button size="small"
                        v-if="shouldShowCertificateInfo(domain.getMainHost())"
                        @click="showDetailCertModal(domain.getMainHost())"
                        type="link"
                        class="ml-n2">
                <a-icon type="info-circle"/>
                {{ $t('Detail') }}
              </a-button>
              <a-popconfirm
                  v-if="shouldShowCertificateRemoveButton(domain.getMainHost())"
                  :title="$t('message.ConfirmCertificateRemove')"
                  :ok-text="$t('Yes')"
                  :cancel-text="$t('No')"
                  @confirm="removeCertificateForDomain(domain.getMainHost())"
              >
                <a-button
                    :loading="deletingDomain === domain.getMainHost()"
                    size="small"
                    type="link"
                    class="ml-n2">
                    <span v-if="deletingDomain === domain.getMainHost()"/>
                    <a-icon v-else type="delete"/>
                  {{ $t('cert.RemoveCert') }}
                </a-button>
              </a-popconfirm>
            </span>
          </a-table>
        </div>

        <!--      <CRow v-if="cert && cert.pk === domain.sslConfig.cert_id">-->
        <!--        <CCol lg="12">-->
        <!--          <div class="cert-information">-->
        <!--            <h6>-->
        <!--              <CIcon name="cil-file"/>-->
        <!--              {{ $t('cert.Information') }}-->
        <!--            </h6>-->
        <!--            <CertInformation :cert="cert"/>-->
        <!--          </div>-->
        <!--        </CCol>-->
        <!--      </CRow>-->

        <div class="mt-5">
          <h6 class="has-icon ml-2 mb-1">
            <a-icon type="lock"/>
            {{ $t('message.ShadowDomainCertificates') }}
          </h6>
          <p v-if="domain.getShadows().length" class="ml-2">{{ $t('message.ConfigureShadowDomainSSL') }}</p>

          <div v-if="domain.getShadows().length">
            <a-table :columns="shadowDomainsTableColumns"
                     :data-source="shadows"
                     :loading="isSslConfigLoading"
                     size="small"
                     rowKey="name"
                     :pagination="{ 'pageSize': 5 }">
              <div
                  slot="filterDropdown"
                  slot-scope="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }"
                  style="padding: 8px"
              >
                <a-input
                    v-ant-ref="c => (searchInput = c)"
                    :placeholder="`Search ${column.dataIndex}`"
                    :value="selectedKeys[0]"
                    style="width: 188px; margin-bottom: 8px; display: block;"
                    @change="e => setSelectedKeys(e.target.value ? [e.target.value] : [])"
                    @pressEnter="() => confirm()"
                />
                <a-button
                    type="primary"
                    icon="search"
                    size="small"
                    style="width: 90px; margin-right: 8px"
                    @click="() => confirm()"
                >
                  {{ $t('Search') }}
                </a-button>
                <a-button size="small" style="width: 90px" @click="() => clearFilters()">
                  {{ $t('Reset') }}
                </a-button>
              </div>
              <span slot="status" slot-scope="text, record">
                <span class="has-icon" v-bind:class="{ 'text-danger': record.isErrorState }">
                  <a-tooltip v-if="record.isErrorState" :title="$t('message.CertificateNotFoundDescription')">
                    <a-icon type="exclamation-circle" class="mr-1"/>
                    <span>{{ text }}</span>
                  </a-tooltip>
                  <a-tooltip v-else-if="record.isAcmeCertificate"
                             :title="$t('message.HTTPSEnabledAcmeCertificateDescription')">
                    <a-icon type="info-circle" class="mr-1"/>
                    <span>{{ text }}</span>
                  </a-tooltip>
                  <span v-else>{{ text }}</span>
                </span>
              </span>
              <span slot="action" slot-scope="text, record">
                <a-button @click="generateSshKey(record.name)"
                          v-if="record.isErrorState"
                          :loading="sslConfigGeneratingKeyDomains.includes(record.name)"
                          :disabled="sslConfigGeneratingKeyDomains.includes(record.name) || isBypassACME"
                          size="small"
                          type="link"
                          class="ml-n2">
                  <a-icon v-if="!sslConfigGeneratingKeyDomains.includes(record.name)" type="reload"/>
                  {{ $t('Generate HTTP') }}
                </a-button>
                <br />
                <a-button @click="generateDnsKey(record.name)"
                          v-if="record.isErrorState"
                          :loading="dnsConfigGeneratingKeyDomains.includes(record.name)"
                          :disabled="dnsConfigGeneratingKeyDomains.includes(record.name) || isBypassACME"
                          size="small"
                          type="link"
                          class="ml-n2"
                          v-c-tooltip="{content: getDNSAlias(record.name), placement: 'top'}">
                  <a-icon v-if="!dnsConfigGeneratingKeyDomains.includes(record.name)" type="reload"/>
                  {{ $t('Generate DNS') }}
                </a-button>                 
                <br />
                <a-button v-if="shouldShowCertificateUploadButton(record.name)"
                          :loading="sslConfigUploadCertDomains.includes(record.name)"
                          :disabled="sslConfigUploadCertDomains.includes(record.name)"
                          @click="showUploadCertModal(record.name)"
                          size="small"
                          type="link"
                          class="ml-n2">
                  <a-icon type="upload"/>
                  {{ $t('Upload') }}
                  
                </a-button>
                <a-button size="small"
                          v-if="shouldShowCertificateInfo(record.name)"
                          @click="showDetailCertModal(record.name)"
                          type="link"
                          class="ml-n2">
                  <a-icon type="info-circle"/>
                  {{ $t('Detail') }}
                </a-button>
                <a-popconfirm
                    v-if="shouldShowCertificateRemoveButton(record.name)"
                    :title="$t('message.ConfirmCertificateRemove')"
                    :ok-text="$t('Yes')"
                    :cancel-text="$t('No')"
                    @confirm="removeCertificateForDomain(record.name)"
                >
                  <a-button
                      :loading="deletingDomain === record.name"
                      size="small"
                      type="link"
                      class="ml-n2">
                    <span v-if="deletingDomain === record.name"/>
                    <a-icon v-else type="delete"/>
                    {{ $t('cert.RemoveCert') }}
                  </a-button>
                </a-popconfirm>
              </span>
            </a-table>
          </div>
          <SimpleEmpty v-else>
            <span slot="description">{{ $t('message.NoConfiguredShadowDomains', [domain.getMainHost()]) }}</span>
          </SimpleEmpty>
        </div>

        <a-divider/>

        <div>
          <h6 class="has-icon ml-2 mb-1">
            <a-icon type="lock"/>
            {{ $t('message.ConfirmHttpsOnly') }}
          </h6>
          <p class="ml-2">{{ $t('message.ConfirmHttpsOnlyDescription') }}</p>

          <div class="ml-2">
            <a-popconfirm
                :title="confirmForceHttpsMessage"
                :ok-text="$t('Yes')"
                :cancel-text="$t('No')" 
                @confirm="proceedToggleForceHttps"
            >
              <a-checkbox :checked="isForceHttps" :disabled="forceHttpsDisabledState">
                {{ $t('message.HTTPSOnly') }}
              </a-checkbox>
            </a-popconfirm>
          </div>
        </div>
      </div>

      <template slot="footer">
        <footer>
          <a-button-group>
            <a-button @click="$emit('hide')">
              {{ $t('Close') }}
            </a-button>
          </a-button-group>
        </footer>
      </template>
    </a-modal>

    <a-modal :visible="addCertModalVisible" :title="addCertModalTitle" :closable="false">
      <a-alert v-if="duplicateSniError"
               :message="duplicateSniError"
               type="error"
               :banner="true"
               class="mb-2"/>

      <a-form-item :label="$t('Certificate')">
        <a-alert v-if="hasUploadCertFieldError('cert')"
                 :message="uploadCertFieldErrors.cert"
                 type="error"
                 :banner="true"
                 class="mb-2"/>

        <a-textarea v-model="formData.certificate" placeholder="-----BEGIN CERTIFICATE-----
MIIEpAIBAAK....
-----END CERTIFICATE-----" style="height: 160px;"/>
      </a-form-item>

      <a-form-item :label="$t('Key')">
        <a-alert v-if="hasUploadCertFieldError('key')"
                 :message="uploadCertFieldErrors.key"
                 type="error"
                 :banner="true"
                 class="mb-2"/>

        <a-textarea v-model="formData.key" placeholder="-----BEGIN RSA PRIVATE KEY-----
MIIEpAIBAAK....
-----END RSA PRIVATE KEY-----" style="height: 160px;"/>
      </a-form-item>

      <template slot="footer">
        <footer>          
          <a-button @click="hideUploadCertModal">{{ $t('Close') }}</a-button>
          <a-button type="primary" @click="uploadCert()" :loading="isCertModalSaving">
            <a-icon v-if="!isCertModalSaving" type="upload"/>
            {{ $t('Upload') }}
          </a-button>
        </footer>
      </template>
    </a-modal>
  </div>
</template>

<style scoped>
dt {
  font-weight: bold;
}
</style>

<script>
import axios from "@/plugins/axios.js";

import {Websocket} from "@/plugins/websocket.js";
import {
  deleteRouteCertificate,
  getDomain,
  getRouteCertificates,
  updateForceSslConfig,
  uploadDomainCertificate,
  generateDomainSshKey
} from '@/utilities/api';
import {CertificateAction} from "@/utilities/websocket";
import SimpleEmpty from '@/views/domain/SimpleEmpty';
import {MODAL_STATES, PLUGIN_NAMES} from '@/utilities/constants';


export default {
  name: "SslConfig",
  components: {SimpleEmpty},
  watch: {
    isVisible: async function () {
      if (this.isVisible) {
        this.refresh();
        this.attachCertificateWSHandler();
      }
    },
  },

  data() {
    return {
      formData: {
        certificate: '',
        key: '',
      },
      isForceHttps: false,
      savingForceHttps: false,
      config: {},
      cert: null,
      renderConfig: 1,
      errors: [],
      saving: false,
      uploadDomainCert: null,
      sslConfigModalState: MODAL_STATES.NONE,
      dnsConfigModalState: MODAL_STATES.NONE,
      // TODO: Better naming instead of specific ConfigGeneratingState ? 
      sslConfigGeneratingKeyState: MODAL_STATES.NONE,
      dnsConfigGeneratingKeyState: MODAL_STATES.NONE,
      sslConfigGeneratingKeyDomains: [],
      dnsConfigGeneratingKeyDomains: [],
      sslConfigUploadCertDomains: [],
      dnsConfigUploadCertDomains: [],
      domainsWithCertificates: {},
      wildcardCerts: [],
      uploadCertModalState: MODAL_STATES.NONE,
      uploadCertForDomain: null,
      uploadCertFieldErrors: null,
      deletingDomain: null,
      isVisibleDetail: false,
      certDetail: null,
    };
  },
  props: {
    domain: Object,
    is_root: Boolean,
    isVisible: Boolean,
    sslLoader: Boolean,
  },
  computed: {
    hasCertificate() {
      return (Object.keys(this.domainsWithCertificates)).length > 0;
    },
    issue_ws() {
      return this.$store.state.websocket.issue_ws;
    },
    domainColumns: function () {
      return [
        {
          dataIndex: 'name',
          key: 'name',
          title: this.$t('Domain'),
        },
        {
          title: this.$t('domain.Status'),
          key: 'status',
          dataIndex: 'status',
          scopedSlots: {customRender: 'status'},
        },
        {
          title: this.$t('Action'),
          key: 'action',
          scopedSlots: {customRender: 'action'},
        },
      ];
    },
    domainData: function () {
      // since we are showing the domain in a table, we need to make the data as an array
      return [{
        name: this.domain.getMainHost(),
        ...this.getSslStatus(this.domain.getMainHost()),
      }]
    },
    shadowDomainsTableColumns: function () {
      return [
        {
          dataIndex: 'name',
          key: 'name',
          title: this.$t('domain.ShadowDomain'),
          scopedSlots: {
            filterDropdown: 'filterDropdown',
          },
          onFilter: function (value, record) {
            return record.name
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase())
          },
          onFilterDropdownVisibleChange: visible => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              }, 0);
            }
          }
        },
        {
          title: this.$t('domain.Status'),
          key: 'status',
          dataIndex: 'status',
          scopedSlots: {customRender: 'status'},
        },
        {
          title: this.$t('Action'),
          key: 'action',
          scopedSlots: {customRender: 'action'},
        },
      ];
    },
    shadows: function () {
      return this.domain.getShadows().map((s) => {
        const status = this.getSslStatus(s);

        return {
          name: s,
          ...status
        }
      });
    },
    isBypassACME: function() {
      const plugins = this.domain.getPlugins();

      for (let p of plugins) {
        if (p.name === PLUGIN_NAMES.GAIUS_CONFIG) {
          return p.config.is_bypass_acme
        }
      }
      return false;
    },
    addCertModalVisible: function () {
      return this.uploadCertModalState !== MODAL_STATES.NONE;
    },
    isCertModalSaving: function () {
      return this.uploadCertModalState === MODAL_STATES.SAVING;
    },
    addCertModalTitle: function () {
      return `${this.$t('cert.UploadCertificate')}: ${this.uploadCertForDomain}`;
    },
    duplicateSniError: function () {
      if (!this.hasUploadCertFieldError('snis'))
        return false;

      const error = this.uploadCertFieldErrors.snis;
      const isDuplicateError = error.indexOf('already associated with existing certificate') === -1;
      if (isDuplicateError)
        return false;

      return this.$t('message.DuplicateCertificateSNI');
    },
    isSslConfigLoading: function () {
      return this.sslConfigModalState === MODAL_STATES.LOADING || this.sslLoader;
    },
    isSslConfigGeneratingKey: function () {
      return this.sslConfigGeneratingKeyState === MODAL_STATES.LOADING || this.sslLoader;
    },
    isSslConfigGeneratingKeyOnDomain: function (domainName) {
      return this.sslConfigGeneratingKeyDomains.includes(domainName);
    },

    isDnsConfigLoading: function () {
      return this.dnsConfigModalState === MODAL_STATES.LOADING || this.sslLoader;
    },
    isDnsConfigGeneratingKey: function () {
      return this.dnsConfigGeneratingKeyState === MODAL_STATES.LOADING || this.sslLoader;
    },
    isDnsConfigGeneratingKeyOnDomain: function (domainName) {
      return this.dnsConfigGeneratingKeyDomains.includes(domainName);
    },

    isSslConfigUploadingCert: function () {
      return this.uploadCertModalState === MODAL_STATES.LOADING || this.sslLoader;
    },
    isSslConfigUploadingCertOnDomain: function (domainName) {
      return this.sslConfigUploadCertDomains.includes(domainName);
    },
    isSslConfigUploadingCertShadowDomain: function () {
      return this.uploadCertModalState === MODAL_STATES.LOADING || this.sslLoader;
    },
    isSslConfigUploadingCertOnShadowDomains: function (domainName) {
      return this.sslConfigUploadCertDomains.includes(domainName);
    },
    confirmForceHttpsMessage: function () {
      // if force https is current enabled, the message should be that force https will be disabled
      if (this.isForceHttps) {
        return this.$t('message.ConfirmForceHTTPSDisable');
      }

      // else, the message should be force https will be enabled
      return this.$t('message.ConfirmForceHTTPSEnable');
    },
    forceHttpsDisabledState: function () {
      return this.savingForceHttps || this.sslConfigModalState === MODAL_STATES.LOADING || this.sslLoader;
    },
  },
  async mounted() {
    //await this.openWs();
  },
  async beforeDestroy() {
    //this.closeWs()
  },
  methods: {
    getDNSAlias(domain) {
      domain = domain.replaceAll('*.', '');
      const aliasChallengeDomain = `_acme-challenge.${domain}`;
      const cname = this.domain.getCname();
      let aliasChallengeAlias = '';
      if (cname && domain) {
        aliasChallengeAlias = `_acme-challenge.${domain.replaceAll('.', '-')}.${cname.replaceAll('.', '-')}.5vp.net`;
      }
      return this.$t('cert.DNSAliasInstruction', {acme_challenge_alias: aliasChallengeAlias, acme_challenge_domain: aliasChallengeDomain})
    },
    async attachCertificateWSHandler() {
      var startTime = performance.now();
      this.issue_ws.onMessage = function (event, result) {        
        let domain = null;
        if (result.data.hasOwnProperty('domain')) {
          domain = result.data.domain;
        }

        if (result.data.hasOwnProperty('error')) {
          result.data.error = result.data.error.replaceAll("*.", "")
          if (result.data.error.includes("doesn't match")) {
            this.notifyError(result.data.error.split('hostname ')[1].replace('",)', ''));
          } else {
            this.notifyError(result.data.error);
          }
          this.removeSshKeyGenerationStatus(domain);
          return;
        }

        if (result.action === CertificateAction.GENERATE_CERT) {
          this.removeSshKeyGenerationStatus(domain);
          this.refresh();
          var endTime = performance.now();
          this.notifySuccess(this.$t('CertTimeInterval',{message: result.data.message, min: Math.round((endTime - startTime)/60000)}));
        }
        
        if (result.action === CertificateAction.UPLOAD_CERT) {
          this.clearUploadCertGenerationStatus();
          this.refresh();
          this.notifySuccess(result.data.message);
        }
      }.bind(this)
    },
    async attachCertificateWSLatestHandler() {
      this.certificate_ws_latest.onMessage = function (event, result) {        
        if (result.data.hasOwnProperty('error')) {

          if (result.data.error.includes("doesn't match")) {
            this.notifyError(result.data.error.split('hostname ')[1].replace('",)', ''));
          } else {
            this.notifyError(this.$t('message.ErrorCertificateGeneration'));
          }
          this.clearUploadCertGenerationStatus();
          return;
        }

        if (result.action === CertificateAction.GENERATE_CERT) {
          this.clearSshKeyGenerationStatus();
          this.refresh();
          this.notifySuccess(result.data.message);
        }
        
        if (result.action === CertificateAction.UPLOAD_CERT) {
          this.clearUploadCertGenerationStatus();
          this.refresh();
          this.notifySuccess(result.data.message);
        }
      }.bind(this)
    },    
    async refresh() {
      this.sslConfigModalState = MODAL_STATES.LOADING;
      const response = await getRouteCertificates(this.domain.getId());
      this.sslConfigModalState = MODAL_STATES.IDLE;

      const certificates = response.data.certificates;
      this.isForceHttps = response.data.force_https;

      const wildcards = [];
      const domainsWithCertificates = {};
      for (const c of certificates) {
        for (const sni of c.snis) {
          if (sni === null ) {
            continue;
          }
          const isWildcard = sni.indexOf('*') > -1;
          if (isWildcard) {
            wildcards.push(c);
          } else {
            // if there is a certificate in Kong and Acme plugin, the certificate in Kong will take precedence.
            // otherwise set the certificate in domainsWithCertificates
            if (domainsWithCertificates[sni] && this.isKongManagedCertificate(domainsWithCertificates[sni])) {
              // noop, do not override certificate 
            } else {
              domainsWithCertificates[sni] = c;
            }
          }
        }
      }

      this.domainsWithCertificates = domainsWithCertificates;
      this.wildcardCerts = wildcards;
      this.$emit('refreshListDomain');

    },
    async generateSshKey(domainName) {

      if (domainName.length >= 64) {
        this.notifyError(this.$t('message.UseDifferentCertMethod'));
        return;
      }
      this.notifyInfo(this.$t('message.GenerateHTTPInfo'));
      this.sslConfigGeneratingKeyState = MODAL_STATES.NONE;
      this.sslConfigGeneratingKeyDomains.push(domainName);

      this.issue_ws.sendMessage(CertificateAction.GENERATE_CERT, {domain: domainName, cert_type: "HTTP"});
    },
    removeSshKeyGenerationStatus(domainName) {
      if (this.sslConfigGeneratingKeyDomains.length > 0) {
        this.sslConfigGeneratingKeyDomains = this.sslConfigGeneratingKeyDomains.filter(item => item !== domainName);
      } else {
        this.clearSshKeyGenerationStatus();
      }
      if (this.dnsConfigGeneratingKeyDomains.length > 0) {
        this.dnsConfigGeneratingKeyDomains = this.dnsConfigGeneratingKeyDomains.filter(item => item != domainName); 
      } else {
        this.clearDnsKeyGenerationStatus();
      }
    },
    clearSshKeyGenerationStatus() {
      this.sslConfigGeneratingKeyState = MODAL_STATES.NONE;
      this.sslConfigGeneratingKeyDomains = [];
    },

    async generateDnsKey(domainName) {
      this.notifyInfo(this.$t('message.GenerateDnsInfo'));
      this.dnsConfigGeneratingKeyState = MODAL_STATES.NONE;
      this.dnsConfigGeneratingKeyDomains.push(domainName);
      this.issue_ws.sendMessage(CertificateAction.GENERATE_CERT, {domain: domainName, cert_type: "DNS"});
    },
    clearDnsKeyGenerationStatus() {
      this.dnsConfigGeneratingKeyState = MODAL_STATES.NONE;
      this.dnsConfigGeneratingKeyDomains = [];
    },

    showUploadCertModal(domainName) {
      
      this.uploadCertForDomain = domainName;
      this.uploadCertModalState = MODAL_STATES.LOADING;
    },
    hideUploadCertModal() {
      this.formData.certificate = '';
      this.formData.key = '';
      this.uploadCertForDomain = null;
      this.uploadCertModalState = MODAL_STATES.NONE;
    },
    isViewedCert(domain) {
      if (this.cert && this.cert.name === domain.name) {
        return true;
      }
      return false;
    },
    toggleCertView(domain) {
      if (this.isViewedCert(domain)) {
        this.cert = null;
      } else {
        this.fetchCert(domain.sslConfig);
      }
    },
    showUploadDomainCert(uploadDomainCert) {
      this.uploadDomainCert = uploadDomainCert;
    },
    activeSslConfig(dom) {
      this.certType = ""
      this.fetchCert(dom.sslConfig)
    },
    fetchCert(sslConfig) {
      if (sslConfig.cert_id) {
        axios({
          method: "GET",
          url: `cert/issue/{cert}/`,
          urlParams: {
            cert: sslConfig.cert_id
          }
        })
            .then(async (response) => {
              this.cert = response.data;
            })
            .catch(error => console.log(error));
      }
    },
    getIssueState(tasklists) {
      axios
          .post("domain/tasks/status/", {
            tasks: tasklists
          })
          .then(async (response) => {
            this.issueTasks = response.data;
            if (this.issueTaskState == "SUCCESS") {
              clearInterval(this.issuePolling)
              this.issuePolling = null;
              //const forceSSL = this.config.forceSSL;
              /**
               * When task has finished, the cert status is saved.
               * So fetch the status from server again.
               */
              let responseDomain;
              try {
                responseDomain = await getDomain(this.domain.name);
              } catch (errors) {
                errors.forEach((message) => {
                  this.flash(message, 'error', {"timeout": 15000});
                });
                return;
              }
              this.domain = responseDomain.data;
              this.$forceUpdate();

              this.generateStatus(this.domain, false);

              //var cert_id = 1
              // restore sslConfig.forceSSL
              // wrapped in setTimeout to make it be the end of the event loop, hopefully
              // Vue has completed executing its reconciliation
              // setTimeout(() => {
              //     this.domain.sslConfig.forceSSL = forceSSL;
              //     this.domain.sslConfig.cert_id = cert_id;
              // }, 1);
            } else if (this.issueTaskState == "FAILURE") {
              clearInterval(this.issuePolling)
              this.issuePolling = null;

              this.flash(this.$t("Issue cert error"), 'error', {"timeout": 15000});

              this.generateStatus(this.domain, false);
            }
          })
          .catch(error => console.log(error));
    },
    updateIssueState(tasklists) {
      clearInterval(this.issuePolling)
      this.issuePolling = null;
      this.issuePolling = setInterval(() => {
        this.getIssueState(tasklists);
      }, 5000);
    },

    switchEnable(val) {
      if (val == false) {
        this.certType = ""
      }
    },
    clickCertType(domain, certType) {
      this.renderConfig += 1;
      this.certType = certType
      domain.sslConfig.cert_type = certType;
    },
    clickRemoveCert(domain) {
      this.renderConfig + 1;
      domain.sslConfig = {
        'enabled': false,
        'forceSSL': false,
        'privateKey': '',
        'cert': '',
        'cert_id': null,
        'issue_state': null,
        'cert_type': null
      };
    },
    generateStatus(domain, active = true) {
      this.generatingDomainCert = domain;
      this.generationActive = active;
    },
    async uploadCert() {
      this.uploadCertModalState = MODAL_STATES.SAVING;
      this.sslConfigUploadCertDomains.push(this.uploadCertForDomain);

      this.issue_ws.sendMessage(CertificateAction.UPLOAD_CERT, {
          domain: this.uploadCertForDomain,
          cert: this.formData.certificate,
          key: this.formData.key,
      });

    },
    clearUploadCertGenerationStatus() {
      this.uploadCertModalState = MODAL_STATES.NONE;
      this.sslConfigUploadCertDomains = [];
    },
    patchShadowConfig(shadow) {
      axios({
        method: "PATCH",
        url: `domain/shadow/{shadow}/`,
        data: {
          sslConfig: shadow.sslConfig,
        },
        urlParams: {
          shadow: shadow.pk
        }
      })
    },
    patchConfig(auto_close = true) {
      if (this.saving)
        return;

      if (this.domain.sslConfig.cert_type === null) {
        this.domain.sslConfig.enabled = false;
        this.domain.sslConfig.forceSSL = false
        this.domain.sslConfig.HTTP2 = true
      } else {
        this.domain.sslConfig.enabled = true;

        if (!this.domain.sslConfig.cert_id) {
          this.flash(this.$t('message.ssl_not_configured_with_cert'), "error", {"timeout": 3000});
          this.domain.sslConfig.enabled = false
          return
        }
      }

      this.saving = true;
      this.domain.getShadows().forEach(item => {
        if (item.sslConfig.hasOwnProperty('enabled') && item.sslConfig.enabled) {
          item.sslConfig['forceSSL'] = this.domain.sslConfig.forceSSL;
        }
      })

      axios({
        method: "POST",
        url: `domain/{domain}/config/`,
        data: {
          section: "sslConfig",
          config: this.domain.sslConfig,
          shadows: this.domain.getShadows()
        },
        urlParams: {
          domain: this.domain.name
        }
      })
          .then(response => {
            this.saving = false;

            this.flash(this.$t("action.success"), "success", {timeout: 3000});
            if (auto_close) {
              this.$emit("hide");
            }
          })
          .catch(({errors}) => {
            this.saving = false;

            errors.forEach((message) => {
              this.flash(message, 'error', {"timeout": 5000});
            });
          });
    },
    shouldShowCertificateInfo(host) {
      const certificate = this.getMatchingCertificate(host);
      if (certificate) {
        return true;
      }
      return false;
    },
    shouldShowCertificateUploadButton(host) {
      const certificate = this.getMatchingCertificate(host);
      if (certificate) {
        // if certificate is in acme (automatically generated) we allow the user to upload their own cert
        /* if (this.isAcmeManagedCertificate(certificate)) { */
          /* return true; */
        /* } */

        // if certificate is not in acme, then it would be a Kong stored certificate. hide the upload
        // button if this is the case. the user will need to remove the cert first if they want
        // to replace it with a new one.
        return false;
      }

      // there is no certificate yet, yes let the user upload a custom cert
      return true;
    },

    shouldShowCertificateRemoveButton(host) {
      const certificate = this.getMatchingCertificate(host);
      // there is no certificate. nothing to remove.
      if (!certificate)
        return false;


      // const isKongManaged = this.isKongManagedCertificate(certificate);

      // users are not allowed to remove wildcard certs because it may have been
      // uploaded by support staff. there is no way to upload a wildcard cert for now.
      /* if (isKongManaged && this.isWildcardCertificate(certificate)) { */
        /* return false; */
      /* } */

      // return isKongManaged;
      return Boolean(certificate);
    },
    isKongManagedCertificate(certificate) {
      // if id is GUID then it is managed by Kong
      // if id starts with kong_acme then it is managed by ACME plugin so here
      // if indexOf returned -1 for kong_acme then it is a GUID
      return certificate.id.indexOf('kong_acme') === -1;
    },
    isAcmeManagedCertificate(certificate) {
      return !this.isKongManagedCertificate(certificate);
    },
    isWildcardCertificate(certificate) {
      for (let sni of certificate.snis) {
        if (sni.indexOf('*') > -1) {
          return true;
        }
      }

      return false;
    },
    getMatchingCertificate(host) {
      if (this.domainsWithCertificates[host])
        return this.domainsWithCertificates[host];

      for (let c of this.wildcardCerts) {
        for (let sni of c.snis) {
          let pattern = sni.replace('*.', '[^.]+.')
          pattern = `^${pattern}$`

          if (sni.match(pattern)) {
            return c;
          }
        }
      }

      return null;
    },
    getSslStatus(host) {
      // we are still fetching certificate information
      if (this.sslConfigModalState === MODAL_STATES.LOADING) {
        return '-';
      }

      let status = this.$t('message.HTTPSNotEnabledForDomain');
      let errorState = false;
      let isAcmeCertificate = false;

      // const hasHttps = this.domain.getProtocols().indexOf('https') > -1;
      const certificate = this.getMatchingCertificate(host);

      if (certificate) {
        status = this.$t('HTTPSEnabled');
        isAcmeCertificate = this.isAcmeManagedCertificate(certificate);
      } else {
        status = this.$t('message.CertificateNotFound');
        errorState = true;
      }

      return {
        status,
        isAcmeCertificate,
        isErrorState: errorState,
      };
    },
    handleShadowSearchReset(clearFilters) {
      clearFilters();
    },
    hasUploadCertFieldError(field) {
      if (!this.uploadCertFieldErrors)
        return false;

      return Boolean(this.uploadCertFieldErrors[field]);
    },
    showDetailCertModal(domain) {
      const moment = require('moment-timezone');
      const certificate = this.getMatchingCertificate(domain);
      /* if (this.isWildcardCertificate(certificate)) { */
        /* return false; */
      /* } */
      this.certDetail = certificate;
      this.certDetail['name'] = domain;
      this.certDetail['issue_at'] = moment.tz(this.certDetail['issue_at'], moment.tz.guess()).format("YYYY-MM-DD HH:mm:ss")
      this.certDetail['expired_at'] = moment.tz(this.certDetail['expired_at'], moment.tz.guess()).format("YYYY-MM-DD HH:mm:ss")
      this.isVisibleDetail = true;
    },
    removeCertificateForDomain(domain) {
      const certificate = this.getMatchingCertificate(domain);

      // no deletion of wildcard cert for now because users can't upload them anyway
      // only way to upload wildcard cert for now is to use Konga by the support staff
      /* if (this.isWildcardCertificate(certificate)) { */
        /* return false; */
      /* } */

      // we cannot remove certificate not managed by Kong
      /* if (!this.isKongManagedCertificate(certificate)) { */
        /* return false; */
      /* } */

      this.deletingDomain = domain;

      deleteRouteCertificate(this.domain.getId(), {certificateId: certificate.id})
          .then((response) => {
            this.refresh();

            this.notifySuccess(this.$t('message.CertificateDeleted'));
          })
          .catch((error) => {
            if (error.response && error.response.data.detail) {
              this.notifyError(this.$t('action.fail_with_msg', {msg: error.response.data.detail}));
            } else {
              this.notifyError(this.$t('action.fail'));
            }
          })
          .finally(() => {
            this.deletingDomain = null;
          });
    },
    async proceedToggleForceHttps() {
      let toggleTo = !this.isForceHttps;
      this.savingForceHttps = true;

      if (!this.hasCertificate && toggleTo) {
        this.notifyError(this.$t('message.SSLForceEnableUnavailabel'));
        return;
      }

      // since the checkbox does not have a loading animation. we prompt a message to the user
      // that something is happening
      this.$message.info(this.$t('message.SavingPleaseWait'));

      let response;
      try {
        response = await updateForceSslConfig(this.domain.getId(), {
          forceHttps: toggleTo
        });
      } catch (error) {
        if (error.response && error.response.data.detail) {
          this.notifyError(this.$t('action.fail_with_msg', {msg: error.response.data.detail}));
        } else {
          this.notifyError(this.$t('action.fail'));
        }
      } finally {
        this.savingForceHttps = false;
      }

      if (toggleTo) {
        this.notifySuccess(this.$t('message.DomainForceHTTPSEnabled'));
      } else {
        this.notifySuccess(this.$t('message.DomainForceHTTPSDisabled'));
      }

      // proactively set isForceHttps so the UI reflects the immediate change of the user.
      // if we do not do this the checkbox will not reflect that actual change, "refresh" method needs
      // to finish before the checkbox will reflect the change from the user.
      this.isForceHttps = toggleTo;

      this.refresh();
    }
  }
};
</script>
