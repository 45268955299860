<template>
  <div class="simple-empty-wrapper">
    <a-empty :image="simpleImage">
      <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope">
        <slot :name="slot" v-bind="scope"/>
      </template>
    </a-empty>
  </div>
</template>

<style scoped>
  .simple-empty-wrapper {
    border-radius: 0.25rem;
    border: 1px solid #d8dbe0;
  }
</style>

<script>
import {Empty} from 'ant-design-vue';

export default {
  beforeCreate() {
    this.simpleImage = Empty.PRESENTED_IMAGE_SIMPLE;
  }
}
</script>