<template>
  <a-modal :visible="clearCacheModal" :centered="true"
           :title="clearCacheTitle"
           @cancel="handleCloseModal"
           @ok="handleOk"
           :closable="false"
           :width="modalWidth">

    <div class="w-100 display-flex">
        <div @click="selectClearCacheType('clear_full_cache')" class="display-inline">
            <input 
                id="clear_full_cache"
                name="select-cache"
                type="radio"
                value="clear_full_cache"
                :checked="!showClearCustomCacheFields">
            <label class="pl-1"><b>{{ $t('ClearFullCache') }}</b></label>
        </div>
        
        <div @click="selectClearCacheType('clear_custom_cache')" class="display-inline">
            <input 
                id="clear_custom_cache" 
                name="select-cache" 
                type="radio" 
                value="clear_custom_cache"
                :checked="showClearCustomCacheFields">
            <label class="pl-1"><b>{{ $t('ClearCustomCache') }}</b></label>
        </div>
    </div>
    
    <div v-if="showClearCustomCacheFields">
        <br />
        <p> Please select clear custom cache type </p>
        <div @click="clearCacheCustomType('purge_by_url')">
            <input
                type="radio"
                name="clear-cache-custom-type"            
                class="w-30"
                :checked="selectedClearCacheCustomType == 'purge_by_url'"/>
            <span> Purge by URL </span>
        </div>
        <div @click="clearCacheCustomType('purge_by_domain')">
            <input
                type="radio"
                name="clear-cache-custom-type"
                class="w-30"
                :checked="selectedClearCacheCustomType == 'purge_by_domain'"/>
            <span> Purge by Domain </span>
        </div>
        <div @click="clearCacheCustomType('purge_by_prefix')">
            <input
                type="radio"
                name="clear-cache-custom-type"
                class="w-30"
                :checked="selectedClearCacheCustomType == 'purge_by_prefix'"/>
            <span> Purge by Prefix </span>
        </div>
        
        <p>            
            <b v-if="selectedClearCacheCustomType">Example:</b><br />
            <b>
                <span v-if="selectedClearCacheCustomType == 'purge_by_url'">https://www.abc.com/images/abc.jpg</span>
                <span v-if="selectedClearCacheCustomType == 'purge_by_domain'">https://www.abc.com</span>
                <span v-if="selectedClearCacheCustomType == 'purge_by_prefix'">https://www.abc.com/images/*</span>
            </b>
        </p>
        <textarea
            id="custom_cache_values"
            class="w-100"
            v-model="clearCacheValues" />
        <span>Separate URL(s) one per line.</span><br />
    </div>
  </a-modal>
</template>

<script>
import validUrl from 'valid-url';
import {SCREEN_TYPES, SCREEN_SIZES} from '@/utilities/constants';
import {EventBus} from "@/plugins/event-bus.js";

export default {
  name: "ClearCache",
  components: {},
  data() {
    return {      
      screenSize: SCREEN_TYPES.LG,
      isLoading: true,
      showClearCustomCacheFields: false,
      selectedClearCacheCustomType: String,
      clearCacheValues: "",      
    };
  },
  computed: {
    modalWidth() {
      if( this.screenSize === SCREEN_TYPES.XS ){
          return '100%';
      }

      if( this.screenSize === SCREEN_TYPES.SM){
        return '75%';
      }

      if( this.screenSize === SCREEN_TYPES.MD ){
        return '65%';
      }

      return "50%";
    },
  },
  watch: {
    
  },
  props: {
    clearCacheModal: Boolean,
    clearCacheTitle: String,    
  },
  methods: {
    handleOk() {        
        if (!this.showClearCustomCacheFields) {
          EventBus.$emit('clearFullCacheEvent');  
        } else {
          if (!this.selectedClearCacheCustomType) {
            this.notifyError(this.$t('domain.selectPurgeCacheType'));
            return;
          }
          const values = this.clearCacheValues.split('\n');
          const data = []
          for (var i=0; i < values.length; i ++) {
            const path = values[i].replace(' ', '');
            if (path == '') {
                continue;
            }
            if (!this.isValidUrl(path)) {
              this.notifyError(this.$t('domain.enterValidPath', [path]));
              return;
            }
            data.push(path);
          }
          if (data.length == 0) {
            this.notifyError(this.$t('domain.enterCachePath'));
            return;
          }
          EventBus.$emit('clearCustomCacheEvent', (data));
        }
        this.$emit('update:clearCacheModal', false);
    },
    isValidUrl: function (value) {
      return Boolean(validUrl.isWebUri(value));
    },
    handleCloseModal() {
      this.$emit('update:clearCacheModal', false);
    },
    handleScreenResize(){
      const width = document.documentElement.clientWidth;

      if( width <= SCREEN_SIZES.XS ){
        this.screenSize = SCREEN_TYPES.XS;
        return;
      }

      if( width <= SCREEN_SIZES.SM ){
        this.screenSize = SCREEN_TYPES.SM;
        return;
      }

      if( width <= SCREEN_SIZES.MD ){
        this.screenSize = SCREEN_TYPES.MD;
        return;
      }

      this.screenSize = SCREEN_TYPES.LG
    },
    selectClearCacheType(cache_type) {
        if (cache_type == 'clear_custom_cache') {
            this.selectedClearCacheCustomType = '';
            this.showClearCustomCacheFields = true;
            return;
        }
        this.showClearCustomCacheFields = false;
    },
    clearCacheCustomType(custom_cache_type) {
        this.selectedClearCacheCustomType = custom_cache_type;
    },
    refresh() {
      this.isLoading = true;     
    }
  },
  mounted() {
    window.addEventListener('resize', this.handleScreenResize);
    this.refresh();
  }
};
</script>

<style>
.display-flex {    
    display: flex;
}
.display-inline {
    width: 50%;
}
</style>