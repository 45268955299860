<template>
  <a-card>
    <p>{{ $t('helps.guard_configuration_overview') }}</p>

    <a-tabs :animated="false" :active-key="activeTab" @change="handleChangedTab" :tab-position="tabPosition">
      <a-tab-pane key="DetectionShield">
        <span slot="tab"
              class="has-icon">
          <a-icon v-if="isGaiusChallengeEnabled" type="check-circle"/>
          {{ $t('domain.DetectionShield') }}
        </span>

        <header>
          <div class="flex-fill">
            <div class="d-flex align-items-center mb-1 mt-2 has-icon">
              <strong class="text-iinfo">{{
                  $t('domain.DetectionShield')
                }}</strong>
            </div>
            <p>{{ $t('helps.domain_detection_shield') }}</p>
          </div>
        </header>

        <a-alert
            v-if="isGaiusChallengeMixed"
            message="Warning"
            :description="$t('message.domain_settings_mixed_selection')"
            type="warning"
            class="mb-2"
            show-icon
        />
        <!-- GAIUS-CHALLENGE -->
        <div class="border rounded">
          <div class="shield-detection-action py-3 px-4">
            <a-checkbox :checked="isGaiusChallengeJsProtection" @change="handleToggleGaiusChallenge">
              {{ $t('message.ShieldDetectionUseJs') }}
              <p class="mt-2 mb-0">{{ $t('message.ShieldDetectionUseJsDescription') }}</p>
            </a-checkbox>
          </div>

          <div class="shield-detection-action py-3 px-4">
            <a-checkbox :checked="isGaiusChallengeDisableProtection"
                        @change="handleToggleGaiusChallengeDisableProtection">
              {{ $t('message.ShieldDetectionDisableProtection') }}
              <p class="mt-2 mb-0">{{ $t('message.ShieldDetectionDisableProtectionDescription') }}</p>

              <a-alert v-if="isGaiusChallengeDisableProtection"
                       class="mt-2 mb-0"
                       :message="$t('message.ShieldDetectionDisableProtectionWarning')"
                       :banner="true"
              />
            </a-checkbox>
          </div>
        </div>
      </a-tab-pane>

      <a-tab-pane key="AccessSpeed">
        <span slot="tab"
              class="has-icon"
              v-bind:class="{'text-danger': gaiusRequestLimitErrors }">
          <a-icon v-if="isGaiusRequestLimitEnabled" type="check-circle"/>
          {{ $t('domain.AccessSpeed') }}
        </span>

        <header>
          <div class="flex-fill">
            <div class="d-flex align-items-center mb-1 mt-2 has-icon">
              <a-switch :checked="isGaiusRequestLimitEnabled" @change="handleToggleRequestLimit" class="mr-2">
                <a-icon slot="checkedChildren" type="check"/>
                <a-icon slot="unCheckedChildren" v-if="!isGaiusRequestLimitMixed" type="close"/>
              </a-switch>
              <strong v-bind:class="{'text-info': isGaiusRequestLimitEnabled}">{{
                  $t('domain.AccessSpeed')
                }}</strong>
            </div>
            <p>{{ $t('message.RateLimitingDescription') }}</p>
          </div>
        </header>

        <a-alert
            v-if="isGaiusRequestLimitMixed"
            message="Warning"
            :description="$t('message.domain_settings_mixed_selection')"
            type="warning"
            class="mb-2"
            show-icon
        />
        <a-form v-else-if="isGaiusRequestLimitEnabled" label-align="left" class="mt-4"
                :colon="false"
                :label-col="{ span: 12 }"
                :wrapper-col="{ span: 12 }"
                :layout="formLayout">
          <a-form-item :label="$t('domain.TimeInterval')"
                       :validate-status="getFieldValidationStatus(gaiusRequestLimitErrors, 'findtime')"
                       :help="getFieldHelpMessage(gaiusRequestLimitErrors, 'findtime')">
            <a-tooltip :title="$t('message.TimeIntervalDescription')"
                       :mouse-enter-delay="0.5">
              <a-input-number :min="1" v-model="rateLimitingTimeInterval"/>
            </a-tooltip>
            {{ $t('Seconds') }}
          </a-form-item>
          <a-form-item :label="$t('domain.FrequencyOfVisit')"
                       :validate-status="getFieldValidationStatus(gaiusRequestLimitErrors, 'maxretry')"
                       :help="getFieldHelpMessage(gaiusRequestLimitErrors, 'maxretry')">
            <a-tooltip :title="$t('message.FrequencyOfVisitDescription')"
                       :mouse-enter-delay="0.5">
              <a-input-number :min="1"
                              v-model="rateLimitingFrequencyOfVisit"/>
            </a-tooltip>
          </a-form-item>
          <a-form-item :label="$t('domain.BanTime')"
                       :validate-status="getFieldValidationStatus(gaiusRequestLimitErrors, 'bantime')"
                       :help="getFieldHelpMessage(gaiusRequestLimitErrors, 'bantime')">
            <a-tooltip :title="$t('message.BanTimeDescription')"
                       :mouse-enter-delay="0.5">
              <a-input-number :min="1" v-model="rateLimitingBanTime"/>

            </a-tooltip>
            {{ $t('Hours') }}
          </a-form-item>
          <a-form-item :label="$t('domain.ProtectedPath')"
                       :validate-status="getFieldValidationStatus(gaiusRequestLimitErrors, 'banpath')"
                       :help="getFieldHelpMessage(gaiusRequestLimitErrors, 'banpath')">
            <a-tooltip :title="$t('message.ProtectedPathDescription')"
                       :mouse-enter-delay="0.5">
              <a-input class="w-50" v-model="rateLimitingBanPath"/>
            </a-tooltip>
          </a-form-item>

          <a-card class="mt-4">
            <div class="pl-2 has-icon">
              <a-switch :checked="isRateLimitingDynamicContentEnabled"
                        @change="handleToggleGaiusRequestLimitDynamicContent" class="mr-2">
                <a-icon slot="checkedChildren" type="check"/>
                <a-icon slot="unCheckedChildren" type="close"/>
              </a-switch>
              {{ $t('message.DynamicContentOnly') }}
              <p class="mt-1" v-html="$t('message.DynamicContentDescription')"/>
            </div>
          </a-card>
        </a-form>
        <SimpleEmpty v-else>
          <span slot="description">{{ $t('message.PluginNotActive') }}</span>
        </SimpleEmpty>
      </a-tab-pane>
      <a-tab-pane key="CrowdsecCaptcha">
        <span slot="tab"
              class="has-icon"
              v-bind:class="{'text-danger': gaiusCrowdsecCaptchaErrors }">
          <a-icon v-if="isGaiusCrowdsecCaptchaEnabled" type="check-circle"/>
          {{ $t('domain.CrowdsecCaptcha') }}
        </span>

        <header>
          <div class="flex-fill">
            <div class="d-flex align-items-center mb-1 mt-2 has-icon">
              <a-switch :checked="isGaiusCrowdsecCaptchaEnabled" @change="handleToggleCrowdsecCaptcha" class="mr-2">
                <a-icon slot="checkedChildren" type="check"/>
                <a-icon slot="unCheckedChildren" v-if="!isGaiusCrowdsecCaptchaMixed" type="close"/>
              </a-switch>
              <strong v-bind:class="{'text-info': isGaiusCrowdsecCaptchaEnabled}">{{
                  $t('domain.CrowdsecCaptcha')
                }}</strong>
            </div>
            <p>{{ $t('message.CrowdsecCaptchaDescription') }}</p>
          </div>
        </header>

        <a-alert
            v-if="isGaiusCrowdsecCaptchaMixed"
            message="Warning"
            :description="$t('message.domain_settings_mixed_selection')"
            type="warning"
            class="mb-2"
            show-icon
        />
        <a-form v-else-if="isGaiusCrowdsecCaptchaEnabled" label-align="left" class="mt-4"
                :colon="false"
                :label-col="{ span: 12 }"
                :wrapper-col="{ span: 12 }"
                :layout="formLayout">
          <a-form-item :label="$t('domain.TimeInterval')"
                       :validate-status="getFieldValidationStatus(gaiusCrowdsecCaptchaErrors, 'findtime')"
                       :help="getFieldHelpMessage(gaiusCrowdsecCaptchaErrors, 'findtime')">
            <a-tooltip :title="$t('message.TimeIntervalDescription')"
                       :mouse-enter-delay="0.5">
              <a-input-number :min="1" v-model="crowdsecCaptchaTimeInterval"/>
            </a-tooltip>
            {{ $t('Seconds') }}
          </a-form-item>
          <a-form-item :label="$t('domain.FrequencyOfVisit')"
                       :validate-status="getFieldValidationStatus(gaiusCrowdsecCaptchaErrors, 'maxretry')"
                       :help="getFieldHelpMessage(gaiusCrowdsecCaptchaErrors, 'maxretry')">
            <a-tooltip :title="$t('message.FrequencyOfVisitDescription')"
                       :mouse-enter-delay="0.5">
              <a-input-number :min="1"
                              v-model="crowdsecCaptchaFrequencyOfVisit"/>
            </a-tooltip>
          </a-form-item>
          <a-form-item :label="$t('domain.BanTime')"
                       :validate-status="getFieldValidationStatus(gaiusCrowdsecCaptchaErrors, 'bantime')"
                       :help="getFieldHelpMessage(gaiusCrowdsecCaptchaErrors, 'bantime')">
            <a-tooltip :title="$t('message.BanTimeDescription')"
                       :mouse-enter-delay="0.5">
              <a-input-number :min="1" v-model="crowdsecCaptchaBanTime"/>

            </a-tooltip>
            {{ $t('Hours') }}
          </a-form-item>
        </a-form>
        <SimpleEmpty v-else>
          <span slot="description">{{ $t('message.PluginNotActive') }}</span>
        </SimpleEmpty>
      </a-tab-pane>
      <a-tab-pane key="BlackRule">
        <span slot="tab"
              class="has-icon"
              v-bind:class="{'text-danger': gaiusBlackRulesErrors }">
          <a-icon v-if="isGaiusBlackrulesEnabled" type="check-circle"/>
          {{ $t('domain.BlackRules') }}
        </span>

        <header>
          <div class="flex-fill">
            <div class="d-flex align-items-center mb-1 mt-2 has-icon">
              <a-switch :checked="isGaiusBlackrulesEnabled" @change="handleToggleBlackrules" class="mr-2">
                <a-icon slot="checkedChildren" type="check"/>
                <a-icon slot="unCheckedChildren" v-if="!isGaiusBlackrulesMixed" type="close"/>
              </a-switch>
              <strong v-bind:class="{'text-info': isGaiusBlackrulesEnabled}">{{
                  $t('domain.BlackRules')
                }}</strong>
            </div>
            <p>{{ $t('message.BlackRulesDescription') }}</p>
          </div>
        </header>

        <a-alert
            v-if="isGaiusBlackrulesMixed"
            message="Warning"
            :description="$t('message.domain_settings_mixed_selection')"
            type="warning"
            class="mb-2"
            show-icon
        />
        <template v-else-if="isGaiusBlackrulesEnabled">
          <div>
            <label>{{ $t('dynamic.ArgumentList') }}</label>
            <table class="dynamic-table">
              <thead>
                <th>{{ $t('dynamic.argument') }}</th>
                <th>{{ $t('dynamic.description') }}</th>
                <th>{{ $t('dynamic.example_value') }}</th>
              </thead> 
              <tbody>
                <tr>
                  <td>METHOD</td>
                  <td>HTTP methods</td>
                  <td>GET, POST, PATCH, PUT, DELETE, HEAD</td>
                </tr>
                <tr>
                  <td>PATH</td>
                  <td>Path of URL with query</td>
                  <td>/example?search=test</td>
                </tr>
                <tr>
                  <td>AGENT</td>
                  <td>User-Agent</td>
                  <td>Mozilla/5.0 (Windows NT 6.1; Win64; x64; rv:47.0) Gecko/20100101 Firefox/47.0</td>
                </tr>
                <tr>
                  <td>REFERER</td>
                  <td>HTTP Referer header</td>
                  <td>https://example.com/</td>
                </tr>
              </tbody>
            </table>
            <label>{{ $t('dynamic.LogicalOperator') }}</label>
            <ul>
              <li>AND : (condition and condition)</li>
              <li>OR : (condition or condition)</li>
              <li>!OR : not (condition or condition)</li>
              <li>!AND : not (condition and condition)</li>
            </ul>
            <label>{{ $t('dynamic.OperatorList') }}</label>
            <table class="dynamic-table">
              <thead>
                <th>{{ $t('dynamic.operator') }}</th>
                <th>{{ $t('dynamic.description') }}</th>
                <th>{{ $t('dynamic.example') }}</th>
              </thead>
              <tbody>
                <tr>
                  <td>==</td>
                  <td>equal</td>
                  <td>METHOD == GET</td>
                </tr>
                <tr>
                  <td>~=</td>
                  <td>not equal</td>
                  <td>METHOD ~= GET</td>
                </tr>
                <tr>
                  <td>~~</td>
                  <td>regular match</td>
                  <td>METHOD ~~ GET</td>
                </tr>
                <tr>
                  <td>~*</td>
                  <td>case insensitive regular match</td>
                  <td>METHOD ~* GET</td>
                </tr>
                <tr>
                  <td>!</td>
                  <td>reverse the result</td>
                  <td>METHOD !~~ GET</td>
                </tr>
              </tbody>
            </table>
          </div>
          <a-modal :visible="isVisibleDetail"
               id="blackRuleModal"
               :closable="false"
               :maskClosable="false"
               width="30%">

               <div>
                  <CInput
                    id="rule_name"
                    :placeholder="$t('domain.RuleNameDescription')"
                    v-model="blackRuleObj.name"
                  ></CInput>
                  <DynamicRule
                       v-if="isVisibleDetail" 
                       v-model="blackRuleObj.rules"
                       :dynamicValidateStatus="getFieldValidationStatus(gaiusBlackRulesErrors, 'rules')"
                       :dynamicHelp="getFieldHelpMessage(gaiusBlackRulesErrors, 'rules')"/>
                       <a-form>
                    <a-form-item :label="$t('domain.Action')"
                                 :validate-status="getFieldValidationStatus(gaiusBlackRulesErrors, 'action')"
                                 :help="getFieldHelpMessage(gaiusBlackRulesErrors, 'action')">
                      <a-tooltip :title="$t('message.ActionDescription')"
                                 :mouse-enter-delay="0.5">
                        <a-select v-model="blackRuleObj.action" :options="blackActionOptions"></a-select>
                      </a-tooltip>
                    </a-form-item>

                    <a-form-item :validate-status="getFieldValidationStatus(gaiusBlackRulesErrors, 'is_rate_limit')"
                                 :help="getFieldHelpMessage(gaiusBlackRulesErrors, 'is_rate_limit')">
                      <a-checkbox v-model="blackRuleObj.is_rate_limit"> {{ $t('domain.IsRateLimit') }}</a-checkbox> 
                    </a-form-item>


                    <div v-show="blackRuleObj.is_rate_limit">
                      <a-form-item :label="$t('domain.Ratelimit')"
                        :validate-status="getFieldValidationStatus(gaiusBlackRulesErrors, 'rate_limit_duration')"
                        :help="getFieldHelpMessage(gaiusBlackRulesErrors, 'rate_limit_duration')">
                        <a-tooltip :mouse-enter-delay="0.5">
                       <a-input-number :min="1" v-model="blackRuleObj.rate_limit_duration"/>
                      </a-tooltip>
                      {{ $t('Second') }}
                      </a-form-item>

                      <a-form-item :label="$t('domain.capacity')"
                        :validate-status="getFieldValidationStatus(gaiusBlackRulesErrors, 'rate_limit_capacity')"
                        :help="getFieldHelpMessage(gaiusBlackRulesErrors, 'rate_limit_capacity')">
                        <a-tooltip :mouse-enter-delay="0.5">
                       <a-input-number :min="1" v-model="blackRuleObj.rate_limit_capacity"/>
                      </a-tooltip>
                      {{ $t('Requests') }}
                      </a-form-item> 
                    </div>
                    <a-form-item :label="$t('domain.BanTime')"
                                 :validate-status="getFieldValidationStatus(gaiusBlackRulesErrors, 'bantime')"
                                 :help="getFieldHelpMessage(gaiusBlackRulesErrors, 'bantime')">
                      <a-tooltip :title="$t('message.BanTimeDescription')"
                                 :mouse-enter-delay="0.5">
                        <a-input-number :min="1" v-model="blackRuleObj.bantime"/>
                      </a-tooltip>
                      {{ $t('Hours') }}
                    </a-form-item>
                  </a-form>
               </div>

               <template slot="footer">
                  <footer>
                    <a-button @click="closeBlackRuleModal">
                        {{ $t('Cancel') }}
                    </a-button>
                    <template>
                        <a-button @click="saveBlackRuleModal"
                        :disabled="blackRuleObj.name.length == 0"
                        type="primary">
                            {{ $t('Save') }}
                        </a-button>
                    </template>
                  </footer>
               </template>                
          </a-modal>
          <a-card :title="$t('domain.BlackRules')" size="small">
            <div class="mt-2">
                <CRow class="mt-3">
                  <CCol>
                    <a-button @click="addBlackRules" class="create-button-group">
                      <a-icon type="plus-circle"/>
                      {{ $t('domain.CreateRule') }}
                    </a-button>
                  </CCol>
                  <CCol v-if="this.blackRules.length > 0">
                    <a-input-search
                    v-model="searchTermModel"
                    :placeholder="$t('Search')"
                    :allow-clear="false"
                    @press-enter="handleSearch"
                    @search="handleSearch">
                    <a-button slot="enterButton" icon="search"/>
                    </a-input-search>
                  </CCol>
                </CRow>
            </div><br>
            <template>
              <div v-if="this.blackRules.length > 0">
                <table class="table" aria-label="Cache Rules">
                  <thead class="header-with-border">
                    <tr>
                      <th colspan="2"></th>
                      <th colspan="2">Name</th>
                      <th colspan="2"></th>
                      <th colspan="2">Enabled</th>
                    </tr>
                  </thead>
                  <tbody>
                      <tr v-for="record in displayList" :key="record.name">
                        <td colspan="2">
                          <span class="drag-handle">
                            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
                              <circle cx="3" cy="3" r="1" fill="#000"/>
                              <circle cx="3" cy="9" r="1" fill="#000"/>
                              <circle cx="8" cy="3" r="1" fill="#000"/>
                              <circle cx="8" cy="9" r="1" fill="#000"/>
                            </svg>
                          </span>
                        </td>
                        <td colspan="2" style="max-width: 130px"> {{ record.name }} </td>
                        <td colspan="2">
                          <button @click="editRule(record)" type="button" class="btn btn-link">{{ $t('Edit') }}</button>
                          <button @click="deleteRule(record)" type="button" class="btn btn-link">{{ $t('Delete') }}</button>
                        </td>
                        <td><a-switch :checked="record.enabled" @change="toggleCustomCaching(record)"/></td>
                      </tr>
                  </tbody>
                </table>
            </div>
            <SimpleEmpty v-else>
                <span slot="description">{{ $t('domain.NoBlackRulesConfigured') }}</span>
              </SimpleEmpty>
            </template>
          </a-card>
        </template>
      </a-tab-pane>

      <a-tab-pane key="SecLink">
        <span slot="tab"
              class="has-icon"
              v-bind:class="{'text-danger': gaiusSecLinkErrors }">
          <a-icon v-if="isGaiusSecLinkEnabled" type="check-circle"/>
          {{ $t('domain.SecLink') }}
        </span>

        <header>
          <div class="flex-fill">
            <div class="d-flex align-items-center mb-1 mt-2 has-icon">
              <a-switch :checked="isGaiusSecLinkEnabled" @change="handleToggleSecLink" class="mr-2">
                <a-icon slot="checkedChildren" type="check"/>
                <a-icon slot="unCheckedChildren" v-if="!isGaiusSecLinkMixed" type="close"/>
              </a-switch>
              <strong v-bind:class="{'text-info': isGaiusSecLinkEnabled}">{{
                  $t('domain.SecLink')
                }}</strong>
            </div>
            <p>
                {{ $t('message.SecLinkDescription') }}
                <a target="_blank" href="https://docs.asians.group/cdn-user-manual/domains/security-settings/sec-link">{{ $t('guide.ClickHere') }}</a>
                {{ $t('guide.MoreDetails') }}
            </p>
          </div>
        </header>

        <a-alert
            v-if="isGaiusSecLinkMixed"
            message="Warning"
            :description="$t('message.domain_settings_mixed_selection')"
            type="warning"
            class="mb-2"
            show-icon
        />

        <template v-else-if="isGaiusSecLinkEnabled">
          <a-form>
            <a-form-item :label="$t('domain.enableSecRedirect')"
                         :validate-status="getFieldValidationStatus(gaiusSecLinkErrors, 'enable_sec_redirect')"
                         :help="getFieldHelpMessage(gaiusSecLinkErrors, 'enable_sec_redirect')">
              <a-checkbox :checked="secLinkApiEnable" v-model="secLinkApiEnable"></a-checkbox>
            </a-form-item>
            <a-form-item :label="$t('domain.apiSecret')"
                         :validate-status="getFieldValidationStatus(gaiusSecLinkErrors, 'api_secret')"
                         :help="getFieldHelpMessage(gaiusSecLinkErrors, 'api_secret')">
              <a-input v-model="secLinkApiSecret"/>
            </a-form-item>
            <a-form-item :label="$t('domain.sleepSeconds')"
                         :validate-status="getFieldValidationStatus(gaiusSecLinkErrors, 'sleep_seconds')"
                         :help="getFieldHelpMessage(gaiusSecLinkErrors, 'sleep_seconds')">
              <a-input-number min="1" v-model="secLinkSleepSeconds" value="5"/>
            </a-form-item>
            <a-form-item :label="$t('domain.expiresSeconds')"
                         :validate-status="getFieldValidationStatus(gaiusSecLinkErrors, 'expire_seconds')"
                         :help="getFieldHelpMessage(gaiusSecLinkErrors, 'expire_seconds')">
              <a-input-number v-model="secLinkExpireSeconds"/>
            </a-form-item>
          </a-form>

          <div v-if="protectedPathlist && protectedPathlist.length" class="max-paths-height">
              <CListGroup>
                <CListGroupItem v-for="(protectedPath) in protectedPathlist" v-bind:key="protectedPath">
                  <div style="display: flex; align-items: center;">
                    <div style="flex: 1">
                      {{ protectedPath }}
                    </div>
                    <div class="shadow-list-action-btns">
                      <CButtonClose v-on:click="removeProtectedPathlist(protectedPath)"/>
                    </div>
                  </div>
                </CListGroupItem>
              </CListGroup>
          </div>
          <SimpleEmpty v-else>
            <span slot="description">{{ $t('message.NoSpecificProtectedPathsListed') }}</span>
          </SimpleEmpty>

          <div class="mt-4 p-4 border-top">
              <p class="mb-1 has-icon text-info">
                <a-icon type="form" class="mr-1"/>
                <strong>{{ $t('message.AddNewProtectedPath') }}</strong>
              </p>

              <CTextarea
                  v-model="newProtectedPathlist"
                  placeholder="/path, /home?q=search"
                  :description="$t('helps.enter_protected_path')"
              >
              </CTextarea>

              <div class="d-flex justify-content-end mt-n4">
                <a-button @click="addNewProtectedPath()" :disabled="!newProtectedPathlist.length">
                  <a-icon type="plus"/>
                  {{ $t('Add') }}
                </a-button>
              </div>
          </div>
        </template>
      </a-tab-pane>

      <!--<a-tab-pane key="MaliciousCrawler" :tab="$t('domain.MaliciousCrawler')" :disabled="true"></a-tab-pane>-->

      <a-tab-pane key="CountryAccessRestrictions">
        <span slot="tab"
              class="has-icon"
              v-bind:class="{'text-danger': gaiusGeoIPErrors }">
          <a-icon v-if="isGaiusGeoIpEnabled" type="check-circle"/>
          {{ $t('domain.CountryAccessRestrictions') }}
        </span>
        <header>
          <div class="flex-fill">
            <div class="d-flex align-items-center mb-1 mt-2 has-icon">
              <a-switch :checked="isGaiusGeoIpEnabled" @change="handleToggleGeoIp" class="mr-2">
                <a-icon slot="checkedChildren" type="check"/>
                <a-icon slot="unCheckedChildren" v-if="!isGaiusGeoIpMixed" type="close"/>
              </a-switch>
              <strong v-bind:class="{'text-info': isGaiusGeoIpEnabled}">{{
                  $t('domain.CountryAccessRestrictions')
                }}</strong>
            </div>
            <p>{{ $t('message.GeoIpDescription') }}</p>
          </div>
        </header>

        <a-alert
            v-if="isGaiusGeoIpMixed"
            message="Warning"
            :description="$t('message.domain_settings_mixed_selection')"
            type="warning"
            class="mb-2"
            show-icon
        />
        <a-form v-else-if="isGaiusGeoIpEnabled" label-align="left"
                :colon="false"
                :label-col="{ span: 6 }"
                :wrapper-col="{ span: 12 }">

          <div class="mt-4">
            <p class="mb-1 has-icon text-info">
              <a-icon type="form" class="mr-1"/>
              <strong>{{ $t('Mode') }}</strong>
            </p>

            <a-select default-value="Blacklist" v-model="geoIpMode">
              <a-select-option key="Blacklist" value="Blacklist">{{ $t('Blacklist') }}</a-select-option>
              <a-select-option key="Whitelist" value="Whitelist">{{ $t('Whitelist') }}</a-select-option>
            </a-select>

            <p v-if="geoIpMode === 'Whitelist'" class="mt-3 mb-1">
              {{ $t('message.GeoIpWhitelistMode') }}
            </p>
            <p v-if="geoIpMode === 'Blacklist'" class="mt-3 mb-1">
              {{ $t('message.GeoIpBlacklistMode') }}
            </p>

            <multiselect v-model="geoIpCountries"
                   :options="countryList"
                   label="name"
                   track-by="code"
                   :placeholder="$t('message.GeoIpCountryPlaceholder')"
                   :multiple="true"
                   :close-on-select="false">
            </multiselect>

            <div class="mt-2">
              <a-checkbox v-if="isGaiusGeoIpEnabled && this.isBatchEdit"
                :checked="isCombineGeoIp"
                @change="handleToggleCombineGeoIp">{{ $t('message.CombineGeoIp') }}
              </a-checkbox>
            </div>

          </div>
        </a-form>
        <SimpleEmpty v-else>
          <span slot="description">{{ $t('message.PluginNotActive') }}</span>
        </SimpleEmpty>
      </a-tab-pane>

      <a-tab-pane key="ip-whitelist">
        <span slot="tab"
              class="has-icon"
              v-bind:class="{'text-danger': ipRestrictionErrors }">
          <a-icon v-if="isIpRestrictionEnabled" type="check-circle"/>
          {{ $t('domain.IPRestriction') }}
        </span>

        <header>
          <div class="flex-fill">
            <div class="d-flex align-items-center mb-1 mt-2 has-icon">
              <a-switch :checked="isIpRestrictionEnabled" @change="handleToggleIpRestriction" class="mr-2">
                <a-icon slot="checkedChildren" type="check"/>
                <a-icon slot="unCheckedChildren" v-if="!isIpRestrictionMixed" type="close"/>
              </a-switch>
              <strong v-bind:class="{'text-info': isIpRestrictionEnabled}">{{
                  $t('domain.IPRestriction')
                }}</strong>
            </div>
            <p>{{ $t('message.IPRestrictionDescription') }}</p>
          </div>
        </header>
        <div v-if="!ipBlacklist && !ipWhitelist && ipRestrictionErrors && isIpRestrictionEnabled">
          <a-alert v-for="error in ipRestrictionErrors.config.non_field_errors"
                   :message="error"
                   :key="error"
                   type="error"
                   :banner="true"/>
        </div>
        <a-alert
            v-if="isIpRestrictionMixed"
            message="Warning"
            :description="$t('message.domain_settings_mixed_selection')"
            type="warning"
            class="mb-2"
            show-icon
        />
        <div class="flex-fill" v-else-if="isIpRestrictionEnabled" :animated="false">
          <div class="d-flex align-items-center mb-1 mt-2 has-icon">
            <a-switch :checked="allowSpiders" @change="handleToggleAllowSpiders" class="mr-2">
              <a-icon slot="checkedChildren" type="check"/>
              <a-icon slot="unCheckedChildren" type="close"/>
            </a-switch>
            <strong v-bind:class="{'text-info': allowSpiders}">{{
                $t('domain.AllowSpiders')
              }}</strong>
          </div>
          <p>{{ $t('message.AllowSpidersDescription') }}</p>
        </div>

        <a-tabs v-if="isIpRestrictionEnabled" :animated="false">
          <a-tab-pane key="whitelist" :tab="ipWhitelistTabTitle">
            <div class="d-flex" v-if="isBatchEdit">
              <!-- Overwrite Button -->
              <div class="d-flex align-items-center mb-1 mt-2 has-icon">
                <a-button :type="whitelistMode === 'overwrite' ? 'primary' : 'default'" @click="setMode('overwrite')" class="mr-2">
                  {{ $t('Overwrite') }}
                </a-button>
              </div>

              <!-- Merge Button -->
              <div class="d-flex align-items-center mb-1 mt-2 has-icon">
                <a-button :type="whitelistMode === 'merge' ? 'primary' : 'default'" @click="setMode('merge')" class="mr-2">
                  {{ $t('Merge') }}
                </a-button>
              </div>
            </div>

            <div v-if="shouldShowWhitelistSection" class="mb-4 mt-4 pb-4 border-bottom">
              <p class="mb-1 has-icon text-info">
                <a-icon type="form" class="mr-1"/>
                <strong>{{ $t('message.AddNewIpWhitelist') }}</strong>
              </p>
              <p>{{ $t('helps.whitelist_addr') }}</p>

              <CTextarea
                  v-model="newIpWhitelist"
                  placeholder="20.1.30.4, 168.31.20.1"
                  :description="$t('helps.enter_ip_address')"
              >
              </CTextarea>

              <div class="d-flex justify-content-end mt-n3">
                <a-button @click="addNewIpWhitelist()" :disabled="!newIpWhitelist.length">
                  <a-icon type="plus"/>
                  {{ $t('Add') }}
                </a-button>
              </div>
              <IpRestrictionList :domain="domain" :ipList="ipWhitelist" :remove="removeIpWhitelist" />
            </div>
            
          </a-tab-pane>

          <a-tab-pane key="black" :tab="ipBlacklistTabTitle">
            <div class="d-flex" v-if="isBatchEdit">
              <!-- Overwrite Button -->
              <div class="d-flex align-items-center mb-1 mt-2 has-icon">
                <a-button :type="blacklistMode === 'overwrite' ? 'primary' : 'default'" @click="setModebalckip('overwrite')" class="mr-2">
                  {{ $t('Overwrite') }}
                </a-button>
              </div>

              <!-- Merge Button -->
              <div class="d-flex align-items-center mb-1 mt-2 has-icon">
                <a-button :type="blacklistMode === 'merge' ? 'primary' : 'default'" @click="setModebalckip('merge')" class="mr-2">
                  {{ $t('Merge') }}
                </a-button>
              </div>
            </div>

            <div v-if="shouldShowblacklistSection" class="mb-4 pb-4 border-bottom">
              <p class="mb-1 has-icon text-info"> 
                <a-icon type="form" class="mr-1"/>
                <strong>{{ $t('message.AddNewIpBlacklist') }}</strong>
              </p>
              <p>{{ $t('helps.blacklist_addr') }}</p>

              <CTextarea
                  v-model="newIpBlacklist"
                  placeholder="20.1.30.4, 168.31.20.1"
                  :description="$t('helps.enter_ip_address')"
              >
              </CTextarea>

              <div class="d-flex justify-content-end mt-n3">
                <a-button @click="addNewIpBlacklist()" :disabled="!newIpBlacklist.length">
                  <a-icon type="plus"/>
                  {{ $t('Add') }}
                </a-button>
              </div>
              <IpRestrictionList :domain="domain" :ipList="ipBlacklist" :remove="removeIpBlacklist" />
            </div>
            
          </a-tab-pane>
        </a-tabs>
        <SimpleEmpty v-else>
          <span slot="description">{{ $t('message.PluginNotActive') }}</span>
        </SimpleEmpty>
      </a-tab-pane>

      <!--<a-tab-pane key="VipCustomRule" :tab="$t('domain.VipCustomRule')" :disabled="true"></a-tab-pane>-->
      <a-tab-pane key="CustomErrorPage">
        <span slot="tab"
              class="has-icon">
          <a-icon v-if="isGaiusCustomErrorPageEnabled" type="check-circle"/>
          {{ $t('domain.CustomErrorPage') }}
        </span>

        <header>
          <div class="flex-fill">
            <div class="d-flex align-items-center mb-1 mt-2 has-icon">
              <a-switch :checked="isGaiusCustomErrorPageEnabled" @change="handleToggleGaiusCustomErrorPage"
                        class="mr-2">
                <a-icon slot="checkedChildren" type="check"/>
                <a-icon slot="unCheckedChildren" v-if="!isGaiusCustomErrorPageMixed" type="close"/>
              </a-switch>
              <strong v-bind:class="{'text-info': isGaiusCustomErrorPageEnabled}">{{
                  $t('domain.CustomErrorPage')
                }}</strong>
            </div>
            <p>{{ $t('message.CustomErrorPageDescription') }}</p>
          </div>
        </header>

        <a-alert
            v-if="isGaiusCustomErrorPageMixed"
            message="Warning"
            :description="$t('message.domain_settings_mixed_selection')"
            type="warning"
            class="mb-2"
            show-icon
        />
        <a-form v-else-if="isGaiusCustomErrorPageEnabled" label-align="left"
                :colon="false"
                class="mt-4"
                :label-col="{ span: 12 }"
                :wrapper-col="{ span: 12 }"
                :layout="formLayout">

          <a-form-item :label="$t('message.StatusCode401')"
                       :validate-status="returnStringIfFailedUrlValidate(customErrorPage401Input, 'error')"
                       :help="returnStringIfFailedUrlValidate(customErrorPage401Input, $t('message.URLFormatInvalid'))">
            <a-tooltip :title="$t('message.StatusCode401Description')"
                       :mouse-enter-delay="0.5">
              <a-input v-model="customErrorPage401Input"
                       placeholder="https://example.com/error401.html"
              />
            </a-tooltip>
          </a-form-item>

          <a-form-item :label="$t('message.StatusCode404')"
                       :validate-status="returnStringIfFailedUrlValidate(customErrorPage404Input, 'error')"
                       :help="returnStringIfFailedUrlValidate(customErrorPage404Input, $t('message.URLFormatInvalid'))">
            <a-tooltip :title="$t('message.StatusCode404Description')"
                       :mouse-enter-delay="0.5">
              <a-input v-model="customErrorPage404Input"
                       placeholder="https://example.com/error404.html"
              />
            </a-tooltip>
          </a-form-item>

          <a-form-item :label="$t('message.StatusCode425')"
                       :validate-status="returnStringIfFailedUrlValidate(customErrorPage425Input, 'error')"
                       :help="returnStringIfFailedUrlValidate(customErrorPage425Input, $t('message.URLFormatInvalid'))">
            <a-tooltip :title="$t('message.StatusCode425Description')"
                       :mouse-enter-delay="0.5">
              <a-input v-model="customErrorPage425Input"
                       placeholder="https://example.com/error425.html"
              />
            </a-tooltip>
          </a-form-item>

          <a-form-item :label="$t('message.StatusCode451')"
                       :validate-status="returnStringIfFailedUrlValidate(customErrorPage451Input, 'error')"
                       :help="returnStringIfFailedUrlValidate(customErrorPage451Input, $t('message.URLFormatInvalid'))">
            <a-tooltip :title="$t('message.StatusCode451Description')"
                       :mouse-enter-delay="0.5">
              <a-input v-model="customErrorPage451Input"
                       placeholder="https://example.com/error451.html"
              />
            </a-tooltip>
          </a-form-item>

          <a-form-item :label="$t('message.StatusCode502')"
                       :validate-status="returnStringIfFailedUrlValidate(customErrorPage502Input, 'error')"
                       :help="returnStringIfFailedUrlValidate(customErrorPage502Input, $t('message.URLFormatInvalid'))">
            <a-tooltip :title="$t('message.StatusCode502Description')"
                       :mouse-enter-delay="0.5">
              <a-input v-model="customErrorPage502Input"
                       placeholder="https://example.com/error502.html"
              />
            </a-tooltip>
          </a-form-item>
        </a-form>
        <SimpleEmpty v-else>
          <span slot="description">{{ $t('message.PluginNotActive') }}</span>
        </SimpleEmpty>

      </a-tab-pane>
    </a-tabs>
  </a-card>
</template>

<style scoped>
.shield-detection-action:first-child {
  border-bottom: 1px solid #d8dbdf;
}

.ant-form-explain-important {
    color: rgba(0, 0, 0, 0.45) !important;
}

.max-paths-height {
    max-height: 250px;
    overflow-y: scroll;
    border-bottom: 1px solid rgba(0, 0, 21, 0.125);
}
</style>

<style>
.multiselect__content-wrapper {
  position: relative !important;
}
</style>

<script>
import {has} from 'lodash';
import validUrl from 'valid-url';
import {Validator} from "ip-num/Validator";
import Multiselect from 'vue-multiselect';
import DomainValidationMixin from '@/utilities/DomainValidationMixin';
import {
  MULTIPLE_VALUES_SPLITTER,
  PLUGIN_NAMES,
  GAIUS_GEOIP_MODES,
  TAG_NAMES,
  SCREEN_TYPES
} from '@/utilities/constants';
import SimpleEmpty from '@/views/domain/SimpleEmpty';
import IpRestrictionList from '@/views/domain/IpRestrictionList';
import countriesJson from '@/views/domain/CountrySelector/countries.json';
import PluginActionsMixin from '@/utilities/PluginActionsMixin';
import DynamicRule from '@/views/domain/EditDomain/DynamicRule';
import {getPluginConfigValue, isPluginEnabled, isPluginMixedValue} from '@/utilities/plugin-actions';
import {EventBus, Events} from '@/plugins/event-bus';
import { addwhitelistip, ipvalidation } from '@/utilities/api';

const JS_SHIELD_TAB = 'DetectionShield';
const RATE_LIMITING_TAB = 'AccessSpeed';
const GEO_RESTRICTION_TAB = 'CountryAccessRestrictions';

export default {
  name: 'GuardConfiguration',
  components: {SimpleEmpty, DynamicRule, IpRestrictionList, Multiselect},
  mixins: [DomainValidationMixin, PluginActionsMixin],
  props: [
    'domain',
    'plugins',
    'pluginErrors',
    'tags',
    'screenSize',
    'isBatchEdit'
  ],
  data() {
    return {
      activeTab: JS_SHIELD_TAB,
      newIpWhitelist: '',
      newIpBlacklist: '',
      countryList: countriesJson,
      mutatedPlugin: null,
      newProtectedPathlist: '',
      isVisibleDetail: false,
      searchTermModel: '',
      searchTerm: '',
      filteredList: [],
      isEditingRule: false,
      editRuleName: '',
      blackRuleObj: {
        name: '',
        action : "captcha",
        rules: [['AND'], ['METHOD', '~*', 'GET'], ['AGENT', '~*', 'Chrome']],
        is_rate_limit: true,        
        rate_limit_duration : 5,
        rate_limit_capacity : 5,
        bantime : '3h',
        enabled: true
      },
      blackRulesData: [],
      internalWhitelistMode: '',
      internalblacklistMode:''
    };
  },
  computed: {
    shouldShowWhitelistSection() {
      return !this.isBatchEdit || this.whitelistMode;
    },
    shouldShowblacklistSection() {
      return !this.isBatchEdit || this.blacklistMode;
    },
    tabPosition: function () {
      if (this.screenSize === SCREEN_TYPES.XS) {
        return 'top';
      }
      return 'left';
    },
    formLayout: function () {
      if (this.screenSize === SCREEN_TYPES.XS) {
        return 'vertical';
      }
      return 'horizontal';
    },
    getCountryOptions: function () {
      return this.countryList.map(country => {
        return Object.assign({}, country, {
          value: country.code,
          label: country.name,
          key: country.code
        });
      })
    },
    blackActionOptions: function () {
      return [
        {value: 'captcha', label: this.$t('Captcha')},
        {value: 'ban', label: this.$t('Ban')}
      ]
    },
    isIpRestrictionEnabled: function () {
      return this.isPluginEnabled(PLUGIN_NAMES.GAIUS_IP_RESTRICTION);
    },
    isIpRestrictionMixed: function () {
      return isPluginMixedValue(this.plugins, PLUGIN_NAMES.GAIUS_IP_RESTRICTION);
    },
    ipRestrictionErrors: function () {
      return this.getPluginErrors(PLUGIN_NAMES.GAIUS_IP_RESTRICTION);
    },
    isGaiusRequestLimitEnabled: function () {
      return this.isPluginEnabled(PLUGIN_NAMES.GAIUS_REQUEST_LIMIT);
    },
    isGaiusRequestLimitMixed: function () {
      return isPluginMixedValue(this.plugins, PLUGIN_NAMES.GAIUS_REQUEST_LIMIT);
    },
    isGaiusCrowdsecCaptchaEnabled: function () {
      return this.isPluginEnabled(PLUGIN_NAMES.GAIUS_CROWDSEC_CAPTCHA);
    },
    isGaiusCrowdsecCaptchaMixed: function () {
      return isPluginMixedValue(this.plugins, PLUGIN_NAMES.GAIUS_CROWDSEC_CAPTCHA);
    },
    gaiusCrowdsecCaptchaErrors: function () {
      return this.getPluginErrors(PLUGIN_NAMES.GAIUS_CROWDSEC_CAPTCHA);
    },
    gaiusRequestLimitErrors: function () {
      const error = this.getPluginErrors(PLUGIN_NAMES.GAIUS_REQUEST_LIMIT);

      if (this.rateLimitingBanPath) {
        this.clearPluginError(PLUGIN_NAMES.GAIUS_REQUEST_LIMIT);
      }

      if (!error) {
        return null;
      }

      this.clearPluginError(PLUGIN_NAMES.GAIUS_REQUEST_LIMIT);

      return error.config;
    },
    gaiusGeoIPErrors: function () {
      return this.getPluginErrors(PLUGIN_NAMES.GAIUS_GEOIP);
    },
    isGaiusGeoIpEnabled: function () {
      return this.isPluginEnabled(PLUGIN_NAMES.GAIUS_GEOIP);
    },
    isGaiusGeoIpMixed: function () {
      return isPluginMixedValue(this.plugins, PLUGIN_NAMES.GAIUS_GEOIP);
    },
    gaiusBlackRulesErrors: function () {
      const error = this.getPluginErrors(PLUGIN_NAMES.GAIUS_BLACKRULES);
      if (!error) {
        return null
      }
      if (error.hasOwnProperty('config')) {
        return error.config;
      }
      return error;
    },
    gaiusSecLinkErrors: function () {
      const error = this.getPluginErrors(PLUGIN_NAMES.GAIUS_SECLINK);
      
      if (this.secLinkProtectionPaths) {
        this.clearPluginError(PLUGIN_NAMES.GAIUS_SECLINK);
      }

      if (this.secLinkApiSecret) {
        this.clearPluginError(PLUGIN_NAMES.GAIUS_SECLINK);
      }

      if (!error) {
        return null;
      }

      return error.config;

    },
    isGaiusBlackrulesEnabled: function () {
      return isPluginEnabled(this.plugins, PLUGIN_NAMES.GAIUS_BLACKRULES);
    },
    isGaiusBlackrulesMixed: function () {
      return isPluginMixedValue(this.plugins, PLUGIN_NAMES.GAIUS_BLACKRULES);
    },
    isGaiusSecLinkEnabled: function () {
      return this.isPluginEnabled(PLUGIN_NAMES.GAIUS_SECLINK);
    },
    isGaiusSecLinkMixed: function () {
      return isPluginMixedValue(this.plugins, PLUGIN_NAMES.GAIUS_SECLINK);
    },
    geoIpMode: {
      get() {
        const plugin = this.getPlugin(PLUGIN_NAMES.GAIUS_GEOIP);
        if (!plugin) {
          return '';
        }

        return plugin.config.mode;
      },
      set(value) {
        const plugin = this.getPlugin(PLUGIN_NAMES.GAIUS_GEOIP);
        if (!plugin) {
          return '';
        }

        const config = {...plugin.config};
        config.mode = value;

        // transfer countries to the right bucket
        if (config.mode === GAIUS_GEOIP_MODES.BLACKLIST) {
          config.blacklist_countries = [...config.whitelist_countries];
          config.whitelist_countries = [];
        } else if (config.mode === GAIUS_GEOIP_MODES.WHITELIST) {
          config.whitelist_countries = [...config.blacklist_countries];
          config.blacklist_countries = [];
        }

        this.updatePlugin({...plugin, config: config});
      }
    },
    allCountry() {
      return this.countryList.map(country => country.code).filter(item => item !== 'all');
    },
    countriesMap: function () {
      const map = {};

      for (let c of this.countryList)
        map[c.code] = c;

      return map;
    },
    geoIpCountries: {
      get() {
        const plugin = this.getPlugin(PLUGIN_NAMES.GAIUS_GEOIP);
        if (!plugin) {
          return [];
        }

        const mode = plugin.config.mode;
        if (mode === GAIUS_GEOIP_MODES.BLACKLIST) {
          return plugin.config.blacklist_countries.map((countryCode) => this.countriesMap[countryCode]);
        } else if (mode === GAIUS_GEOIP_MODES.WHITELIST) {
          return plugin.config.whitelist_countries.map((countryCode) => this.countriesMap[countryCode]);
        }

        return [];
      },
      set(values) {
        const plugin = this.getPlugin(PLUGIN_NAMES.GAIUS_GEOIP);
        if (!plugin) {
          return;
        }

        const mode = plugin.config.mode;
        let countries = values.map((s) => s.code);
        if (countries.indexOf('all') >= 0) {
          if (plugin.config.blacklist_countries.length === 0 || plugin.config.whitelist_countries.length === 0) {
            let allCountry = Object.keys(this.countriesMap);
            allCountry = allCountry.filter(item => item !== 'all');
            countries = allCountry;
          } else {
            countries = [];
          }
        }

        if (mode === GAIUS_GEOIP_MODES.BLACKLIST) {
          if (plugin.config.blacklist_countries.length === this.countryList.length - 1 && plugin.config.blacklist_countries.length === countries.length) {
            countries= [];
          }
        } else {
          if (plugin.config.whitelist_countries.length === this.countryList.length - 1 && plugin.config.whitelist_countries.length === countries.length) {
            countries = [];
          } 
        }

        if (mode === GAIUS_GEOIP_MODES.BLACKLIST) {
          this.setPluginConfigValue(PLUGIN_NAMES.GAIUS_GEOIP, 'blacklist_countries', [...countries]);
        } else if (mode === GAIUS_GEOIP_MODES.WHITELIST) {
          this.setPluginConfigValue(PLUGIN_NAMES.GAIUS_GEOIP, 'whitelist_countries', [...countries]);
        }
      }
    },
    geoIpWhitelistOverride: {
      get() {
        const plugin = this.getPlugin(PLUGIN_NAMES.GAIUS_GEOIP);
        if (!plugin) {
          return [];
        }

        return plugin.config.whitelist_ips;
      },
      set(values) {
        const plugin = this.getPlugin(PLUGIN_NAMES.GAIUS_GEOIP);
        if (!plugin) {
          return;
        }

        for (let ip of values) {
          if (!this.validateIp(ip)) {
            this.notifyError(this.$t('message.IPAddressFormatInvalidFor', [ip]), this.$t('message.InputDataVerificationError'));
            return;
          }
        }

        this.setPluginConfigValue(PLUGIN_NAMES.GAIUS_GEOIP, 'whitelist_ips', [...values]);
      }
    },
    blackRules: {
      get() {
        const plugin = this.getPlugin(PLUGIN_NAMES.GAIUS_BLACKRULES);
        if (!plugin) {
          return [];
        }        
        return plugin.config.black_rules;
      },
      set(values) {
        const plugin = this.getPlugin(PLUGIN_NAMES.GAIUS_BLACKRULES);
        if (!plugin) {
          return [];
        }
        this.setPluginConfigValue(PLUGIN_NAMES.GAIUS_BLACKRULES, 'black_rules', values);
      }
    },
    secLinkApiEnable: {
      get() {
        return this.getPluginConfigValue(PLUGIN_NAMES.GAIUS_SECLINK, 'enable_sec_redirect');
      },
      set(value) {
        this.setPluginConfigValue(PLUGIN_NAMES.GAIUS_SECLINK, 'enable_sec_redirect', value);
      }
    },
    secLinkProtectionPaths: {
      get() {
        let protection_paths_value = this.getPluginConfigValue(PLUGIN_NAMES.GAIUS_SECLINK, 'protection_paths');
        if (protection_paths_value && !protection_paths_value == "MIXED_VALUE") {
            return protection_paths_value.join(", ");
        }
        return protection_paths_value;
      },
      set(value) {
        if (value) {
            var value = value.split(",").map(function (data) {
                return data.trim();
            });

            for (var i in value) {
                if (value[i] && !value[i].startsWith('/')) {
                    return;
                }
            }
        } else {
            value = [];
        }
        this.setPluginConfigValue(PLUGIN_NAMES.GAIUS_SECLINK, 'protection_paths', value);
      },
    },
    secLinkApiSecret: {
      get() {
        return this.getPluginConfigValue(PLUGIN_NAMES.GAIUS_SECLINK, 'api_secret');
      },
      set(value) {
        this.setPluginConfigValue(PLUGIN_NAMES.GAIUS_SECLINK, 'api_secret', value);
      },
    },
    secLinkSleepSeconds: {
      get() {
        return this.getPluginConfigValue(PLUGIN_NAMES.GAIUS_SECLINK, 'sleep_seconds');
      },
      set(value) {
        const check = parseInt(value);
        if (isNaN(check)) {
          return;
        }
        this.setPluginConfigValue(PLUGIN_NAMES.GAIUS_SECLINK, 'sleep_seconds', value);
      }
    },
    secLinkExpireSeconds: {
      get() {
        return this.getPluginConfigValue(PLUGIN_NAMES.GAIUS_SECLINK, 'expire_seconds');
      },
      set(value) {
        const check = parseInt(value);
        if (isNaN(check)) {
          return;
        }

        this.setPluginConfigValue(PLUGIN_NAMES.GAIUS_SECLINK, 'expire_seconds', value);
      }
    },
    isGaiusCustomErrorPageEnabled: function () {
      return isPluginEnabled(this.plugins, PLUGIN_NAMES.GAIUS_CUSTOM_ERROR_PAGE);
    },
    isGaiusCustomErrorPageMixed: function () {
      return isPluginMixedValue(this.plugins, PLUGIN_NAMES.GAIUS_CUSTOM_ERROR_PAGE);
    },
    isGaiusChallengeEnabled: function () {
      // gaius-challenge is always enabled for the domain unless the service is tagged with "no_js_challenge=1"
      // explained:
      // always enabled because gaius-challenge is configured globally as well as the manually adding the plugin to the 
      // service. so the only other way to disable this is to tag the service with "no_js_challenge=1"
      return !this.isGaiusChallengeDisableProtection;
    },
    isGaiusChallengeMixed: function () {
      return this.isGaiusChallengeJsProtectionMixed || this.isGaiusChallengeDisableProtectionMixed;
    },
    isGaiusChallengeJsProtection: function () {
      return isPluginEnabled(this.plugins, PLUGIN_NAMES.GAIUS_CHALLENGE);
    },
    isGaiusChallengeJsProtectionMixed: function () {
      return isPluginMixedValue(this.plugins, PLUGIN_NAMES.GAIUS_CHALLENGE);
    },
    isGaiusChallengeDisableProtection: function () {
      if (this.isGaiusChallengeJsProtection == true) {
        return false;
      }
      return Boolean(this.tags.find((t) => t === TAG_NAMES.NO_JS_CHALLENGE));      
    },
    isGaiusChallengeDisableProtectionMixed: function () {
      return Boolean(this.tags.find((t) => t === TAG_NAMES.NO_JS_CHALLENGE_MIXED));
    },
    ipWhitelistTabTitle: function () {
      return this.$t('allow') + ` (${this.ipWhitelist.length})`;
    },
    ipBlacklistTabTitle: function () {
      return this.$t('deny') + ` (${this.ipBlacklist.length})`;
    },
    ipWhitelist: {
      get() {
        return this.getIpRestriction('allow');
      },
      set(value) {
        this.setIpRestriction('allow', value);
      },
    },
    whitelistMode: {
      get() {
        // Return the value you want to expose
        return this.internalWhitelistMode;
      },
      set(value) {
        // Handle the value update
        this.setIpRestriction('whitelistMode', value);
        this.internalWhitelistMode = value; // Update the internal data property
      }
    },
    blacklistMode: {
      get() {
        // Return the value you want to expose
        return this.internalblacklistMode;
      },
      set(value) {
        // Handle the value update
        this.setIpRestriction('blacklistMode', value);
        this.internalblacklistMode = value; // Update the internal data property
      }
    },
    allowSpiders: {
      get() {
        return this.getIpRestriction('allow_spiders');
      },
      set(value) {
        this.setIpRestriction('allow_spiders', value);
      }
    },
    ipBlacklist: {
      get() {
        return this.getIpRestriction('deny');
      },
      set(value) {
        this.setIpRestriction('deny', value);
      },
    },
    protectedPathlist: {
      get() {
        return this.getProtectedPath();
      },
      set(value) {
        this.setProtectedPath(value);
      },
    },
    rateLimitingTimeInterval: {
      get() {
        return this.getGaiusRequestLimitConfigValue('findtime');
      },
      set(value) {
        const check = parseInt(value);
        if (isNaN(check)) {
          return;
        }

        this.setGaiusRequestLimitConfigValue('findtime', value);
      },
    },
    rateLimitingFrequencyOfVisit: {
      get() {
        return this.getGaiusRequestLimitConfigValue('maxretry');
      },
      set(value) {
        const check = parseInt(value);
        if (isNaN(check)) {
          return;
        }

        this.setGaiusRequestLimitConfigValue('maxretry', value);
      },
    },
    rateLimitingBanTime: {
      get() {
        // example value of value is "3h" so in order for the 
        // input-number to display it, we strip the 'h' 
        const value = this.getGaiusRequestLimitConfigValue('bantime');

        return parseInt(value.replace('h', ''));
      },
      set(value) {
        const check = parseInt(value);
        if (isNaN(check)) {
          return;
        }

        this.setGaiusRequestLimitConfigValue('bantime', `${value}h`);
      },
    },
    rateLimitingBanPath: {
      get() {
        return this.getGaiusRequestLimitConfigValue('banpath');
      },
      set(value) { 
        this.setGaiusRequestLimitConfigValue('banpath', value);
      },
    },
    isRateLimitingDynamicContentEnabled: function () {
      return this.getPluginConfigValue(PLUGIN_NAMES.GAIUS_REQUEST_LIMIT, 'only_dynamic');
    },
    crowdsecCaptchaTimeInterval: {
      get() {
        return this.getGaiusCrowdsecCaptchaConfigValue('findtime');
      },
      set(value) {
        const check = parseInt(value);
        if (isNaN(check)) {
          return;
        }

        this.setGaiusCrowdsecCaptchaConfigValue('findtime', value);
      },
    },
    crowdsecCaptchaFrequencyOfVisit: {
      get() {
        return this.getGaiusCrowdsecCaptchaConfigValue('maxretry');
      },
      set(value) {
        const check = parseInt(value);
        if (isNaN(check)) {
          return;
        }

        this.setGaiusCrowdsecCaptchaConfigValue('maxretry', value);
      },
    },
    crowdsecCaptchaBanTime: {
      get() {
        // example value of value is "3h" so in order for the 
        // input-number to display it, we strip the 'h' 
        const value = this.getGaiusCrowdsecCaptchaConfigValue('bantime');

        return parseInt(value.replace('h', ''));
      },
      set(value) {
        const check = parseInt(value);
        if (isNaN(check)) {
          return;
        }

        this.setGaiusCrowdsecCaptchaConfigValue('bantime', `${value}h`);
      },
    },
    reqUrlRule: {
      get() {
        if (!this.plugins.url_rule || !this.plugins.url_rule.length)
          return 0;

        return this.plugins.url_rule[0];
      },
      set(value) {
        const customUrlRule = this.plugins.url_rule;
        const _customUrlRule = [parseInt(value)];
        const defaultReq = 0;

        if (!customUrlRule)
          _customUrlRule.push(defaultReq);
        else if (customUrlRule.length === 2)
          _customUrlRule.push(customUrlRule[1]);

        this.plugins.url_rule = _customUrlRule;
      }
    },
    customUrlRule: {
      get() {
        if (!this.plugins.url_rule || this.plugins.url_rule.length !== 2)
          return '';

        return this.plugins.url_rule[1];
      },
      set(value) {
        const customUrlRuleBase = this.plugins.url_rule;
        const defaultSec = 0;
        let _customUrlRule = [];

        if (!customUrlRuleBase) {
          _customUrlRule.push(defaultSec);
        } else if (customUrlRuleBase.length >= 1) {
          _customUrlRule.push(customUrlRuleBase[0]);
        }

        _customUrlRule.push(value);

        this.plugins.url_rule = _customUrlRule;
      }
    },
    customErrorPage401Input: {
      get() {
        return getPluginConfigValue(this.plugins, PLUGIN_NAMES.GAIUS_CUSTOM_ERROR_PAGE, 'error_401');
      },
      set(value) {
        this.handleCustomErrorPageChange(value, 'error_401');
      }
    },
    customErrorPage404Input: {
      get() {
        return getPluginConfigValue(this.plugins, PLUGIN_NAMES.GAIUS_CUSTOM_ERROR_PAGE, 'error_404');
      },
      set(value) {
        this.handleCustomErrorPageChange(value, 'error_404');
      }
    },
    customErrorPage425Input: {
      get() {
        return getPluginConfigValue(this.plugins, PLUGIN_NAMES.GAIUS_CUSTOM_ERROR_PAGE, 'error_425');
      },
      set(value) {
        this.handleCustomErrorPageChange(value, 'error_425');
      }
    },
    customErrorPage451Input: {
      get() {
        return getPluginConfigValue(this.plugins, PLUGIN_NAMES.GAIUS_CUSTOM_ERROR_PAGE, 'error_451');
      },
      set(value) {
        this.handleCustomErrorPageChange(value, 'error_451');
      }
    },
    customErrorPage502Input: {
      get() {
        return getPluginConfigValue(this.plugins, PLUGIN_NAMES.GAIUS_CUSTOM_ERROR_PAGE, 'error_502');
      },
      set(value) {
        this.handleCustomErrorPageChange(value, 'error_502');
      }
    },
    isCombineGeoIp:{
      get(){
        return this.getPluginConfigValue(PLUGIN_NAMES.GAIUS_GEOIP, 'combine_geoip');
      },
      set(value){
        this.setPluginConfigValue(PLUGIN_NAMES.GAIUS_GEOIP, 'combine_geoip',value);
      }
    },
    displayList() {
      if (this.searchTerm.length) {
        return this.filteredList;
      }
      return this.blackRules;
    },
    displayList: {
      get() {
        if (this.searchTerm.length) {
          return this.filteredList;
        }
        return this.blackRules;
      }      
    },
  },
  mounted() {
    this.mutatedPlugin = this.plugins;
    
    EventBus.$on(Events.SHOW_SHIELD_DETECTION, () => {
      this.activeTab = JS_SHIELD_TAB;
    });
    EventBus.$on('internalWhitelistMode', () => {
      this.internalWhitelistMode='';
    });
    EventBus.$on('internalblacklistMode', () => {
      this.internalblacklistMode='';
    });
    
    EventBus.$on(Events.SHOW_RATE_LIMITING, () => {
      this.activeTab = RATE_LIMITING_TAB;
    });
    
    EventBus.$on(Events.SHOW_GEO_RESTRICTION, () => {
      this.activeTab = GEO_RESTRICTION_TAB;
    });

    EventBus.$on('disableToggleBlackRules', () => {
        this.handleToggleBlackrules(false);
    });
  },
  methods: {
    setMode(mode) {
      this.whitelistMode = mode;
    },
    setModebalckip(mode){
      this.blacklistMode = mode;
    },
    handleToggleCombineGeoIp(checkbox){
      this.isCombineGeoIp = checkbox.target.checked;
    },
    handleChangedTab(activeTab) {      
      this.activeTab = activeTab;      
    },
    handleToggleGaiusRequestLimitDynamicContent(enable) {
      this.setPluginConfigValue(PLUGIN_NAMES.GAIUS_REQUEST_LIMIT, 'only_dynamic', enable);
    },
    handleCustomErrorPageChange(value, fieldKey) {
      let val = value.trim();
      if (val === '')
        val = null;

      if (val && !this.isValidUrl(val)) {
        return;
      }

      this.setPluginConfigValue(PLUGIN_NAMES.GAIUS_CUSTOM_ERROR_PAGE, fieldKey, val);
    },
    isValidUrl: function (value) {
      return Boolean(validUrl.isWebUri(value));
    },
    returnStringIfFailedUrlValidate: function (value, returnValue) {
      if (!value || value === '')
        return '';

      if (!validUrl.isWebUri(value)) {
        return returnValue;
      }

      return '';
    },
    filterCountryOption: function (input, option) {
      return (
          option.data.props.name.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
    getFieldValidationStatus(errors, field) {
      if (!errors)
        return '';
        
      if (errors.hasOwnProperty('config')) {        
        const errorMsg = errors.config[field];
        
        if (!errorMsg)
            return '';
        
        if (errorMsg.constructor === Array) {
            if (errorMsg.length > 0) { return 'error'; } else { return ''; }
        } else {
            return 'error';
        }
      }

      if (errors[field])
        return 'error';

      return '';
    },
    getFieldHelpMessage(errors, field) {
      if (!errors)
        return '';
        
      if (errors.hasOwnProperty('config')) {
        return errors.config[field];
      }

      return errors[field];
    },
    getPluginErrors(pluginName) {      
      const pluginError = this.pluginErrors.find((p) => {
        if (has(p, pluginName)) {
          return p;
        }
      });
      if (!pluginError)
        return null;

      return pluginError[pluginName];
    },
    clearPluginError(pluginName) {
      const pluginError = this.pluginErrors.find((p) => {
        if (has(p, pluginName)) {
          return p;
        }
      });
      if (pluginError)
        pluginError[pluginName] = {};
      return null;
    },    
    getGaiusRequestLimitConfigValue(key) {
      return this.getPluginConfigValue(PLUGIN_NAMES.GAIUS_REQUEST_LIMIT, key);
    },
    setGaiusRequestLimitConfigValue(key, value) {
      let plugin = this.getPlugin(PLUGIN_NAMES.GAIUS_REQUEST_LIMIT);
      if (!plugin) {
        plugin = this.getPluginDefaults(PLUGIN_NAMES.GAIUS_REQUEST_LIMIT);
      }

      const config = {...plugin.config};
      if (key === 'banpath') {
        let testRegex
        try {
          testRegex = new RegExp(value);
        } catch {
          if (testRegex === undefined) {
            let pluginError = this.getPluginErrors(PLUGIN_NAMES.GAIUS_REQUEST_LIMIT);
            if (pluginError) {
              pluginError.config['banpath'] = this.$t('message.InvalidRegexPath');
            } else {
              this.pluginErrors[PLUGIN_NAMES.GAIUS_REQUEST_LIMIT] = {
                config: {
                  banpath: this.$t('message.InvalidRegexPath')
                }
              }; 
            }
            this.$emit('update:pluginErrors', this.pluginErrors);
          }
        }
        config[key] = value;

        this.updatePlugin({...plugin, config: config});
      } else { 
        config[key] = value;
      
        this.updatePlugin({...plugin, config: config}); 
      }
    },
    getGaiusCrowdsecCaptchaConfigValue(key) {
      return this.getPluginConfigValue(PLUGIN_NAMES.GAIUS_CROWDSEC_CAPTCHA, key);
    },
    setGaiusCrowdsecCaptchaConfigValue(key, value) {
      let plugin = this.getPlugin(PLUGIN_NAMES.GAIUS_CROWDSEC_CAPTCHA);
      if (!plugin) {
        plugin = this.getPluginDefaults(PLUGIN_NAMES.GAIUS_CROWDSEC_CAPTCHA);
      }
      const config = {...plugin.config};
      config[key] = value;
      this.updatePlugin({...plugin, config: config});
    },
    getIpRestriction(key) {
      const plugin = this.getPlugin(PLUGIN_NAMES.GAIUS_IP_RESTRICTION);
      if (!plugin) {
        return [];
      }

      return plugin.config[key];
    },
    setIpRestriction(key, value) {
      let plugin = this.getPlugin(PLUGIN_NAMES.GAIUS_IP_RESTRICTION);
      if (!plugin) {
        plugin = this.getPluginDefaults(PLUGIN_NAMES.GAIUS_IP_RESTRICTION);
      }

      const config = {...plugin.config};
      config[key] = value;

      this.updatePlugin({...plugin, config: config});
    },
    getProtectedPath() {
      const plugin = this.getPlugin(PLUGIN_NAMES.GAIUS_SECLINK);

      if (!plugin) {
        return [];
      }

      if (!plugin.config.protection_paths) {
        return [];
      }

      return plugin.config.protection_paths;
    },
    setProtectedPath(value) {
      let plugin = this.getPlugin(PLUGIN_NAMES.GAIUS_SECLINK);
      if (!plugin) {
        plugin = this.getPluginDefaults(PLUGIN_NAMES.GAIUS_SECLINK);
      }

      const config = {...plugin.config};
      config.protection_paths = value;

      this.updatePlugin({...plugin, config: config});
    },
    handleToggleGaiusChallenge(checkbox) {
      // remove no_js_challenge=1 and no_js_challenge=mixed because the user
      // has already clicked on Use Js Protection.
      const tags = this.tags.filter((t) => t !== TAG_NAMES.NO_JS_CHALLENGE && t !== TAG_NAMES.NO_JS_CHALLENGE_MIXED);
      this.updateTags(tags);
      this.togglePlugin(PLUGIN_NAMES.GAIUS_CHALLENGE, checkbox.target.checked);
    },
    handleToggleGaiusChallengeDisableProtection(checkbox) {
      const enabled = checkbox.target.checked;

      let tags = [...this.tags].filter((t) => t !== TAG_NAMES.NO_JS_CHALLENGE);
      if (enabled) {
        tags.push(TAG_NAMES.NO_JS_CHALLENGE);
      }

      // the user already clicked for adding no_js_challenge=1, so we remove
      // no_js_challenge=mixed because it no longer applies.
      tags = tags.filter((t) => t !== TAG_NAMES.NO_JS_CHALLENGE_MIXED);

      this.updateTags(tags);
      this.removeGaiusChallenge();
    },
    removeGaiusChallenge() {
      const plugins = this.plugins.filter((p) => p.name !== PLUGIN_NAMES.GAIUS_CHALLENGE);
      this.updatePlugins(plugins);
    },
    updateTags(tags) {
      this.$emit('update:tags', tags);
    },
    handleToggleRequestLimit(enabled) {
      this.togglePlugin(PLUGIN_NAMES.GAIUS_REQUEST_LIMIT, enabled);
    },
    handleToggleCrowdsecCaptcha(enabled) {
      this.togglePlugin(PLUGIN_NAMES.GAIUS_CROWDSEC_CAPTCHA, enabled);
    },
    handleToggleIpRestriction(enabled) {
      this.togglePlugin(PLUGIN_NAMES.GAIUS_IP_RESTRICTION, enabled);
    },
    handleToggleAllowSpiders(enabled) {
      this.allowSpiders = enabled;
    },
    handleToggleGaiusCustomErrorPage(enabled) {
      this.togglePlugin(PLUGIN_NAMES.GAIUS_CUSTOM_ERROR_PAGE, enabled);
    },
    handleToggleGeoIp(enabled) {
      this.togglePlugin(PLUGIN_NAMES.GAIUS_GEOIP, enabled);
    },
    handleToggleBlackrules(enabled) {
      localStorage.setItem('blackRules', JSON.stringify(this.blackRules));
      this.togglePlugin(PLUGIN_NAMES.GAIUS_BLACKRULES, enabled);
    },
    handleToggleSecLink(enabled) {
      this.togglePlugin(PLUGIN_NAMES.GAIUS_SECLINK, enabled);
      this.setPluginConfigValue(PLUGIN_NAMES.GAIUS_SECLINK, 'enable_sec_redirect', enabled);
    },
    extractNewIps(input, baseEntries, suppressErrorMessage) {
      const ips = [...baseEntries];
      const newEntries = [];
      const rawInputValues = input.split(MULTIPLE_VALUES_SPLITTER);
      for (let r of rawInputValues) {
        if (!this.validateIp(r)) {
          !suppressErrorMessage && this.notifyError(this.$t('message.IPAddressFormatInvalidFor', [r]), this.$t('message.InputDataVerificationError'));
          continue;
        }

        const duplicate = ips.find((_t) => _t === r);
        if (duplicate) {
          !suppressErrorMessage && this.notifyError(this.$t('message.DuplicateEntryFor', [r]));
          continue;
        }

        ips.push(r);
        newEntries.push(r);
      }

      return newEntries;
    },
    filterIpDuplicates(ips, ipsToCompare, label) {
      const finalIps = [];
      for (const i of ips) {
        if (ipsToCompare.includes(i)) {
          this.notifyError(this.$t('message.DuplicateEntryForIn', [i, label]))
          continue;
        }
        
        finalIps.push(i);
      }
      
      return finalIps;
    },
    async addNewIpWhitelist(suppressErrorMessage = false) {
      this.newIpWhitelist = this.newIpWhitelist.trim();
      if (!this.newIpWhitelist.length) {
        return;
      }
      const allowList = this.newIpWhitelist
        .split("\n")
        .map(ip => ip.trim())
        .filter(ip => ip !== "");
      const invalidIps = this.newIpWhitelist
      .split("\n")
      .map(ip => ip.trim())
      .filter(ip => ip !== "")
      .filter(ip => ip.endsWith("/0"));

      if (invalidIps.length > 0) {
        if (!suppressErrorMessage) {
          this.$notification.error({ message: this.$t('message.InvalidUpstream', [this.newIpWhitelist])});
        }
        return;
      }
      const data = {
        "allow": allowList,
        "cname" : this.$store.getters.cname
      };

      const ips_list = {
        "ips": allowList,
      }

      try {
        const response = await ipvalidation(ips_list);
        if (response.data.error){
          this.$notification.error({message: this.$t('ErrorOccurred'), description: response.data.error});
          return;
        }
      }
      catch (error) {
          return;
      }

      try {
        const response = await addwhitelistip(data);
        if (response.data.warning === "Duplicate allow values detected") {
          const duplicateIps = response.data.duplicate_ips;
          const ipsChunks = [];
          for (let i = 0; i < duplicateIps.length; i += 3) {
            ipsChunks.push(duplicateIps.slice(i, i + 3));
          }
          const messageChunks = ipsChunks.map(chunk => chunk.join(', '));
          const message = this.$t('message.DuplicateDetected', [messageChunks.join('\n')]);
          this.$notification.warning({ message });
      }
      } catch (error) {
          return;
      }
      const newIps = this.extractNewIps(this.newIpWhitelist, this.ipWhitelist, suppressErrorMessage);
      // entries that has no duplicate in blacklist
      const finalIps = this.filterIpDuplicates(newIps, this.ipBlacklist, this.$t('Blacklist'));
      
      this.ipWhitelist = [...this.ipWhitelist, ...finalIps];
      this.newIpWhitelist = '';
    },
    removeIpWhitelist(ip) {
      let forRemoval = ip;
      if (!Array.isArray(ip)) {
        forRemoval = [ip];
      }
      
      this.ipWhitelist = this.ipWhitelist.filter((i) => !forRemoval.includes(i));
    },
    async addNewIpBlacklist(suppressErrorMessage = false) {
      this.newIpBlacklist = this.newIpBlacklist.trim();
      if (!this.newIpBlacklist.length) {
        return;
      }

      const invalidIps = this.newIpBlacklist
        .split("\n")
        .map(ip => ip.trim())
        .filter(ip => ip !== "" && ip.endsWith("/0"));
      if (invalidIps.length > 0) {
        if (!suppressErrorMessage) {
          this.$notification.error({ message: this.$t('message.InvalidUpstream', [invalidIps.join(', ')]) });
        }
        return;
      }

      const allowList = this.newIpBlacklist
        .split("\n")
        .map(ip => ip.trim())
        .filter(ip => ip !== "");

      const ips_list = {
        "ips": allowList,
      }
      try {
        const response = await ipvalidation(ips_list);
        if (response.data.error){
          this.$notification.error({message: this.$t('ErrorOccurred'), description: response.data.error});
          return;
        }
      }
      catch (error) {
          return;
      }
      const newIps = this.extractNewIps(this.newIpBlacklist, this.ipBlacklist, suppressErrorMessage);
      // entries that has no duplicate in whitelist
      const finalIps = this.filterIpDuplicates(newIps, this.ipWhitelist, this.$t('Whitelist'));

      this.ipBlacklist = [...this.ipBlacklist, ...finalIps];
      this.newIpBlacklist = '';

    },
    removeIpBlacklist(ip) {
      let forRemoval = ip;
      if (!Array.isArray(ip)) {
        forRemoval = [ip];
      }
      
      this.ipBlacklist = this.ipBlacklist.filter((i) => !forRemoval.includes(i));
    },
    validateIp(value) {
      return Validator.isValidIPv4String(value)[0] || Validator.isValidIPv4CidrNotation(value)[0] || Validator.isValidIPv6String(value)[0] || Validator.isValidIPv6CidrNotation(value)[0];
    },
    processProtectedPathEntry(input, baseEntries, suppressErrorMessage) {
      const protectedPaths = [...baseEntries];
      const rawInputValues = input.split(MULTIPLE_VALUES_SPLITTER);
      for (let r of rawInputValues) {
        const duplicate = protectedPaths.find((_t) => _t === r);
        if (duplicate) {
          !suppressErrorMessage && this.notifyError(this.$t('message.DuplicateEntryFor', [r]));
          continue;
        }
        if (!r.startsWith('/')) {
          !suppressErrorMessage && this.notifyError(this.$t('message.pathsStartWithSlashFor', [r]));
          continue;
        }

        protectedPaths.push(r);
      }

      return protectedPaths;
    },
    addNewProtectedPath(suppressErrorMessage = false) {
      this.newProtectedPathlist = this.newProtectedPathlist.trim()

      if (!this.newProtectedPathlist.length) {
        return;
      }

      this.protectedPathlist = this.processProtectedPathEntry(this.newProtectedPathlist, this.protectedPathlist, suppressErrorMessage);
      this.newProtectedPathlist = '';
    },
    removeProtectedPathlist(protectedPath) {
      this.protectedPathlist = this.protectedPathlist.filter((i) => i !== protectedPath);
    },
    addBlackRules() {
      this.isVisibleDetail = true;
      localStorage.setItem('blackRules', JSON.stringify(this.blackRules));
    },
    handleSearch() {
      this.searchTerm = this.searchTermModel;
      this.filteredList = this.doSearch(this.searchTermModel);
    },
    closeBlackRuleModal() {
      this.isVisibleDetail = false;
      this.isEditingRule = false;      
      this.blackRuleObj = {
        name: '',
        action : "captcha",
        rules: [['AND'], ['METHOD', '~*', 'GET'], ['AGENT', '~*', 'Chrome']],
        is_rate_limit: true,        
        rate_limit_duration : 5,
        rate_limit_capacity : 5,
        bantime : '3h',
        enabled: true
      }
      this.editRuleName = '';
      this.blackRules = JSON.parse(localStorage.getItem('blackRules'));
      if (!this.blackRules) {
        this.blackRules = [];
      }
    },
    saveBlackRuleModal() {
      let getRules = JSON.parse(localStorage.getItem('blackRules'));
        if (!getRules) {
            getRules = this.blackRules;
        } 
        if (this.isEditingRule) {
            const isDuplicateName = getRules.some((rule) => rule.name === this.blackRuleObj.name);
            
            if (isDuplicateName && (this.editRuleName != this.blackRuleObj.name)) {
                this.notifyError("Black Rule with this name already exists")
                return;
            } else {                
              const index = this.blackRules.findIndex((rule) => rule.name === this.editRuleName);                
                if (!this.blackRuleObj.bantime.toString().includes('h')) {
                  this.blackRuleObj.bantime = this.blackRuleObj.bantime + 'h'
                }
                this.blackRules[index] = this.blackRuleObj;
                localStorage.setItem('blackRules', JSON.stringify(this.blackRules));
            }
        } else {          
          const isDuplicateName = getRules.some((rule) => rule.name === this.blackRuleObj.name);
          if (this.blackRules.length >= 5) {
            this.notifyError("Maximum 5 black rules allowed to create")
            return;
          }
          if (isDuplicateName) {
            this.notifyError("Black Rule with this name already exists")
            return;
          } else {   
            if (!this.blackRuleObj.bantime.toString().includes('h')) {
              this.blackRuleObj.bantime = this.blackRuleObj.bantime + 'h'
            }                
            getRules.push(this.blackRuleObj)
            this.blackRules = getRules;
            localStorage.setItem('blackRules', JSON.stringify(this.blackRules));
          }
        }
        this.closeBlackRuleModal();
    },
    editRule(record) {
      const index = this.blackRules.findIndex((rule) => rule.name === record.name);      
      if (index !== -1) {
        this.blackRulesData = this.blackRules;        
        let ruleToEdit = this.blackRules[index];
        if (!ruleToEdit.bantime.toString().includes('h')) {
          ruleToEdit.bantime = ruleToEdit.bantime + 'h';
        }
        localStorage.setItem('blackRules', JSON.stringify(this.blackRules));
        this.blackRuleObj = ruleToEdit;
        this.editRuleName = record.name
        this.isEditingRule = true;
        this.isVisibleDetail = true;
      }
    },
    deleteRule(record) {
      const deleterule = this.blackRules;
      const indexToDelete = deleterule.findIndex(rule => rule.name === record.name);
      if (indexToDelete !== -1) {
        deleterule.splice(indexToDelete, 1);
      }
      this.blackRules = deleterule;
    },
    toggleCustomCaching(record) {
      const rule = this.blackRules.find(r => r.name === record.name);
      if (rule) {
        rule.enabled = !rule.enabled;
      }
    },
    doSearch(searchTerm) {
      const match = [];
      for (const i of this.blackRules) {
        if (i.name.toLowerCase().includes(searchTerm.toLowerCase())) {
          match.push(i);
        }
      }
      return match;
    },
  }
}
</script>
