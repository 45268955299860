<template>    
  <CListGroup v-if="!isLoading">
      <DgStatus :dgItem="dg.init" :dgName="$t('Initialization')"></DgStatus>
      <DgStatus :dgItem="dg.cname_query" :dgName="$t('domain.CNAMECheck')"></DgStatus>
      <DgStatus :dgItem="dg.cname_visit" :dgName="$t('domain.CNAMEAccess')"></DgStatus>
      <DgStatus :dgItem="dg.source_setup" :dgName="$t('domain.SourceCdnCheck')"></DgStatus>
      <DgStatus :dgItem="dg.source_visit" :dgName="$t('domain.OriginSiteVisitCheck')"></DgStatus>
      <DgStatus :dgItem="dg.source_cname" :dgName="$t('domain.SourceCdnPointingCheck')"></DgStatus>
      <DgStatus :dgItem="dg.ssl_visit" :dgName="$t('domain.OriginSiteHttpsAccessCheck')"></DgStatus>
      <DgStatus :dgItem="dg.site_visit" :dgName="$t('domain.SiteVisitCheck')"></DgStatus>
      <DgStatus :dgItem="dg.site_visit_v6" :dgName="$t('domain.SiteVisitV6Check')"></DgStatus>

      <CListGroupItem
        class="d-flex justify-content-between align-items-center"
        >
        <CCard borderColor="white" v-if="dg.source_visit[0]==false">
          <CCardHeader>{{ $t('domain.OriginSiteAccessDetection') }}</CCardHeader>
          <CCardBody>
            <CAlert color="warning">
              <p>{{ $t('domain.DomainName') }}：<strong>{{ domain ? domain.getMainHost() : '' }}</strong></p>
              <p>{{ $t('Source') }}:  <strong>{{ domain && domain.getUpstreamTargets()[0] ? domain.getUpstreamTargets()[0].target : '' }}</strong></p>
            </CAlert>
            
            <CAlert color="danger" v-if="dg.source_visit[1]=='HTTPS is required'">
              <p>{{ $t('message.cdn_detects_mandatory_https') }}</p>
            </CAlert>
            <CAlert color="danger" v-else>
              <p>{{ $t('domain.SourceDetectionReturnedAnError') }}： {{ dg.source_visit[1] }} </p>
            </CAlert>

            <p class="text-right">
              <CButton color="success" @click="refresh">
              <CIcon name="cil-reload" />
              {{ $t("Refresh")}}
              </CButton>
            </p>
          </CCardBody>
        </CCard>
        <CCard borderColor="white" v-else-if="dg.source_cname[0]==false">
          <CCardHeader>
            <p>{{ $t('message.add_following_cname_then_refresh') }}</p>
            <p>{{ $t('message.cname_operator_message') }}</p>
          </CCardHeader>
          <CCardBody>
            <CAlert color="warning">
              <p>{{ $t('domain.DomainName') }}：<strong>{{ domain ? domain.getMainHost() : '' }}</strong></p>
              <p v-if="domain && domain._data.tags && domain._data.tags.find((t) => t.includes('cname'))">{{ $t('domain.Cname') }}:  <strong>{{ domain._data.tags.find((t) => t.includes('cname')).split('"')[1] }}</strong></p>
            </CAlert>
            <a href='https://wiki.suyuncdn.com/public/howto/cname' target='_blank'>{{ $t('ViewConfigurationHelp') }}</a>
            
            <p class="text-right">
              <CButton color="success" @click="refresh">
              <CIcon name="cil-reload" />
              {{ $t("Refresh")}}
              </CButton>
            </p>
          </CCardBody>
        </CCard>
        <CCard borderColor="white" v-else-if="dg.source_visit[0]==false">
          <CCardHeader>{{ $t('domain.OriginCheckError') }}</CCardHeader>
          <CCardBody>
            <CAlert color="warning">
              <p>{{ $t('domain.SourceSiteAccessAbnormal') }}</p>
              <p>{{ $t('message.your_website_does_not_provide_access') }}</p>
              <p>{{ $t('contact_us.contact_service_message') }}</p>
            </CAlert>
            <p class="text-right">
              <CButton color="success" @click="refresh">
              <CIcon name="cil-reload" />
              {{ $t("Refresh")}}
              </CButton>
            </p>
          </CCardBody>
        </CCard>
        
      </CListGroupItem>
      <CListGroupItem v-if="!hideContactUs">
        <strong>{{ $t('contact_us.having_problems_message') }}</strong>
        <Contacts />
      </CListGroupItem>
    </CListGroup>
</template>

<script>
import DgStatus from "./DgStatus.vue"
import Contacts from '@/containers/Contacts'
import { DomainAction } from '@/utilities/websocket';
import { Websocket } from "@/plugins/websocket";
import {SCREEN_TYPES, SCREEN_SIZES} from '@/utilities/constants';

export default {
  name: "BatchDiagnoseDetail",
  components: {DgStatus, Contacts},
  data() {
    return {      
      screenSize: SCREEN_TYPES.LG,
      domain_ws: null
    };
  },
  computed: {
    modalWidth() {
      if( this.screenSize === SCREEN_TYPES.XS ){
          return '100%';
      }

      if( this.screenSize === SCREEN_TYPES.SM){
        return '75%';
      }

      if( this.screenSize === SCREEN_TYPES.MD ){
        return '65%';
      }

      return "50%";
    }
  },
  watch: {
    diagnoseModal() {
      if (this.diagnoseModal) {
          this.domain_ws.sendMessage(DomainAction.DOMAIN_CHECKER, {
            route_id: this.domain.getId(),
            row: this.domain
          });
      } else {
        this.isLoading = true;
      }
    }
  },
  props: {
    domain: Object,
    dg: Object,
    hideContactUs: {
      type: Boolean,
      default: false
    },
    diagnoseModal: Boolean,
    diagnoseTitle: String,
    isLoading: Boolean    
  },
  methods: {
    openWs() {
        this.domain_ws = new Websocket(`domain/`);
        
        this.domain_ws.onopen = function(event) {
            console.log("Successfully connected to the domain websocket server...")
        }
        
        this.domain_ws.onMessage = function(event, result) {
            if (result.action === DomainAction.DOMAIN_CHECKER) {                
                this.dg = result.data.data;
                this.isLoading = false;
            }
        }.bind(this)        
    },
    handleOk() {
      this.$emit('update:diagnoseModal', false);
    },
    handleCloseModal() {
      this.$emit('update:diagnoseModal', false);
    },
    handleScreenResize(){
      const width = document.documentElement.clientWidth;

      if( width <= SCREEN_SIZES.XS ){
        this.screenSize = SCREEN_TYPES.XS;
        return;
      }

      if( width <= SCREEN_SIZES.SM ){
        this.screenSize = SCREEN_TYPES.SM;
        return;
      }

      if( width <= SCREEN_SIZES.MD ){
        this.screenSize = SCREEN_TYPES.MD;
        return;
      }

      this.screenSize = SCREEN_TYPES.LG
    },
    refresh() {
      this.isLoading = true;
      this.domain_ws.sendMessage(DomainAction.DOMAIN_CHECKER, {
        route_id: this.domain.getId(),
        row: this.domain
      });
    }
  },
  mounted() {
    this.openWs();
    window.addEventListener('resize', this.handleScreenResize);
  }
};
</script>