<template>
  <div :class="{'domain-list-item': true, 'd-flex': true, 'flex-column': true, 'selected': isSelected}">
    <div class="actionable-buttons px-5 py-2 d-flex align-items-center">
      <div>
        <input type="checkbox" v-model="isSelected" @change="handleSelectBoxChange" class="select-box">
      </div>

      <div class="d-flex flex-fill justify-content-between align-items-center">
        <div class="plugin-indicators d-flex">
          <!-- cert -->
          <div>
            <a-tooltip placement="bottom" :mouse-enter-delay="0.5">
              <template slot="title">
                <span v-if="domain.hasCert()">{{ $t('message.CertificateActive') }}</span>
                <span v-else>{{ $t('message.NoCertificateActive') }}</span>
              </template>
              <button @click="showSslModal" type="button"
                      :class="{'btn': true, 'btn-sm': true, 'text-success': domain.hasCert()}">
                <!-- solid icon -->
                <svg v-if="domain.isForceHttps()" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
                     fill="currentColor">
                  <path fill-rule="evenodd"
                        d="M12 1.5a5.25 5.25 0 00-5.25 5.25v3a3 3 0 00-3 3v6.75a3 3 0 003 3h10.5a3 3 0 003-3v-6.75a3 3 0 00-3-3v-3c0-2.9-2.35-5.25-5.25-5.25zm3.75 8.25v-3a3.75 3.75 0 10-7.5 0v3h7.5z"
                        clip-rule="evenodd"/>
                </svg>
                <!-- outline icon -->
                <svg v-else xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                     stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round"
                        d="M16.5 10.5V6.75a4.5 4.5 0 10-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 002.25-2.25v-6.75a2.25 2.25 0 00-2.25-2.25H6.75a2.25 2.25 0 00-2.25 2.25v6.75a2.25 2.25 0 002.25 2.25z"/>
                </svg>
              </button>
            </a-tooltip>
          </div>

          <!-- cache -->
          <div>
            <a-tooltip placement="bottom" :mouse-enter-delay="0.5">
              <template slot="title">
                <span v-if="isProxyCacheEnabled">{{ $t('message.CacheStrategyEnabled') }}</span>
                <span v-else>{{ $t('message.CacheStrategyDisabled') }}</span>
              </template>
              <button @click="showCacheStrategy" type="button"
                      :class="{'btn': true, 'btn-sm': true, 'text-success': isProxyCacheEnabled}">
                <!-- solid icon -->
                <svg v-if="isProxyCacheEnabled" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
                     fill="currentColor">
                  <path d="M21 6.375c0 2.692-4.03 4.875-9 4.875S3 9.067 3 6.375 7.03 1.5 12 1.5s9 2.183 9 4.875z"/>
                  <path
                      d="M12 12.75c2.685 0 5.19-.586 7.078-1.609a8.283 8.283 0 001.897-1.384c.016.121.025.244.025.368C21 12.817 16.97 15 12 15s-9-2.183-9-4.875c0-.124.009-.247.025-.368a8.285 8.285 0 001.897 1.384C6.809 12.164 9.315 12.75 12 12.75z"/>
                  <path
                      d="M12 16.5c2.685 0 5.19-.586 7.078-1.609a8.282 8.282 0 001.897-1.384c.016.121.025.244.025.368 0 2.692-4.03 4.875-9 4.875s-9-2.183-9-4.875c0-.124.009-.247.025-.368a8.284 8.284 0 001.897 1.384C6.809 15.914 9.315 16.5 12 16.5z"/>
                  <path
                      d="M12 20.25c2.685 0 5.19-.586 7.078-1.609a8.282 8.282 0 001.897-1.384c.016.121.025.244.025.368 0 2.692-4.03 4.875-9 4.875s-9-2.183-9-4.875c0-.124.009-.247.025-.368a8.284 8.284 0 001.897 1.384C6.809 19.664 9.315 20.25 12 20.25z"/>
                </svg>
                <!-- outline icon -->
                <svg v-else xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                     stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round"
                        d="M20.25 6.375c0 2.278-3.694 4.125-8.25 4.125S3.75 8.653 3.75 6.375m16.5 0c0-2.278-3.694-4.125-8.25-4.125S3.75 4.097 3.75 6.375m16.5 0v11.25c0 2.278-3.694 4.125-8.25 4.125s-8.25-1.847-8.25-4.125V6.375m16.5 0v3.75m-16.5-3.75v3.75m16.5 0v3.75C20.25 16.153 16.556 18 12 18s-8.25-1.847-8.25-4.125v-3.75m16.5 0c0 2.278-3.694 4.125-8.25 4.125s-8.25-1.847-8.25-4.125"/>
                </svg>
              </button>
            </a-tooltip>
          </div>

          <!-- shield -->
<!--          <div>-->
<!--            <a-tooltip placement="bottom" :mouse-enter-delay="0.5">-->
<!--              <template slot="title">-->
<!--                <span v-if="isGaiusChallengeEnabled">{{ $t('message.ShieldDetectionEnabled') }}</span>-->
<!--                <span v-else>{{ $t('message.ShieldDetectionDisabled') }}</span>-->
<!--              </template>-->
<!--              <button @click="showJsShield" type="button"-->
<!--                      :class="{'btn': true, 'btn-sm': true, 'text-success': isGaiusChallengeEnabled}">-->
<!--                &lt;!&ndash; solid icon &ndash;&gt;-->
<!--                <svg v-if="isGaiusChallengeEnabled" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"-->
<!--                     fill="currentColor">-->
<!--                  <path fill-rule="evenodd"-->
<!--                        d="M12.516 2.17a.75.75 0 00-1.032 0 11.209 11.209 0 01-7.877 3.08.75.75 0 00-.722.515A12.74 12.74 0 002.25 9.75c0 5.942 4.064 10.933 9.563 12.348a.749.749 0 00.374 0c5.499-1.415 9.563-6.406 9.563-12.348 0-1.39-.223-2.73-.635-3.985a.75.75 0 00-.722-.516l-.143.001c-2.996 0-5.717-1.17-7.734-3.08zm3.094 8.016a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z"-->
<!--                        clip-rule="evenodd"/>-->
<!--                </svg>-->
<!--                &lt;!&ndash; outline icon &ndash;&gt;-->
<!--                <svg v-else xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"-->
<!--                     stroke="currentColor">-->
<!--                  <path stroke-linecap="round" stroke-linejoin="round"-->
<!--                        d="M9 12.75L11.25 15 15 9.75m-3-7.036A11.959 11.959 0 013.598 6 11.99 11.99 0 003 9.749c0 5.592 3.824 10.29 9 11.623 5.176-1.332 9-6.03 9-11.622 0-1.31-.21-2.571-.598-3.751h-.152c-3.196 0-6.1-1.248-8.25-3.285z"/>-->
<!--                </svg>-->
<!--              </button>-->
<!--            </a-tooltip>-->
<!--          </div>-->

          <!-- rate limit -->
          <div>
            <a-tooltip placement="bottom" :mouse-enter-delay="0.5">
              <template slot="title">
                <span v-if="isGaiusRequestLimitEnabled">{{ $t('message.RateLimitingEnabled') }}</span>
                <span v-else>{{ $t('message.RateLimitingDisabled') }}</span>
              </template>
              <button @click="showRateLimiting" type="button"
                      :class="{'btn': true, 'btn-sm': true, 'text-success': isGaiusRequestLimitEnabled}">
                <!-- solid icon -->
                <svg v-if="isGaiusRequestLimitEnabled" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
                     fill="currentColor">
                  <path fill-rule="evenodd"
                        d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zM12.75 6a.75.75 0 00-1.5 0v6c0 .414.336.75.75.75h4.5a.75.75 0 000-1.5h-3.75V6z"
                        clip-rule="evenodd"/>
                </svg>
                <!-- outline icon -->
                <svg v-else xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                     stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round"
                        d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z"/>
                </svg>
              </button>
            </a-tooltip>
          </div>

          <!-- geo restriction -->
          <div>
            <a-tooltip placement="bottom" :mouse-enter-delay="0.5">
              <template slot="title">
                <span v-if="isGaiusGeoIpEnabled">{{ $t('message.GeoRestrictionEnabled') }}</span>
                <span v-else>{{ $t('message.GeoRestrictionDisabled') }}</span>
              </template>
              <button @click="showGeoRestriction" type="button"
                      :class="{'btn': true, 'btn-sm': true, 'text-success': isGaiusGeoIpEnabled}">
                <!-- solid icon -->
                <svg v-if="isGaiusGeoIpEnabled" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
                     fill="currentColor">
                  <path
                      d="M21.721 12.752a9.711 9.711 0 00-.945-5.003 12.754 12.754 0 01-4.339 2.708 18.991 18.991 0 01-.214 4.772 17.165 17.165 0 005.498-2.477zM14.634 15.55a17.324 17.324 0 00.332-4.647c-.952.227-1.945.347-2.966.347-1.021 0-2.014-.12-2.966-.347a17.515 17.515 0 00.332 4.647 17.385 17.385 0 005.268 0zM9.772 17.119a18.963 18.963 0 004.456 0A17.182 17.182 0 0112 21.724a17.18 17.18 0 01-2.228-4.605zM7.777 15.23a18.87 18.87 0 01-.214-4.774 12.753 12.753 0 01-4.34-2.708 9.711 9.711 0 00-.944 5.004 17.165 17.165 0 005.498 2.477zM21.356 14.752a9.765 9.765 0 01-7.478 6.817 18.64 18.64 0 001.988-4.718 18.627 18.627 0 005.49-2.098zM2.644 14.752c1.682.971 3.53 1.688 5.49 2.099a18.64 18.64 0 001.988 4.718 9.765 9.765 0 01-7.478-6.816zM13.878 2.43a9.755 9.755 0 016.116 3.986 11.267 11.267 0 01-3.746 2.504 18.63 18.63 0 00-2.37-6.49zM12 2.276a17.152 17.152 0 012.805 7.121c-.897.23-1.837.353-2.805.353-.968 0-1.908-.122-2.805-.353A17.151 17.151 0 0112 2.276zM10.122 2.43a18.629 18.629 0 00-2.37 6.49 11.266 11.266 0 01-3.746-2.504 9.754 9.754 0 016.116-3.985z"/>
                </svg>
                <!-- outline icon -->
                <svg v-else xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                     stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round"
                        d="M12 21a9.004 9.004 0 008.716-6.747M12 21a9.004 9.004 0 01-8.716-6.747M12 21c2.485 0 4.5-4.03 4.5-9S14.485 3 12 3m0 18c-2.485 0-4.5-4.03-4.5-9S9.515 3 12 3m0 0a8.997 8.997 0 017.843 4.582M12 3a8.997 8.997 0 00-7.843 4.582m15.686 0A11.953 11.953 0 0112 10.5c-2.998 0-5.74-1.1-7.843-2.918m15.686 0A8.959 8.959 0 0121 12c0 .778-.099 1.533-.284 2.253m0 0A17.919 17.919 0 0112 16.5c-3.162 0-6.133-.815-8.716-2.247m0 0A9.015 9.015 0 013 12c0-1.605.42-3.113 1.157-4.418"/>
                </svg>
              </button>
            </a-tooltip>
          </div>

          <div>
            <a-tooltip 
              placement="bottom" 
              :mouse-enter-delay="0.5" 
              :title="$moment(domain.getDeployedTime()).format('MMMM Do YYYY, h:mm:ss a')">
              
              <a-button type="dashed" :class="{'btn': true, 'btn-sm': true, 'mx-2': true }">
                {{ deploymentMessage }}
              </a-button>
              
            </a-tooltip>
          </div>
        </div>

        <div class="action-buttons d-flex">
          <button @click="showSslModal" type="button" class="btn btn-link">{{ $t('domain.ConfigureSSL') }}</button>
          <button @click="showDiagnose" type="button" class="btn btn-link">{{ $t('domain.Diagnose') }}</button>
          <button @click="showApplyRule" type="button" class="btn btn-link">{{ $t('rule.ApplyRule') }}</button>
          <button @click="showEditDomain" type="button" class="btn btn-link">{{ $t('Edit') }}</button>
        </div>
      </div>
    </div>

    <div class="px-5 py-4 d-flex">
      <div class="w-100 d-flex flex-column justify-content-center">
        <div class="d-flex align-items-center">
          <a-popover placement="right" :visible="isTooltipVisible">
            <template slot="content">
              <div class="d-flex align-items-center" v-if="tooltipData">
                <a-icon v-if="tooltipData.type === 'success'" type="check-circle" theme="twoTone" two-tone-color="#52c41a" class="mr-2" /> 
                <a-icon v-if="tooltipData.type === 'error'" type="exclamation-circle" theme="twoTone" two-tone-color="#eb2f96" class="mr-2" />
                {{ tooltipData.content }}
              </div>
            </template>
            <h3 class="mb-0 mr-1">{{ domain.getMainHost() }}</h3>
          </a-popover>
          
          <div>
            <ExclusiveCnamePopover v-if="!isFreeCname" :value="generateExclusiveCname()"/>
          </div>
          <a-tag v-if="domain.getStatus() === 'Running'" color="green">
            {{ domain.getStatus() }}
          </a-tag>
          <a-tag v-if="domain.getStatus() === 'Deactivated'">
            {{ domain.getStatus() }}
          </a-tag>
        </div>

        <section class="shadow-domains" v-if="domain.getShadows().length">
          <ul v-if="domain.getShadows().length >= 3" class="p-0">
            <li class="mr-2">{{ domain.getShadows()[0] }}</li>
            <li class="mr-2">{{ domain.getShadows()[1] }}</li>
            <li><em>{{ domain.getShadows().length - 2 }} more</em></li>
          </ul>
          <ul v-else class="p-0">
            <li v-for="s in domain.getShadows()" :key="s" class="mr-2">{{ s }}</li>
          </ul>
        </section>

        <section class="notes mt-4" v-if="domain._meta && domain._meta.description && domain.getDescription().length > 0">
          <header>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                 stroke="currentColor" class="mr-1">
              <path stroke-linecap="round" stroke-linejoin="round"
                    d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125"/>
            </svg>
            <span>{{ $t('notes') }}</span>
          </header>
          <p class="notes w-75">
            {{ truncateLongNotes(domain.getDescription()) }}
          </p>
        </section>
      </div>

      <div class="w-25 d-flex justify-content-end">
        <section class="domain-action d-flex flex-column align-items-end">
          <header>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                 stroke="currentColor" class="mr-1">
              <path stroke-linecap="round" stroke-linejoin="round"
                    d="M12.75 15l3-3m0 0l-3-3m3 3h-7.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"/>
            </svg>
            <span v-if="domain.isRedirectDomainAction()">{{ $t('domain.RedirectToUrl') }}</span>
            <span v-else>{{ $t('domain.Upstream') }}</span>
          </header>
          <div>
            <ul v-if="domain.isRedirectDomainAction()" class="domain-action-target mt-1 p-0">
              <li>{{ domain.getRedirectUrl() }}</li>
            </ul>
            <ul v-else-if="domain.getUpstreamTargets().length >= 3" class="domain-action-target mt-1 p-0">
              <li class="ml-3">{{ domain.getUpstreamTargets()[0].target }}</li>
              <li class="ml-3" @click="showAllTargets">
                <em v-if="showFullList">{{ domain.getUpstreamTargets().slice(1).map(target => target.target).join(', ') }}</em>
                <em v-else>{{ domain.getUpstreamTargets().length - 1 }} more</em>
              </li>
              <li v-if="showFullList" class="ml-3">
                <em @click="toggleList">Hide</em>
              </li>
            </ul>
            <ul v-else class="domain-action-target mt-1 p-0">
              <li v-for="t in domain.getUpstreamTargets()" :key="t.id" class="ml-3">{{ t.target }}</li>
            </ul>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import {truncate} from 'lodash';
import {EventBus, Events} from '@/plugins/event-bus';
import {isPluginEnabled} from '@/utilities/plugin-actions';
import {PLUGIN_NAMES, TAG_NAMES} from '@/utilities/constants';
import ExclusiveCnamePopover from '/src/views/domain/ExclusiveCnamePopover';


export default {
  props: ['domain', 'isFreeCname'],
  components: {
    ExclusiveCnamePopover,
  },
  computed: {
    isProxyCacheEnabled: function () {
      return isPluginEnabled(this.domain.getPlugins(), PLUGIN_NAMES.GAIUS_PROXY_CACHE);
    },
    isGaiusChallengeEnabled: function () {
      if (isPluginEnabled(this.domain.getPlugins(), PLUGIN_NAMES.GAIUS_CHALLENGE)) {
        return true;
      }

      const hasNoJsTag = Boolean(this.domain.getServiceTags().find((t) => t === TAG_NAMES.NO_JS_CHALLENGE));

      // if there is a no js tag, then gaius challenge is disabled.
      return !hasNoJsTag;
    },
    isGaiusRequestLimitEnabled: function () {
      return isPluginEnabled(this.domain.getPlugins(), PLUGIN_NAMES.GAIUS_REQUEST_LIMIT);
    },
    isGaiusGeoIpEnabled: function () {
      return isPluginEnabled(this.domain.getPlugins(), PLUGIN_NAMES.GAIUS_GEOIP);
    },
    isTooltipVisible: function() {
      return Boolean(this.tooltipData);
    },
    deploymentMessage: function() {
      if (!this.domain.getDeployedTime()) return this.$t('domain.NoDeploymentMessage');
      return this.$t('domain.LastDeployed') + ' ' + this.$moment(this.domain.getDeployedTime()).from(this.currentTime);
    }
  },
  data() {
    return {
      isSelected: false,
      tooltipData: null,
      showFullList: false,
      currentTime: this.$moment(),
      intervalId: null,
    }
  },
  mounted() {
    EventBus.$on(Events.SELECT_ALL_DOMAIN, this.selectAllDomain);

    EventBus.$on(Events.DESELECT_ALL_DOMAIN, this.deselectAllDomain);

    EventBus.$on(Events.DOMAIN_TOOLTIP_MESSAGE, this.onDomainTooltipMessage);

    this.intervalId = setInterval(() => {
      this.currentTime = this.$moment();
    }, 60000)
  },
  beforeDestroy() {
    EventBus.$off(Events.SELECT_ALL_DOMAIN, this.selectAllDomain);

    EventBus.$off(Events.DESELECT_ALL_DOMAIN, this.deselectAllDomain);

    EventBus.$off(Events.DOMAIN_TOOLTIP_MESSAGE, this.onDomainTooltipMessage);

    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  },
  methods: {
    showAllTargets() {
      this.showFullList = !this.showFullList;
    },
    toggleList() {
      this.showFullList = !this.showFullList;
    },
    onDomainTooltipMessage(data) {
      // bail out. the data is not for this domain.
      if (data.domain_id !== this.domain.getId()) {
        return;
      }

      this.tooltipData = data;

      setTimeout(() => {
        this.tooltipData = null;
      }, 5000);
    },
    selectAllDomain() {
      this.doSelectBoxChange(true);
    },
    deselectAllDomain() {
      this.isSelected = false;
    },
    truncateLongNotes(notes) {
      return truncate(notes, {length: 250})
    },
    showDiagnose() {
      EventBus.$emit(Events.SHOW_DIAGNOSE_DOMAIN_DIALOG, {domain: this.domain});
    },
    showApplyRule() {
      EventBus.$emit(Events.SHOW_APPLY_RULE_DIALOG, {domain: this.domain});
    },
    showEditDomain() {
      localStorage.removeItem('blackRules');
      EventBus.$emit(Events.SHOW_EDIT_DOMAIN_DIALOG, {domain: this.domain});
    },
    showSslModal() {
      EventBus.$emit(Events.SHOW_CONFIGURE_SSL_DIALOG, {domain: this.domain});
    },
    showCacheStrategy() {
      EventBus.$emit(Events.SHOW_EDIT_DOMAIN_DIALOG_SHOW_ADVANCED_CONFIGURATION, {domain: this.domain});

      // this is a hack! the EventBus.$on is triggered in AdvancedConfiguration when it is mounted.
      // but since the AdvancedConfiguration component will only be mounted
      // when its container tab pane is active in EditDomain component, we need to do another emit for another
      // event that actually shows the cache strategy. but we do it after a timeout
      // to give time for AdvancedConfiguration to be mounted.
      setTimeout(() => {
        EventBus.$emit(Events.SHOW_CACHE_STRATEGY);
      }, 10);
    },
    showJsShield() {
      this.showSecuritySettingSection(Events.SHOW_SHIELD_DETECTION);
    },
    showRateLimiting() {
      this.showSecuritySettingSection(Events.SHOW_RATE_LIMITING);
    },
    showGeoRestriction() {
      this.showSecuritySettingSection(Events.SHOW_GEO_RESTRICTION);
    },
    showSecuritySettingSection(section) {
      EventBus.$emit(Events.SHOW_EDIT_DOMAIN_DIALOG_SHOW_SECURITY_SETTING, {domain: this.domain});

      // this is a hack! the EventBus.$on is triggered in SecuritySetting when it is mounted.
      // but since the SecuritySetting component will only be mounted
      // when its container tab pane is active in EditDomain component, we need to do another emit for another
      // event that actually shows the cache strategy. but we do it after a timeout
      // to give time for SecuritySetting to be mounted.
      setTimeout(() => {
        EventBus.$emit(section);
      }, 10);
    },
    handleSelectBoxChange(e) {
      this.doSelectBoxChange(e.target.checked);
    },
    doSelectBoxChange(checked) {
      this.isSelected = checked;

      EventBus.$emit(checked ? Events.SELECT_DOMAIN : Events.DESELECT_DOMAIN, {domain: this.domain});
    },
    generateExclusiveCname() {
      const safeHosts = this.domain.getSafeHosts();
      const exclusiveCnameDomain = safeHosts.find((sf) => sf.host === this.domain.getMainHost()).safe_host;

      return `${exclusiveCnameDomain}.${this.$store.getters.cname}`;
    },
  },
}
</script>

<style lang="scss" scoped>
.domain-list-item.selected {
  border: 2px solid orange;
}

h3 {
  color: #0EA5E9;
  font-size: 1.35em;
}

.plugin-indicators {
  svg {
    height: 20px;
    width: 20px;
  }
}

.domain-cname-button {
  svg {
    height: 20px;
    width: 20px;
  }
}

section.shadow-domains {
  ul {
    li {
      display: inline-block;
    }
  }
}

section.notes {
  header {
    color: #94A3B8;
    font-size: 0.8em;

    svg {
      width: 14px;
      height: 14px;
    }
  }
}

section.domain-action {
  header {
    color: #94A3B8;
    font-size: 0.8em;

    svg {
      width: 14px;
      height: 14px;
    }
  }
}

.domain-action-target {
  text-align: right;
  list-style-type: none;
  color: #0EA5E9;
  display: table-caption;


  li {
    display: inline-block;
    cursor: pointer;

  }
}

.actionable-buttons {
  background-color: #f9fafb;
  border-bottom: 2px solid #E2E8F0;
}

.select-box {
  margin-left: -1.75rem;
  margin-right: 0.5rem;
}
</style>
