import {PLUGIN_NAMES} from '@/utilities/constants';
import { TAG_NAMES } from '../utilities/constants';

export class Route {
  constructor(data, meta = null) {
    this._data = data || {};
    this._meta = meta || {};
  }
  
  getId() {
    return this._data.id;
  }
  
  getSafeHosts() {
    return this._data.safe_hosts || [];
  }

  getRawData() {
    return this._data;
  }

  getRawMeta() {
    return this._meta;
  }
  
  isNew() {
    return !this._data.id;
  }

  getHosts() {
    return this._data.hosts || [];
  }

  getMainHost() {
    return this.getHosts()[0];
  }

  getStatus() {
    return this._data.status;
  }

  hasCert() {
    return this._data.has_cert;
  }
  
  getProtocols() {
    if (!this._data)
      return [];
    
    return this._data.protocols || ['http', 'https']; // http and https are Kong defaults
  }

  isForceHttps() {
    if (!this._data) {
      return false;
    }
    return this._data.protocols.length === 1 && this._data.protocols[0] === 'https';
  }

  getShadows() {
    const [_, ...shadows] = this.getHosts();

    return shadows;
  }
  
  getServiceHost() {
    if (!this._data.service)
      return '';

    return this._data.service.host;
  }
  
  getServicePath() {
    if (!this._data.service)
      return '/';

    return this._data.service.path;
  }
  
  getServiceTags() {
    if (!this._data.service)
      return [];

    return this._data.service.tags || [];
  }

  getCname() { 
    let tags = this.getServiceTags();
    if (!tags) {
      return ''
    }
    let cname = ''
    tags.forEach(item => {
      if (item.includes("cname")) {
        cname = item
      }
    });
    cname = cname.replaceAll('cname="', '').replaceAll('"', '')
    return cname;
  }

  hasAutoGenerateCertificate() {
    let tags = this.getServiceTags();

    if (!tags) {
      return false
    }
    return tags.includes(TAG_NAMES.HAS_AUTO_GENERATE_CERTIFICATE)
  }
  
  getUpstreamTargets() {
    if (!this._data.upstream) {
      return [];
    }
    
    return this._data.upstream.targets || [];
  }
  
  getServiceProtocol() {
    if (!this._data.service) {
      return '';
    }
    
    return this._data.service.protocol;
  }
  
  getDescription() {
    return this._meta.description || '';
  }
  
  getPlugins() {
    if (!this._data.plugins) {
      return [
        {
          'name': 'gaius-proxy-cache',
          'config': {
              'request_method': ['GET', 'HEAD', 'OPTIONS'],
              'response_code': [200, 301, 302],
              'cache_postfixes': [],
              'bypass_rules': [],
              'cache_paths': ["/static", "/uploads"],
              'enable_cache_control': false,
              'enable_cache_fullsize': false
          },
          'enabled': true
      }
  ];
}
    return this._data.plugins || [];
  }
  
  isRedirectDomainAction() {
    const plugins = this.getPlugins();
    
    for (let p of plugins) {
      if (p.name === PLUGIN_NAMES.GAIUS_REDIRECT) {
        return true;
      }
    }
    
    return false;
  }
  
  getRedirectUrl() {
    const plugins = this.getPlugins();

    for (let p of plugins) {
      if (p.name === PLUGIN_NAMES.GAIUS_REDIRECT) {
        return p.config.redirect_url;
      }
    }
    
    return null;
  }

  getDeployedTime() {
    if (!this._data.service) {
      return '';
    }

    return this._data.service.updated_at;
  }
}
