<template>
  <div class="mixed-selection-empty">
    <a-empty>
      <span slot="image">
        <a-icon type="disconnect" :style="{ fontSize: '50px', color: 'lightgray' }" />
      </span>
      <span slot="description" class="text-muted">Mixed selection</span>
    </a-empty>
  </div>
</template>

<style scoped>
.mixed-selection-empty {
  border-radius: 0.25rem;
  border: 1px solid #d8dbe0;
}
</style>

<style>
.mixed-selection-empty .ant-empty {
  padding: 30px;
}
.mixed-selection-empty .ant-empty .ant-empty-image {
  height: 40px;
  margin-bottom: 12px;
}
</style>

<script>
import {Empty} from 'ant-design-vue';

export default {
  beforeCreate() {
    this.simpleImage = Empty.PRESENTED_IMAGE_SIMPLE;
  }
}
</script>