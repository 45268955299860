<template>
  <a-modal :visible="patchShow"
           :centered="true"
           :title="$t('domain.BulkDomainConfiguration')"
           @ok="save"
           @cancel="$emit('hide')"
           :closable="false"
           :width="modalWidth">

    <a-tabs :animated="false">
      <a-tab-pane key="basic" :tab="$t('domain.BasicConfiguration')">
        <div v-if="patchDomain">
          <BasicConfiguration
              ref="basicConfiguration"
              :domain-edit-disabled="true"
              :batch-edit="true"
              :domain="patchDomain"
              v-on:update:domain="handleDomainUpdate"
              :upstream-targets="domainFormData.upstream.targets"
              v-on:update:upstream-targets="handleTargetsUpdate"
              :service-protocol="domainFormData.service.protocol"
              v-on:update:service-protocol="handleServiceProtocolUpdate"
              :domain-action="domainAction"
              v-on:toggle-redirect-domain-action="handleRedirectDomainAction"
              :redirect-url="redirectUrl"
              v-on:update:redirect-url="handleRedirectUrlUpdate"
              :field-errors="field_errors"
              :tags.sync="domainFormData.service.tags"
          />
        </div>
      </a-tab-pane>

      <a-tab-pane key="shadow-domain" :tab="$t('domain.ShadowDomain')">
        <ShadowDomainDelete :selectedDomain="domains" @domains-updated="handleDomainsUpdated"/>
      </a-tab-pane>

      <a-tab-pane key="advanced-configuration" :tab="$t('domain.AdvancedConfiguration')">
        <AdvancedConfiguration :domain="patchDomain"
                               :plugins.sync="domainFormData.plugins"
                               :plugin-errors="pluginErrors"
                               :service-path="domainFormData.service.path"
                               :has-mixed-service-path="hasMixedServicePathPrefix"
                               v-on:update:service-path="handleServicePathUpdate"
                               :screenSize="screenSize" :is-batch-edit="true"/>
      </a-tab-pane>

      <a-tab-pane key="security" :tab="$t('domain.GuardConfiguration')">
        <GuardConfiguration
            :domain="patchDomain"
            :plugins.sync="domainFormData.plugins"
            :plugin-errors="pluginErrors"
            :tags.sync="domainFormData.service.tags"
            :screenSize="screenSize" :isBatchEdit="true"
            ref="guardConfiguration"></GuardConfiguration>
      </a-tab-pane>

      <a-tab-pane key="metadata" :tab="$t('metadata')">
        <MetadataManagement :description.sync="description"/>
      </a-tab-pane>

    </a-tabs>

    <template slot="footer">
      <footer>
        <a-button @click="$emit('hide')" color="danger" :disabled="processing">{{ $t('Cancel') }}</a-button>
        <a-button @click="save"
                  :disabled="processing || !isButtonEnabled"
                  :loading="processing"
                  type="primary">
          <a-icon v-if="!processing" type="save"/>
          {{ $t('Save') }}
        </a-button>
      </footer>
    </template>
  </a-modal>
</template>

<style scoped>
.tab-pane > .card:first-child {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
</style>

<script>
import {cloneDeep} from 'lodash';
import validUrl from 'valid-url';
import {DomainAction} from '@/utilities/websocket';
import {
  MIXED_VALUE,
  DOMAIN_TRAFFIC_ACTIONS,
  PLUGIN_NAMES,
  SCREEN_TYPES, PLUGIN_DEFAULTS, TAG_NAMES,
} from '@/utilities/constants';
import {getClientIP} from '../../utilities/api';
import BasicConfiguration from '@/views/domain/EditDomain/BasicConfiguration';
import AdvancedConfiguration from '@/views/domain/EditDomain/AdvancedConfiguration';
import GuardConfiguration from '@/views/domain/GuardConfiguration';
import DomainValidationMixin from '@/utilities/DomainValidationMixin';
import MetadataManagement from '@/views/domain/EditDomain/MetadataManagement';
import ShadowDomainDelete from '@/views/domain/EditDomain/ShadowDomainDelete';
import {GroupedRoutes} from '@/models/GroupedRoutes';
import {getPluginConfigValue, setPluginConfigValue} from '@/utilities/plugin-actions';
import axios from "@/plugins/axios.js";
import { notification } from 'ant-design-vue';
import {EventBus} from "@/plugins/event-bus.js";

const DEFAULT_DOMAIN_FORM_DATA = {
  hosts: [''],
  protocols: [], // this is the default in Kong
  upstream: {
    targets: []
  },
  service: {
    protocol: '',
    tags: [],
    path: '/',
  },
  plugins: [],
};

export default {
  name: "PatchDomain",
  mixins: [DomainValidationMixin],
  components: {
    BasicConfiguration,
    AdvancedConfiguration,
    GuardConfiguration,
    MetadataManagement,
    ShadowDomainDelete,
  },
  computed: {
    modalWidth() {
      if (this.screenSize === SCREEN_TYPES.XS) {
        return '100%';
      }

      if (this.screenSize === SCREEN_TYPES.SM) {
        return '75%';
      }

      if (this.screenSize === SCREEN_TYPES.MD) {
        return '65%';
      }

      return "50%";
    },
    domainAction: function () {
      if (this.hasMixedDomainActions) {
        return null;
      }

      let hasRedirectPlugin = false;
      for (let p of this.domainFormData.plugins) {
        if (p.name === PLUGIN_NAMES.GAIUS_REDIRECT) {
          hasRedirectPlugin = true;
          break;
        }
      }

      if (hasRedirectPlugin && this.domainFormData.upstream.targets === MIXED_VALUE) {
        return MIXED_VALUE;
      } else if (hasRedirectPlugin) {
        return DOMAIN_TRAFFIC_ACTIONS.REDIRECT;
      }

      return DOMAIN_TRAFFIC_ACTIONS.UPSTREAM;
    },
    redirectUrl: function () {
      return getPluginConfigValue(this.domainFormData.plugins, PLUGIN_NAMES.GAIUS_REDIRECT, 'redirect_url');
    },
    pluginErrors: function () {
      if (!this.field_errors) {
        return [];
      }

      return [this.field_errors['plugins']] || [];
    },
    domain_ws: {
      get() {
        return this.$store.state.websocket.domain_ws;
      },
      set(value) {
        this.$store.state.websocket.domain_ws = value;
      }
    },
    groupedRoutes() {
      return new GroupedRoutes(this.domains);
    },
    isButtonEnabled() {
      return this.invalidshadowdomain.length === 0 && this.duplicateshadowdomain.length === 0;
    }
  },
  watch: {
    patchShow(show) {
      if (show && this.domains.length) {
        const [hasSameDomainAction, commonDomainAction] = this.groupedRoutes.hasSameDomainAction();

        this.hasMixedDomainActions = hasSameDomainAction === false;

        if (commonDomainAction === DOMAIN_TRAFFIC_ACTIONS.UPSTREAM) {
          this.domainFormData.upstream.targets = this.groupedRoutes.hasTheSameUpstreamTargets() ? [...this.domains[0].getUpstreamTargets()] : MIXED_VALUE;

          const [sameServiceProtocol, commonServiceProtocol] = this.groupedRoutes.hasSameServiceProtocol();
          if (sameServiceProtocol) {
            this.domainFormData.service.protocol = commonServiceProtocol;
          }
        }

        if (commonDomainAction === DOMAIN_TRAFFIC_ACTIONS.REDIRECT) {
          // noop.
          // since the redirect domain action is determined by the gaius-redirect plugin, we let the
          // groupedRoutes.getPlugins handle if the value of gaius-redirect has MIXED_VALUE
        }

        this.domainFormData.plugins = this.groupedRoutes.getPlugins();
        this.domainFormData.service.tags = this.groupedRoutes.getServiceTags();
        let tags = [...this.domainFormData.service.tags].filter((tag) => tag !== TAG_NAMES.HAS_AUTO_GENERATE_CERTIFICATE);
        const hasCommonAutoGenerateCertificateValue = this.groupedRoutes.getCommonAutoGenerateCertificateValue();
        if (hasCommonAutoGenerateCertificateValue)
        tags.push(TAG_NAMES.HAS_AUTO_GENERATE_CERTIFICATE);
        
        const [hasSameServicePath, commonServicePath] = this.groupedRoutes.hasSameServicePath();
        this.hasMixedServicePathPrefix = !hasSameServicePath
        if (hasSameServicePath) {
          this.domainFormData.service.path = commonServicePath;
        }
      }

      if (!show) {
        // reset domainFormData on modal close
        this.domainFormData = cloneDeep(DEFAULT_DOMAIN_FORM_DATA);
      }
    },
  },
  data() {
    return {
      domainFormData: cloneDeep(DEFAULT_DOMAIN_FORM_DATA),
      field_errors: null,
      screenSize: SCREEN_TYPES.LG,
      processing: false,
      hasMixedDomainActions: false,
      hasMixedServicePathPrefix: false,
      description: '',
      selectedDomain: [],
      shadowdomaindelete:[],
      invalidshadowdomain:[],
      duplicateshadowdomain:[]
    };
  },
  props: {
    domains: Array,
    patchDomain: Object,
    patchShow: Boolean,
  },
  methods: {
    clearFieldError(field) {
      if (!this.field_errors)
        return;

      if (!this.field_errors[field])
        return;

      const f = {...this.field_errors};
      delete f[field];

      this.field_errors = f;
    },
    handleDomainUpdate(domain) {
      const [_, ...tail] = this.domainFormData.hosts;
      const _domain = (domain || '').trim().toLowerCase();

      this.domainFormData.hosts = [_domain, ...tail];
    },
    handleDomainsUpdated(domains) {
      const { domainsInput, invalidDomains, duplicateDomains } = domains;
      this.shadowdomaindelete = domainsInput
      this.duplicateshadowdomain = duplicateDomains
      this.invalidshadowdomain = invalidDomains
      if (this.duplicateshadowdomain == ['']){
        this.duplicateshadowdomain = []
      }
      else if (invalidDomains.length <= 0 && invalidDomains == ''){
        this.invalidshadowdomain = []
      }
    },
    handleTargetsUpdate(targets) {
      this.domainFormData.upstream.targets = targets;
      this.clearFieldError('upstream');
    },
    handleServiceProtocolUpdate(serviceProtocol) {
      this.domainFormData.service.protocol = serviceProtocol;
      this.clearFieldError('protocol');
    },
    handleRedirectDomainAction(enabled) {
      // once this handler is called we know that the user clicked either upstream targets or redirect url
      // for the domain action, so we can set hasMixedDomainActions to false.
      this.hasMixedDomainActions = false;

      const plugins = this.domainFormData.plugins;

      // already exist. bail out.
      if (enabled && plugins.find((p) => p.name === PLUGIN_NAMES.GAIUS_REDIRECT))
        return;

      if (enabled) {
        this.domainFormData.plugins = [...plugins, {...PLUGIN_DEFAULTS[PLUGIN_NAMES.GAIUS_REDIRECT]}];
        this.domainFormData.upstream.targets = [];
      } else {
        this.domainFormData.plugins = plugins.filter((p) => p.name !== PLUGIN_NAMES.GAIUS_REDIRECT);
      }
    },
    handleRedirectUrlUpdate(redirectUrl) {
      this.domainFormData.plugins = this.domainFormData.plugins.map((p) => {
        if (p.name === PLUGIN_NAMES.GAIUS_REDIRECT) {
          return setPluginConfigValue([p], PLUGIN_NAMES.GAIUS_REDIRECT, 'redirect_url', redirectUrl);
        }

        return p;
      });
    },
    async save() {
      if (this.domainAction === DOMAIN_TRAFFIC_ACTIONS.REDIRECT) {
        if (!validUrl.isWebUri(this.redirectUrl) && this.redirectUrl !== MIXED_VALUE) {
          this.notifyError(this.$t('message.InputDataVerificationErrorOn', {on: this.$t('domain.RedirectToUrl')}));
          return;
        }
      }
      if (this.domainFormData.upstream.targets !== MIXED_VALUE){
        if (this.domainAction === DOMAIN_TRAFFIC_ACTIONS.UPSTREAM) {
          if (!this.domainFormData.upstream.targets.length) {
            this.notifyError(this.$t('message.InputDataVerificationErrorOn', {on: this.$t('message.UpstreamTargetRequired')}));
            return;
          }

          this.field_errors = {upstream: []};

          if (this.domainFormData.service.protocol === "http") {
            this.domainFormData.upstream.targets.forEach(item => {
              if (item.target.split(':')[1] == 443) {
                this.field_errors['upstream'].push(this.$t('message.UpstreamInvalid', {
                  target: item.target,
                  port: 443,
                  protocol: 'http'
                }));
              }
            });
          } else if (this.domainFormData.service.protocol === "https") {
            this.domainFormData.upstream.targets.forEach(item => {
              if (item.target.split(':')[1] == 80 || item.target.split(':')[1] == 8080) {
                this.field_errors['upstream'].push(this.$t('message.UpstreamInvalid', {
                  target: item.target,
                  port: item.target.split(':')[1],
                  protocol: 'https'
                }));
              }
            });
          }

          if (this.domainFormData.upstream.targets.length > 0 && this.domainFormData.service.protocol === "") {
            delete this.field_errors.upstream;
            this.field_errors['protocol'] = this.$t('message.UpstreamProtocolRequired');
            return;
          }

          if (this.field_errors['upstream'].length) {
            return;
          }
        }
      }
      if (this.domainFormData.upstream.targets === MIXED_VALUE) {
        // we don't actually send the MIXED_VALUE flag to the backend. if the backend
        // sees that the upstream targets is empty, then it will just use whatever upstream 
        // targets was configured for a domain.
        this.domainFormData.upstream.targets = [];
      }
      if (this.domainFormData.plugins) {

        const gaiusAdvanceProxyCache = this.domainFormData.plugins.find((p) => {
          if (p.name == 'gaius-advance-proxy-cache' && p.enabled && p.config) {
            return p;
          }
        });

        if (gaiusAdvanceProxyCache) {
          if (!gaiusAdvanceProxyCache.config.enable_cache_control && !gaiusAdvanceProxyCache.config.global_custom_caching && !gaiusAdvanceProxyCache.config.global_default_caching && !gaiusAdvanceProxyCache.config.global_disable_cache_completely) {
            this.notifyError(this.$t('message.DomainSavingFailed'));
            return ;
          }
        }
      }
      try {
        if (this.shadowdomaindelete != ''){
          const domains = this.shadowdomaindelete
          const response = await axios.post('/domain/batch/shadow/', { domains });
          if (response.data['success']) {
            notification.open({
              message: response.data['success'],
            });
            EventBus.$emit('shadowdomainarea');
            setTimeout(() => {
              this.$emit('shadow-domain-delete');
            }, 5000);
          }
          else if (response.data['error_code']) {
            notification.open({
              message: response.data['error_code'],
            });
          }
        }
        else{
          console.log(this.shadowdomaindelete)
        }
      } catch (error) {
        console.error('Error deleting domains:', error);
      }
      const updatePlugins = this.domainFormData.plugins.filter((p) => p.config !== MIXED_VALUE);

      const hasMixedNoJsChallenge = this.domainFormData.service.tags.find((tag) => tag === TAG_NAMES.NO_JS_CHALLENGE_MIXED);
      const hasAutoGenerateCertificate = this.domainFormData.service.tags.find((tag) => tag === TAG_NAMES.HAS_AUTO_GENERATE_CERTIFICATE);
      let tags = [...this.domainFormData.service.tags].filter((tag) => tag !== TAG_NAMES.HAS_AUTO_GENERATE_CERTIFICATE);
      this.domainFormData.service.tags = hasMixedNoJsChallenge ? [] : [...this.groupedRoutes.getOwnerInformationServiceTags(), ...tags];
      if (hasAutoGenerateCertificate)
      this.domainFormData.service.tags.push(TAG_NAMES.HAS_AUTO_GENERATE_CERTIFICATE);

      this.field_errors = null;
      this.$refs.basicConfiguration.addTarget(true);
      const clientIP = await getClientIP();
      try {
        const route_ids = [];
        for (let d of this.domains) {
          route_ids.push(d.getId());
        }
        this.domainFormData.hosts = [''];
        this.domain_ws.sendMessage(DomainAction.DOMAIN_UPDATES, {
          domain: {...this.domainFormData, plugins: updatePlugins},
          route_ids: route_ids,
          meta: {cname: this.$store.getters.cname, description: this.description},
          client_ip: clientIP.data.ip
        });
      } catch (errors) {
        console.log("======>>>>errors: ", errors);

        this.notifyError(this.$t('message.DomainSavingFailed'));
        return;
      }
      EventBus.$emit('internalWhitelistMode');
      EventBus.$emit('internalblacklistMode');
      this.$emit('hide');
    },
    handleServicePathUpdate(path) {
      this.domainFormData.service.path = path;
    },
  }
};
</script>
