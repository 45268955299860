<template>
  <div>
    <WidgetsStat/>

    <div class="mb-3">
      <a-alert
          class="exclusive-cname"
          type="info"
          show-icon>
         <span slot="message">
           {{ $t('ExclusiveCNAME') }}:
           <strong>{{ cname }}</strong>
         <a-button type="link" @click="handleConfigureCnameClick">
          {{ $t('ConfigureSubscription') }}
        </a-button>
         </span>
      </a-alert>
    </div>
    
    <!--<div>
      <CAlert color="info">
        <p><strong>{{ $t('GenevaAnnouncement') }}</strong></p>
        <span v-html="$t('GenevaAnnouncementDescription')"></span>
      </CAlert>
    </div>-->

    <div>
      <CAlert color="warning" v-if="isFreeCname">
        <p><strong>{{ $t("GeneralAnnouncement") }}：</strong></p>
        <p>{{ $t('GeneralAnnouncementDescription') }}</p>
      </CAlert>
    </div>

    
    <!-- <div>
      <CAlert color="warning">
        <p><strong>{{ $t('MaintenanceTitle') }}</strong></p>
        <span v-html="$t('MaintenanceDescription')"></span>
      </CAlert>
    </div> -->
    
    
    <div>
      <div class="d-flex">
        <div>
          <a-popover v-model="showPopoverListFilter" :title="$t('Filter')" trigger="click" placement="right">
            <div slot="content">
              <table>
                <tbody>
                <tr>
                  <td>{{ $t('DomainsPerPage') }}</td>
                  <td class="pl-2">
                    <a-select size="small" :default-value="perpage" style="width: 120px"
                              @change="pageSizeChanged">
                      <a-select-option :value="100">
                        100
                      </a-select-option>
                      <a-select-option :value="250">
                        250
                      </a-select-option>
                      <a-select-option :value="1000">
                        1000
                      </a-select-option>
                    </a-select>
                  </td>
                </tr>
                <tr>
                  <td>{{ $t('DomainStatus') }}</td>
                  <td class="pl-2">
                    <a-select size="small" default-value="all" style="width: 120px"
                              @change="handleDomainStatusFilter">
                      <a-select-option value="all">
                        {{ $t('all') }}
                      </a-select-option>
                      <a-select-option value="running">
                        {{ $t('Running') }}
                      </a-select-option>
                      <a-select-option value="disabled">
                        {{ $t('Deactivated') }}
                      </a-select-option>
                    </a-select>
                  </td>
                </tr>
                <tr>
                  <td>{{ $t('CertStatus') }}</td>
                  <td class="pl-2">
                    <a-select size="small" default-value="all" style="width: 120px"
                              @change="handleCertStatusFilter">
                      <a-select-option value="all">
                        {{ $t('all') }}
                      </a-select-option>
                      <a-select-option value="https">
                        {{ $t('SSLActive') }}
                      </a-select-option>
                      <a-select-option value="http">
                        {{ $t('SSLInactive') }}
                      </a-select-option>
                      <a-select-option value="force">
                        {{ $t('ForceSSL') }}
                      </a-select-option>
                    </a-select>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
            <button type="button" class="popover-list-filter-trigger btn btn-sm">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                   stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round"
                      d="M12 3c2.755 0 5.455.232 8.083.678.533.09.917.556.917 1.096v1.044a2.25 2.25 0 01-.659 1.591l-5.432 5.432a2.25 2.25 0 00-.659 1.591v2.927a2.25 2.25 0 01-1.244 2.013L9.75 21v-6.568a2.25 2.25 0 00-.659-1.591L3.659 7.409A2.25 2.25 0 013 5.818V4.774c0-.54.384-1.006.917-1.096A48.32 48.32 0 0112 3z"/>
              </svg>
            </button>
          </a-popover>
        </div>
        <ListActionBar>
          <template v-slot:filters>
            <div class="d-flex w-80">
              <div class="d-flex w-100 align-items-center">
                <div class="searchInput w-75 mr-2">
                  <a-input-search
                      :placeholder="$t('message.KeywordSearch')"
                      size="large"
                      @search="doSearch">
                    <a-button slot="enterButton" icon="search"/>
                  </a-input-search>
                </div>
                <div class="w-75 ml-2 mr-2">
                  <a-button class="ant-btn-lg" @click="visibleModalFindDomain = !visibleModalFindDomain">
                    {{ $t('FindDomain') }}
                  </a-button>
                  <a-modal :visible="visibleModalFindDomain" :title="$t('FindDomain')">
                    <a-textarea rows="25" v-model="findMultiDomain" :placeholder="$t('PlaceholderFindDomain')"
                                allow-clear/>
                    <template #footer>
                      <a-button key="close" @click="visibleModalFindDomain = !visibleModalFindDomain">{{
                          $t('Close')
                        }}
                      </a-button>
                      <a-button key="clear" @click="findMultiDomain = ''">{{ $t('Clear') }}</a-button>
                      <a-button key="submit" type="primary" @click="findBtnClicked = true; fetchRoutes()">{{
                          $t('Find')
                        }}
                      </a-button>
                    </template>
                  </a-modal>
                </div>

                <div>
                  <a-tag v-if="count" color="blue">{{ $t('SearchCount') }} {{ total_rows }}</a-tag>
                </div>
              </div>
            </div>
          </template>
          <template v-slot:action-buttons>
            <div>
              <a-button-group class="batch-edit-button-group mr-2">
                <a-button @click="endSelect" v-if="selectedRows.length">
                  {{ $t('Cancel') }} {{ selectedRows.length }}
                  <span v-if="selectedRows.length === 1">&nbsp;{{ $t('selection') }}</span>
                  <span v-else>&nbsp;{{ $t('selections') }}</span>
                </a-button>
                <a-button @click="selectAll">
                  {{ $t('SelectAll') }}
                </a-button>
                <a-dropdown-button @click="patchDomain" :disabled="!isBatchEditMode">
                  <a-icon type="edit"/>
                  {{ $t('BatchEdit') }}
                  <a-icon slot="icon" type="down"/>
                  <a-menu slot="overlay" @click="handleExpandedBatchEditButton">
                    <a-menu-item key="clear-cache">
                      {{ $t('ClearCache') }}
                    </a-menu-item>
                    <a-menu-item key="diagnose">
                      {{ $t('domain.Diagnose') }}
                    </a-menu-item>
                    <a-sub-menu key="https" title="HTTPS">
                      <a-menu-item key="enable-https">{{ $t('Enable') }}</a-menu-item>
                      <a-menu-item key="enable-force-https">{{ $t('EnableForceHTTPS') }}</a-menu-item>
                      <a-menu-item key="disable-force-https">{{ $t('DisableForceHTTPS') }}</a-menu-item>
                    </a-sub-menu>
                    <a-sub-menu key="status" :title="$t('domain.Status')">
                      <a-menu-item key="status-restore">{{ $t('Restore') }}</a-menu-item>
                      <a-menu-item key="status-deactivate">{{ $t('Deactivate') }}</a-menu-item>
                      <a-menu-item key="status-delete" :disabled="loadingRemove">
                        <a-spin v-if="loadingRemove" size="small"/>
                        {{ $t('Delete') }}
                      </a-menu-item>
                    </a-sub-menu>
                    <a-menu-item key="download">
                      {{ $t('Download') }}
                    </a-menu-item>
                  </a-menu>
                </a-dropdown-button>
              </a-button-group>
              <a-dropdown-button @click="addDomain" class="create-button-group">
                <a-icon type="plus-circle"/>
                {{ $t('domain.AddDomain') }}
                <a-icon slot="icon" type="down"/>
                <a-menu slot="overlay" @click="handleExpandedCreateButton">
                  <a-menu-item key="import">
                    {{ $t('domain.Import') }}
                  </a-menu-item>
                  <a-menu-item key="create-rules">
                    Create rules
                  </a-menu-item>
                </a-menu>
              </a-dropdown-button>
            </div>
          </template>
        </ListActionBar>
      </div>
      <div class="mt-2">
        <p :class="{'mb-0': true, 'opacity-0': (selectedRows.length === 0)}">{{ $t('SelectedDomains') }}
          {{ selectedRows.length }}</p>
      </div>
      <div class="domain-list-container mb-2">
        <DomainList :fetching-domains="listLoading"
                    :domains="routeList"
                    :is-free-cname="isFreeCname"
                    :current-page="page"
                    :domain-per-page="perpage"
                    :total-domains-to-paginate="total_items"
                    @pageChanged="pageChanged"/>
      </div>
    </div>

    <EditDomain :source-route="selectedDomain"
                @refresh="refresh"
                :editShow="activeModal === modalFlags.EDIT"
                @hide="activeModal = null"
                @onStopConfirmModal="attachDomainWsHandler"/>

    <ApplyRule :source-route="selectedDomain"
               @refresh="refresh"
               :domain="selectedDomain"
               :editShow="activeModal === modalFlags.APPLY_RULE"
               @hide="activeModal = null"/>

    <ConfigureCname :show="activeModal === modalFlags.CONFIGURE_CNAME"
                    @hide="activeModal = null"/>

    <PatchDomain ref="patchDomainModal"
                 :patchDomain="selectedDomain"
                 :domains="selectedRows"
                 :domain_ws="domain_ws"
                 @hide="endSelect"
                 @shadow-domain-delete="refresh"
                 :patchShow.sync="patchModal"
                 id="patchModal"/>

    <SslConfig :domain="selectedDomain"
               :issue_ws="issue_ws"
               :isVisible="activeModal === modalFlags.SSL_CONFIG"
               :sslLoader="sslLoader"
               @hide="handleHideSslConfig"
               @auto-generate="redeploy"
               @refresh="refresh"/>

    <ClearCache v-if="clearCacheModal"
                :clearCacheModal.sync="clearCacheModal"
                :clearCacheTitle="clearCacheTitle"/>

    <Diagnose :domain="selectedDomain"
              v-if="selectedDomain"
              :diagnoseModal.sync="diagnoseModal"
              :diagnoseTitle="diagnoseTitle"/>

    <CModal :title="$t('Diagnose')"
            color="warning"
            :show.sync="showSummaryDiagnoseModal">
      <BatchDiagnose :domains="selectedRows"
                     :diagnose="showSummaryDiagnoseModal"
                     v-if="showSummaryDiagnoseModal"/>
    </CModal>

    <CModal
        :title="$t('cluster.UpgradeServiceArea')"
        color="warning"
        :show.sync="clusterUpgradeModal"
        v-if="selectedDomain && clusterUpgradeModal"
        id="clusterUpgradeModal"
    >
      <ClusterUpgrade :domain="selectedDomain" @hide="clusterUpgradeModal=false"/>
      <template slot="footer-wrapper">
        <footer class="modal-footer" style="justify-content: space-between;">&nbsp;</footer>
      </template>
    </CModal>

    <CModal :title="$t('message.DomainActivationConfirmation')" color="warning" :show.sync="showConfirmActivateModal">
      <div>
        <CAlert color="warning">
          <p><strong>{{ $t("Domain") }}： {{ selectedDomain ? selectedDomain.name : '' }}</strong></p>
          <p>{{ $t('message.domain_for_activation_deploy') }}</p>
        </CAlert>
      </div>
      <template slot="footer-wrapper">
        <footer class="modal-footer">
          <CButton @click="closeActivateDomainModal">{{ $t('Cancel') }}</CButton>
          <CButton
              type="submit"
              color="primary"
              size="sm"
              @click="activateDomain(selectedDomain)"
          >
            <span>{{ $t('proceed') }}</span>
          </CButton>
        </footer>
      </template>
    </CModal>

    <a-modal :visible="showNewUiFeedbackDialog" :title="$t('message.NewUiFeedbackModalTitle')"
             @cancel="showNewUiFeedbackDialog = false">
      {{ $t('message.AskingFeedback') }}
      <template #footer>
        <a-button key="back" @click="handleGoToOldDomainUI">{{ $t('message.NoThanks') }}</a-button>
        <a-button key="submit" type="primary" @click="handleGiveFeedback">
          <span v-if="showThankYou">{{ $t('message.SwitchToOldUI') }}</span>
          <span v-else>{{ $t('message.SureGiveFeedback') }}</span>
        </a-button>
      </template>
    </a-modal>
  </div>
</template>

<script>
import {debounce, isEmpty} from 'lodash';
import Multiselect from 'vue-multiselect';
import axios from "@/plugins/axios.js";
import {Websocket} from "@/plugins/websocket.js";
import EditDomain from "./EditDomain/EditDomain";
import ApplyRule from "./EditDomain/ApplyRule";
import PatchDomain from "./PatchDomain";
import SslConfig from "./SslConfig";
import ClearCache from "./ClearCache.vue";
import Diagnose from "./Diagnose.vue";
import ClusterUpgrade from "./ClusterUpgrade.vue";
import WidgetsStat from '../widgets/WidgetsStat';
import {bus} from '@/main';
import {EventBus, Events} from "@/plugins/event-bus.js";
import Driver from "driver.js"
import {getToken, onMessage} from "firebase/messaging";
import "driver.js/dist/driver.min.css"
import getDefaultDomainConfig from '@/utilities/getDefaultDomainConfig';
import {
  getRoutes,
  fcmRegister,
  sendNotification,
  getClientIP,
  findDomains,
} from '../../utilities/api';
import BatchDiagnose from '@/views/domain/BatchDiagnose';
import ListActionBar from '@/views/domain/ListActionBar';
import {truncate, isArray} from 'lodash';
import {DomainAction} from '@/utilities/websocket';
import {ExportDomainMixin, COLUMN_KEYS} from '@/mixins/ExportDomainMixin';
import {LIMIT_BATCH_DEPLOY} from '@/utilities/batch';
import {
  EVENT_BUS_EVENTS,
  MIXED_VALUE,
  TAG_NAMES,
  PLUGIN_NAMES,
  TASK_NAMES
} from '@/utilities/constants';
import {makeRoutes} from '../../models/RouteFactory';
import {Route} from '../../models/Route';
import Fuse from 'fuse.js';
import {Modal} from 'ant-design-vue';


import {FETCH_COIN_SUPPORT_IF_EMPTY} from '@/store/types';
import ConfigureCname from '@/views/domain/EditDomain/ConfigureCname';
import {$i18n} from '@/plugins/i18n';
import DomainList from '@/views/domain/DomainList.vue';
import router from '@/router';

const MILLISECONDS_DELAY = 500;

const refreshDelay = 3000; // anti-rebound for 500ms
let lastExecution = 0;

const SHOW_DOMAIN_NOTES_STORAGE_KEY = 'SHOW_DOMAIN_NOTES';
let SHOW_DOMAIN_NOTES_DEFAULT = false;
if (window.localStorage) {
  SHOW_DOMAIN_NOTES_DEFAULT = localStorage.getItem(SHOW_DOMAIN_NOTES_STORAGE_KEY) === "true";
}

const MODAL_FLAGS = {
  EDIT: 'edit',
  APPLY_RULE: 'apply_rule',
  SSL_CONFIG: 'ssl_config',
  CONFIGURE_CNAME: 'configure_cname',
};

export default {
  name: "List",
  mixins: [ExportDomainMixin],
  components: {
    DomainList,
    Multiselect,
    BatchDiagnose,
    EditDomain,
    ApplyRule,
    PatchDomain,
    SslConfig,
    ClearCache,
    Diagnose,
    ClusterUpgrade,
    WidgetsStat,
    ConfigureCname,
    ListActionBar,
  },
  watch: {
    editModal() {
      if (!this.editModal) {
        this.attachDomainWsHandler();
      }
    },
    showDomainNotes(value) {
      if (window.localStorage) {
        localStorage.setItem(SHOW_DOMAIN_NOTES_STORAGE_KEY, value);
      }
    },
    sslModal: function (newSslModal, oldSslModal) {
      const fromVisibleToHidden = oldSslModal && !newSslModal;
    },
  },
  computed: {
    isBatchEditMode: function () {
      return this.selectedRows.length > 0;
    },
    isFreeCname: function () {
      const subscriptions = this.$store.state.subscriptions;
      for (let s of subscriptions) {
        if (!s.plan) {
          return true;
        }
        if (s && s.plan && s.plan.name == "Free") {
          return true;
        }
      }

      return false;
    },
    cname: function () {
      return this.$store.getters.cname;
    },
    issue_ws: {
      get() {
        return this.$store.state.websocket.issue_ws
      },
      set(value) {
        this.$store.state.websocket.issue_ws = value
      }
    },
    shadow_ws: {
      get() {
        return this.$store.state.websocket.shadow_ws;
      },
      set(value) {
        this.$store.state.websocket.shadow_ws = value
      }
    },
    domain_ws: {
      get() {
        return this.$store.state.websocket.domain_ws;
      },
      set(value) {
        this.$store.state.websocket.domain_ws = value;
      }
    },
    clearCacheTitle: function () {
      var title = this.$t('domain.ClearCache');
      return title;
    },
    diagnoseTitle: function () {
      var title = this.$t('domain.DomainStatusCheck');
      if (this.selectedDomain) {
        title += " [" + this.selectedDomain.getMainHost() + "]"
      }
      return title
    },
    selectedDomains: function () {
      //return []

      // todo:
      return this.items.filter(i => i._selected === true);
    },
    routeList: function () {
      if (this.searchTerm.length) {
        return this.searchResult;
      }

      return this.routes.filter(item => {
        if (item._data.upstream.hasOwnProperty('targets')) {
          if (item._data.upstream.targets.length) {
            return item;
          }
        } else {
          for (let plugin of item._data.plugins) {
            if (plugin.name === 'gaius-redirect') {
              return item;
            }
          }
        }
      });
    },
    routeMap: function () {
      const map = {};
      for (let r of this.routes) {
        map[r.getId()] = r;
      }

      return map;
    },
    searchData: function () {
      return this.routes.map((m) => {
        return {
          routeId: m.getId(),
          hosts: m.getHosts(),
          serviceHost: m.getServiceHost()
        }
      });
    },
    searchIndex: function () {
      return Fuse.createIndex(['hosts', 'serviceHost'], this.searchData);
    },
  },

  data() {
    return {
      selectedSearchDomains: [],
      domainsPage: 1,
      storedDomainSearch: null,
      items: [],
      routes: [],
      driver: null,
      searchTerm: "",
      searchResult: [],
      total_rows: 0,
      total_pages: 10,
      total_items: 0,
      current_page_offset: null,
      next_page_offset: null,
      previous_page_offset: null,
      columnFilter: {
        external: true, lazy: true
      },
      dg: null,
      clearCacheModal: false,
      diagnoseModal: false,
      showSummaryDiagnoseModal: false,
      diagnoseKey: 0,
      show: false,
      listLoading: false,
      queryParams: {},
      page: 1,
      perpage: 100,
      editModal: false,
      patchModal: false,
      sslModal: false,
      clusterUpgradeModal: false,
      defaultDomain: this.getDefaultDomain(),
      selectedDomain: new Route(),
      domainSubscription: {},
      showConfirmActivateModal: false,
      queueDomainDeploy: [],
      onProgressDomainDeploy: [],
      queueDomainCleanCache: [],
      onProgressDomainCleanCache: [],
      queueDomainDeactivate: [],
      onProgressDomainDeactivate: [],
      queueDomainResume: [],
      onProgressDomainResume: [],
      queueDomainDelete: [],
      onProgressDomainDelete: [],
      showDomainNotes: SHOW_DOMAIN_NOTES_DEFAULT,
      activeModal: null,
      modalFlags: MODAL_FLAGS,
      selectedRows: [],
      sslLoader: false,
      certStatus: null,
      domainStatus: null,
      domainQ: null,
      domainQ1: null,
      count: false,
      isBatchDeleteInProgress: false,
      loadingRemove: false,
      showPopoverListFilter: false,
      showNewUiFeedbackDialog: false,
      showThankYou: false,
      findBtnClicked: false
    };
  },

  props: {
    caption: {
      type: String,
      default: 'domain.DomainList'
    },
    group: Object,
    hover: Boolean,
    striped: Boolean,
    bordered: Boolean,
    fixed: Boolean,
    dark: Boolean,
  },

  created() {
    this.$store.dispatch(FETCH_COIN_SUPPORT_IF_EMPTY);

    bus.$on('updateDomainList', () => {
      this.refresh();
    });
    EventBus.$on('clearFullCacheEvent', () => {
      this.patchCleancache();
    });
    EventBus.$on('clearCustomCacheEvent', (targets) => {
      this.patchCleanCustomcache(targets);
    });
  },

  async mounted() {
    this.fcmRegisterToken();
    await this.openWs();
    this.onNotificationMessageReceived();
    this.refresh();

    this.listenEvent();
  },
  beforeDestroy() {
    this.closeWs()
  },
  methods: {
    handleGiveFeedback() {
      if (this.showThankYou) {
        this.handleGoToOldDomainUI();
      } else {
        this.showThankYou = true;
      
        let formLink = 'https://forms.gle/bgTKyFaLjidUne9JA';
        if (this.$i18n.locale === 'zh-CN') {
          formLink = 'https://forms.gle/PACB5Mz1BuWz7vtE7';
        }
        
        window.open(formLink, '_blank'); 
      }
    },
    updateSelectedRows(rows) {
      this.selectedRows = rows;
    },
    sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },
    async getFCMToken() {
      let token = await getToken(this.$messaging, {vapidKey: this.$messaging.vapidKey});
      return token;
    },
    async fcmRegisterToken() {
      let currentToken = await this.getFCMToken();
      fcmRegister(currentToken).then(response => {

      }).catch(({errors}) => {

      });
    },
    notifyUser(batch_type) {
      sendNotification(batch_type).then(response => {

      }).catch(({errors}) => {
        console.log(errors);
      });
    },
    async onNotificationMessageReceived() {
      onMessage(this.$messaging, (payload) => {
        this.flash(payload.notification.body, "success", {timeout: 3000});
      });
    },
    pageChanged(page) {
      this.page = page;
      this.refresh();
    },
    pageSizeChanged(pageSize) {
      this.page = 1;
      this.perpage = pageSize;
      this.refresh();
    },
    showDomainTooltip(domain, content, type, delay = 5000) {
      this.items = {...this.routeList, ...domain};
      //this.refresh(false);
      this.$set(domain, '_message', {
        content: content,
        show: true,
        type: type
      });
      setTimeout(() => {
        domain._message.show = false;
        domain._message = {};
        if (domain.hasOwnProperty('last_entry') && domain["last_entry"] == true) {
          window.location.reload();
        }
      }, delay);
    },
    async openWs() {
      this.domain_ws = new Websocket(`domain/`);
      if (!this.domain_ws.isOpen()) {
        this.domain_ws.onopen = function (event) {
          console.log("==========>>>>> Successfully connected to the domain websocket server...")
        }
      }

      this.issue_ws = new Websocket(`certificate/`, process.env.VUE_APP_CERT_WSS);
      if (!this.issue_ws.isOpen()) {
        this.issue_ws.onopen = function (event) {
          console.log("==========>>>>> Successfully connected to the certificate websocket server...");
        }
      }

      this.attachDomainWsHandler();
      this.attachIssueWsHandler();
    },

    onPatchResume() {
      this.onProgressDomainResume.forEach(domain => {
        domain = this.items.find(d => d.name === domain.name);
        this.showDomainTooltip(domain, this.$i18n.t('DeployActionResume'), 'success');
      })
    },
    onPatchDeactivate() {
      this.onProgressDomainDeactivate.forEach(domain => {
        domain = this.items.find(d => d.name === domain.name);
        this.showDomainTooltip(domain, this.$i18n.t('DeployActionDeactivate'), 'success');
      })
    },
    onPatchDelete() {
      this.onProgressDomainDelete.forEach(domain => {
        domain = this.items.find(d => d.name === domain.name);
        this.showDomainTooltip(domain, this.$i18n.t('DeployActionDelete'), 'success');
      })
    },
    getDomainFromRouteList(domainData) {
      let domain = this.routeList.find(d => d._data.name === domainData.name);
      if (!domain) {
        domain = this.routeList.find(d => d._data.domain_name === domainData.name);
      }
      if (!domain) {
        domain = this.routeList.find(d => d._data.domain_name === domainData.name.replaceAll('.', '-').replaceAll('*', 'all'));
      }

      return domain;
    },
    attachDomainWsHandler() {
      this.domain_ws.onMessage = function (event, result) {

        let domain = null;
        if (result.data.domain) {
          domain = this.getDomainFromRouteList(result.data.domain);

          if (result.data.domain.hasOwnProperty('last_entry')) {
            domain["last_entry"] = result.data.domain["last_entry"];
          }
        }
        // DOMAIN_UPDATES has a different way of displaying errors, so only execute below if-block
        // the action was not DOMAIN_UPDATES
        if (result.data.hasOwnProperty('error') && (result.action !== DomainAction.DOMAIN_UPDATES)) {
          if (domain) {
            this.showDomainTooltip(domain, result.data.error, 'danger', 8000)
            return;
          }
        }

        if (result.action === DomainAction.DOMAIN_REDEPLOY) {
          if (result.data.dp.success) {
            if (domain) {
              // assign(domain, data.domain);  #TODO  //use this to full assign domain. but the status hasn;t updated after push task. there is another task to update domain status.
              this.onProgressDomainDeploy = this.onProgressDomainDeploy.filter(item => item.name !== domain.name);
              const domain_deploy = this.queueDomainDeploy.shift();
              if (domain_deploy) {
                this.onProgressDomainDeploy.push(domain_deploy);
                this.sendDeploy(DomainAction.DOMAIN_REDEPLOY, [domain_deploy])
              }
            }

            this.showDomainTooltip(domain, this.$i18n.t('DeployActionSuccessful'), "success", 8000);
          }
        } else if (result.action === DomainAction.FORCE_HTTPS) {
          if (result && result.data && result.data.message) {
            if (result.data.message.includes('Enable')) {
              this.selectedRows.map((route) => {
                if (route._data.has_cert) {
                  var message_type = 'success';
                  var content = this.$t("message.BatchForceSSLEnabledSuccess");
                } else {
                  var message_type = 'danger';
                  var content = this.$t("message.BatchForceSSLEnabledError");
                }

                this.$set(route, '_message', {
                  content: content,
                  show: true,
                  type: message_type
                });

                setTimeout(() => {
                  this.$set(route, '_message', {
                    show: false,
                  });
                }, 3000);

                return route;
              });
              //this.flash(this.$t("message.BatchForceSSLEnabled"), 'success', {"timeout": 3000});
              setTimeout(() => {
                this.refresh();
                this.endSelect();
              }, 3000);
            } else {
              this.refresh();
              this.endSelect();
              this.flash(this.$t("message.BatchForceSSLDisabled"), 'success', {"timeout": 3000});
            }
          }
        } else if (result.action === DomainAction.CLEAR_CACHE) {
          if (result.data.message) {
            this.flash(result.data.message, 'success', {"timeout": 8000});
          }
        } else if (result.action === DomainAction.DOMAIN_DEACTIVATE) {
          if (result.data.dp.success) {
            this.showDomainTooltip(domain, this.$i18n.t('DeactivateActionSuccessful'), 'success', 8000);

            // Remove item from queue
            if (this.queueDomainDeactivate.length === 0) {
              this.$refs.patchDomainModal.processing = false;
            }
            this.queueDomainDeactivate = this.queueDomainDeactivate.filter(item => item.name !== domain.name);
            this.onProgressDomainDeactivate = this.onProgressDomainDeactivate.filter(item => item.name !== domain.name);
            const domain_deploy = this.queueDomainDeactivate.shift();
            if (domain_deploy) {
              this.onProgressDomainDeactivate.push(domain_deploy);
              this.sendDeploy(DomainAction.DOMAIN_DEACTIVATE, [domain_deploy]);
            }
          }
        } else if (result.action === DomainAction.DOMAIN_RESUME) {
          if (result.data.dp.success) {
            this.showDomainTooltip(domain, this.$i18n.t('ResumeActionSuccessful'), 'success', 8000);

            // Remove item from queue
            if (this.queueDomainResume.length === 0) {
              this.$refs.patchDomainModal.processing = false;
            }
            this.queueDomainResume = this.queueDomainResume.filter(item => item.name !== domain.name);
            this.onProgressDomainResume = this.onProgressDomainResume.filter(item => item.name !== domain.name);
            const domain_deploy = this.queueDomainResume.shift();
            if (domain_deploy) {
              this.onProgressDomainResume.push(domain_deploy);
              this.sendDeploy(DomainAction.DOMAIN_RESUME, [domain_deploy]);
            }
          }
        } else if (result.action === DomainAction.DOMAIN_DELETE) {

          if (result.data.row.index == result.data.row.count) {
            this.showLoader = false;
            this.selectedRows = [];

            this.refresh();

            bus.$emit('updateDomainStat');
            bus.$emit('updateQuota');
            this.isBatchDeleteInProgress = false;
            this.listLoading = true;

            this.notifyUser('batch_delete');
          }

          if (result.data.message) {
            // this.showDomainTooltip(domain, this.$i18n.t('DeleteActionSuccessful'), 'success', 8000);

            // Remove item from queue
            if (this.queueDomainDelete.length === 0) {
              this.$refs.patchDomainModal.processing = false;
            }
            this.queueDomainDelete = this.queueDomainDelete.filter(item => item.name !== domain.name);
            this.onProgressDomainDelete = this.onProgressDomainDelete.filter(item => item.name !== domain.name);
            const domain_deploy = this.queueDomainDelete.shift();

            if (domain_deploy) {
              this.onProgressDomainDelete.push(domain_deploy);
              this.sendDeploy(DomainAction.DOMAIN_DELETE, [domain_deploy]);
            }
          }
        } else if (result.action === DomainAction.PAUSE_RESTORE_DOMAIN) {
          if (result.data.error) {
            this.$notification.error({message: result.data.error});
          } else {
            this.refresh();
            this.endSelect();
            bus.$emit('updateDomainStat');
            bus.$emit('updateQuota');
            this.flash(this.$t("domain.SaveSuccess"), 'info', {"timeout": 3000});
            if (result.data.row.paused) {
              this.notifyUser('batch_deactivate');
            } else {
              this.notifyUser('batch_active');
            }
          }
        } else if (result.action === DomainAction.DOMAIN_UPDATES) {
          const updatedDomainData = result.data.domain;
          this.routes = this.routes.map((r) => {
            if (r.getId() === updatedDomainData.id) {
              EventBus.$emit(Events.DOMAIN_TOOLTIP_MESSAGE, {
                domain_id: updatedDomainData.id,
                content: result.data.message ? result.data.message : result.data.error,
                type: result.data.message ? 'success' : 'error'
              });
              
              if (result.data.message) {
                return new Route(updatedDomainData, result.data.domain_meta);
              }
            }

            return r;
          });
        } else if (result.action === DomainAction.CLEAR_CUSTOM_CACHE) {
          this.flash(result.data.message, 'success', {"timeout": 3000});
        }
      }.bind(this);
    },

    attachIssueWsHandler() {
      this.issue_ws.onMessage = function (event, data) {
        this.sslLoader = false;
        if (data.data.error) {
          if (data.data.error.cert_issues) {
            for (let field in data.data.error.cert_issues[0]) {
              const msg = data.data.error.cert_issues[0][field].pop();
              this.flash(field + ': ' + msg, "error", {"timeout": 3000});
            }
          } else if (data.data.error) {
            this.flash(data.data.error, "error", {"timeout": 3000});
          } else {
            this.flash(this.$t('message.ErrorCertificateGeneration'), "error", {"timeout": 3000});
          }
          return;
        }

        if (data.action === DomainAction.ENABLE_HTTPS) {
          this.flash((data.data.message), "success", {timeout: 3000});
          this.refresh();
          this.endSelect();
          return;
        }

        this.flash(this.$t('message.CertificateIssued'), "success", {timeout: 3000});
        this.refresh();

        return;

      }.bind(this);
    },

    closeWs() {
      this.domain_ws.connection.close();
      this.issue_ws.connection.close();
      // this.shadow_ws.connection.close();
    },

    sendDeploy(action, domains) {
      var domain_names = domains.map(d => d.name)

      if (action === DomainAction.DOMAIN_REDEPLOY) {
        domains.forEach((domain) => {
          domain = this.items.find(d => d.name === domain.name);
          this.showDomainTooltip(domain, this.$i18n.t('DeployActionRunning'), 'success')
        })
      } else if (action === DomainAction.DOMAIN_DEACTIVATE) {
        domains.forEach(domain => {
          domain = this.items.find(d => d.name === domain.name);
          this.showDomainTooltip(domain, this.$i18n.t('DeployActionDeactivate'), 'success');
        });
      } else if (action === DomainAction.DOMAIN_RESUME) {
        domains.forEach(domain => {
          domain = this.items.find(d => d.name === domain.name);
          this.showDomainTooltip(domain, this.$i18n.t('DeployActionResume'), 'success');
        });
      } else if (action === DomainAction.DOMAIN_DELETE) {
        domains.forEach(domain => {
          domain = this.items.find(d => d.name === domain.name);
          this.showDomainTooltip(domain, this.$i18n.t('DeployActionDelete'), 'success');
        });
      }

      this.domain_ws.sendMessage(action, {domains: domain_names})
    },

    sendIssue(action, domains) {
      const cert_issues = []
      const filtered_domains = domains.filter(item => {
        if (item.sslConfig && item.sslConfig.enabled == true) {
          this.showDomainTooltip(item, this.$t('message.SSLAlreadyEnabled'), 'success', 3000)
          this.$set(item, '_patch_status', "done")
          return null
        }
        this.$set(item, '_patch_status', "processing")
        cert_issues.push({
          "cert_type": process.env.NODE_ENV === 'development' ? 'DNS' : 'AUTO',
          "name": item.name,
          "cname": item.cname,
          "model_name": item.model_name ? item.model_name : 'shadowdomain',
          "forceSSL": true
        });
        return item
      });

      this.issue_ws.sendMessage(action, {cert_issues: cert_issues})

      return cert_issues.length > 0;
    },

    endSelect() {
      this.patchModal = false;
      this.selectedRows = [];

      EventBus.$emit(Events.DESELECT_ALL_DOMAIN);
    },

    selectAll() {
      EventBus.$emit(Events.SELECT_ALL_DOMAIN);
    },

    deselectRow(item) {
      if (this.selectedRows.includes(item)) {
        this.selectedRows.splice(this.selectedRows.indexOf(item), 1);
      }
    },

    selectRow(item) {
      if (!this.selectedRows.includes(item)) {
        this.selectedRows.push(item);
      }
    },

    makeDriver() {
      this.driver = new Driver({
        //className: "scoped-class", // className to wrap driver.js popover
        animate: true, // Animate while changing highlighted element
        stageBackground: 'rgba(255, 255, 255, 0.3)',   // This will override the one set in driver
        opacity: 0.1, // Background opacity (0 means only popovers and without overlay)
        padding: 10, // Distance of element from around the edges
        allowClose: true, // Whether clicking on overlay should close or not
        overlayClickNext: false, // Should it move to next step on overlay click
        doneBtnText: this.$t('Done'), // Text on the final button
        closeBtnText: this.$t('Close'), // Text on the close button for this step
        nextBtnText: this.$t('Next'), // Next button text for this step
        prevBtnText: this.$t('Prev'), // Previous button text for this step
        // onHighlightStarted: (Element) => {
        //   this.addDomain() ; // = true;
        // }
        // onNext: (Element) => {
        //   if( Element.node.id == "add-domain" ) {
        //     this.driver.preventMove();
        //     this.driver.moveNext();
        //   }
        // } // Called when element is about to be highlighted
        // Called when moving to next step on any step
      })
    },
    guide() {
      this.makeDriver();
      this.driver.defineSteps([
        {
          element: "#add-domain",
          popover: {
            title: this.$t('guide.TitleAddDomain'),
            description: this.$t('guide.DescriptionAddDomain'),
            position: "left"
          },
          onNext: () => {
            // Prevent moving to the next step
            this.driver.preventMove();

            this.addDomain(); // = true;

            setTimeout(() => {
              this.driver.moveNext();
            }, 100);
          }
        },
        {
          element: "#edit-input-domain",
          popover: {
            title: this.$t('guide.TitleEditDomain'),
            description: this.$t('guide.DescriptionEditDomain'),
            position: "bottom"
          },
        },
        {
          element: "#edit-input-source",
          popover: {
            title: this.$t('guide.TitleEditSource'),
            description: this.$t('guide.DescriptionEditSource'),
            position: "bottom"
          }
        },
        {
          element: "#edit-submit",
          popover: {
            title: this.$t('Save'),
            position: "left"
          },
          onNext: () => {
            // Prevent moving to the next step
            this.driver.preventMove();
            this.editModal = false;
            setTimeout(() => {
              this.driver.moveNext();
            }, 100);
          }
        },
        {
          element: "#display-cname",
          popover: {
            title: this.$t('guide.TitleDisplayCNAME'),
            description: this.$t('guide.DescriptionDisplayCNAME'),
            position: "bottom"
          }
        },
        {
          element: "#member-plans",
          popover: {
            title: this.$t('guide.TitleMemberPlan'),
            description: this.$t('guide.DescriptionMemberPlan'),
            position: "bottom"
          },
          onNext: () => {
            // Prevent moving to the next step
            this.driver.preventMove();
            EventBus.$emit('show-header-dropdown');
            setTimeout(() => {
              this.driver.moveNext();
            }, 100);
          }
        },
        {
          element: "#contact-support",
          popover: {
            title: this.$t('guide.TitleContactSupport'),
            description: this.$t('guide.DescriptionContactSupport'),
            position: 'left-bottom',
          }
        }
      ]);
      this.driver.reset();
      this.driver.start();
    },
    getDefaultDomain() {
      return getDefaultDomainConfig();
    },
    patchSave(domain) {
      //別問我為什麼要這麼寫，我也看不懂。
      (async () => {
        for (const item of this.selectedDomains) {
          if (domain.sourceConfig.full_url === MIXED_VALUE) {
            // noop
          } else if (domain.sourceConfig.full_url) {
            item.sourceConfig.full_url = domain.sourceConfig.full_url
          }

          if (domain.sourceConfig.redirect === MIXED_VALUE) {
            // noop
          } else {
            item.sourceConfig.redirect = domain.sourceConfig.redirect;
          }

          if (domain.notes === MIXED_VALUE) {
            // noop
          } else {
            item.notes = domain.notes;
          }

          // domain.strategies is null if the selected domains have different advanced rules.
          // if domain.strategies is null we retain the selected domain advanced rules.
          if (domain.strategies === null) {
            // noop
          } else if (isArray(domain.strategies)) {
            item.strategies = domain.strategies
          } else if (domain._strategies_cleanall) {
            item.strategies = []
          }

          if (domain.cacheConfig.cache_type) {
            item.cacheConfig.cache_type = domain.cacheConfig.cache_type;
            item.cacheConfig.nocache = domain.cacheConfig.nocache || [];
          }

          if (domain.guardConfig) {
            item.guardConfig = {...item.guardConfig, ...domain.guardConfig};
          }

          // promises.push(
          let response = await axios({
            method: "PATCH",
            url: `domain/{domain}/`,
            data: item,
            urlParams: {
              domain: item.name
            }
          }).catch(({errors}) => {
            errors.forEach((message) => {
              this.flash(message, 'error', {"timeout": 5000});
            });
          })
          if (response.status == "200") {
            this.flash(item.name + " " + this.$t("domain.SaveSuccess"), "success", {
              timeout: 5000
            });
          }
        }
      })().then(() => {
        this.endSelect();
        this.refresh();
      })
    },
    patchCleancache() {
      this.handleClearCacheMultiple();
      this.endSelect();
    },
    patchCleanCustomcache(targets) {
      this.handleClearCustomCacheMultiple(targets);
      this.endSelect();
    },
    saveShadow(shadow, domain) {
      let isNew = shadow.id == null;
      axios({
        method: isNew ? "POST" : "PATCH",
        url: isNew
            ? `domain/{domain}/shadow/`
            : `domain/{domain}/shadow/{shadow}/`,
        data: shadow,
        urlParams: {
          domain: domain.name,
          shadow: shadow.name
        }
      }).then(response => {
        shadow = response.data;
        if (response.status == "201") {
          this.flash(this.$t("domain.SaveSuccess"), "success", {
            timeout: 5000
          });
        }
      })
          .catch(({errors}) => {
            errors.forEach((message) => {
              this.flash(message, 'error', {"timeout": 5000});
            });
          });
    },
    closeActivateDomainModal() {
      this.selectedDomain = null;
      this.showConfirmActivateModal = false;
    },
    async activateDomain(domain) {
      await this.sendDeploy(DomainAction.DOMAIN_RESUME, [domain]);
      this.selectedDomain = null;
      this.showConfirmActivateModal = false;
    },
    getLink(domain) {
      let hh = "http";
      if (domain.sslConfig && domain.sslConfig.enabled) {
        hh = "https";
      }
      return `${hh}://${domain.name}/`;
    },
    doSearch(value) {
      this.domainQ = value;
      this.page = 1;
      this.refresh();

      EventBus.$emit(Events.DESELECT_ALL_DOMAIN);
    },
    filterBySearch() {
      const options = {
        includeScore: true,
        useExtendedSearch: true,
        keys: ['hosts', 'serviceHost'],
      };

      const fuse = new Fuse(this.searchData, options, this.searchIndex);
      // Use Fuse's extended search "include-match" match type: https://fusejs.io/examples.html#extended-search
      const result = fuse.search("'" + this.searchTerm)
      this.searchResult = result.map((r) => this.routeMap[r.item.routeId]);
    },
    listenEvent() {
      // Send the event on a channel (i-got-clicked) with a payload (the click count.)
      EventBus.$on("show-guide", () => {
        this.guide()
      });

      EventBus.$on([
        Events.SHOW_EDIT_DOMAIN_DIALOG,
        Events.SHOW_EDIT_DOMAIN_DIALOG_SHOW_ADVANCED_CONFIGURATION,
        Events.SHOW_EDIT_DOMAIN_DIALOG_SHOW_SECURITY_SETTING
      ], (data) => {
        this.selectedDomain = data.domain;
        this.activeModal = MODAL_FLAGS.EDIT;
      });

      EventBus.$on(Events.SHOW_DIAGNOSE_DOMAIN_DIALOG, (data) => {
        this.diagnose(data.domain);
      });

      EventBus.$on(Events.SHOW_APPLY_RULE_DIALOG, (data) => {
        this.applyRule(data.domain);
      });

      EventBus.$on(Events.SHOW_CONFIGURE_SSL_DIALOG, (data) => {
        this.showSslConfig(data.domain);
      });

      EventBus.$on(Events.SELECT_DOMAIN, (data) => {
        this.selectRow(data.domain);
      });

      EventBus.$on(Events.DESELECT_DOMAIN, (data) => {
        this.deselectRow(data.domain);
      });

      EventBus.$on(Events.CLEAR_CACHE_DOMAIN, (data) => {
        this.cleancache(data.domain);
      });
      EventBus.$off(Events.DOWNLOAD_CERTIFICATE);
      EventBus.$on(Events.REPLACE_COMBINE_RULES, () => {
        this.fetchRoutes(this.current_page_offset);
      });
    },

    onChangeQuery(queryParams) {
      this.queryParams = queryParams;
      this.showLoader = true;
    },
    addDomain() {
      this.selectedDomain = new Route();
      this.activeModal = MODAL_FLAGS.EDIT;
      localStorage.removeItem('blackRules');

    },
    refresh(force = false) {
      if ((lastExecution + refreshDelay) < Date.now() || force) {
        this.selectedRows = [];
        if (this.cname) {
          this.fetchRoutes(this.current_page_offset);
        }
        this.enableSelect = false;
        // document.getElementsByClassName("custom-control-input")[0].checked = false;
        lastExecution = Date.now()
      }
    },
    editDomain(domain) {
      this.selectedDomain = domain;
      this.activeModal = MODAL_FLAGS.EDIT;
      //this.showDomainTooltip(domain, "errrrrrooorrr", 'success', 8000);
    },
    applyRule(domain) {
      this.selectedDomain = domain;
      this.activeModal = MODAL_FLAGS.APPLY_RULE;
    },
    patchDomain() {
      if (this.selectedRows.length == 0) {
        this.flash(this.$t('message.PleaseCheckTheDomainNameFirst'), "error", {timeout: 3000});
        return
      }
      this.selectedDomain = new Route();
      this.patchModal = false;
      this.patchModal = true;
    },
    patchSSL() {
      this.handleEnableHttpsMultiple();
      this.endSelect();
      this.refresh();
      this.sslLoader = true;
    },
    enableForceSSL() {
      this.handleForceHttpsMultiple(true);
      //this.endSelect();
      //this.refresh();
    },
    disableForceSSL() {
      this.handleForceHttpsMultiple(false);
      this.endSelect();
      this.refresh();
    },
    async toggleForceSSL(isEnable = true) {
      function isDomainAllowed(domain) {
        if (!domain.sslConfig || !domain.sslConfig.enabled) {
          return false;
        }
        // is already in the desired action
        if (domain.sslConfig.forceSSL == isEnable) {
          return false;
        }
        return true;
      }

      const allAreNotHTTPSEnabled = this.selectedDomains.every((domain) => !domain.sslConfig || !domain.sslConfig.enabled)
      if (allAreNotHTTPSEnabled) {
        this.flash(this.$t('message.BatchSelectedDomainsNotHTTPSEnabled'), 'info', {"timeout": 5000});
        return;
      }

      const hasValidDomain = this.selectedDomains.some((domain) => isDomainAllowed(domain));
      if (!hasValidDomain) {
        const message = isEnable ? this.$t('message.BatchForceHTTPSNoEnableAction') : this.$t('message.BatchForceHTTPSNoDisableAction');
        this.flash(message, 'info', {"timeout": 5000});
        return;
      }

      let hasErrorResponse = false;
      for (let domain of this.selectedDomains) {
        if (!isDomainAllowed(domain)) {
          continue;
        }

        const sslConfig = {...domain.sslConfig, forceSSL: isEnable};

        let response;
        try {
          response = await axios({
            method: "POST",
            url: `domain/{domain}/config/`,
            urlParams: {
              domain: domain.name
            },
            data: {
              section: "sslConfig",
              config: sslConfig,
              shadows: domain.shadows
            }
          });
        } catch (errors) {
          errors.forEach((message) => {
            this.flash(message, 'error', {"timeout": 5000});
          });
        }

        if (!(response.status > 200)) {
          hasErrorResponse = true;
        }
      }

      this.endSelect();
      this.refresh();

      if (hasErrorResponse) {
        this.flash(this.$t('message.OperationCompletedWithError'), 'info', {"timeout": 5000});
        return;
      }

      const message = isEnable ? this.$t('message.BatchForceSSLEnabled') : this.$t('message.BatchForceSSLDisabled');
      this.flash(message, "success", {timeout: 3000});
    },
    showSslConfig(domain) {
      // alert('TODO: Feature to be implemented');
      this.selectedDomain = domain;
      this.sslModal = true;
      this.activeModal = MODAL_FLAGS.SSL_CONFIG;
    },
    showClusterUpgrade(domain) {
      this.selectedDomain = domain;
      this.clusterUpgradeModal = true;
    },
    doClearCache() {
      this.clearCacheModal = true;
    },
    diagnose(domain) {
      this.selectedDomain = domain;
      this.diagnoseModal = true;
    },

    async cleancache(domain) {

      let plugins = domain.getPlugins();

      if (!this.isClearCacheEnabled(domain)) {
        this.$notification.info({message: this.$t('ClearCacheActionNotAvailable')});
        return;
        // plugins.push({
        //   name: PLUGIN_NAMES.GAIUS_PROXY_CACHE,
        //   config: {
        //       request_method: ["GET", "HEAD", "OPTIONS"],
        //       response_code: [200, 301, 302],
        //       enable_cache_control: true,
        //       bypass_rules: [],
        //       content_type: ["image", "css", "javascript", "audio", "application"],
        //       cache_version: "1"
        //   },
        //   "enabled": true
        // });
      }

      const newPlugins = plugins.map((p) => {
        if (p['name'] === PLUGIN_NAMES.GAIUS_PROXY_CACHE) {
          const _p = {...p};
          _p.config.cache_version = (_p.config.cache_version || 1) + 1;
          _p.enabled = true;

          return _p;
        }

        return p;
      });

      const newDomain = {...domain.getRawData()};
      newDomain.plugins = newPlugins;

      const payload = {
        domain: newDomain,
        meta: domain.getRawMeta(),
        clear_cache: true
      };

      this.$notification.info({message: this.$t('ClearCacheActionRunning')});

      try {
        await axios({
          method: "PATCH",
          url: `domain/routes/${domain.getId()}/`,
          data: payload,
        });
      } catch (error) {
        this.$notification.error({message: this.$t('ClearCacheActionFailed')});
        return;
      }

      this.refresh(true);

      setTimeout(() => {
        this.$notification.success({message: this.$t('ClearCacheActionSuccessful')});
      }, 1000);


    },

    isClearCacheEnabled(domain) {
      const plugins = domain.getPlugins();

      for (let p of plugins) {
        if (p['name'] === PLUGIN_NAMES.GAIUS_PROXY_CACHE && p['enabled'] == true) {
          return true;
        }
      }

      return false;
    },
    handleDeploy(domain) {
      if (domain.status == 'disabled') {
        this.selectedDomain = domain;
        this.showConfirmActivateModal = true;
      } else {
        this.redeploy(domain);
      }
    },

    redeploy(domain) {
      this.sendDeploy(DomainAction.DOMAIN_REDEPLOY, [domain])
    },

    patchDeploy() {
      this.queueDomainDeploy = this.selectedDomains;
      this.onProgressDomainDeploy = this.queueDomainDeploy.splice(0, LIMIT_BATCH_DEPLOY);
      this.queueDomainDeploy = this.queueDomainDeploy.filter(item => {
        item.deploy_status = "running";
        let result = true;
        this.onProgressDomainDeploy.forEach(t => {
          if (item.name === t.name) {
            result = false;
          }
        })
        return result;
      });
      this.sendDeploy(DomainAction.DOMAIN_REDEPLOY, this.onProgressDomainDeploy)
      this.endSelect();
    },
    fetchRoutes() {
      this.listLoading = true;
      let searchDomains = [];
      for (let d of this.selectedSearchDomains) {
        searchDomains.push(d.name);
      }
      if (this.findMultiDomain) {        
        let findMultipleDomain = this.findMultiDomain.toLowerCase();
        findMultipleDomain = findMultipleDomain.split('\n');
        for (let i in findMultipleDomain) {
          const findDomainQ = findMultipleDomain[i].trim();
          if (findDomainQ == "") {
            continue;
          }
          if (findDomainQ.includes(' ')) {
            this.notifyError(this.$t('message.InvalidDomain',[findMultipleDomain[i]]));
            return;
          }
          searchDomains.push(findDomainQ);
        }
        searchDomains = [...new Set(searchDomains)];
      }
      if (this.findBtnClicked) {
        this.visibleModalFindDomain = !this.visibleModalFindDomain;
        this.findBtnClicked = false;
      }

      if (this.domainQ !== null && this.domainQ.length > 0) {        
        this.domainQ = this.domainQ.trim();
      }

      const params = {
        page: this.page,
        perpage: this.perpage,
        cert_status: this.certStatus,
        status: this.domainStatus,
      }

      const payload = {
        q: this.domainQ,
        q1: searchDomains.join(","),
      }

      return findDomains(payload, params)
          .then((response) => {
            const responseData = response.data;

            this.routes = makeRoutes(responseData.domains);
            if (this.routeList.length >= this.perpage || responseData.total_pages == responseData.page) {
              this.total_pages = responseData.total_pages;
            } else {
              this.total_pages = Math.ceil(this.routeList.length / this.perpage) || 1;
            }
            this.total_rows = responseData.domains.length;
            this.total_items = this.total_pages * this.perpage;

            if ((this.domainQ !== null && this.domainQ.length > 0) || searchDomains.length > 0) {              
              this.count = true;
            } else {              
              this.count = false;
            }
          })
          .finally(() => {
            this.listLoading = false;
            this.endSelect();
          });
    },
    handleHideSslConfig() {
      this.endSelect();
      this.sslModal = false;
      this.activeModal = null;
      this.refresh();
      this.attachIssueWsHandler();
    },
    doDiagnoseCheck() {
      if (this.selectedRows.length == 0) {
        this.flash(this.$t('message.PleaseCheckTheDomainNameFirst'), "error", {timeout: 3000});
        return;
      }

      this.showSummaryDiagnoseModal = true;
    },
    patchActive() {
      if (this.selectedRows.length == 0) {
        this.flash(this.$t('message.PleaseCheckTheDomainNameFirst'), "error", {timeout: 3000});
        return;
      }
      const answer = window.confirm($i18n('message.ConfirmDomainsActive'));
      if (answer) {
        this.handleActiveDomains(false);
        this.endSelect();
      }
    },
    patchDeactive() {
      if (this.selectedRows.length == 0) {
        this.flash(this.$t('message.PleaseCheckTheDomainNameFirst'), "error", {timeout: 3000});
        return;
      }
      const answer = window.confirm($i18n('message.ConfirmDomainsDeactive'));
      if (answer) {
        this.handleActiveDomains(true);
        this.endSelect();
      }
    },
    async batchDelete() {
      if (this.isBatchDeleteInProgress == false) {
        this.listLoading = true;
        let totalDomains = this.selectedRows.length - 1;
        var data = []
        const clientIP = await getClientIP();
        for (var i = 0; i < this.selectedRows.length; i++) {
          var tags = this.selectedRows[i]._data.tags;
          if (tags && tags.find((t) => t.includes('cname'))) {
            data.push({
              route_id: this.selectedRows[i]._data.id,
              cname: tags.find((t) => t.includes('cname')).split('"')[1],
              row: {
                name: this.selectedRows[i]._data.domain_name.replace('-', '.'),
                index: i,
                count: totalDomains,
                hit_broadcast: true
              },
              domain: this.selectedRows[i]
            })
            if (data.length == 50) {
              await this.domain_ws.sendMessage(DomainAction.DOMAIN_DELETE, {
                "route_ids": data,
                "client_ip": clientIP.data.ip
              })
              data = []
              await this.sleep(7000)
            }
          }
        }
        if (data.length > 0) {
          await this.domain_ws.sendMessage(DomainAction.DOMAIN_DELETE, {
            "route_ids": data,
            "client_ip": clientIP.data.ip
          })
        }
      }
    },

    async exportAllDomain() {
      this.setExportImportLang();
      this.flash(this.$t('message.DownloadStarted'), 'info', {"timeout": 3000});
      const body_domains = this.selectedRows.map((sr) => sr._data.domain_name);
      let domains = await axios({
        method: "POST",
        url: `domain/download/`,
        data: {
          domains: body_domains,
          page: this.page,
          perpage: this.perpage,
        }
      })
      const data = domains.data.domains;
      const download_data = [];
      data.forEach(item => {
        let status = item.domain.status;
        switch (item.domain.status) {
          case "Running":
            status = "Online";
            break;
          case "Deactivated":
            status = "Disabled";
            break;
        }

        if (item.domain.domain_action == "redirect") {
          const plugins = item.domain.plugins;
          plugins.forEach(plugin => {
            if (plugin.name === 'gaius-redirect') {
              const plugin_data = plugin.config.redirect_url;
              download_data.push({
                [COLUMN_KEYS.NAME]: item.domain.hosts[0],
                [COLUMN_KEYS.ORIGIN_IP]: plugin_data,
                [COLUMN_KEYS.PROTOCOL]: item.domain.service.protocol,
                [COLUMN_KEYS.SHADOW_DOMAINS]: item.domain.hosts.slice(1, item.domain.hosts.length).join(', '),
                [COLUMN_KEYS.STATUS]: status,
                [COLUMN_KEYS.AUTO_GENERATE_CERT]: item.domain.service.tags.includes(TAG_NAMES.HAS_AUTO_GENERATE_CERTIFICATE),
                [COLUMN_KEYS.REMARK]: item.meta.description,
              })
            }
          })
        } else {
          download_data.push({
            [COLUMN_KEYS.NAME]: item.domain.hosts[0],
            [COLUMN_KEYS.ORIGIN_IP]: item.domain.upstream.targets.map((sr) => sr.target).join(', '),
            [COLUMN_KEYS.PROTOCOL]: item.domain.service.protocol,
            [COLUMN_KEYS.SHADOW_DOMAINS]: item.domain.hosts.slice(1, item.domain.hosts.length).join(', '),
            [COLUMN_KEYS.STATUS]: status,
            [COLUMN_KEYS.AUTO_GENERATE_CERT]: item.domain.service.tags.includes(TAG_NAMES.HAS_AUTO_GENERATE_CERTIFICATE),
            [COLUMN_KEYS.REMARK]: item.meta.description,
          })
        }

      });
      await this._exportDomainData(download_data);
      this.endSelect();
      this.flash(this.$t("message.DownloadCompleted"), 'success', {"timeout": 3000});
    },
    getRoutes(serviceId) {
      return this.routeMap[serviceId].hosts;
    },
    handleGoToOldDomainUI() {
      if (window.localStorage) {
        window.localStorage.setItem('OPT_OUT_NEW_DOMAIN_UI', 'true');
      }
      this.$router.push({path: '/domain/list0'});
    },
    handleConfigureCnameClick() {
      this.activeModal = MODAL_FLAGS.CONFIGURE_CNAME;
    },
    async handleClearCacheMultiple() {
      if (!this.selectedRows) {
        return;
      }
      const serviceIds = this.selectedRows.map((sr) => sr._data.service.id);
      const clientIP = await getClientIP();
      this.domain_ws.sendMessage(DomainAction.CLEAR_CACHE, serviceIds, clientIP?.data?.ip);
    },
    async handleClearCustomCacheMultiple(targets) {
      this.domain_ws.sendMessage(DomainAction.CLEAR_CUSTOM_CACHE, targets);
    },
    async handleForceHttpsMultiple(enable) {
      if (!this.selectedRows) {
        return;
      }
      const routeIds = this.selectedRows.map((sr) => sr._data.id);
      const clientIP = await getClientIP();
      this.domain_ws.sendMessage(
        DomainAction.FORCE_HTTPS,
        {routeIds: routeIds, enabled: enable},
        clientIP?.data?.ip
      );
      if (!enable) {
        EventBus.$emit(EVENT_BUS_EVENTS.TASK_SCHEDULED, {
          task: TASK_NAMES.FORCE_HTTPS,
          options: {
            enabled: false,
          }
        });
        return;
      } else {
        EventBus.$emit(EVENT_BUS_EVENTS.TASK_SCHEDULED, {
          task: TASK_NAMES.FORCE_HTTPS,
          options: {
            enabled: true,
          }
        });
      }
    },
    async handleEnableHttpsMultiple() {
      if (!this.selectedRows) {
        return;
      }
      const routeIds = this.selectedRows.map((sr) => sr._data.id);
      const clientIP = await getClientIP();
      this.issue_ws.sendMessage(
        DomainAction.ENABLE_HTTPS,
        {routeIds: routeIds},
        clientIP?.data?.ip
      );
      EventBus.$emit(EVENT_BUS_EVENTS.TASK_SCHEDULED, {
        task: TASK_NAMES.ENABLE_HTTPS,
        options: {}
      });
    },
    async handleActiveDomains(paused) {
      const routeIds = this.selectedRows.map((sr) => sr._data.id);
      const clientIP = await getClientIP();
      this.domain_ws.sendMessage(
          DomainAction.PAUSE_RESTORE_DOMAIN, {
            routeIds: routeIds,
            paused: paused,
            row: {
              paused: paused
            },
            client_ip: clientIP?.data?.ip
          }
      );
    },
    handleExpandedCreateButton(e) {
      if (e.key === 'import') {
        router.push({path: '/domain/import'})
      } else if (e.key === 'create-rules') {
        router.push({path: '/domain/rules'})
      }
    },
    handleExpandedBatchEditButton(e) {
      if (e.key === 'clear-cache') {
        this.doClearCache();
      } else if (e.key === 'diagnose') {
        this.doDiagnoseCheck();
      } else if (e.key === 'enable-https') {
        this.patchSSL();
      } else if (e.key === 'enable-force-https') {
        this.enableForceSSL();
      } else if (e.key === 'disable-force-https') {
        this.disableForceSSL();
      } else if (e.key === 'status-restore') {
        this.patchActive();
      } else if (e.key === 'status-deactivate') {
        this.patchDeactive();
      } else if (e.key === 'status-delete') {
        const me = this;

        Modal.confirm({
          title: this.$t('message.ConfirmBatchDomainDelete'),
          onOk() {
            me.batchDelete();
          },
        });
      } else if (e.key === 'download') {
        this.exportAllDomain();
      }
    },
    handleDomainStatusFilter(filter) {
      this.domainStatus = filter === 'all' ? '' : filter;
      this.refresh(true);
    },
    handleCertStatusFilter(filter) {
      this.certStatus = filter === 'all' ? '' : filter;
      this.refresh(true);
    },
  }
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>
.searchInput .form-control {
  height: 43px !important;
}

.multiselect-search {
  top: 5px;
}

.filter-controls .multiselect__content .load-more {
  font-size: 0.9em;
  margin-left: 12px;
  color: #39f;
  cursor: pointer;
}

.filter-controls .multiselect__content .load-more:hover {
  text-decoration: underline;
}

.GrayLock {
  color: gray;
}

.GreenLock {
  color: green;
}

.source-config-value {
  display: flex;
  align-items: center;
}

.source-config-value span {
  padding-left: 6px;
}

.domain-notes {
  white-space: nowrap;
  width: 100%; /* IE6 needs any width */
  overflow: hidden; /* "overflow" value must be different from  visible"*/
  -o-text-overflow: ellipsis; /* Opera < 11*/
  text-overflow: ellipsis; /* IE, Safari (WebKit), Opera >= 11, FF > 6 */
}

#filterBox {
  position: relative;
  margin-bottom: 10px;
  width: 100%;
  display: none;
}

#filterBox.Show {
  display: flex !important;
}
</style>

<style lang="scss">
.root-domain-message-warning-icon {
  font-size: 42px;
}

.opacity-0 {
  opacity: 0;
}

.domain-list-container .ant-list-items .ant-list-item:first-child {
  padding-top: 0;
}

.multiselect .multiselect__tags {
  padding: 5px 40px 0 8px;
  border: 1px solid #d9d9d9;
}

.searchInput .ant-input::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  font-size: 0.9em;
}

.searchInput .ant-input::-moz-placeholder { /* Firefox 19+ */
  font-size: 0.9em;
}

.searchInput .ant-input:-ms-input-placeholder { /* IE 10+ */
  font-size: 0.9em;
}

.searchInput .ant-input:-moz-placeholder { /* Firefox 18- */
  font-size: 0.9em;
}

.popover-list-filter-trigger.btn svg {
  height: 20px;
  width: 20px;
}

.exclusive-cname {
  svg {
    margin-top: 5px;
  }
}

.create-button-group .ant-btn-default {
  background-color: #00affe;
  color: white;
  border-color: #00a2eb;
}
</style>
