<template>
  <div>
    <a-card v-if="!batchEdit">
      <div>
        <p class="mb-1 has-icon" v-bind:class="domainErrorClass">
          <a-icon v-if="!domainEditDisabled" type="form" class="mr-1"/>
          <strong>{{ $t('domain.DomainName') }}
            <a-tooltip :title="$t('message.FieldRequired')">
              <span v-if="!domainEditDisabled" class="text-danger">*</span>
            </a-tooltip>
          </strong>
        </p>

        <a-alert v-if="hasFieldError('domain') || (fieldErrors && fieldErrors.hasOwnProperty('hosts'))"
                 :message="domainError" type="error" :banner="true"/>
      </div>
      <div class="mt-2">
        <CInput
            id="domain_name"
            :disabled="domainEditDisabled"
            :placeholder="$t('domain.PleaseEnterTheDomainName')"
            :is-valid="isDomainValid(domain)"
            :value="domain"
            @input="$emit('update:domain', $event)"
            @keydown="catchInvalidChars"
            style="margin-bottom: 6px"
        ></CInput>

        
        <!-- since editing of domain name is not allowed we hide the help text on edit. we only show on create -->
        <p v-if="!domainEditDisabled" class="domain-input-description"
           v-html="$t('message.DomainInputDescription')"/>
      </div>
    </a-card>

    <!-- Domain Action Tab -->
    <a-card :title="$t('domain.DomainAction')" class="mt-4">
      <div class="container">
        <div v-if="domainAction === null" class="ml-n3 mr-n3">
          <a-alert
            message="Warning"
            :description="$t('message.domain_action_mixed_selection')"
            type="warning"
            class="mb-2"
            show-icon
          />
        </div>
          
        <p class="ml-n3">{{ $t('message.TrafficActionDescription') }}</p>
        
        <div class="traffic-actions row">
          <div class="traffic-action col-sm-12 col-lg-6">
            <div>
              <input @click="handleDomainActionChange(trafficActionUpstream)"
                     id="origin-traffic-action"
                     type="radio"
                     value="origin"
                     :checked="domainAction === trafficActionUpstream">
            </div>
            <div>
              <label @click="handleDomainActionChange(trafficActionUpstream)">{{
                  $t('domain.RouteToOriginServer')
                }}</label>
              <p>{{ $t('message.RouteToOriginServerDescription') }}</p>
            </div>
          </div>

          <div class="traffic-action col-sm-12 col-lg-6">
            <div>
              <input @click="handleDomainActionChange(trafficActionRedirect)"
                     id="redirect-traffic-action"
                     type="radio"
                     value="redirect"
                     :checked="domainAction === trafficActionRedirect">
            </div>
            <div style="flex: 1">
              <label @click="handleDomainActionChange(trafficActionRedirect)">{{
                  $t('domain.RedirectTraffic')
                }}</label>
              <p>{{ $t('message.RedirectTrafficDescription') }}</p>
            </div>
          </div>
        </div>
      </div>
    </a-card>

    <a-card v-show="domainAction === trafficActionUpstream" :title="$t('domain.Upstream')" class="mt-4">
      <p class="mb-1"><strong>{{ $t('domain.UpstreamTargets') }}</strong></p>
      <p>{{ $t('message.UpstreamTargetsDescription') }}</p>

      <a-alert
          v-if="isUpstreamTargetsMixed"
          message="Warning"
          :description="$t('message.domain_settings_mixed_selection')"
          type="warning"
          class="mb-2"
          show-icon
      />

      <small class="mt-4 mb-1 text-muted">{{ $t('message.UpstreamTargetsList') }}</small>
      <div v-if="!isUpstreamTargetsMixed && upstreamTargets.length">
        <CListGroup>
          <CListGroupItem v-for="(target) in upstreamTargets" v-bind:key="target.target">
            <div style="display: flex; align-items: center;">
              <div style="flex: 1">
                {{ target.target }}
              </div>
              <div class="shadow-list-action-btns">
                <CButtonClose v-on:click="removeTarget(target)"/>
              </div>
            </div>
          </CListGroupItem>
        </CListGroup> 
      </div>
      <MixedSelectionEmpty v-else-if="isUpstreamTargetsMixed"></MixedSelectionEmpty>
      <SimpleEmpty v-else>
        <span slot="description">{{ $t('message.NoUpstreamTargetsAdded') }}</span>
      </SimpleEmpty>

      <div class="mt-4 p-4 border-top">
        <p class="mb-1 has-icon text-info"
           v-bind:class="{'text-info': !hasFieldError('upstream_targets'), 'text-danger': hasFieldError('upstream_targets')}">
          <a-icon type="form" class="mr-1"/>
          <strong>{{ $t('message.AddNewTarget') }}
            <a-tooltip :title="$t('message.FieldRequired')">
              <span v-if="!domainEditDisabled" class="text-danger">*</span>
            </a-tooltip>
          </strong>
        </p>
        <p>
          {{ $t('message.UpstreamTargetDescription') }}
        </p>

        <a-alert v-if="hasFieldError('upstream')"
                 :message="upstreamTargetError"
                 type="error"
                 :banner="true"
                 class="mb-2"/>

        <CTextarea
            id="add_new_target"
            v-model="newTargetName"
            placeholder="20.1.30.4:8001"
            :description="$t('InputFormat') + ': 20.1.30.4:8001 or www.domain.com'"
        >
        </CTextarea>

        <div class="d-flex justify-content-end mt-n4">
          <a-button @click="addTarget()" :disabled="!newTargetName.length">
            <a-icon type="plus"/>
            {{ $t('Add') }}
          </a-button>
        </div>
      </div>

      <div class="mt-4 p-4 border-top">
        <p class="mb-1 has-icon text-info"
           v-bind:class="{'text-info': !hasFieldError('protocol'), 'text-danger': hasFieldError('protocol')}">
          <a-icon type="form" class="mr-1"/>
          <strong>{{ $t('domain.UpstreamProtocol') }}
            <a-tooltip :title="$t('message.FieldRequired')">
              <span v-if="!domainEditDisabled" class="text-danger">*</span>
            </a-tooltip>
          </strong>
        </p>
        <p>{{ $t('message.UpstreamProtocolDescription') }}</p>

        <a-alert v-if="hasFieldError('protocol')"
                 :message="serviceProtocolError"
                 type="error"
                 :banner="true"
                 class="mb-2"/>

        <a-button-group>
          <a-button @click="$emit('update:service-protocol', 'http')"
                    :type="serviceProtocol === 'http' ? 'primary' : 'default'">HTTP
          </a-button>
          <a-button @click="$emit('update:service-protocol', 'https')"
                    :type="serviceProtocol === 'https' ? 'primary' : 'default'">HTTPS
          </a-button>
        </a-button-group>

        <p v-if="checkingDomainUpstreamProtocol" class="mt-2 mb-0 d-flex justify-content">
          <a-icon type="loading" class="mt-1"/>
          <span class="ml-1">{{ $t('message.DetectingUpstreamProtocol') }}</span>
        </p>
      </div>
    </a-card>
    <a-card v-show="domainAction === trafficActionRedirect" :title="$t('domain.RedirectTraffic')" class="mt-4">
      <div>
        <p class="mb-1 has-icon text-info"
           v-bind:class="{'text-info': true, 'text-danger': false}">
          <a-icon type="form" class="mr-1"/>
          <strong>{{ $t('domain.RedirectToUrl') }}
            <a-tooltip :title="$t('message.FieldRequired')">
              <span class="text-danger">*</span>
            </a-tooltip>
          </strong>
        </p>
        <p>{{ $t('message.SpecifyRedirectUrl') }}</p>

        <a-alert
          v-if="isMixedValue(redirectUrl)"
          message="Warning"
          :description="$t('message.domain_settings_mixed_selection')"
          type="warning"
          class="mb-2"
          show-icon
      />

        <a-form-item :validate-status="redirectUrlValidateStatus" :help="redirectUrlInputHelpMessage">
          <a-input v-model="syncRedirectUrl"
                   class="w-100"
                   placeholder="https://www.example.com"/>
        </a-form-item>

        <a-checkbox :checked="ispreservepath" @change="ispreservepathchange">
          {{ $t('domain.Preservepath') }}
          <p class="mt-2 mb-0">{{ $t('message.Preservepathenabled') }}</p>
        </a-checkbox>

      </div>
    </a-card>

    <a-card :title="$t('domain.AutoGenerateCert')" class="mt-4">
      <div>
        <div class="d-flex align-items-center mb-1 mt-2 has-icon">
          <a-switch class="mr-2" :checked="hasAutoGenerateCert" @change="hasAutoGenerateCertChange">
            <a-icon slot="checkedChildren" type="check"/>
            <a-icon slot="unCheckedChildren" type="close"/>
          </a-switch>
          <strong class="text-info">
            {{ $t('domain.AutoGenerateCertTitle') }}
          </strong>
        </div>
        <p>{{ $t('domain.AutoGenerateCertDescription') }}</p>
      </div>
    </a-card>
  </div>
</template>

<script>
import validUrl from 'valid-url';
import DomainValidationMixin from '@/utilities/DomainValidationMixin';
import {DOMAIN_TRAFFIC_ACTIONS, MIXED_VALUE, MULTIPLE_VALUES_SPLITTER, TAG_NAMES} from '@/utilities/constants';
import SimpleEmpty from '@/views/domain/SimpleEmpty';
import MixedSelectionEmpty from '@/views/domain/MixedSelectionEmpty';
import {domainConfig, getDomainVerifyUpstreamProtocol} from '@/utilities/api';
import { bus } from '@/main';


export default {
  name: 'BasicConfiguration',
  components: {SimpleEmpty, MixedSelectionEmpty},
  mixins: [DomainValidationMixin],
  props: [
    'domain',
    'domainEditDisabled',
    'batchEdit',
    'upstreamTargets',
    'fieldErrors',
    'serviceProtocol',
    'domainAction',
    'redirectUrl',
    'preservePath',
    'tags'
  ],
  data() {
    return {
      newTargetName: "",
      blackRulExtensions: [],
      checkingDomainUpstreamProtocol: false,
    };
  },
  created() {
    bus.$on('emptyNewTargetName', () => {
      this.newTargetName = '';
    });
  },
  computed: {
    isEditMode: function () {
      return this.domainEditDisabled;
    },
    isPaidCname: function () {
      return !this.isFreeCname;
    },
    isFreeCname: function () {
      const subscriptions = this.$store.state.subscriptions;
      for (let s of subscriptions) {
        if (!s.plan)
          return true;
      }

      return false;
    },
    cname: function () {
      return this.$store.getters.cname;
    },
    redirectUrlValidateStatus: function () {
      if (this.syncRedirectUrl === '')
        return '';

      if (!validUrl.isWebUri(this.syncRedirectUrl)) {
        return 'error';
      }

      return '';
    },
    redirectUrlInputHelpMessage: function () {
      if (this.redirectUrlValidateStatus === 'error') {
        return this.$t('message.URLFormatInvalid');
      }

      return '';
    },
    syncRedirectUrl: {
      get() {
        if (this.isMixedValue(this.redirectUrl)) 
          return '';
        
        return this.redirectUrl;
      },
      set(value) {
        this.$emit('update:redirect-url', value);
      }
    },
    ispreservepath: {
      get() {
        if (this.isMixedValue(this.preservePath))
          return '';
        return this.preservePath;
      },
      set(value) {
        this.$emit('update:preserve-path', value);
      },
    },
    hasAutoGenerateCert: function () {
      return Boolean(this.tags.find((tag) => tag === TAG_NAMES.HAS_AUTO_GENERATE_CERTIFICATE));  
    },
    domainError: function () {
      if (Object.hasOwn(this.fieldErrors, 'hosts')) {
        return this.fieldErrors.hosts[0];
      }

      if (!this.hasFieldError('domain'))
        return false;

      if (this.fieldErrors.domain === 'unique constraint violation') {
        return this.$t('message.domain_already_exist');
      }
      return this.$t('message.FieldHasError');
    },
    domainErrorClass: function () {
      if (this.domainEditDisabled)
        return '';

      if (this.hasFieldError('domain'))
        return 'text-danger';

      return 'text-info';
    },
    upstreamTargetError: function () {
      if (!this.hasFieldError('upstream'))
        return;

      if (!this.upstreamTargets.length)
        return this.$t('message.UpstreamTargetRequired');

      // default to the first error the backend provided
      return this.fieldErrors.upstream[0];
    },
    serviceProtocolError: function () {
      if (!this.hasFieldError('protocol'))
        return;

      return this.$t('message.UpstreamProtocolRequired');
    },
    trafficActionUpstream: function () {
      return DOMAIN_TRAFFIC_ACTIONS.UPSTREAM;
    },
    trafficActionRedirect: function () {
      return DOMAIN_TRAFFIC_ACTIONS.REDIRECT;
    },
    selectedSubscription: function () {
      return this.$store.state.selectedSubscription;
    },
    isUpstreamTargetsMixed: function () {
      return this.upstreamTargets === MIXED_VALUE;
    },
  },
  async mounted() {
    try {
      let response = await domainConfig();

      if ("BLACKRULE_DOMAINS" in response.data) {
        this.blackRulExtensions = response.data["BLACKRULE_DOMAINS"];
      }  

    } catch (errors) {
      errors.forEach((message) => {
        console.log("================blackRulExtensions Erro:", message);
      });
      this.blackRulExtensions = [];
    }
  },
  methods: {
    ispreservepathchange(checkbox){
      let enabled = checkbox.target.checked
      this.ispreservepath = enabled;
    },
    hasAutoGenerateCertChange(enabled) {
      let tags = [...this.tags].filter((tag) => tag !== TAG_NAMES.HAS_AUTO_GENERATE_CERTIFICATE);
      if (enabled) {
        tags.push(TAG_NAMES.HAS_AUTO_GENERATE_CERTIFICATE);
      }
      this.updateTags(tags);
    },
    hasFieldError: function (field) {
      if (!this.fieldErrors)
        return false;

      return Boolean(this.fieldErrors[field]);
    },
    isDomainValid: function (domain) {
      // return undefined to not show the validation styling
      if (this.hasFieldError('domain') || this.domainEditDisabled || !domain || !domain.length) {
        return;
      }
      
      if (domain) {
        let check_domain = domain.split('.')
        if (this.blackRulExtensions.includes(check_domain[check_domain.length-1].toUpperCase())) {
          //true && this.notifyError(this.$t('message.InvalidDomainExtension', {on: this.$t('domain.DomainName')}));
          return false;
        }
      }

      return this.domain_validator(domain);
    },
    addTarget(suppressErrorMessage = false) {
      this.newTargetName = this.newTargetName.trim();
      if (!this.newTargetName.length) {
        return;
      }
      
      const targets = this.isMixedValue(this.upstreamTargets) ? [] : [...this.upstreamTargets];
      const rawTargets = this.newTargetName.split(MULTIPLE_VALUES_SPLITTER);

      for (let t of rawTargets) {

        if (this.selectedSubscription.plan) {
          if (this.selectedSubscription.plan.name == this.$t('widget.FreeMembership') && this.domain_validator(t)) {
            this.notifyError(this.$t('message.FreeAccountCanNotAddDomain'));
            bus.$emit('show-contact');
            return;
          }
          if (this.selectedSubscription.plan.name == this.$t('widget.FreeMembership') && this.source_hostname_validator(t)) {
            console.log()
            this.notifyError(this.$t('message.FreeAccountCanNotAddDomain'));
            bus.$emit('show-contact');
            return;
          }
        }

        if (t.split(':').length - 1 > 1) {
          if (!this.source_ipv6_validator(t)) {
            !suppressErrorMessage && this.notifyError(this.$t('message.IsNotAValidHostnameOrIpFormat', [t]), this.$t('message.InputDataVerificationError'));
            continue;
          }
        } else {
          if (!this.source_hostname_validator(t) && !this.source_ipv4_validator(t)) {
            !suppressErrorMessage && this.notifyError(this.$t('message.IsNotAValidHostnameOrIpFormat', [t]), this.$t('message.InputDataVerificationError'));
            continue;
          }
        }

        // add default port 80 if not specified
        let hasNoPort = t.indexOf(':') < 0;
        if (hasNoPort) {
          t = t + ':80';
        }

        const duplicate = targets.find((_t) => _t.target === t);
        if (duplicate) {
          !suppressErrorMessage && this.notifyError(this.$t('message.TargetDuplicate', [t]));
          continue;
        }

        targets.push({target: t});
      }

      this.$emit('update:upstream-targets', targets);
      this.verifyUpstreamTargets(targets);
      this.newTargetName = '';
    },
    removeTarget(target) {
      const targets = this.upstreamTargets.filter((t) => t.target !== target.target);

      this.$emit('update:upstream-targets', targets);
      this.verifyUpstreamTargets(targets);
    },
    handleDomainActionChange(action) {
      this.$emit('toggle-redirect-domain-action', action === this.trafficActionRedirect);
    },
    isMixedValue(value) {
      return value === MIXED_VALUE;
    },
    catchInvalidChars(e) {
      if (e.code === 'Space' || e.code === 'Comma') {
        e.preventDefault();
      }
    },
    verifyUpstreamTargets(upstreamTargets) {
      if (!upstreamTargets.length) {
        return;
      }

      const upstreams = upstreamTargets.map(n => n.target);
      this.checkingDomainUpstreamProtocol = true;

      getDomainVerifyUpstreamProtocol(upstreams.join(','))
        .then(response => {
          this.checkingDomainUpstreamProtocol = false;

          if (response.data['https']) {
            this.$emit('update:service-protocol', 'https');
          } else if (response.data['http']) {
            this.$emit('update:service-protocol', 'http');
          } else {
            for (const n of upstreamTargets) {
              const target = n.target;
              const segments = target.split(':');
              const port = segments[1];

              if (port === '80') {
                this.$emit('update:service-protocol', 'http');
              } else if (port === '443') {
                this.$emit('update:service-protocol', 'https');
              }
            }
          }
        })
    },
    updateTags(tags) {
      this.$emit('update:tags', tags);
    },
  }
}
</script>

<style scoped>
/* domain action related */
.traffic-actions {
  display: flex;
  border: solid 1px #d8dbdf;
  border-radius: 3px;
}

.traffic-action {
  display: flex;
  padding: 12px 16px 0 16px;
}

.traffic-action:first-child {
  border-right: solid 1px #d8dbdf;
  border-bottom: solid 1px #d8dbdf;
}

.traffic-action > div:first-child {
  padding-top: 1px;
  margin-right: 10px;
}

.traffic-action label {
  font-weight: 500;
  margin-bottom: 0;
}

.traffic-action label + p {
  color: rgba(107, 114, 128, 1);
}
</style>
