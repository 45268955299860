<template>
  <a-card size="small" :title="$t('notes')">
    <a-form class="p-3">
      <p class="mb-1 has-icon text-info">
        <a-icon type="form" class="mr-1"/>
        <strong>{{ $t('domain.DomainNotes') }}</strong>
      </p>
      <p>{{ $t('message.DomainNotesDescription') }}</p>
      <a-textarea :value="description" @change="handleDescriptionKeyPress"></a-textarea>
    </a-form>
  </a-card>
</template>

<script>
export default {
  name: 'MetadataManagement',
  props: ['description'],
  methods: {
    handleDescriptionKeyPress(e) {
      const value = e.target.value

      this.$emit('update:description', value);
    }
  }
}
</script>