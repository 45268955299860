<template>
  <a-popover placement="right" trigger="click">
    <template slot="content">
      <div class="d-flex">
        <a-tooltip :visible="showCopiedTooltip">
          <template slot="title">
            <span>{{ $t('Copied') }}</span>
          </template>
          <a-input v-on:focus="$event.target.select()"
                   ref="clone"
                   readonly
                   style="width: 300px"
                   :value="value"/>
        </a-tooltip>

        <a-button @click="copy" class="ml-2">
          <a-icon type="copy"/>
        </a-button>
      </div>
    </template>
    <template slot="title">
      <span>{{ $t('ExclusiveCNAME') }}</span>
    </template>
    <button type="button" class="btn btn-sm">
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
           stroke="currentColor" style="width: 20px; height: 20px;">
        <path stroke-linecap="round" stroke-linejoin="round"
              d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"/>
      </svg>
    </button>
  </a-popover>
</template>

<script>
export default {
  props: ['value'],
  data() {
    return {
      showCopiedTooltip: false,
    }
  },
  methods: {
    copy() {
      this.$refs.clone.focus();
      document.execCommand('copy');

      this.showCopiedTooltip = true;
      setTimeout(() => {
        this.showCopiedTooltip = false;
      }, 1000)
    }
  }
}
</script>
