<template>
  <div>
    <div class="d-lg-flex justify-content-between">
      <div class="w-100 ml-2 mr-2">
        <CWidgetBrand
            color="gradient-success"
            :right-header="$t(memberInfo.level)"
            :right-footer="$t('widget.MembershipLevel')"
            :left-header="memberInfo.expiredAt | moment('YYYY-MM-DD')"
            :left-footer="$t('widget.ValidPeriod')"
            class="thesmall"
            ref="memberInfo"
        >
          <CIcon name="cib-dashlane" height="52" class="my-4"/>

          <CRow>
            <CCol md="12">
              <template v-if="memberInfo.status === 'trialing'">
                <CButton color="primary"
                         @click="pay('paytrial')"
                         class="mb-1">
                  {{ $t('Pay') }}
                </CButton>
              </template>
              <template v-else>
                <template v-if="memberInfo.level==='Free'">
                  <CButton color="warning" @click="$router.push({name: 'MemberPlans'})">
                    <span>{{ $t('widget.JoinMember') }}</span>
                  </CButton>
                </template>
                <template v-else>
                  <CButton color="warning" @click="pay('renew')"
                           class="mr-1 mb-1">
                    {{ $t('widget.Renew') }}
                  </CButton>
                </template>
              </template>
            </CCol>
            <CCol>
              <CBadge>{{ $t('widget.Subscription') }}</CBadge>
            </CCol>
          </CRow>

          <template v-slot:body>
            <div class="card-body text-center widget-subscription">
            <span id="icon-info"
                  @click="showModalSubscription=true">
              <CIcon name="cil-info"/>
            </span>
              <div class="row">
                <div class="col">
                  <div class="text-value-lg">
                    {{ memberInfo.level }}
                  </div>
                  <div class="text-uppercase text-muted small">
                    {{ $t('widget.MembershipLevel') }}
                  </div>
                </div>
                <div class="c-vr">
                </div>
                <div class="col"
                     :class="{Expired: isNotifyTenDaysExpire}"
                     v-if="isNotifyTenDaysExpire"
                     v-c-tooltip="{
                   active: true,
                   content: $t('widget.WarningExpired')
                   }">
                  <div class="text-value-lg">
                    {{ memberInfo.expiredAt }}
                  </div>
                  <div class="text-uppercase text-muted small">
                    {{ $t('widget.ExpireAt') }}
                  </div>
                </div>
                <div class="col"
                     v-else>
                  <div class="text-value-lg">
                    {{ memberInfo.expiredAt }}
                  </div>
                  <div class="text-uppercase text-muted small">
                    {{ $t('widget.ExpireAt') }}
                  </div>
                </div>
              </div>
            </div>
          </template>
        </CWidgetBrand>
      </div>

      <div class="w-100 ml-2 mr-2">
        <CWidgetBrand
            color="gradient-info"
            :right-header="domain_stat.running + ''|| '0'"
            :right-footer="$t('widget.Accelerating')"
            :left-header="quotas.DOMAIN + ''"
            :left-footer="$t('widget.AvailableDomainNames')"
            class="thesmall"
        >
          <CIcon name="cib-dashlane" height="52" class="my-4"/>

          <CRow>
            <CCol>
              <CBadge>{{ $t('Domain') }}</CBadge>
            </CCol>
          </CRow>

        </CWidgetBrand>
      </div>

      <div class="w-100 ml-2 mr-2">
        <CWidgetBrand
            color="gradient-success"
            class="thesmall"
            :class="{'bg-danger': (month_stat.nout + month_stat.nin) > numeralMethod(quotas.TRAFFIC).value(), 'text-white': (month_stat.nout + month_stat.nin) > numeralMethod(quotas.TRAFFIC).value()}"
        >
          <CIcon name="cib-buzzfeed" height="52" class="my-4 mx-3"/>

          <template v-slot:body>
            <div class="card-body row text-center">
              <div class="col">
                <div class="text-value-lg">
                  {{ total_stat.nout + total_stat.nin | numeralFormat('0.00 b') }}
                </div>
                <div class="text-uppercase text-muted small">
                  {{ $t('Usage') }}
                </div>
              </div>
              <div class="c-vr"></div>
              <div class="col">
                <div class="text-value-lg">
                  {{ quotas.TRAFFIC }}
                </div>
                <div class="text-uppercase text-muted small">
                  {{ $t('Total') }}
                </div>
              </div>
            </div>
            <span id="alertOverrunTraffic"
                  v-if="(total_stat.nout + total_stat.nin) > numeralMethod(quotas.TRAFFIC).value()">{{
                $t('widget.OverrunTrafficAlert')
              }}</span>
          </template>

          <CBadge>{{ $t('message.TrafficQuota') }}</CBadge>
        </CWidgetBrand>
      </div>

      <div class="w-100 ml-2 mr-2">
        <!-- 域名流量趨勢。 找到流量前5的域名，繪圖 -->
        <CWidgetBrand
            color="linkedin"
            :right-header="today_stat.nout| numeralFormat('0.00 b')"
            :right-footer="$t('Outbound')"
            :left-header="today_stat.nin| numeralFormat('0.00 b')"
            :left-footer="$t('Inbound')"
            class="thesmall"
        >
          <CIcon name="cib-buzzfeed" height="52" class="my-4 mx-3"/>
          <CRow>
            <CCol md="12">
              <h2 ml="2" class="text-white">{{ today_stat.nout + today_stat.nin | numeralFormat('0.00 b') }}</h2>
            </CCol>
            <CCol>
              <CBadge>{{ $t('widget.TotalTrafficToday') }}</CBadge>
            </CCol>
          </CRow>
        </CWidgetBrand>
      </div>

      <div class="w-100 ml-2 mr-2">
        <CWidgetBrand
            color="gradient-warning"
            :right-header="today_stat.pv | numeralFormat('0 a')"
            :right-footer="$t('total_request')"
            :left-header="today_stat.hit_pv | numeralFormat('0 a')"
            :left-footer="$t('widget.CacheHits')"
            class="thesmall"
        >
          <CIcon name="cib-ethereum" height="52" class="my-4"/>
          <CRow>
            <CCol md="12">
              <h2 ml="2" class="text-white">{{ 1.0 * today_stat.hit_pv / today_stat.pv | numeralFormat('0.0 %') }}</h2>
            </CCol>
            <CCol>
              <CBadge>{{ $t('widget.CacheHitRate') }}</CBadge>
            </CCol>
          </CRow>
        </CWidgetBrand>
      </div>
    </div>
    
    <PaymentCheckout :order="order" @hide="paymentShow=false" :paymentShow.sync="paymentShow" id="paymentcheckout"/>

    <CModal :title="memberInfo.level + ' - ' + memberInfo.cname"
            :show.sync="showModalSubscription"
            size="sm">
      <CRow class="text-center">
        <CCol>
          <div class="text-value-lg">
            {{ memberInfo.expiredAt }}
          </div>
          <div class="text-uppercase text-muted small">
            {{ $t('message.CurrentPlanExpiration') }}
          </div>
        </CCol>
      </CRow>
      <hr>
      <CRow class="text-center">
        <CCol>
          <div class="text-value-lg">
            $
            <template v-if="isFreeCname">0</template>
            <template v-else>{{ memberInfo.amount_price }}</template>
          </div>
          <div class="text-uppercase text-muted small">
            {{ $t('widget.AmountPrice') }}
          </div>
        </CCol>

        <CCol>
          <div class="text-value-lg">
            {{ memberInfo.additional_domain_number }}
          </div>
          <div class="text-uppercase text-muted small">
            {{ $t('widget.AdditionalDomainNumber') }}
          </div>
        </CCol>
      </CRow>
      <CRow class="text-center">
        <CCol>
          <div class="text-value-lg">
            {{ memberInfo.additional_traffic }}
          </div>
          <div class="text-uppercase text-muted small">
            {{ $t('widget.AdditionalTraffic') }}
          </div>
        </CCol>
      </CRow>
      <template v-slot:footer-wrapper>
        <div></div>
      </template>
    </CModal>
  </div>
</template>

<script>
import axios from "@/plugins/axios.js";
// import { StripeCheckout } from "vue-stripe-checkout";
import {mapGetters} from "vuex";
import PaymentCheckout from '../payment/Checkout'
import numeral from 'numeral';
import {bus} from '@/main';

const DOMAIN_STAT_CACHE_KEY = 'domain_stat';
const MONTH_STAT_CACHE_KEY = 'month_stat';
const TODAY_STAT_CACHE_KEY = 'today_stat';
const QUOTAS_CACHE_KEY = 'quotas';

export default {
  name: "WidgetsBrand",
  components: {
    PaymentCheckout
  },
  props: {
    noCharts: Boolean
  },
  computed: {
    isFreeCname: function () {
      const subscriptions = this.$store.state.subscriptions;
      for (let s of subscriptions) {
        if (s.plan.name === "Free")
          return true;
      }

      return false;
    },
    subscriptions: function () {
      return this.$store.state.subscriptions;
    },
    selectedSubscription: function () {
      return this.$store.state.selectedSubscription;
    },
    memberInfo: function () {
      let info = {
        level: this.$t('widget.FreeMembership'),
        expiredAt: "-",
        cname: "-",
        additional_domain_number: "-",
        additional_traffic: '-',
        amount_price: 0
      };
      if (this.selectedSubscription) {
        if (this.selectedSubscription.plan) {
          info["level"] = this.selectedSubscription.plan.name;
          info["amount_price"] = this.selectedSubscription.amount_price;
          if (this.selectedSubscription.plan.price.quantity > 0) {
            info["expiredAt"] = this.selectedSubscription.next_bill_date;
          }
        } else {
          info["level"] = this.$t('widget.FreeMembership')
        }

        info["status"] = this.selectedSubscription.status;
        info["cname"] = this.selectedSubscription.cname;
        if ('DOMAIN' in this.selectedSubscription.add_meta) {
          info["additional_domain_number"] = this.selectedSubscription.add_meta.DOMAIN;
        }
        if ('TRAFFIC' in this.selectedSubscription.add_meta) {
          info["additional_traffic"] = this.selectedSubscription.add_meta.TRAFFIC;
        }
      }

      return info;
    },
    isNotifyTenDaysExpire() {
      const notifyExpireDays = 10;
      const expireDays = this.$moment(this.memberInfo.expiredAt).subtract(notifyExpireDays, 'days');
      const today = this.$moment();
      if (this.memberInfo.expiredAt === '-') {
        return false;
      } else if (expireDays <= today) {
        return true;
      }
      return false;
    },
    clientReferenceId() {
      if (this.authInfo && this.authInfo.sub) {
        return this.authInfo.sub
      } else {
        return ''
      }
    },
    customerEmail() {
      if (this.authInfo && this.authInfo.email) {
        return this.authInfo.email
      } else {
        return ''
      }
    },

    subscriptionListFields() {
      return [
        {
          key: 'plan',
          label: this.$t('widget.PlanName')
        },
        {
          key: 'cname',
          label: this.$t('widget.CNAME')
        },
        {
          key: 'czone',
          label: this.$t('widget.CZONE')
        },
        {
          key: 'status',
          label: this.$t('widget.Status')
        },
        {
          key: 'additional_domain_number',
          label: this.$t('widget.AdditionalDomainNumber')
        },
        // {
        // key: 'additional_traffic',
        // label: this.$t('widget.AdditionalTraffic')
        // },
        {
          key: 'next_bill_date',
          label: this.$t('widget.ExpireAt')
        },
        {
          key: 'amount_price',
          label: this.$t('widget.AmountPrice')
        },
        {
          key: 'action',
          label: this.$t('widget.Action')
        }
      ]
    },
    ...mapGetters(["authInfo"]),
  },
  data() {
    return {
      selectedPaymentType: null,
      paymentShow: false,
      showModalSubscription: false,
      quotas: {},
      domain_stat: {},
      today_stat: {},
      month_stat: {},
      total_stat: {},
      stat: {},
      paymentTypes: "alipay",
      publishableKey: process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY,
      checkBtnState: false,
      checkTrafficBtnState: false,
      page: 1,
      perpage: 2,
      total_rows: 0,
      total_pages: 0,
      order: {
        amountPrice: 0,
        type: ""
      },
    };
  },
  watch: {
    domain_stat: function () {
      window.localStorage && localStorage.setItem(DOMAIN_STAT_CACHE_KEY, JSON.stringify(this.domain_stat));
    },
    month_stat: function () {
      window.localStorage && localStorage.setItem(MONTH_STAT_CACHE_KEY, JSON.stringify(this.month_stat));
    },
    today_stat: function () {
      window.localStorage && localStorage.setItem(TODAY_STAT_CACHE_KEY, JSON.stringify(this.today_stat));
    },
    quotas: function () {
      window.localStorage && localStorage.setItem(QUOTAS_CACHE_KEY, JSON.stringify(this.quotas));
    }
  },
  created() {
    bus.$on('updateDomainStat', () => {
      this.fetchDomainStat();
    });
    bus.$on('updateQuota', () => {
      this.fetchQuota();
    });
  },
  mounted() {
    if (window.localStorage) {
      // pre-populate widget data with cache values - let's not wait for the ajax to display info
      if (localStorage[DOMAIN_STAT_CACHE_KEY]) {
        this.domain_stat = JSON.parse(localStorage[DOMAIN_STAT_CACHE_KEY]);
      }
      if (localStorage[MONTH_STAT_CACHE_KEY]) {
        this.month_stat = JSON.parse(localStorage[MONTH_STAT_CACHE_KEY]);
      }
      if (localStorage[TODAY_STAT_CACHE_KEY]) {
        this.today_stat = JSON.parse(localStorage[TODAY_STAT_CACHE_KEY]);
      }
      if (localStorage[QUOTAS_CACHE_KEY]) {
        this.quotas = JSON.parse(localStorage[QUOTAS_CACHE_KEY]);
      }
    }

    this.fetchQuota();
    this.fetchTotalStat();
    this.fetchMonthStat();
    this.fetchTodayStat();
    this.fetchDomainStat();
  },
  methods: {
    numeralMethod(value) {
      return numeral(value);
    },
    pageChanged(page) {
      this.page = page
    },
    checkoutDomain() {
      this.$refs.checkoutRefDomain.redirectToCheckout();
    },
    checkoutTraffic() {
      this.$refs.checkoutRefTraffic.redirectToCheckout();
    },
    fetchQuota() {
      axios
          .get(`plan/quota/`, {
            params: {},
            urlParams: {}
          })
          .then(response => {
            this.quotas = response.data;
          })
          .catch(error => {
            console.log(error);
          });
    },
    fetchDomainStat() {
      axios
          .get(`domain/widget/domain_stat/`, {
            params: {},
            urlParams: {}
          })
          .then(response => {
            this.domain_stat = response.data;
          })
          .catch(error => {
            console.log(error);
          });
    },
    fetchTodayStat() {
      axios
          .get(`domain/traffic/today_stat/`, {
            params: {},
            urlParams: {}
          })
          .then(response => {
            this.today_stat = response.data;
          })
          .catch(error => {
            console.log(error);
          });
    },
    fetchMonthStat() {
      axios
          .get(`domain/traffic/monthly_stat/`, {
            params: {},
            urlParams: {}
          })
          .then(response => {
            this.month_stat = response.data;
          })
          .catch(error => {
            console.log(error);
          });
    },
    fetchTotalStat() {
      axios
          .get(`domain/traffic/total_stat/`, {
            params: {},
            urlParams: {}
          })
          .then(response => {
            this.total_stat = response.data;
          })
          .catch(error => {
            console.log(error);
          });
    },
    // renew(type) {
    //   //this.order.type = "renew"
    //   this.order.amountPrice = this.selectedSubscription.amount_price
    //   this.order.subscription_id = this.selectedSubscription.id
    //   this.paymentShow = true
    // },
    pay(payType) {
      this.order.type = payType
      this.order.amountPrice = this.selectedSubscription.amount_price
      this.order.subscription_id = this.selectedSubscription.id
      this.paymentShow = true
    },
    clickModify() {
      this.$router.push({name: 'MemberSubscriptionModify', params: {id: this.selectedSubscription.id}})
    },
    clickPayPlan() {
      if (this.selectedPaymentType) {
        if (this.selectedPaymentType === 'cryptocurrency') {
          if (!this.selectedCoin) {
            this.flash(this.$t('checkout.WarningCoin'), 'warning')
            return
          }
        }
        if (this.selectedPaymentType === 'account_balance') {
          if (parseFloat(this.$store.state.balance) < parseFloat(this.selectedSubscription.amount_price)) {
            this.flash(this.$t('checkout.WarningBalance'), 'warning')
            return
          }
          this.selectedCoin = 'USDT'
        }
      }
    },
    clickCoins(coin, $event) {
      if (this.selectedPaymentType === 'cryptocurrency') {
        const li = document.querySelectorAll('ul#coins li')
        li.forEach(item => {
          item.classList.remove('Active')
        })
        const $target = $event.target
        $target.parentNode.classList.toggle('Active')
        this.selectedCoin = coin
      }
    }
  }
};
</script>

<style>
#alertOverrunTraffic {
  padding-left: 1.25rem;
  padding-bottom: 1rem;
  font-size: small;
}

.c-chart-brand {
  position: absolute;
  width: 100%;
  height: 100px;
}

.thesmall .card-body div.text-value-lg {
  font-size: 1.1rem;
  word-wrap: normal;
}

.thesmall .card-body div.text-value-lg + div {
  word-wrap: normal;
}

.thesmall .card-body .text-value-lg,
.thesmall .card-body .text-muted {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.widget-subscription {
  position: relative;
}

.widget-subscription #icon-info {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 2;
  cursor: pointer;
}

div.Expired {
  background-color: #e55353;
  color: white;
}

div.Expired .text-muted {
  color: white !important;
}

.WidgetCname {
  margin-bottom: 5px;
  font-size: 0.9em;
}

.BalanceDanger {
  color: #e55353;
}
</style>
