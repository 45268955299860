<template>
  <div>
    <a-form :form="form"> 
      <a-form-item v-for="(condition, index) in conditions"
                  :key="index"
                  :label="$t('dynamic.Condition') + '-' + (index + 1)"
                  :validate-status="conditionErrors && conditionErrors[index] == null ? '' : 'error'"
                  :help="conditionErrors[index]"
                  style="margin-bottom: 1px">
        <a-select v-if="index > 0" v-model="logical_operator[index - 1]" :options="logicalOptions" style="width: 40%; margin-right: 8px"></a-select>
        <a-input v-model="conditions[index]"  style="width: 80%; margin-right: 8px'"/> 
        <a-icon v-if="conditions.length > 2"
                class="dynamic-delete-button"
                type="minus-circle-o"
                :disabled="conditions.length === 2"
                @click="() => removeCondition(index)"/>
      </a-form-item>
      <a-form-item>
        <a-button type="dashed" @click="addCondition">
          <a-icon type="plus" />
            {{ $t('dynamic.AddCondition') }}
        </a-button>
      </a-form-item>
    </a-form>
  </div>
</template>

<style>
.dynamic-delete-button {
  cursor: pointer;
  position: relative;
  top: -2px;
  font-size: 24px;
  color: #999;
  transition: all 0.3s;
}
.dynamic-delete-button:hover {
  color: #777;
}
.dynamic-delete-button[disabled] {
  cursor: not-allowed;
  opacity: 0.5;
}

.dynamic-table {
  margin-bottom: 10px;
}
.dynamic-table th, .dynamic-table td {
  border: 1px solid gainsboro;
  padding: 2px;
}
</style>

<script>
export default {
  name: 'DynamicRule',
  data() {
    return {
      form: null,
      logical_operator: ['AND'],
      conditions: [''],
      conditionErrors: [],
      argumentOptions: ['METHOD', 'PATH', 'AGENT', 'REFERER'],
      operatorOptions: ['==', '~=', '~~', '~*'],
      logicalOptions: [
        {label: 'AND', value: 'AND'}, 
        {label: 'OR', value: 'OR'},
        {label: '!OR', value: '!OR'}, 
        {label: '!AND', value: '!AND'}
      ],      
    }
  },
  props: ['value', 'dynamicValidateStatus'],
  computed: {
    tabPaneActive() {                  
      return this.$parent.$parent.$attrs.id == "blackRuleModal";      
    } 
  },  
  watch: {
    conditions() {    
      let conditions = [...this.conditions];
      conditions = this.parseCondition(conditions); 
      if (this.conditionErrors.every(item => item !== null)) {
        return
      } 
      conditions.unshift(this.logical_operator);
      this.$emit('input', conditions);
    },
    logical_operator() {
      let conditions = [...this.conditions];
      conditions = this.parseCondition(conditions);
      if (this.conditionErrors.every(item => item !== null)) {
        return
      }
      conditions.unshift(this.logical_operator);
      this.$emit('input', conditions);
    },
    tabPaneActive() {           
      if (this.tabPaneActive) {                
        const value = [...this.value];
        this.logical_operator = value.shift();
        this.conditions = value.map((item) => item.join(' ')); 
        this.form.getFieldDecorator('keys', { initialValue: [...this.conditions.keys()], preserve: true });
      }
    },    
  },
  created() {
    this.form = this.$form.createForm(this, { name: 'dynamic_form_item' });      
    if (this.tabPaneActive) {
      const value = [...this.value];
      this.logical_operator = value.shift();
      this.conditions = value.map((item) => item.join(' ')); 
      this.form.getFieldDecorator('keys', { initialValue: [...this.conditions.keys()], preserve: true });
    }
  },
  methods: {    
    parseCondition(conditions) {
      conditions = conditions.map((item, index) => {
        if (item === undefined) {
          return item;
        };
        const result = item.split(' ');
        if (result.length < 3) {
          this.conditionErrors[index] = this.$t('ConditionNotValid', {condition: item});
          return result;
        }
        if (!this.argumentOptions.includes(result[0])) {
          this.conditionErrors[index] = this.$t('ArgumentNotValid', {arg: result[0]});
          return result;
        }
        const operator = result[1]
        if (operator.length > 2) {
          if (operator[0] !== '!') {
            this.conditionErrors[index] = this.$t('OperatorNotValid', {operator: operator});
            return result;
          }
          if (!this.operatorOptions.includes(operator.slice(1, operator.length))) {
            this.conditionErrors[index] = this.$t('OperatorNotValid', {operator: operator});
            return result; 
          }
        } else if (operator.length == 2) {
          if (!this.operatorOptions.includes(operator)) {
            this.conditionErrors[index] = this.$t('OperatorNotValid', {operator: operator});
            return result; 
          }
        } else {
          if (!this.operatorOptions.includes(operator)) {
            this.conditionErrors[index] = this.$t('OperatorNotValid', {operator: operator});
            return result;
          }
        }
        this.conditionErrors[index] = null;
        return result; 
      });
      return conditions;
    },
    removeCondition(k) {
      // can use data-binding to get
      const keys = this.form.getFieldValue('keys');
      // We need at least one passenger
      if (keys.length === 1) {
        return;
      }

      this.conditions.splice(k, 1);
      this.logical_operator.splice(k - 1, 1)

      // can use data-binding to set
      this.form.setFieldsValue({
        keys: [...this.conditions.keys()],
      });
    },
    addCondition() {
      if (this.conditions.length >= 4) {
        return;
      }
      this.conditions.push('');
      this.logical_operator = [...this.logical_operator, 'AND'];
      // can use data-binding to set
      // important! notify form to detect changes
      this.form.setFieldsValue({
        keys: [...this.conditions.keys()],
      });
    }
  }
}
</script>
