<template>
  <a-modal :visible="editShow" :centered="true"
           :title="modalTitle"
           @cancel="handleCloseModal"
           :closable="false"
           :width="modalWidth">

    <a-tabs :active-key="activeTab" @change="handleChangedTab" :animated="false">
      <a-tab-pane key="basic" :tab="$t('rule.ApplyRule')">
        <RuleList
            ref="ruleList"
            :domain="this.sourceRoute"
            :source-route="this.sourceRoute"
        />
      </a-tab-pane>
    </a-tabs>

    <template slot="footer">
      <footer class="d-flex justify-content-between">
        <a-button @click="handleCloseModal" class="ml-auto">{{ $t('Close') }}</a-button>
      </footer>
    </template>
  </a-modal>
</template>


<script>
import {isEqual} from 'lodash';
import DomainValidationMixin from '@/utilities/DomainValidationMixin';
// import GuardConfiguration from '@/views/domain/GuardConfiguration';
import {MODAL_STATES, SCREEN_SIZES, SCREEN_TYPES } from '@/utilities/constants';
import RuleList from './RuleList';
import {EventBus, Events} from "@/plugins/event-bus.js";

// import ShadowDomainConfiguration from '@/views/domain/EditDomain/ShadowDomainConfiguration';
// import MetadataManagement from '@/views/domain/EditDomain/MetadataManagement';
// import AdvancedConfiguration from '@/views/domain/EditDomain/AdvancedConfiguration';

const DEFAULT_ACTIVE_TAB = 'basic';

export default {
  name: "ApplyRule",
  mixins: [DomainValidationMixin],
  components: {
    // AdvancedConfiguration,
    // MetadataManagement,
    // ShadowDomainConfiguration,
    RuleList,
    // ProxyConfiguration,
    // GuardConfiguration,
  },
  props: {
    sourceRoute: Object,
    editShow: Boolean,
    is_root: Boolean,
    isPaused: Boolean
  },
  computed: {
    domain: function () {
      if (!this.domainFormData.hosts.length)
        return '';

      return this.domainFormData.hosts[0].toLowerCase();
    },
    shadows: function () {
      if (!this.domainFormData.hosts.length)
        return []

      const [_, ...tail] = this.domainFormData.hosts;

      return tail;
    },
    modalTitle() {
      return this.$t('rule.ApplyRuleTo') + " - " + this.sourceRoute.getMainHost();
    },
    modalWidth() {
      if( this.screenSize === SCREEN_TYPES.XS ){
          return '100%';
      }

      if( this.screenSize === SCREEN_TYPES.SM){
        return '75%';
      }

      if( this.screenSize === SCREEN_TYPES.MD ){
        return '65%';
      }

      return "50%";
    },
    inLoadingState: function () {
      return (this.modalState === MODAL_STATES.SAVING) || this.loadingRemove;
    },
    isDomainFormDataDirty: function () {
      return !isEqual(this.domainFormData, this.frozenDomainFormData);
    },
    isSaveButtonDisabled: function () {
      const notesChanged = this.description !== this.sourceRoute.getDescription();

      if (notesChanged || this.isDomainFormDataDirty)
        return false;

      return true;
    },
  },
  watch: {
    editShow() {
      if (this.editShow) {
        this.refresh();        
      }
    },
  },
  mounted() {
    window.addEventListener('resize', this.handleScreenResize);
    this.listenEvent();
  },
  data() {
    const domainFormData = {
      hosts: [''],
      protocols: ['http', 'https'], // this is the default in Kong
      upstream: {
        targets: []
      },
      service: {
        protocol: '',
        tags: []
      },
      plugins: [],
    };

    return {
      description: '',
      domainFormData: domainFormData,
      frozenDomainFormData: domainFormData,
      frozenPlugins: [],
      field_errors: null,
      activeTab: DEFAULT_ACTIVE_TAB,
      guardConfigPendingValues: {},
      pendingValues: {},
      formValidStates: {},
      mutatedShow: false,
      loadingRemove: false,
      modalState: MODAL_STATES.IDLE,
      screenSize: SCREEN_TYPES.LG
    };
  },
  methods: {
    refresh() {      
      this.domainFormData.hosts = this.sourceRoute.getHosts();
      this.domainFormData.protocols = this.sourceRoute.getProtocols();
      this.domainFormData.upstream.targets = this.sourceRoute.getUpstreamTargets();
      this.domainFormData.service.protocol = this.sourceRoute.getServiceProtocol();
      this.domainFormData.service.tags = this.sourceRoute.getServiceTags();
      this.description = this.sourceRoute.getDescription();
      this.frozenDomainFormData = JSON.parse(JSON.stringify(this.domainFormData)); 
      
      if (this.domainFormData.service.tags.includes('paused="1"')) {        
        this.isPaused = true;
      }
      
      // reset to first tab
      this.activeTab = DEFAULT_ACTIVE_TAB;
      this.modalState = MODAL_STATES.IDLE;
      this.field_errors = null;

      this.preparePlugins();
      
    },
    listenEvent() {
      // Send the event on a channel (i-got-clicked) with a payload (the click count.)
      EventBus.$on(Events.HANDLE_CLOSE_MODEL_APPLY_RULE, () => {
        this.handleCloseModal();
      });
    },
    async preparePlugins() {
      this.domainFormData.plugins = this.sourceRoute.getPlugins();
      this.frozenPlugins = JSON.parse(JSON.stringify(this.domainFormData.plugins));
    },
    handleScreenResize(){
      const width = document.documentElement.clientWidth;

      if( width <= SCREEN_SIZES.XS ){
        this.screenSize = SCREEN_TYPES.XS;
        return;
      }

      if( width <= SCREEN_SIZES.SM ){
        this.screenSize = SCREEN_TYPES.SM;
        return;
      }

      if( width <= SCREEN_SIZES.MD ){
        this.screenSize = SCREEN_TYPES.MD;
        return;
      }

      this.screenSize = SCREEN_TYPES.LG
    },
    clearFieldError(field) {
      if (!this.field_errors)
        return;

      if (!this.field_errors[field])
        return;

      const f = {...this.field_errors};
      delete f[field];

      this.field_errors = f;
    },
    handleCloseModal() {
      this.$emit('hide');
    },

    handleChangedTab(activeTab) {
      this.activeTab = activeTab;
    },

  }
};
</script>
