<template>
  <a-modal :visible="show"
           :title="$t('ConfigureSubscription')"
           :closable="false">

    <div v-if="isLoading">
      <a-skeleton/>
    </div>
    <div v-else>
      <a-alert
          v-if="isFreeCname"
          :message="$t('message.ConfigureSubscriptionVIPOnlyDescription')"
          type="info"
          :show-icon="true"
          class="mb-5"
      />
      <a-tabs type="card" @change="tabCallback">
        <a-tab-pane key="1" :tab="memberInfo.level + ' - ' + memberInfo.cname">
          <CRow class="text-center">
            <CCol>
              <div class="text-value-lg">
                {{ memberInfo.level }}
              </div>
              <div class="text-uppercase text-muted small">
                {{ $t('widget.MembershipLevel') }}
              </div>
            </CCol>
            <div class="c-vr">
            </div>
            <CCol
              :class="{Expired: isNotifyTenDaysExpire}"
              v-if="isNotifyTenDaysExpire"
              v-c-tooltip="{
                active: true,
                content: $t('widget.WarningExpired')
              }"
            >
              <div class="text-value-lg">
                {{ memberInfo.expiredAt }}
              </div>
              <div class="text-uppercase text-muted small">
                {{ $t('widget.ExpireAt') }}
              </div>
            </CCol>
            <CCol v-else>
              <div class="text-value-lg">
                {{ memberInfo.expiredAt }}
              </div>
              <div class="text-uppercase text-muted small">
                {{ $t('widget.ExpireAt') }}
              </div>
            </CCol>
            
              <a-divider />
          </CRow>
          <CRow class="text-center">
            <CCol>
              <div class="text-value-lg">
                $ <template v-if="isFreeCname">0</template><template v-else>{{ memberInfo.amount_price }}</template>
              </div>
              <div class="text-uppercase text-muted small">
                {{ $t('widget.AmountPrice') }}
              </div>
            </CCol>
            <div class="c-vr">
            </div>
            <CCol>
              <div class="text-value-lg">
                {{ memberInfo.additional_domain_number }}
              </div>
              <div class="text-uppercase text-muted small">
                {{ $t('widget.AdditionalDomainNumber') }}
              </div>
            </CCol> 
            <a-divider />
          </CRow>
          <CRow class="text-center">
            <CCol>
              <div class="text-value-lg">
                {{ memberInfo.additional_traffic }}
              </div>
              <div class="text-uppercase text-muted small">
                {{ $t('widget.AdditionalTraffic') }}
              </div>
            </CCol>
          </CRow>
        </a-tab-pane>
        <a-tab-pane key="2" :tab="$t('UpgradeDomains')" v-if="!isFreeCname">
          <ul role="list-items" class="list-group">
            <li class="list-group-item d-flex align-items-center">
              {{ $t('Accelerateddomainname') }} <span class="badge badge-info badge-pill ml-1"> {{ $t('Effectivemonthly') }} </span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <button data-shoppy-product="13rtGMV" class="btn badge badge-success badge-pill"> 1 / ${{ domain_price }}</button>&nbsp;&nbsp;
            </li>
            <li class="list-group-item d-flex align-items-center">
              {{ $t('HighspeedandhighflowpreventionMinimum') }} <span class="badge badge-info badge-pill ml-1"> {{ $t('Effectivemonthly') }} </span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <button data-shoppy-product="4tQ2kdN" class="btn badge badge-success badge-pill"> 100GB / ${{ traffic_price * 100 }}</button>&nbsp;&nbsp;
            </li>
          </ul>
          <CRow class="text-left pt-3 px-3">
            <CCol sm="6">
              <CInput :label="$t('AdditionalDomainNumber')"
                      type="number"
                      :placeholder="$t('EnterDomainNumber')"
                      v-model="additionalDomainNumber"
                      />
              <CInput :label="$t('AdditionalTraffic')"
                      type="number"
                      :placeholder="$t('EnterTrafficNumber')"
                      v-model="additionalTrafficNumber"
                      />
            </CCol>
            <CCol sm="6"
                  class="text-right price-wrapper">
              <span id="price"><b>{{ $t('Price') }} </b> : $  {{ order.amountPrice }}</span>
            </CCol>
          </CRow>
              <span class="help-block text-danger" v-if="isAdditionalModify && additionalTrafficNumber < 100">{{ $t('message.MinimumAdditionalTraffic') }}</span>
        </a-tab-pane>
        <a-tab-pane key="3" :tab="$t('ConfigureSubscription')">
          <a-card>
            <div class="pl-2 has-icon">
              <a-switch :checked="isIPV6Enabled"
                        :disabled="isFreeCname"
                        @change="handleIPV6Enable"
                        class="mr-2">
                <a-icon slot="checkedChildren" type="check"/>
                <a-icon slot="unCheckedChildren" type="close"/>
              </a-switch>
              {{ $t('domain.EnableIPV6') }}
              <p class="mt-1">{{ $t('message.EnableIPV6Description') }}</p>

            </div>
          </a-card>

          <a-card size="small" class="mt-3">
            <div class="pl-4 pb-3">
              <p class="mt-2 mb-1">
                {{ $t(`domain.CustomizedPorts`) }}
              </p>
              <a-tag v-for="port in customizePorts" :key="port">{{ port }}</a-tag>
              <SimpleEmpty v-if="!customizePorts.length" class="mr-4" />
            </div>
          </a-card>
        </a-tab-pane>
      </a-tabs>
    </div>

    <PaymentCheckout :order="order" @hide="paymentShow=false" :paymentShow.sync="paymentShow" id="paymentcheckout"/>

    <template slot="footer">
      <footer>
        <a-button @click="handleCloseModal">
          {{ currentTab === '1' ? $t('Close') :  $t('Cancel') }}
        </a-button>
        <template v-if="currentTab !== '1'">
          <a-button @click="currentTab === '2' ? clickSubmit() : save()"
                    :loading="isSaving"
                    :disabled="isSaveButtonDisabled && currentTab === '3'"
                    type="primary">
            <a-icon v-if="!isSaving" type="save"/>
            {{ currentTab === '2' ? $t('Submit') : $t('Save') }}
          </a-button>
        </template>
        <template v-else>
          <template v-if="memberInfo.status === 'trialing'">
            <a-button type="primary"
                      @click="pay('paytrial')"
                      class="mb-1">
              {{ $t('Pay') }}
            </a-button>
          </template>
          <template v-else>
            <template v-if="memberInfo.level===$t('widget.FreeMembership')">
              <a-button type="danger" @click="$router.push({name: 'MemberPlans'})">
                <span>{{ $t('widget.JoinMember') }}</span>
              </a-button>
            </template>
            <template v-else>
              <a-button type="danger" @click="pay('renew')"
                        class="mr-1 mb-1">
                {{ $t('widget.Renew') }}
              </a-button>
            </template>
          </template>
        </template>
      </footer>
    </template>
  </a-modal>
</template>

<script>
import {enableIPv6Subscription, getDomainSubscription} from '@/utilities/api';
import {MODAL_STATES} from '@/utilities/constants';
import SimpleEmpty from '@/views/domain/SimpleEmpty';
import Price from '@/utilities/price'
import { listPlan, subscriptionPlan } from '@/utilities/api'
import numeral from 'numeral';
import PaymentCheckout from '../../payment/Checkout'
import { bus } from '@/main';

export default {
  props: ['show'],
  components: {
    SimpleEmpty,
    PaymentCheckout,
  },
  data() {
    return {
      currentTab: '1',
      readOnly: false,
      modalState: MODAL_STATES.IDLE,
      isIPV6Enabled: false,
      dirtyCheck: {},
      customizePorts: [],
      order: {
        amountPrice: 0,
        type: "domain",
        plan: null,
        additional_domain_number: 0, 
        additional_traffic: 0
      },
      domain_price: Price.DOMAIN_PRICE,
      traffic_price: Price.TRAFFIC_PRICE,
      paymentShow: false,
      plans: [],
      showCheckoutModal: false,
      selectedCoin: null,
      additionalDomainNumber: 0,
      additionalTrafficNumber: 0,
      isAdditionalModify: false,
      selectedPaymentType: null,
      checkout: {
        cname: null,
        additional_domain_number: 0,
        additional_traffic: 0,
        amount_currency1: 0,
        plan: {
          name: null
        }
      }
    }
  },
  mounted() {
    listPlan().then(response => {
      this.plans = response.data
    }).catch((error) => {
      console.log(error)
    })
  },
  computed: {
    isFreeCname: function () {
      const subscriptions = this.$store.state.subscriptions;
      for (let s of subscriptions) {
        if (s.plan.name === "Free")
          return true;
      }

      return false;
    },
    isLoading: function () {
      return this.modalState === MODAL_STATES.LOADING;
    },
    isSaving: function () {
      return this.modalState === MODAL_STATES.SAVING;
    },
    isSaveButtonDisabled: function () {
      return this.isFreeCname || this.readOnly || !this.isIPV6SwitchDirty || this.isLoading;
    },
    isIPV6SwitchDirty: function () {
      return this.isIPV6Enabled !== this.dirtyCheck.isIPV6Enabled;
    },
    selectedSubscription: function() {
      return this.$store.state.selectedSubscription;
    },
    isNotifyTenDaysExpire() {
      const notifyExpireDays = 10;
      const expireDays = this.$moment(this.memberInfo.expiredAt).subtract(notifyExpireDays, 'days');
      const today = this.$moment();
      if (this.memberInfo.expiredAt === '-'){
        return false;
      } else if ( expireDays <= today) {
        return true;
      }
      return false;
    },
    memberInfo: function() {
      let info = { level: this.$t('widget.FreeMembership'), expiredAt: "-", cname: "-", additional_domain_number: "-", additional_traffic: '-', amount_price: 0 };
      if (this.selectedSubscription) {
        if (this.selectedSubscription.plan) {
          info["level"] = this.selectedSubscription.plan.name;
          info["amount_price"] = this.selectedSubscription.amount_price;
          if (this.selectedSubscription.plan.price.quantity > 0) {
            info["expiredAt"] = this.selectedSubscription.next_bill_date;
          }
        } else {
          info["level"] = this.$t('widget.FreeMembership')
        }

        info["status"] = this.selectedSubscription.status;
        info["cname"] = this.selectedSubscription.cname;
        if ('DOMAIN' in this.selectedSubscription.add_meta) {
          info["additional_domain_number"] = this.selectedSubscription.add_meta.DOMAIN;
        }
        if ('TRAFFIC' in this.selectedSubscription.add_meta) {
          info["additional_traffic"] = this.selectedSubscription.add_meta.TRAFFIC;
        }
      }
      
      return info;
    },
  },
  methods: {
    async refresh() {
      this.modalState = MODAL_STATES.LOADING;
      const response = await getDomainSubscription();
      this.modalState = MODAL_STATES.IDLE;

      this.isIPV6Enabled = response.data.ipv6;
      this.dirtyCheck = {isIPV6Enabled: this.isIPV6Enabled};
      this.readOnly = false;
      this.customizePorts = response.data.customize_ports;
    },
    async save() {
      this.modalState = MODAL_STATES.SAVING;
      try {
        await enableIPv6Subscription(this.isIPV6Enabled);
      } catch (e) {
        this.notifyError(this.$t('action.fail'));
        return;
      } finally {
        this.modalState = MODAL_STATES.IDLE;
      }

      this.notifySuccess(this.$t('message.SubscriptionUpdatedSuccessfully'));
      this.handleCloseModal();
    },
    handleIPV6Enable() {
      if (this.readOnly)
        this.isIPV6Enabled = true;
      else
        this.isIPV6Enabled = !this.isIPV6Enabled;
    },
    handleCloseModal() {
      this.currentTab = '1';
      this.$emit('hide');
    },
    tabCallback(key) {
      this.currentTab = key;
    },
    clickSubmit() {
      this.order.plan = ""
      this.order.type = "domain"
      if (this.order.additional_traffic === 0) {
        this.order.additional_traffic = '0GB';
      }
      if (this.order.amountPrice > 0) {
          this.paymentShow = true;
      } else {
        this.flash(this.$i18n.t('WarningNoUpgradeable'), 'warning')
      }
    },
    pay(payType) {
      this.order.type = payType
      this.order.amountPrice = this.selectedSubscription.amount_price
      this.order.subscription_id = this.selectedSubscription.id
      this.paymentShow = true
    },
  },
  watch: {
    show: function (show) {
      if (show && !this.isFreeCname) {
        this.refresh();
      }
    },
    additionalDomainNumber() {
        this.order.amountPrice = 0;
        this.order.additional_domain_number = this.additionalDomainNumber;
        this.order.amountPrice += (this.additionalDomainNumber * Price.DOMAIN_PRICE);
        if (this.additionalTrafficNumber) {
            this.order.additional_traffic = this.additionalTrafficNumber + 'GB';
            const add_traffic = numeral(this.order.additional_traffic);
            const constanta = numeral('1GB');
            this.order.amountPrice += (add_traffic.value() / constanta.value()) * Price.TRAFFIC_PRICE;
        }
        this.order.amountPrice = this.order.amountPrice.toFixed(2);
    },
    additionalTrafficNumber() {
        this.order.amountPrice = 0;
        if (this.additionalTrafficNumber != 0 && this.additionalTrafficNumber < 100) {
          this.isAdditionalModify = true;
          return;
        }
        this.isAdditionalModify = false;
        if (this.additionalTrafficNumber > 0) {
          this.order.additional_traffic = this.additionalTrafficNumber + 'GB';
        } else {
          this.order.additional_traffic = '0GB';
        }
        const add_traffic = numeral(this.order.additional_traffic);
        const constanta = numeral('1GB');
        this.order.amountPrice += (add_traffic.value() / constanta.value()) * Price.TRAFFIC_PRICE;
        if (this.additionalDomainNumber) {
          this.order.additional_domain_number = this.additionalDomainNumber;
          this.order.amountPrice += (this.additionalDomainNumber * Price.DOMAIN_PRICE);
        }
        this.order.amountPrice = this.order.amountPrice.toFixed(2);
    }
  },
  created() {
    bus.$on('updateSelectedSubscription', () => {
        return subscriptionPlan().then(response => {
            if (response.data.length) {
                this.$store.state.selectedSubscription = response.data[0];
            }
        })
         
    });
  },
}
</script>

<style scoped>
/*--------------------------------------------------------------
# Pricing
--------------------------------------------------------------*/
.pricing .row {
  padding-top: 40px;
}

.pricing .box {
  padding: 20px;
  margin-bottom: 30px;
  box-shadow: 0px 0px 30px rgba(73, 78, 92, 0.15);
  background: #fff;
  text-align: center;
}

.pricing h3 {
  font-weight: 300;
  margin-bottom: 15px;
  font-size: 28px;
}

.pricing h4 {
  font-size: 46px;
  color: #5a5af3;
  font-weight: 400;
  font-family: "Open Sans", sans-serif;
  margin-bottom: 25px;
}

.pricing h4 span {
  color: #bababa;
  font-size: 18px;
  display: block;
}

.pricing ul {
  padding: 0;
  list-style: none;
  color: #999;
  text-align: left;
  line-height: 20px;
}

.pricing ul li {
  padding-bottom: 12px;
}

.pricing ul i {
  color: #5a5af3;
  font-size: 18px;
  padding-right: 4px;
}

.pricing ul .na {
  color: #ccc;
}

.pricing ul .na i {
  color: #ccc;
}

.pricing ul .na span {
  text-decoration: line-through;
}

.pricing .btn-buy {
  background: #434175;
  display: inline-block;
  padding: 10px 35px;
  border-radius: 50px;
  color: #fff;
  transition: none;
  font-size: 14px;
  font-weight: 400;
  font-family: "Raleway", sans-serif;
  transition: 0.3s;
  border: none;
      margin-top: 30px;
}

.pricing .btn-trial {
  background-color: #2982cc;
}

.pricing .btn-buy:hover {
  background: #5a5af3;
}

.pricing .featured {
  z-index: 10;
  margin: -30px -5px 0 -5px;
}

.pricing .featured .btn-buy {
  background: #5a5af3;
}

.pricing .featured .btn-buy:hover {
  background: #7272f5;
}

@media (max-width: 992px) {
  .pricing .box {
    max-width: 60%;
    margin: 0 auto 30px auto;
  }
}

@media (max-width: 767px) {
  .pricing .box {
    max-width: 80%;
    margin: 0 auto 30px auto;
  }
}

@media (max-width: 420px) {
  .pricing .box {
    max-width: 100%;
    margin: 0 auto 30px auto;
  }
}

/*--------------------------------------------------------------
# Sections General
--------------------------------------------------------------*/
section {
  padding: 60px 0;
}

.section-bg {
  background-color: #eeeef5;
}

.section-title {
  text-align: center;
  padding-bottom: 30px;
}

.section-title h2 {
  font-size: 32px;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 20px;
  padding-bottom: 20px;
  position: relative;
  color: #434175;
}

.section-title h2::after {
  content: '';
  position: absolute;
  display: block;
  width: 50px;
  height: 3px;
  background: #5a5af3;
  bottom: 0;
  left: calc(50% - 25px);
}

.section-title p {
  margin-bottom: 0;
}

/*--------------------------------------------------------------
# Contact
--------------------------------------------------------------*/
.contact .info-box {
  color: #444444;
  text-align: center;
  box-shadow: 0 0 30px rgba(214, 215, 216, 0.6);
  padding: 20px 0 30px 0;
  background: #fff;
}

.contact .info-box i {
  font-size: 32px;
  color: #5a5af3;
  border-radius: 50%;
  padding: 8px;
}

.contact .info-box h3 {
  font-size: 20px;
  color: #777777;
  font-weight: 700;
  margin: 10px 0;
}

.contact .info-box p {
  padding: 0;
  line-height: 24px;
  font-size: 14px;
  margin-bottom: 0;
}

.contact .php-email-form {
  background: #fff;
  box-shadow: 0 0 30px rgba(214, 215, 216, 0.6);
  padding: 30px;
}

.contact .php-email-form .validate {
  display: none;
  color: red;
  margin: 0 0 15px 0;
  font-weight: 400;
  font-size: 13px;
}

.contact .php-email-form .error-message {
  display: none;
  color: #fff;
  background: #ed3c0d;
  text-align: center;
  padding: 15px;
  font-weight: 600;
}

.contact .php-email-form .sent-message {
  display: none;
  color: #fff;
  background: #18d26e;
  text-align: center;
  padding: 15px;
  font-weight: 600;
}

.contact .php-email-form .loading {
  display: none;
  background: #fff;
  text-align: center;
  padding: 15px;
}

.contact .php-email-form .loading:before {
  content: "";
  display: inline-block;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin: 0 10px -6px 0;
  border: 3px solid #18d26e;
  border-top-color: #eee;
  -webkit-animation: animate-loading 1s linear infinite;
  animation: animate-loading 1s linear infinite;
}

.contact .php-email-form input, .contact .php-email-form textarea {
  border-radius: 0;
  box-shadow: none;
  font-size: 14px;
  border-radius: 5px;
}

.contact .php-email-form input:focus, .contact .php-email-form textarea:focus {
  border-color: #5a5af3;
}

.contact .php-email-form input {
  padding: 20px 15px;
}

.contact .php-email-form textarea {
  padding: 12px 15px;
}

.contact .php-email-form button[type="submit"] {
  background: #5a5af3;
  border: 0;
  padding: 10px 24px;
  color: #fff;
  transition: 0.4s;
  border-radius: 50px;
}

.contact .php-email-form button[type="submit"]:hover {
  background: #7272f5;
}

@-webkit-keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.mt-10 {
  margin-top: 10px;
}

.btn-buy {
  cursor: pointer;
}

ul#coins {
  padding: 0;
  list-style: none;
}

ul#coins.Disabled li {
  border: unset !important;
  background-color: #eee;
}

ul#coins li {
  position: relative;
  display: inline-block;
  padding: 10px;
  margin: 10px;
  border: 1px solid #eee;
  border-radius: 10px;
  cursor: pointer;
}

ul#coins li.Active {
  border: 1px solid #0064bd;
}

ul#coins li div.box {
  position: absolute;
  top: 0;
  width: 100px;
  height: 100px;
}

ul#coins li img {
  width: 100px;
  height: 100px;
}

ul#coins p {
  margin: 0;
  text-align: center;
}

div.Selected {
  border: 2px solid blue;
}

div.price-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
}

span#price {
  font-size: 1.2em;
  display: block;
}
</style>
