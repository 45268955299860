<template>
  <a-list class="domain-list" 
          item-layout="horizontal" 
          :row-key="getRowKey"
          :loading="fetchingDomains" 
          :data-source="domains" 
          :pagination="paginationConfig">
    <a-list-item slot="renderItem" slot-scope="domain">
      <a-card :body-style="{ padding: 0 }" class="w-100">
        <DomainListItem :domain="domain" :is-free-cname="isFreeCname" />
      </a-card>
    </a-list-item>
  </a-list>
</template>

<script>
import DomainListItem from '@/views/domain/DomainListItem.vue';

export default {
  components: {DomainListItem},
  props: ['fetchingDomains', 'domains', 'isFreeCname', 'currentPage', 'domainPerPage', 'totalDomainsToPaginate'],
  computed: {
    paginationConfig() {
      const me = this;
      
      return {
        disabled: this.fetchingDomains,
        current: this.currentPage,
        pageSize: this.domainPerPage,
        total: this.totalDomainsToPaginate,
        onChange: function(page) {
          me.$emit('pageChanged', page);
        },
      }
    }
  },
  data() {
    return {};
  },
  methods: {
    getRowKey(domain) {
      return domain.getMainHost();
    }
  }
};
</script>

<style>
.domain-list .ant-list-pagination {
  text-align: center;
}
</style>