<template>
  <div>
    <CRow>
      <CCol sm="12">
        <CCard>

          <CCardBody>
            <CDataTable
                :hover="hover"
                :striped="striped"
                :bordered="bordered"
                :fixed="fixed"
                :items="rules"
                :fields="ruleListFields"
                :dark="dark"
                :loading="listLoading"
                :items-per-page="perpage"
                :items-per-page-select='{"label": $t("NumberOfItemsPerPage"), "values": [10,25,50]}'
                @pagination-change="pageSizeChanged"                
                ref="ruleTable"
            >

              <template #name="{item}">
                <td>
                  <span class="has-icon">
                    {{ item.getName() }}
                  </span>
                </td>
              </template>

              <template #actions="{item}">
                <td>
                  <CButtonGroup>
                    <CButton
                        color="primary"
                        variant="outline"
                        square
                        @click="ReplaceRule(item)"
                        class="float-right"
                    >{{ $t('rule.Replace') }}
                    </CButton>
                  </CButtonGroup>

                  <CButtonGroup>
                    <CButton
                        color="primary"
                        variant="outline"
                        square
                        @click="CombineRule(item)"
                        class="float-right"
                    >{{ $t('rule.Combine') }}
                    </CButton>
                  </CButtonGroup>                  
                </td>
              </template>

            </CDataTable>
            
            <CPagination
                :activePage.sync="page"
                :pages="total_pages"
                align="center"
                @update:activePage="refresh"
            />
          </CCardBody>

        </CCard>
      </CCol>
    </CRow>

  </div>
</template>

<script>
import axios from "@/plugins/axios.js";
import {$i18n} from '@/plugins/i18n';
import {getRules} from '@/utilities/api';
import CDataTableCustom from '@/views/domain/CDataTableCustom';
import {COLUMN_KEYS} from '@/mixins/ExportDomainMixin';
import {makeRules, Rule} from '../../../models/RuleFactory';
import {EventBus, Events} from '@/plugins/event-bus';

export default {
  name: 'RuleList',
  components: {
    CDataTableCustom
  },
  mixins: [],
  props: {
    caption: {
      type: String,
      default: 'domain.DomainRules'
    },
    domain: Object,
    sourceRoute: Object,
    group: Object,
    hover: Boolean,
    striped: Boolean,
    bordered: Boolean,
    fixed: Boolean,
    dark: Boolean
  },
  data() {
    return {
      ruleAction: "",
      rules: [],
      searchTerm: "",
      searchResult: [],
      newTargetName: "",
      domainQ: null,
      listLoading: false,
      queryParams: {},
      page: 1,
      perpage: 10,
      total_rows: 0,
      total_pages: 10,
      current_page_offset: null,
      next_page_offset: null,
      previous_page_offset: null,
      field_errors: null,
      columnFilter: {
        external: true, lazy: true
      },            
    }
  },
  mounted() {
    this.refresh();
  },  
  computed: {
    ruleListFields: function () {
      let columns = [
        {
          key: COLUMN_KEYS.NAME,
          label: this.$t("rule.Name"),
          _style: "width:70%",
          sorter: false,
          filter: false,
        },
        {
          key: "actions",
          label: "",
          _style: "width:30%",
          sorter: false,
          filter: false
        },
      ];

      return columns;
    },
    ruleList: function () {
      if (this.searchTerm.length) {
        return this.searchResult;
      }
      return this.rules;      
    },
  },
  methods: {
    pageSizeChanged(pageSize) {
      this.page = 1;
      this.perpage = pageSize;
      this.refresh();
    },    
    ReplaceRule(rule) {
      const answer = window.confirm($i18n('rule.AreYouSureToReplaceRuleToDomain'));
      if (answer) {
        this.ruleAction = "replace";
        this.save(rule);
      }
    },
    CombineRule(rule) {
      const answer = window.confirm($i18n('rule.AreYouSureToCombineRuleToDomain'));
      if (answer) {
        this.ruleAction = "combine";
        this.save(rule);
      }      
    },
    handleCloseModal() {
      this.$emit('hide');
      EventBus.$emit(Events.REPLACE_COMBINE_RULES);
      EventBus.$emit(Events.HANDLE_CLOSE_MODEL_APPLY_RULE);
    },    
    async save(rule) {
      this.field_errors = null;
      try {
        await axios({
          method: "POST",
          url: `domain/rules/${rule.getId()}/assign/`,
          data: {
            "action": this.ruleAction,
            "route_id": this.sourceRoute.getId()
          },
        });
      } catch ({error, errors}) {
        const errorData = error.response.data;
        this.field_errors = errorData.field_errors;

        if (this.field_errors == this.$t('rule.RulePortError')) {
          this.notifyError(this.$t('rule.RulePortErrorMessage'));
          return;
        }else {
          this.notifyError(this.$t('rule.RuleAssignedFailed'));
          return;
        }
      }        

      this.notifySuccess(this.$t('rule.RuleAssignedSuccessfully'));
      this.handleCloseModal();
      // this.$router.go(this.$router.currentRoute);
    },
    refresh() {
      this.fetchRules(this.current_page_offset);
      this.field_errors = null;
    },    
    fetchRules() {
      this.listLoading = true;

      return getRules({
        page: this.page, 
        perpage: this.perpage,
        q: this.domainQ
      })
          .then((response) => {
            const responseData = response.data;
            this.rules = makeRules(responseData.rules);
            this.total_pages = responseData.total_pages;
          })
          .finally(() => {
            this.listLoading = false;
          });
    },
  }
}
</script>

<style scoped>
/* domain action related */
.traffic-actions {
  display: flex;
  border: solid 1px #d8dbdf;
  border-radius: 3px;
}

.traffic-action {
  display: flex;
  padding: 12px 16px 0 16px;
}

.traffic-action:first-child {
  border-right: solid 1px #d8dbdf;
  border-bottom: solid 1px #d8dbdf;
}

.traffic-action > div:first-child {
  padding-top: 1px;
  margin-right: 10px;
}

.traffic-action label {
  font-weight: 500;
  margin-bottom: 0;
}

.traffic-action label + p {
  color: rgba(107, 114, 128, 1);
}
</style>
