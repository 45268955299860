import {MIXED_VALUE, PLUGIN_DEFAULTS, PLUGIN_NAMES} from '@/utilities/constants';

export function getPlugin(plugins, pluginName) {
  const plugin = plugins.find((p) => p.name === pluginName);

  if (plugin && plugin.name === PLUGIN_NAMES.GAIUS_IP_RESTRICTION && plugin.config !== MIXED_VALUE) {
    if (plugin.config.deny_status === null) {
      delete plugin.config.deny_status;
    }
    if (plugin.config.message === null) {
      delete plugin.config.message;
    }
    plugin.config.allow = plugin.config.allow || [];
    plugin.config.deny = plugin.config.deny || [];
    plugin.config.whitelistMode = plugin.config.whitelistMode || 'whitelist';
    plugin.config.blacklistMode = plugin.config.blacklistMode || 'blacklist';
  }

  return plugin;
}

export function isPluginMixedValue(plugins, pluginName) {
  const plugin = getPlugin(plugins, pluginName);
  if (!plugin) {
    return false;
  }

  return plugin.config === MIXED_VALUE;
}

export function setPluginConfigValue(plugins, pluginName, configKey, value) {
  let plugin = getPlugin(plugins, pluginName);
  if (!plugin) {
    plugin = PLUGIN_DEFAULTS[pluginName];
  }

  const config = plugin.config === MIXED_VALUE ? {...PLUGIN_DEFAULTS[pluginName].config} : {...plugin.config};
  config[configKey] = value;

  plugin.config = config;

  return plugin;
}

export function getPluginConfigValue(plugins, pluginName, key) {
  const plugin = getPlugin(plugins, pluginName);
  if (!plugin) {
    return PLUGIN_DEFAULTS[pluginName].config[key];
  }

  if (plugin.config === MIXED_VALUE) {
    return MIXED_VALUE;
  }

  return plugin.config[key];
}

export function isPluginEnabled(plugins, pluginName) {
  const plugin = getPlugin(plugins, pluginName);
  if (!plugin) {
    return false;
  }

  // we only tag a plugin as enabled if it does not have config=MIXED_VALUE
  // we do not tag it as enabled because we want the a-switch to display in the off state.
  // making it to an off state will enable the user to override the plugin config again which
  // will remove the config MIXED_VALUE.
  if (plugin.config === MIXED_VALUE) {
    return false;
  }

  return plugin.enabled;
}