<template>
  <div>
    <div v-if="ipList.length">
      <a-input-search
          v-model="searchTermModel"
          :placeholder="$t('Search')"
          :allow-clear="true"
          @press-enter="handleSearch"
          @search="handleSearch">
        <a-button slot="enterButton" icon="search"/>
      </a-input-search>

      <div class="mt-3 mb-2 d-flex justify-content-end">
        <a-button type="link" @click="handleCancelSelection" v-show="selected.length">
          {{ $t('Cancel') }} {{ selected.length }}
          <span v-if="selected.length === 1">&nbsp;{{ $t('selection') }}</span>
          <span v-else>&nbsp;{{ $t('selections') }}</span>
        </a-button>
        <a-button type="link" @click="handleSelectAll">
          {{ $t('SelectAll') }}
        </a-button>
        <a-button type="link" @click="handleRemoveSelected" :disabled="selected.length === 0">
          {{ $t('RemoveSelected') }}
        </a-button>
      </div>

      <a-list class="domain-list"
              item-layout="horizontal"
              :data-source="displayList"
              :pagination="paginationConfig">
        <a-list-item slot="renderItem" slot-scope="ip">
          <div>
            <a-checkbox :checked="isSelected(ip)" @change="handleSelect($event, ip)"/>
            <span class="ml-4">{{ ip }}</span>
          </div>
          <span slot="actions">
                      <CButtonClose v-on:click="remove(ip)"/>
                    </span>
        </a-list-item>
      </a-list>
    </div>
    <SimpleEmpty v-else>
      <span slot="description">{{ $t('message.NoSpecificIpListed') }}</span>
    </SimpleEmpty>
  </div>
</template>

<script>
import SimpleEmpty from '@/views/domain/SimpleEmpty';

export default {
  components: {SimpleEmpty},
  props: ['domain', 'ipList', 'remove',],
  watch: {
    domain() {
      // when a new domain is selected reset the state
      this.reset();
    },
  },
  computed: {
    displayList() {
      if (this.searchTerm.length) {
        return this.filteredList;
      }

      return this.ipList;
    },
    paginationConfig() {
      return {
        pageSize: 10,
        total: this.displayList.length,
      };
    },
  },
  data() {
    return {
      // This dictates the search result. `searchTerm` will be updated when the user actually 
      // hits the search button or enter key. We are not using this as the v-model of the search 
      // input because if we did the searching would be real-time, but we want to search to only
      // happen on enter/search button click
      searchTerm: '',
      // The state that is used as the v-model in the search input. This is separate
      // because when a new domain is selected, we want this to be 
      // reset to empty (see reset method and watch of `domain`).
      searchTermModel: '',
      filteredList: [],
      selected: [],
    };
  },
  methods: {
    handleSearch() {
      this.searchTerm = this.searchTermModel;
      // if we are going to do a search we want the selection to be cleared because
      // the search result may not contain the selected items creating confusion
      // to the user that there are X number of items selected yet the search
      // result may be 0.
      this.selected = [];
      this.filteredList = this.doSearch(this.searchTerm);
    },
    doSearch(searchTerm) {
      const match = [];
      for (const i of this.ipList) {
        if (i.includes(searchTerm)) {
          match.push(i);
        }
      }

      return match;
    },
    reset() {
      this.searchTerm = '';
      this.searchTermModel = '';
      this.filteredList = [];
      this.selected = [];
    },
    handleSelect(e, ip) {
      if (e.target.checked) {
        this.selected = [...this.selected, ip];
      } else {
        this.selected = this.selected.filter((i) => {
          return i !== ip;
        });
      }
    },
    isSelected(ip) {
      return this.selected.includes(ip);
    },
    handleSelectAll() {
      this.selected = [...this.displayList];
    },
    handleCancelSelection() {
      this.selected = [];
    },
    handleRemoveSelected() {
      this.remove(this.selected);
      this.selected = [];
    },
  },
}
</script>