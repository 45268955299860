<template>
  <a-modal :visible="editShow" :centered="true"
           :title="modalTitle"
           @ok="save"
           @cancel="handleCloseModal"
           :closable="false"
           :width="modalWidth">

    <a-tabs :active-key="activeTab" @change="handleChangedTab" :animated="false">
      <a-tab-pane key="basic" :tab="$t('domain.BasicConfiguration')">
        <BasicConfiguration
            ref="basicConfiguration"
            :domain-edit-disabled="!sourceRoute.isNew()"
            :batch-edit="false"
            :domain="domain"
            v-on:update:domain="handleDomainUpdate"
            :upstream-targets="domainFormData.upstream.targets"
            v-on:update:upstream-targets="handleTargetsUpdate"
            :service-protocol="domainFormData.service.protocol"
            v-on:update:service-protocol="handleServiceProtocolUpdate"
            :domain-action="domainAction"
            v-on:toggle-redirect-domain-action="handleRedirectDomainAction"
            :redirect-url="redirectUrl"
            v-on:update:redirect-url="handleRedirectUrlUpdate"
            :preserve-path="preservePath"
            v-on:update:preserve-path="handlePreservePathUrlUpdate" 
            :field-errors="field_errors"
            :tags.sync="domainFormData.service.tags"
            />
      </a-tab-pane>

      <a-tab-pane key="shadow" :tab="$t('domain.ShadowDomain')">
        <ShadowDomainConfiguration
            ref="shadowDomainConfiguration"
            :shadows="shadows"
            :source-domain="sourceRoute"
            :mainDomain="domain"
            :field-errors="field_errors"
            :is-domain-saved="Boolean(sourceRoute.getId())"
            v-on:update:shadows="handleShadowsUpdate"
            v-on:promote:shadow="handlePromoteShadow"/>
      </a-tab-pane>

      <a-tab-pane key="advanced-configuration" :tab="$t('domain.AdvancedConfiguration')">
        <AdvancedConfiguration :domain="sourceRoute"
                               :plugins.sync="domainFormData.plugins"
                               :plugin-errors="pluginErrors"
                               :service-path="domainFormData.service.path"
                               v-on:update:service-path="handleServicePathUpdate"
                               :screenSize="screenSize"
                               :isBypassACME="isBypassACME"
                               v-on:update:is-bypass-acme="handleIsBypassACMEUpdate"/>
      </a-tab-pane>

      <a-tab-pane key="security" :tab="$t('domain.GuardConfiguration')">
        <GuardConfiguration
            :domain="sourceRoute"
            :plugins.sync="domainFormData.plugins"
            :plugin-errors.sync="pluginErrors"
            :tags.sync="domainFormData.service.tags"
            :screenSize="screenSize"
            ref="guardConfiguration"></GuardConfiguration>
      </a-tab-pane>

      <a-tab-pane key="metadata" :tab="$t('metadata')">
        <MetadataManagement :description.sync="description"/>
      </a-tab-pane>
    </a-tabs>

    <template slot="footer">
      <footer class="d-flex justify-content-between">
        <a-button-group>
          <a-popconfirm
              :title="$t('message.ConfirmDomainDelete')"
              :ok-text="$t('Yes')"
              :cancel-text="$t('No')"
              @confirm="remove"
          >
            <a-button
                id="btn_delete"
                color="danger"
                type="submit"
                v-if="!sourceRoute.isNew()"
                :disabled="inLoadingState"
                :loading="loadingRemove"
            >
              <a-icon v-if="!inLoadingState" type="delete"/>
              {{ $t('Delete') }}
            </a-button>
          </a-popconfirm>

          <a-button
                id="btn_pause"
                type="submit"
                class="ml-2"
                v-if="!sourceRoute.isNew()"
                @click="handlePauseModal"
            >
              <a-icon :type="`${isPaused ? 'play' : 'pause'}`" />              
              {{ isPaused ? $t('Restore') : $t('Pause') }}
            </a-button>
        </a-button-group>

        <div>
          <a-button @click="handleCloseModal" color="danger" :disabled="inLoadingState">{{ $t('Cancel') }}</a-button>
          <a-button @click="save"
                    :loading="inLoadingState"
                    type="primary">
            <a-icon v-if="!inLoadingState" type="save"/>
            {{ $t('Save') }}
          </a-button>
        </div>
      </footer>
    </template>
  </a-modal>
</template>


<script>
import { bus } from '@/main';
import isValidDomain from 'is-valid-domain';
import { cloneDeep, isEqual } from 'lodash';
import DomainValidationMixin from '@/utilities/DomainValidationMixin';
import GuardConfiguration from '@/views/domain/GuardConfiguration';
import {DOMAIN_TRAFFIC_ACTIONS, MODAL_STATES, PLUGIN_NAMES, SCREEN_SIZES, SCREEN_TYPES, DEFAULT_CACHE_POST_FIXES } from '@/utilities/constants';
import {deleteDomain} from '@/utilities/api';
import BasicConfiguration from './BasicConfiguration';
import axios from '@/plugins/axios';
import ShadowDomainConfiguration from '@/views/domain/EditDomain/ShadowDomainConfiguration';
import MetadataManagement from '@/views/domain/EditDomain/MetadataManagement';
import validUrl from 'valid-url';
import AdvancedConfiguration from '@/views/domain/EditDomain/AdvancedConfiguration';
import {EventBus, Events} from '@/plugins/event-bus';

const DEFAULT_ACTIVE_TAB = 'basic';
const ADVANCED_CONFIGURATION_TAB = 'advanced-configuration';
const SECURITY_SETTING_TAB = 'security';
const SHADOW_CACHE_KEY = 'shadow';

export default {
  name: "EditDomain",
  mixins: [DomainValidationMixin],
  components: {
    AdvancedConfiguration,
    MetadataManagement,
    ShadowDomainConfiguration,
    BasicConfiguration,
    GuardConfiguration,
  },
  props: {
    sourceRoute: Object,
    editShow: Boolean,
    is_root: Boolean,
  },
  computed: {
    isPaused() {
      return this.domainFormData.service.tags.includes('paused="1"');
    },
    pluginErrors: {
      get() {
        if (!this.field_errors) {
          return [];
        }
        return [this.field_errors['plugins']];
      },
      set(value) {
        if (this.field_errors === null) {
          this.field_errors = {};
        }
        this.field_errors['plugins'] = value;
      },
    },
    domain: function () {
      if (!this.domainFormData.hosts.length)
        return '';

      return this.domainFormData.hosts[0] ? this.domainFormData.hosts[0].toLowerCase() : '';
    },
    shadows: function () {
      if (!this.domainFormData.hosts.length)
        return []

      const [_, ...tail] = this.domainFormData.hosts;

      return tail;
    },
    domainAction: function () {
      for (let p of this.domainFormData.plugins) {
        if (p.name === PLUGIN_NAMES.GAIUS_REDIRECT) {
          return DOMAIN_TRAFFIC_ACTIONS.REDIRECT;
        }
      }

      return DOMAIN_TRAFFIC_ACTIONS.UPSTREAM;
    },
    redirectUrl: function () {
      const plugins = this.domainFormData.plugins;

      for (let p of plugins) {
        if (p.name === PLUGIN_NAMES.GAIUS_REDIRECT) {
          return p.config.redirect_url;
        }
      }

      return '';
    },
    isBypassACME: function() { 
      const plugins = this.domainFormData.plugins;

      for (let p of plugins) {
        if (p.name === PLUGIN_NAMES.GAIUS_CONFIG) {
          return p.config.is_bypass_acme
        }
      }
      return false;
    },
    preservePath: function () {
      const plugins = this.domainFormData.plugins;

      for (let p of plugins) {
        if (p.name === PLUGIN_NAMES.GAIUS_REDIRECT) {
          return p.config.preserve_path;
        }
      }

      return '';
    },
    modalTitle() {
      if (this.sourceRoute.isNew()) {
        return this.$t('domain.DomainNameConfiguration');
      }

      return this.$t('domain.DomainNameConfiguration') + ": " + this.sourceRoute.getMainHost();
    },
    modalWidth() {
      if (this.screenSize === SCREEN_TYPES.XS) {
        return '100%';
      }

      if (this.screenSize === SCREEN_TYPES.SM) {
        return '75%';
      }

      if (this.screenSize === SCREEN_TYPES.MD) {
        return '65%';
      }

      return "50%";
    },
    inLoadingState: function () {
      return (this.modalState === MODAL_STATES.SAVING) || this.loadingRemove;
    },
    isDomainFormDataDirty: function () {
      return !isEqual(this.domainFormData, this.frozenDomainFormData);
    },
    isSaveButtonDisabled: function () {
      const notesChanged = this.description !== this.sourceRoute.getDescription();

      if (notesChanged || this.isDomainFormDataDirty)
        return false;

      return true;
    },
  },
  watch: {
    editShow() {
      if (this.editShow) {
        this.refresh();
      }
      if (window.localStorage) {
        // pre-populate widget data with cache values - let's not wait for the ajax to display info
        localStorage.setItem(SHADOW_CACHE_KEY, 0)
      }
    },
  },
  mounted() {
    window.addEventListener('resize', this.handleScreenResize);

    EventBus.$on(Events.SHOW_EDIT_DOMAIN_DIALOG_SHOW_ADVANCED_CONFIGURATION, this.showAdvancedConfiguration);
    EventBus.$on(Events.SHOW_EDIT_DOMAIN_DIALOG_SHOW_SECURITY_SETTING, this.showSecuritySetting);
  },
  data() {
    const domainFormData = {
      hosts: [''],
      protocols: ['http', 'https'], // this is the default in Kong
      upstream: {
        targets: []
      },
      service: {
        protocol: '',
        tags: [],
        path: '/',
      },
      plugins: [],
      isPaused: false,
    };

    return {
      description: '',
      domainFormData: domainFormData,
      frozenDomainFormData: domainFormData,
      field_errors: null,
      activeTab: DEFAULT_ACTIVE_TAB,
      guardConfigPendingValues: {},
      pendingValues: {},
      formValidStates: {},
      mutatedShow: false,
      loadingRemove: false,
      modalState: MODAL_STATES.IDLE,
      screenSize: SCREEN_TYPES.LG
    };
  },
  methods: {
    showAdvancedConfiguration() {
      // wait some short amount of time for the refresh method to complete. because there
      // we set the active tab to DEFAULT_ACTIVE_TAB so trigger ADVANCED_CONFIGURATION_TAB immediately
      // would immediately be overriden by DEFAULT_ACTIVE_TAB.
      setTimeout(() => {
        this.activeTab = ADVANCED_CONFIGURATION_TAB;
      }, 0);
    },
    showSecuritySetting() {
      // wait some short amount of time for the refresh method to complete. because there
      // we set the active tab to DEFAULT_ACTIVE_TAB so trigger SECURITY_SETTING_TAB immediately
      // would immediately be overriden by DEFAULT_ACTIVE_TAB.
      setTimeout(() => {
        this.activeTab = SECURITY_SETTING_TAB;
      }, 0);
    },
    refresh() {
      this.domainFormData.hosts = this.sourceRoute.getHosts();
      this.domainFormData.protocols = this.sourceRoute.getProtocols();
      this.domainFormData.upstream.targets = this.sourceRoute.getUpstreamTargets();
      this.domainFormData.service.protocol = this.sourceRoute.getServiceProtocol();
      this.domainFormData.service.tags = this.sourceRoute.getServiceTags();
      this.domainFormData.service.path = this.sourceRoute.getServicePath();
      this.description = this.sourceRoute.getDescription();
      this.frozenDomainFormData = JSON.parse(JSON.stringify(this.domainFormData));
      
      // reset to first tab
      this.activeTab = DEFAULT_ACTIVE_TAB;
      this.modalState = MODAL_STATES.IDLE;
      this.field_errors = null;
      
      // cloneDeep so that we can make sure the sourceRoute.getPlugins() dont get mutated when we pass it around
      this.domainFormData.plugins = cloneDeep(this.sourceRoute.getPlugins());
      this.frozenDomainFormData = JSON.parse(JSON.stringify(this.domainFormData));
    },
    handleScreenResize() {
      const width = document.documentElement.clientWidth;

      if (width <= SCREEN_SIZES.XS) {
        this.screenSize = SCREEN_TYPES.XS;
        return;
      }

      if (width <= SCREEN_SIZES.SM) {
        this.screenSize = SCREEN_TYPES.SM;
        return;
      }

      if (width <= SCREEN_SIZES.MD) {
        this.screenSize = SCREEN_TYPES.MD;
        return;
      }

      this.screenSize = SCREEN_TYPES.LG
    },
    clearFieldError(field) {
      if (!this.field_errors)
        return;

      if (!this.field_errors[field])
        return;

      const f = {...this.field_errors};
      delete f[field];

      this.field_errors = f;
    },
    handleCloseModal() {
      bus.$emit('emptyNewTargetName');
      bus.$emit('emptycustomRequestHeader');
      EventBus.$emit('disableToggleBlackRules');
      bus.$emit('emptycustomResponseHeaders');
      this.$emit('hide');
    },
    async handlePauseModal(domain) {
      try {
        await axios({
          method: "POST",
          url: `domain/pause_restore/`,
          data: {
            hosts: this.domainFormData.hosts,
            paused: this.domainFormData.service.tags.includes('paused="1"') ? false : true,
          },
        }).then(response => {
          bus.$emit('updateDomainStat');
        });
      } catch ({error, errors}) {
        const errorData = error.response.data;
        this.field_errors = errorData.field_errors;

        this.notifyError(this.$t('message.DomainSavingFailed'));
        this.modalState = MODAL_STATES.IDLE;
        return;
      }

      this.notifySuccess(this.$t('message.DomainSavedSuccessfully'));
      this.modalState = MODAL_STATES.IDLE;
      this.$emit('refresh');
      this.handleCloseModal();
    },
    handleDomainUpdate(domain) {
      const [_, ...tail] = this.domainFormData.hosts;
      const _domain = (domain || '').trim().toLowerCase();

      this.domainFormData.hosts = [_domain, ...tail];
    },
    handleTargetsUpdate(targets) {
      this.domainFormData.upstream.targets = targets;
      this.clearFieldError('upstream');
    },
    handleShadowsUpdate(shadows) {
      const [head, ..._] = this.domainFormData.hosts;
      this.domainFormData.hosts = [head, ...shadows];
    },
    handlePromoteShadow(shadowDomain) {
      // replace promote shadowDomain with main host
      const shadows = this.shadows.map((s) => {
        if (s === shadowDomain) {
          return this.domain;
        }

        return s;
      });

      this.handleDomainUpdate(shadowDomain);
      this.handleShadowsUpdate(shadows);
    },
    handleServiceProtocolUpdate(serviceProtocol) {
      this.domainFormData.service.protocol = serviceProtocol;
      if (serviceProtocol == 'https') {
        this.domainFormData.protocols = ['https', 'http'];
      } else {
        this.domainFormData.protocols = ['http', 'https'];
      }
      this.clearFieldError('protocol');
    },
    handleServicePathUpdate(path) {
      this.domainFormData.service.path = path;
    },
    removeShadow(shadow) {
      this.domainFormData.shadows = this.domainFormData.shadows.filter((d) => d !== shadow);
    },
    remove() {
      this.loadingRemove = true;

      deleteDomain({routeId: this.sourceRoute.getId(), cname: this.$store.getters.cname})
          .then(() => {
            this.$emit('refresh');
            bus.$emit('updateDomainStat');
            this.$emit('hide');
            this.notifySuccess(this.$t('message.SuccessfullyDeleted'));
          })
          .catch((error) => {
            if (error.response && error.response.data.detail) {
              this.notifyError(this.$t('action.fail_with_msg', {msg: error.response.data.detail}));
            } else {
              this.notifyError(this.$t('action.fail'));
            }
          })
          .finally(() => {
            this.loadingRemove = false;
          });
    },
    async save() {
      const domainParts = this.domain.split('.');
      const domainBeforeDotCom = domainParts[0];

      if (domainBeforeDotCom.length > 63) {
        this.notifyError(this.$t('message.DomainNameValidationError'));
        return;
      }

      if (this.domain === '' || !this.domain_validator(this.domain)) {
        this.notifyError(this.$t('message.DomainValidationError'));
        return;
      }

      if (this.domainAction === DOMAIN_TRAFFIC_ACTIONS.REDIRECT && !validUrl.isWebUri(this.redirectUrl)) {
        this.notifyError(this.$t('message.InputDataVerificationErrorOn', {on: this.$t('domain.RedirectToUrl')}));
        return;
      }

      if (this.domainAction === DOMAIN_TRAFFIC_ACTIONS.UPSTREAM) {
        this.field_errors = {upstream: []};
        if (this.domainFormData.service.protocol === "http") {
          this.domainFormData.upstream.targets.forEach(item => {
            if (item.target.split(':')[1] == 443) {
              this.field_errors['upstream'].push(this.$t('message.UpstreamInvalid', {
                target: item.target,
                port: 443,
                protocol: 'http'
              }));
            }
          });
        } else if (this.domainFormData.service.protocol === "https") {
          this.domainFormData.upstream.targets.forEach(item => {
            if (item.target.split(':')[1] == 80 || item.target.split(':')[1] == 8080) {
              this.field_errors['upstream'].push(this.$t('message.UpstreamInvalid', {
                target: item.target,
                port: item.target.split(':')[1],
                protocol: 'https'
              }));
            }
          });
        }

        if (this.field_errors['upstream'].length) {
          this.modalState = MODAL_STATES.IDLE;
          return;
        }
      }

      if (this.$refs.shadowDomainConfiguration && !this.$refs.shadowDomainConfiguration.addShadow()) {
        this.notifyError(this.$t('message.ShadowDomainInputError'));
        return;
      }

      // There was no change detected so we just bail-out immediately
      if (this.isSaveButtonDisabled) {
        this.handleCloseModal();
        return;
      }

      if (this.pluginErrors) {
        for (const key in this.pluginErrors) {
          const plugin = this.domainFormData.plugins[key];
          if (plugin && !plugin.enabled & this.$refs.guardConfiguration) {
            if (this.domainFormData.plugins[key] && this.$refs.guardConfiguration.mutatedPlugin[key]) {
              this.domainFormData.plugins[key].config = this.$refs.guardConfiguration.mutatedPlugin[key].config;
            }
          }
        }
      }

      if (this.domainFormData.plugins) {

        const gaiusUpstreamHost = this.domainFormData.plugins.find((p) => {
          if (p.name == 'gaius-upstream-host' && p.enabled && p.config.hostname) {
            return p;
          }
        });

        if (gaiusUpstreamHost) {
          if (!isValidDomain(gaiusUpstreamHost.config.hostname)) {
            this.modalState = MODAL_STATES.IDLE;
            return;
          }
        }

        const gaiusAdvanceProxyCache = this.domainFormData.plugins.find((p) => {
          if (p.name == 'gaius-advance-proxy-cache') {
            return p;
          }
        });

        if (gaiusAdvanceProxyCache) {
          if (!gaiusAdvanceProxyCache.config.enable_cache_control && !gaiusAdvanceProxyCache.config.global_custom_caching && !gaiusAdvanceProxyCache.config.global_default_caching && !gaiusAdvanceProxyCache.config.global_disable_cache_completely) {
            this.notifyError(this.$t('message.DomainSavingFailed'));
            this.modalState = MODAL_STATES.IDLE;
            return ;
          }
        }

        const gaiusProxyCache = this.domainFormData.plugins.find((p) => {
          if (p.name == 'gaius-proxy-cache') {
            return p;
          }
        });

        if (gaiusProxyCache) {
          if (gaiusProxyCache.config.cache_postfixes) {
            if (isEqual(gaiusProxyCache.config.cache_postfixes,DEFAULT_CACHE_POST_FIXES)) {
              gaiusProxyCache.config.cache_postfixes = []
            }
          }
        }

        const gaiusblackrule = this.domainFormData.plugins.find((p) => {
          if (p.name == 'gaius-blackrules') {
            return p;
          }
        });
        if (gaiusblackrule) {
          if (gaiusblackrule.enabled && gaiusblackrule.config && gaiusblackrule.config.black_rules.length == 0) {
            this.notifyError(this.$t('message.DomainSavingFailed'));
            this.modalState = MODAL_STATES.IDLE;
            return ;
          }
        }
        const gaiusIpRestrictionPlugin = this.domainFormData.plugins.find((p) => {
          if (p.name == 'gaius-ip-restriction') {
            p.config.whitelistMode = 'whitelist'
            p.config.blacklistMode = 'blacklist'
            return p;
          }
        });
        if (gaiusIpRestrictionPlugin) {
          if (gaiusIpRestrictionPlugin.enabled && gaiusIpRestrictionPlugin.config && gaiusIpRestrictionPlugin.config.allow.length === 0 && gaiusIpRestrictionPlugin.config.deny.length === 0) {
            this.notifyError(this.$t('message.IPRestrictionError'));
            this.modalState = MODAL_STATES.IDLE;
            return;
          }
        }      
      }

      this.field_errors = null;

      this.$refs.basicConfiguration.addTarget(true);

      this.modalState = MODAL_STATES.SAVING;

      try {
        await axios({
          method: this.sourceRoute.isNew() ? "POST" : "PATCH",
          url: this.sourceRoute.isNew()
              ? `domain/routes/`
              : `domain/routes/${this.sourceRoute.getId()}/`,
          data: {
            domain: this.domainFormData,
            meta: {cname: this.$store.getters.cname, description: this.description}
          },
        });
      } catch ({ error, errors }) {
          const errorData = error.response.data;
          this.field_errors = errorData.field_errors;

          let pluginErrorFound = false;

          if (this.field_errors.plugins && this.field_errors.plugins.length > 0) {
            for (const plugin of this.field_errors.plugins) {
              if (plugin.non_field_errors && plugin.non_field_errors.length > 0) {
                this.notifyError(plugin.non_field_errors[0]);
                pluginErrorFound = true;
                break;
              }
            }
          }
          
          if (!pluginErrorFound) {
              this.notifyError(this.$t('message.DomainSavingFailed'));
          }
          
          this.modalState = MODAL_STATES.IDLE;
          return;
        }

      this.notifySuccess(this.$t('message.DomainSavedSuccessfully'));
      this.modalState = MODAL_STATES.IDLE;
      this.$emit('refresh');
      bus.$emit('updateDomainStat');
      this.handleCloseModal();
      if (window.localStorage) {
        // pre-populate widget data with cache values - let's not wait for the ajax to display info
        localStorage.setItem(SHADOW_CACHE_KEY, 0)
      }
    },
    handleChangedTab(activeTab) {
      this.activeTab = activeTab;
    },
    handleGuardConfigPendingValues(pendingValues) {
      Object.assign(this.guardConfigPendingValues, pendingValues);
    },
    handleRedirectDomainAction(enabled) {
      const plugins = this.domainFormData.plugins;

      // already exist. bail out.
      if (enabled && plugins.find((p) => p.name === PLUGIN_NAMES.GAIUS_REDIRECT))
        return;

      if (enabled) {
        this.domainFormData.plugins = [...plugins, {
          name: PLUGIN_NAMES.GAIUS_REDIRECT,
          config: {
            redirect_url: '',
          },
          enabled: true,
        }];
      } else {
        this.domainFormData.plugins = plugins.filter((p) => p.name !== PLUGIN_NAMES.GAIUS_REDIRECT);
      }
    },
    handleRedirectUrlUpdate(redirectUrl) {
      const plugins = this.domainFormData.plugins;

      this.domainFormData.plugins = plugins.map((p) => {
        if (p.name === PLUGIN_NAMES.GAIUS_REDIRECT) {
          const newPlugin = {...p};
          newPlugin.config.redirect_url = redirectUrl;

          return newPlugin;
        }
        return p;
      });
    },
    handlePreservePathUrlUpdate(preservePath) {
      const plugins = this.domainFormData.plugins;

      this.domainFormData.plugins = plugins.map((p) => {
        if (p.name === PLUGIN_NAMES.GAIUS_REDIRECT) {
          const newPlugin = {...p};
          newPlugin.config.preserve_path = preservePath;

          return newPlugin;
        }
        return p;
      });
    },
    handleIsBypassACMEUpdate(is_bypass_acme) {
      const plugins = this.domainFormData.plugins;
      let isConfigExist = false;

      this.domainFormData.plugins = plugins.map((p) => {
        if (p.name === PLUGIN_NAMES.GAIUS_CONFIG) {
          isConfigExist = true;
          const newPlugin = {...p};
          newPlugin.config.is_bypass_acme = is_bypass_acme;

          return newPlugin;
        }
        return p;
      });

      if (!isConfigExist) {
        this.domainFormData.plugins = [...plugins, {
          name: PLUGIN_NAMES.GAIUS_CONFIG,
          config: {
            is_bypass_acme: is_bypass_acme
          },
          enabled: true,
        }];
      }
    },
  }
};
</script>
