<template>
  <div>
    <a-tabs :animated="false" :tab-position="tabPosition" :active-key="activeTab" @change="handleChangedTab">

     <a-tab-pane key="custom-request-header">
        <span slot="tab"
              class="has-icon"
              v-bind:class="{'text-danger': customHeaderRequestErrors }">
          <a-icon v-if="isCustomHeaderRequestEnabled" type="check-circle"/>
          {{ $t('domain.CustomRequestHeader') }}
        </span>

        <header>
          <div class="flex-fill">
            <div class="d-flex align-items-center mb-1 mt-2 has-icon">
              <a-switch :checked="isCustomHeaderRequestEnabled" @change="handleToggleCustomRequestHeader" class="mr-2">
                <a-icon slot="checkedChildren" type="check"/>
                <a-icon slot="unCheckedChildren" v-if="!isCustomHeaderRequestMixed" type="close"/>
              </a-switch>
              <strong>
                {{ $t('domain.CustomRequestHeader') }}
              </strong>
            </div>
            <p>{{ $t('message.inject_custom_header_request') }}</p>
          </div>
        </header>

        <div v-if="(!customRequestHeaders.length || !removeRequestHeaders.length) && customHeaderRequestErrors && isCustomHeaderRequestEnabled">
          <a-alert v-for="error in customHeaderRequestErrors.config.add.non_field_errors"
                   :message="error"
                   :key="error"
                   type="error"
                   :banner="true"/>
        </div>

        <a-alert
            v-if="isCustomHeaderRequestMixed"
            message="Warning"
            :description="$t('message.domain_settings_mixed_selection')"
            type="warning"
            class="mb-2"
            show-icon
        />
        <template v-else-if="isCustomHeaderRequestEnabled">
          <small class="mt-4 mb-1 text-muted">{{ $t('message.AddCustomHeaderList') }}</small>
          <div v-if="customRequestHeaders.length" class="mb-4">
            <CListGroup>
              <CListGroupItem v-for="(header) in customRequestHeaders" v-bind:key="header">
                <div style="display: flex; align-items: center;">
                  <div style="flex: 1">
                    {{ header.toUpperCase() }}
                  </div>
                  <div class="shadow-list-action-btns">
                    <CButtonClose v-on:click="__removeRequestHeader(header)"/>
                  </div>
                </div>
              </CListGroupItem>
            </CListGroup>
          </div>

          <a-form layout="inline">

            <a-form-item>
              <a-input class="customRequestHeaderText" v-model:value="customRequestHeader.key" :placeholder="$t('Key')"></a-input>
            </a-form-item>

            <a-form-item>
              <a-input class="customRequestHeaderValueText" v-model:value="customRequestHeader.value" :placeholder="$t('Value')"></a-input>
            </a-form-item>

            <a-form-item>
              <a-button type="primary"
                        :disabled="customRequestHeader.key === '' || customRequestHeader.value === ''"
                        @click="addRequestHeader">
                {{ $t('Add') }}
              </a-button>
            </a-form-item>

          </a-form>

          <small class="mt-4 mb-1 text-muted">{{ $t('message.RemoveCustomHeaderList') }}</small>
          <div v-if="removeRequestHeaders.length" class="mb-4">
            <CListGroup>
              <CListGroupItem v-for="(header) in removeRequestHeaders" v-bind:key="header">
                <div style="display: flex; align-items: center;">
                  <div style="flex: 1">
                    {{ header.toUpperCase() }}
                  </div>
                  <div class="shadow-list-action-btns">
                    <CButtonClose v-on:click="___removeRequestHeader(header)"/>
                  </div>
                </div>
              </CListGroupItem>
            </CListGroup>
          </div>

          <a-form layout="inline">

            <a-form-item>
              <a-input class="customRequestHeaderText" v-model:value="removeRequestHeader.key" :placeholder="$t('Key')"></a-input>
            </a-form-item>

            <a-form-item>
              <a-button type="primary"
                        :disabled="removeRequestHeader.key === ''"
                        @click="_removeRequestHeader">
                {{ $t('Remove') }}
              </a-button>
            </a-form-item>

          </a-form>
        </template>

        <SimpleEmpty v-else>
          <span slot="description">{{ $t('message.PluginNotActive') }}</span>
        </SimpleEmpty>
      </a-tab-pane>

      <a-tab-pane key="custom-response-header">
        <span slot="tab"
              class="has-icon"
              v-bind:class="{'text-danger': customHeaderErrors }">
          <a-icon v-if="isCustomHeaderEnabled" type="check-circle"/>
          {{ $t('domain.CustomResponseHeader') }}
        </span>

        <header>
          <div class="flex-fill">
            <div class="d-flex align-items-center mb-1 mt-2 has-icon">
              <a-switch :checked="isCustomHeaderEnabled" @change="handleToggleCustomHeader" class="mr-2">
                <a-icon slot="checkedChildren" type="check"/>
                <a-icon slot="unCheckedChildren" v-if="!isCustomHeaderMixed" type="close"/>
              </a-switch>
              <strong>
                {{ $t('domain.CustomResponseHeader') }}
              </strong>
            </div>
            <p>{{ $t('message.inject_custom_header') }}</p>
          </div>
        </header>

        <div v-if="(!customHeaders.length || !removeHeaders.length) && customHeaderErrors && isCustomHeaderEnabled">
          <a-alert v-for="error in customHeaderErrors.config.add.non_field_errors"
                   :message="error"
                   :key="error"
                   type="error"
                   :banner="true"/>
        </div>

        <a-alert
            v-if="isCustomHeaderMixed"
            message="Warning"
            :description="$t('message.domain_settings_mixed_selection')"
            type="warning"
            class="mb-2"
            show-icon
        />
        <template v-else-if="isCustomHeaderEnabled">
          <small class="mt-4 mb-1 text-muted">{{ $t('message.AddCustomHeaderList') }}</small>
          <div v-if="customHeaders.length" class="mb-4">
            <CListGroup>
              <CListGroupItem v-for="(header) in customHeaders" v-bind:key="header">
                <div style="display: flex; align-items: center;">
                  <div style="flex: 1">
                    {{ header }}
                  </div>
                  <div class="shadow-list-action-btns">
                    <CButtonClose v-on:click="__removeHeader(header)"/>
                  </div>
                </div>
              </CListGroupItem>
            </CListGroup>
          </div>

          <a-form layout="inline">

            <a-form-item>
              <a-input v-model:value="customHeader.key" :placeholder="$t('Key')"></a-input>
            </a-form-item>

            <a-form-item>
              <a-input v-model:value="customHeader.value" :placeholder="$t('Value')"></a-input>
            </a-form-item>

            <a-form-item>
              <a-button type="primary"
                        :disabled="customHeader.key === '' || customHeader.value === ''"
                        @click="addHeader">
                {{ $t('Add') }}
              </a-button>
            </a-form-item>

          </a-form>

          <a-form layout="inline">
            <a-form-item>
              <a-button type="secondary"
                        @click="customHeaderHSTS">
                {{ $t('domain.HSTS') }}
              </a-button>
            </a-form-item>

            <!--<a-form-item>
              <a-button type="secondary"
                        @click="customHeaderNoCache">
                {{ $t('domain.DisableCache') }}
              </a-button>
            </a-form-item>-->

            <a-form-item>
              <a-button type="secondary"
                        @click="customHeaderAllowCORS">
                {{ $t('domain.AllowCORS') }}
              </a-button>
            </a-form-item>
          </a-form>

          <small class="mt-4 mb-1 text-muted">{{ $t('message.RemoveCustomHeaderList') }}</small>
          <div v-if="removeHeaders.length" class="mb-4">
            <CListGroup>
              <CListGroupItem v-for="(header) in removeHeaders" v-bind:key="header">
                <div style="display: flex; align-items: center;">
                  <div style="flex: 1">
                    {{ header }}
                  </div>
                  <div class="shadow-list-action-btns">
                    <CButtonClose v-on:click="___removeHeader(header)"/>
                  </div>
                </div>
              </CListGroupItem>
            </CListGroup>
          </div>

          <a-form layout="inline">

            <a-form-item>
              <a-input v-model:value="removeHeader.key" :placeholder="$t('Key')"></a-input>
            </a-form-item>

            <a-form-item>
              <a-button type="primary"
                        :disabled="removeHeader.key === ''"
                        @click="_removeHeader">
                {{ $t('Remove') }}
              </a-button>
            </a-form-item>

          </a-form>
        </template>

        <SimpleEmpty v-else>
          <span slot="description">{{ $t('message.PluginNotActive') }}</span>
        </SimpleEmpty>
      </a-tab-pane>

      <a-tab-pane key="cache">
        <span slot="tab" class="has-icon">
          <a-icon v-if="isProxyCacheEnabled" type="check-circle"/>
          {{ $t('domain.CacheStrategy') }}
        </span>

          <header>
          <div class="d-flex align-items-center flex-fill">
            <div class="flex-fill">
              <div class="d-flex align-items-center mb-1 mt-2 has-icon">
                <a-switch :checked="isProxyCacheEnabled" @change="handleToggleCacheStrategy" class="mr-2">
                  <a-icon slot="checkedChildren" type="check"/>
                  <!-- we only show the unchecked icon if the selection is not mixed. not showing unchecked will be
                   our undetermined state to indicate visually the mixed values -->
                  <a-icon slot="unCheckedChildren" v-if="!isProxyCacheMixed" type="close"/>
                </a-switch>
                <strong>
                  {{ $t('domain.CacheStrategy') }}
                </strong>
              </div>
              <p>{{ $t('message.cdn_intelligent_caching') }}</p>
            </div>
            <div v-if="showClearCacheButton">
              <button @click="handleClearCacheEvent" type="button" class="btn btn-outline-warning mr-1">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                     stroke="currentColor" style="height: 16px; width: 16px;">
                  <path stroke-linecap="round" stroke-linejoin="round"
                        d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99"/>
                </svg>

                {{ $t('domain.ClearCache') }}
              </button>
            </div>
          </div>
        </header>


        <a-alert
            v-if="isProxyCacheMixed"
            message="Warning"
            :description="$t('message.domain_settings_mixed_selection')"
            type="warning"
            class="mb-2"
            show-icon
        />

          <a-form v-else-if="isAdvanceProxyCacheEnabled">
            <a-alert
              message="Info"
              :description="$t('message.AdvanceCacheStarteryOrCacheStatergy')"
              type="info"
              class="mb-2"
              show-icon
          />
          </a-form>
        
        <a-form v-else-if="isProxyCacheEnabled" layout="horizontal"
                label-align="left"
                :colon="false"
                :label-col="{ span: 6 }"
                :wrapper-col="{ span: 12 }">

          <div v-if="cachePathlist && cachePathlist.length" class="max-paths-height">
            <CListGroup>
              <CListGroupItem v-for="(cachePaths) in cachePathlist" v-bind:key="cachePaths">
                <div style="display: flex; align-items: center;">
                  <div style="flex: 1">
                    {{ cachePaths }}
                  </div>
                  <div class="shadow-list-action-btns">
                    <CButtonClose v-on:click="removeCachePathlist(cachePaths)"/>
                  </div>
                </div>
              </CListGroupItem>
            </CListGroup>
          </div>
          <SimpleEmpty v-else>
            <span slot="description">{{ $t('message.NoSpecificProtectedPathsListed') }}</span>
          </SimpleEmpty>

          <div class="mt-4 p-4 border-top">
            <p class="mb-1 has-icon text-info">
              <a-icon type="form" class="mr-1"/>
              <strong>{{ $t('message.AddNewCachePath') }}</strong>
            </p>

            <CTextarea
                v-model="newCachePathlist"
                placeholder="/path, /test"
                :description="$t('helps.enter_cache_path')"
            >
            </CTextarea>

            <div class="d-flex justify-content-end mt-n4">
              <a-button @click="addNewCachePath()" :disabled="!newCachePathlist.length">
                <a-icon type="plus"/>
                {{ $t('Add') }}
              </a-button>
            </div>
          </div>

          <a-card :title="$t('Request')" size="small">
            <div class="pl-4 pb-4 row">
              <div class="col-lg-12">
                <p class="mb-1">
                  {{ $t('domain.CookieBypassRules') }}
                </p>
                <TagInput :values.sync="cookieBypassRules"/>
              </div>
              <div class="col-lg-12 mt-3">
                <p class="mb-1">
                  {{ $t('domain.PathBypassRules') }}
                </p>
                <TagInput :values.sync="pathBypassRules"/>
              </div>
              <div class="col-lg-12 mt-3">
                <p class="mb-1">
                  {{ $t('domain.QueryBypassRules') }}
                </p>
                <TagInput :values.sync="queryBypassRules"/>
              </div>
              <div class="col-lg-12 mt-3">
                <p class="mb-1">
                  {{ $t('domain.RequestMethod') }}
                </p>
                <TagInput :values.sync="requestMethods"/>
              </div>
            </div>
          </a-card>

          <a-card :title="$t('Response')" size="small" class="mt-4">
            <div class="row pl-4 pb-4">
              <div class="col-lg-12 mt-3">
                <p class="mb-1">
                  {{ $t('domain.ResponseCode') }}
                </p>
                <TagInput :values.sync="responseCode"/>
              </div>

              <div class="col-lg-12 mt-3">
                <p class="mb-1">
                  {{ $t('domain.ExtensionType') }}
                </p>
                <div class="border rounded p-2 mt-2 mb-2 d-flex">
                  <div class="flex-fill">
                    <p class="mb-0 text-muted font-weight-light">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                           stroke="currentColor" style="width: 16px; height: 16px;">
                        <path stroke-linecap="round" stroke-linejoin="round"
                              d="M16.5 3.75V16.5L12 14.25 7.5 16.5V3.75m9 0H18A2.25 2.25 0 0120.25 6v12A2.25 2.25 0 0118 20.25H6A2.25 2.25 0 013.75 18V6A2.25 2.25 0 016 3.75h1.5m9 0h-9"/>
                      </svg>
                      {{ $t('helps.PredefinedContentTypes') }}
                    </p>
                  </div>
                  <div>
                  </div>
                </div>
                <TagInput :values.sync="contentType"/>
                <br>
                <a-checkbox :checked="isSmartFullSiteCache" @change="handleSmartFullSiteCache">Enable Cache FullSite</a-checkbox>
              </div>
            </div>
          </a-card>
        </a-form>
        <SimpleEmpty v-else>
          <span slot="description">{{ $t('message.PluginNotActive') }}</span>
        </SimpleEmpty>
      </a-tab-pane>

      <!-- <a-tab-pane key="advancecache">
        <span slot="tab" class="has-icon">
          <a-icon v-if="isAdvanceProxyCacheEnabled" type="check-circle"/>
          {{ $t('Advance Cache Strategy') }}
        </span>

          <header>
          <div class="d-flex align-items-center flex-fill">
            <div class="flex-fill">
              <div class="d-flex align-items-center mb-1 mt-2 has-icon">
                <a-switch :checked="isAdvanceProxyCacheEnabled" @change="handleToggleAdvanceCacheStrategy" class="mr-2">
                  <a-icon slot="checkedChildren" type="check"/>
                  <a-icon slot="unCheckedChildren" v-if="!isAdvanceProxyCacheMixed" type="close"/>
                </a-switch>
                <strong>
                  {{ $t('Advance Cache Strategy') }}
                </strong>
                </div>
                <p>{{ $t('message.cdn_intelligent_caching') }}</p>
            </div>
          </div>
        </header>

        <a-alert
            v-if="isAdvanceProxyCacheEnabled && !this.HonourOriginCacheControl && !this.DefaultCaching && !this.DisableCacheCompletely && !this.CustomCaching"
            message="Warning"
            :description="$t('message.SelectAdvanceProxyCacheOptions')"
            type="warning"
            class="mb-2"
            show-icon
        />

        <a-alert
            v-if="isAdvanceProxyCacheMixed"
            message="Warning"
            :description="$t('message.domain_settings_mixed_selection')"
            type="warning"
            class="mb-2"
            show-icon
        />

        <a-form v-else-if="isProxyCacheEnabled">
          <a-alert
            message="Info"
            :description="$t('message.AdvanceCacheStarteryOrCacheStatergy')"
            type="info"
            class="mb-2"
            show-icon
        />
        </a-form>

        <a-form v-else-if="isAdvanceProxyCacheEnabled" layout="horizontal"
                label-align="left"
                :colon="false"
                :label-col="{ span: 6 }"
                :wrapper-col="{ span: 12 }">

          <a-card :title="$t('domain.GlobalSetting')" size="small">
            <div class="pl-4 pb-4 row">
                <div class="col-lg-12 mt-3">
                  <a-switch :checked="HonourOriginCacheControl" @change="handleToggleHonourOriginCacheControl" class="mr-2">
                    <a-icon slot="checkedChildren" type="check"/>
                    <a-icon slot="unCheckedChildren" type="close"/>
                  </a-switch>
                    <strong>{{
                      $t('domain.HonourOriginCacheControl')
                    }}</strong>
                </div>
                <div class="col-lg-12 mt-3">
                  <a-switch :checked="DefaultCaching" @change="handleToggleDefaultCaching" class="mr-2">
                    <a-icon slot="checkedChildren" type="check"/>
                    <a-icon slot="unCheckedChildren" type="close"/>
                  </a-switch>
                  <strong>{{ $t('domain.DefaultCaching') }}</strong>
                </div>
                <div class="col-lg-12 mt-3">
                  <a-switch :checked="DisableCacheCompletely" @change="handleToggleDisableCacheCompletely" class="mr-2">
                    <a-icon slot="checkedChildren" type="check"/>
                    <a-icon slot="unCheckedChildren" type="close"/>
                  </a-switch>
                  <strong>{{ $t('domain.DisableCacheCompletely') }}</strong>
                </div>
                <div class="col-lg-12 mt-3">
                  <a-switch :checked="CustomCaching" @change="handleToggleCustomCaching" class="mr-2">
                    <a-icon slot="checkedChildren" type="check"/>
                    <a-icon slot="unCheckedChildren" type="close"/>
                  </a-switch>
                  <strong>{{ $t('domain.CustomCaching') }}</strong>
                  <a-input-number v-if="CustomCaching" :min="1" v-model="CustomCachingTime" class="ml-4 mr-2 ant-input-number" />
                  <span v-if="CustomCaching">{{ $t('Hours') }}</span>
                </div>
            </div>
          </a-card>
        </a-form>
        
        <SimpleEmpty v-else>
          <span slot="description">{{ $t('message.PluginNotActive') }}</span>
        </SimpleEmpty>
      </a-tab-pane> -->

      <a-tab-pane key="l7-ban" v-if=!isFreeCname>
        <span slot="tab"
              class="has-icon"
              v-bind:class="{'text-danger': gaiusBanErrors }">
          <a-icon v-if="isGaiusBanEnabled" type="check-circle"/>
          {{ $t('domain.L7Block') }}
        </span>

        <header>
          <div class="flex-fill">
            <div class="d-flex align-items-center mb-1 mt-2 has-icon">
              <a-switch :checked="isGaiusBanEnabled" @change="handleToggleGaiusBan" class="mr-2">
                <a-icon slot="checkedChildren" type="check"/>
                <a-icon slot="unCheckedChildren" v-if="!isGaiusBanMixed" type="close"/>
              </a-switch>
              <strong>
                {{ $t('domain.L7Block') }}
              </strong>
            </div>
            <p>{{ $t('message.cdn_l7_protection') }}</p>
          </div>
        </header>

        <a-alert
            v-if="isGaiusBanMixed"
            message="Warning"
            :description="$t('message.domain_settings_mixed_selection')"
            type="warning"
            class="mb-2"
            show-icon
        />
        <a-form v-else-if="isGaiusBanEnabled" label-align="left" class="mt-4"
                :colon="false"
                :label-col="{ sm : { span: 8 }, md : { span: 10} }"
                :wrapper-col="{ sm : { span: 12 } }"
        >
          <a-form-item :label="$t('domain.BanDuration')"
                       :validate-status="getFieldValidationStatus(gaiusBanErrors, 'default_timeout')"
                       :help="getFieldHelpMessage(gaiusBanErrors, 'default_timeout', 'gaius-ban')">
            <a-input-number :min="1" v-model="syncDurationBanInput"/>
          </a-form-item>
        </a-form>
        <SimpleEmpty v-else>
          <span slot="description">{{ $t('message.PluginNotActive') }}</span>
        </SimpleEmpty>
      </a-tab-pane>


      <a-tab-pane key="proxy-host">
        <header>
          <div class="flex-fill">
            <div class="d-flex align-items-center mb-1 mt-2 has-icon">
              <a-switch :checked="isProxyHostEnabled" @change="handleToggleProxyHost" class="mr-2">
                <a-icon slot="checkedChildren" type="check"/>
                <a-icon slot="unCheckedChildren" v-if="!isProxyHostMixed" type="close"/>
              </a-switch>
              <strong>
                {{ $t('domain.ProxyHost') }}
              </strong>
            </div>
            <p>{{ $t('message.ProxyHostDescription') }}</p>
          </div>
        </header>

        <a-alert
            v-if="isProxyHostMixed"
            message="Warning"
            :description="$t('message.domain_settings_mixed_selection')"
            type="warning"
            class="mb-2"
            show-icon
        />
        <a-form v-else-if="isProxyHostEnabled" label-align="left" class="mt-4"
                :colon="false"
                :label-col="{ sm : { span: 8 }, md : { span: 10} }"
                :wrapper-col="{ sm : { span: 12 } }"
        >
          <a-form-item :label="$t('domain.ProxyHostDomain')"
                       :validate-status="getFieldValidationStatus(gaiusUpstreamHostErrors, 'hostname')"
                       :help="getFieldHelpMessage(gaiusUpstreamHostErrors, 'hostname', 'gaius-upstream-host')">
            <a-input v-model="syncProxyHost"
                     placeholder="www.example.com"
            />
          </a-form-item>
        </a-form>
        <SimpleEmpty v-else>
          <span slot="description">{{ $t('message.PluginNotActive') }}</span>
        </SimpleEmpty>

        <span slot="tab"
              class="has-icon"
              v-bind:class="{'text-danger': gaiusUpstreamHostErrors }">
          <a-icon v-if="isProxyHostEnabled" type="check-circle"/>
          {{ $t('domain.ProxyHost') }}
        </span>
      </a-tab-pane>

      <a-tab-pane key="origin-path-prefix">
        <header>
          <div class="flex-fill">
            <div class="d-flex align-items-center mb-1 mt-2 has-icon">
              <strong>
                {{ $t('domain.OriginPathPrefix') }}
              </strong>
            </div>
            <p>{{ $t('message.OriginPathPrefixDescription') }}</p>
          </div>
        </header>
        
        <a-alert
            v-if="hasMixedServicePath"
            message="Warning"
            :description="$t('message.domain_settings_mixed_selection')"
            type="warning"
            class="mb-2"
            show-icon
        />
        <a-form label-align="left" class="mt-4"
                :colon="false"
                :label-col="{ sm : { span: 8 }, md : { span: 10} }"
                :wrapper-col="{ sm : { span: 12 } }"
        >
          <a-form-item :label="$t('message.PathPrefix')" :validate-status="servicePathValidity" :help="servicePathHelpText">
            <a-input v-model="syncServicePath"
                     @change="handleServicePathChange"
                     placeholder="/foo/bar"
            />
          </a-form-item>
        </a-form>

        <span slot="tab"
              class="has-icon"
              v-bind:class="{'text-danger': servicePathValidity }">
          <a-icon v-if="isOriginPathEnabled" type="check-circle"/>
          {{ $t('domain.OriginPathPrefix') }}
        </span>
      </a-tab-pane>

      <a-tab-pane key="acme-configure" :tab="$t('domain.ACMEConfigure')">
        <header>
          <div class="flex-fill">
            <div class="d-flex align-items-center mb-1 mt-2 has-icon">
              <strong>
                {{ $t('domain.ACMEConfigure') }}
              </strong>
            </div>
            <p>{{ $t('message.ACMEConfigureDescription') }}</p> 
          </div>
        </header>

        <div class="mt-4">
          <CInputCheckbox :checked="isBypassACME"
                          v-on:update:checked="$emit('update:is-bypass-acme', $event)"
                          :label="$t('domain.IsBypassACMEDescription')">

          </CinputCheckbox>
        </div>
      </a-tab-pane>

      <a-tab-pane key="advanced-rules" :tab="$t('domain.AdvancedRules')">
        <header>
          <div class="flex-fill">
            <div class="d-flex align-items-center mb-1 mt-2 has-icon">
              <strong>
                {{ $t('domain.AdvancedRules') }}
              </strong>
            </div>
            <p>{{ $t('message.AdvanceRulesDescription') }}</p>
          </div>
        </header>

        <div>
          <CListGroup>
            <CListGroupItem class="d-flex flex-column">
              <div class="d-flex justify-content-between align-items-center">
                <div class="has-icon">
                  {{ $t('domain.SocketSupport') }}
                  <a-tooltip :title="$t('helps.websockets_use')">
                    <a-icon type="question-circle" @click="showContact()"/>
                  </a-tooltip>
                </div>
                <a-icon :type="isFreeCname ? 'close-circle' : 'check-circle'" :class="{'text-success': !isFreeCname, 'text-danger': isFreeCname}"/>
              </div>
              <a-alert v-if="isFreeCname" type="error" :message="$t('domain.UnsupportWebsocketMessage')"/>
            </CListGroupItem>


            <CListGroupItem class="d-flex justify-content-between align-items-center">
              <div class="has-icon">
                {{ $t('domain.DdosHighDefense') }}
                <a-tooltip :title="$t('helps.protection_reflection_attacks_etc')">
                  <a-icon type="question-circle" @click="showContact()"/>
                </a-tooltip>
              </div>
              <a-icon type="check-circle" class="text-success"/>
            </CListGroupItem>
          </CListGroup>
        </div>
        <p class="mt-2 text-muted">
          {{ $t('message.AdvanceRulesSupport') }}
          <a-button type="link" class="has-icon" @click="showContact">
            <a-icon type="phone"/>
            {{ $t('Support') }}
          </a-button>
        </p>
      </a-tab-pane>
    </a-tabs>
  </div>
</template>

<script>
import { bus } from '@/main';
import isValidDomain from 'is-valid-domain';
import {has, debounce} from 'lodash';
import {EventBus, Events} from '@/plugins/event-bus';
import TagInput from '@/components/TagInput';
import {
  MIXED_VALUE,
  PLUGIN_NAMES,
  SCREEN_TYPES,
  MULTIPLE_VALUES_SPLITTER,
  PLUGIN_DEFAULTS,
  DEFAULT_CACHE_POST_FIXES,
} from '@/utilities/constants';
import SimpleEmpty from '@/views/domain/SimpleEmpty';
import PluginActionsMixin from '@/utilities/PluginActionsMixin';
import {getPlugin, getPluginConfigValue, isPluginEnabled, isPluginMixedValue} from '@/utilities/plugin-actions';

const DEFAULT_ACTIVE_TAB = 'custom-request-header';
const CACHE_TAB = 'cache';

const COOKIE_BYPASS_RULE_KEY = 'COOKIE:';
const PATH_BYPASS_RULE_KEY = 'PATH:';
const QUERY_BYPASS_RULE_KEY = 'QUERY:';
export default {
  name: 'AdvancedConfiguration',
  mixins: [PluginActionsMixin],
  components: {TagInput, SimpleEmpty},
  props: ['domain', 'plugins', 'screenSize', 'pluginErrors', 'isBatchEdit', 'servicePath', 'hasMixedServicePath', 'isBypassACME'],
  mounted() {
    EventBus.$on(Events.SHOW_CACHE_STRATEGY, () => {
      this.activeTab = CACHE_TAB;
    });
  },
  data() {
    return {
      newCachePathlist: '',
      customRequestHeader: {
        key: '',
        value: ''
      },
      removeRequestHeader: {
        key: ''
      },
      customHeader: {
        key: '',
        value: ''
      },
      removeHeader: {
        key: ''
      },
      activeTab: DEFAULT_ACTIVE_TAB,
      rawServicePath: this.servicePath,
      default_caching_config: {
          cache_paths: ["/static", "/uploads"],
          request_method: ['GET', 'HEAD', 'OPTIONS'],
          cache_postfixes: ['css', 'image', 'javascript', 'audio'],
          response_code: [200, 301, 302],
          bypass_rules: [],
          cache_ttl: 7200
        }
    }
  },
  created() {
    bus.$on('emptycustomRequestHeader', () => {
      this.customRequestHeader.key = '';
      this.customRequestHeader.value = '';
    });
    bus.$on('emptycustomResponseHeaders', () => {
      this.customHeader.key = '';
      this.customHeader.value = '';
    });
  },
  computed: {
    syncServicePath: {
      get() {
        return this.servicePath;
      },
      set(value) {
        if (value.trim() === '') {
          value = '/';
        }
        this.$emit('update:service-path', value);
      },
    },
    servicePathValidity: function () {
      if (this.isServicePathValid(this.rawServicePath)) {
        return '';
      }
      
      return 'error';
    },
    servicePathHelpText: function () {
      if (this.isServicePathValid(this.rawServicePath)) {
        return '';
      }
      
      return 'The value must conform to RFC 3986. i.e /pages/public';
    },
    isFreeCname: function () {
      const subscriptions = this.$store.state.subscriptions;
      for (let s of subscriptions) {
        if (!s.plan) {
          return true;
        }
        if (s && s.plan && s.plan.name == "Free") {
          return true;
        }
      }
      return false;
    },
    isProxyCacheEnabled: function () {
      return isPluginEnabled(this.plugins, PLUGIN_NAMES.GAIUS_PROXY_CACHE);
    },
    isProxyCacheMixed: function () {
      return isPluginMixedValue(this.plugins, PLUGIN_NAMES.GAIUS_PROXY_CACHE);
    },
    isAdvanceProxyCacheEnabled: function () {
      return isPluginEnabled(this.plugins, PLUGIN_NAMES.GAIUS_ADVANCE_PROXY_CACHE);
    },
    isAdvanceProxyCacheMixed: function () {
      return isPluginMixedValue(this.plugins, PLUGIN_NAMES.GAIUS_ADVANCE_PROXY_CACHE);
    },
    isGaiusBanEnabled: function () {
      return isPluginEnabled(this.plugins, PLUGIN_NAMES.GAIUS_BAN);
    },
    isGaiusBanMixed: function () {
      return isPluginMixedValue(this.plugins, PLUGIN_NAMES.GAIUS_BAN);
    },
    syncDurationBanInput: {
      get() {
        const value = getPluginConfigValue(this.plugins, PLUGIN_NAMES.GAIUS_BAN, 'default_timeout');

        if (value === MIXED_VALUE)
          return null;

        return value;
      },
      set(value) {
        this.setPluginConfigValue(PLUGIN_NAMES.GAIUS_BAN, 'default_timeout', value);
      }
    },
    isCustomHeaderRequestEnabled: function () {
      return isPluginEnabled(this.plugins, PLUGIN_NAMES.GAIUS_CUSTOM_HEADER);
    },
    isCustomHeaderEnabled: function () {
      return isPluginEnabled(this.plugins, PLUGIN_NAMES.RESPONSE_TRANSFORMER);
    },
    isCustomHeaderRequestMixed: function () {
      return isPluginMixedValue(this.plugins, PLUGIN_NAMES.GAIUS_CUSTOM_HEADER);
    },
    isCustomHeaderMixed: function () {
      return isPluginMixedValue(this.plugins, PLUGIN_NAMES.RESPONSE_TRANSFORMER);
    },
    gaiusCacheStrategyErrors: function () {
      return this.getPluginErrors(PLUGIN_NAMES.GAIUS_PROXY_CACHE);
    },
    customHeaderRequestErrors: function () {
      return this.getPluginErrors(PLUGIN_NAMES.GAIUS_CUSTOM_HEADER);
    },
    customHeaderErrors: function () {
      return this.getPluginErrors(PLUGIN_NAMES.RESPONSE_TRANSFORMER);
    },
    isProxyHostEnabled: function () {
      return isPluginEnabled(this.plugins, PLUGIN_NAMES.GAIUS_UPSTREAM_HOST);
    },
    isProxyHostMixed: function () {
      return isPluginMixedValue(this.plugins, PLUGIN_NAMES.GAIUS_UPSTREAM_HOST);
    },
    syncProxyHost: {
      get() {
        const value = getPluginConfigValue(this.plugins, PLUGIN_NAMES.GAIUS_UPSTREAM_HOST, 'hostname');

        if (value === MIXED_VALUE)
          return '';

        return value;
      },
      set(value) {
        this.setPluginConfigValue(PLUGIN_NAMES.GAIUS_UPSTREAM_HOST, 'hostname', value);
      }
    },
    isOriginPathEnabled: function () {
      const pathPrefix = (this.syncServicePath || '');
      
      // not empty and not the default
      return pathPrefix.length > 0 && pathPrefix !== '/';
    },
    tabPosition: function () {
      if (this.screenSize === SCREEN_TYPES.XS
      ) {
        return 'top';
      }
      return 'left';
    },
    customRequestHeaders: {
      get() {
        const plugin = getPlugin(this.plugins, PLUGIN_NAMES.GAIUS_CUSTOM_HEADER);
        if (!plugin) {
          return [];
        }
        const headers = getPluginConfigValue(this.plugins, PLUGIN_NAMES.GAIUS_CUSTOM_HEADER, 'add').headers;
        if (headers === undefined) {
          return []
        }
        return [...headers]
      },
      set(values) {
        const plugin = getPlugin(this.plugins, PLUGIN_NAMES.GAIUS_CUSTOM_HEADER);
        if (!plugin) {
          return;
        }

        this.setPluginConfigValue(PLUGIN_NAMES.GAIUS_CUSTOM_HEADER, 'add', {headers: values});
      }
    },
    removeRequestHeaders: {
      get() {
        const plugin = getPlugin(this.plugins, PLUGIN_NAMES.GAIUS_CUSTOM_HEADER);
        if (!plugin) {
          return [];
        }

        let removeValue = getPluginConfigValue(this.plugins, PLUGIN_NAMES.GAIUS_CUSTOM_HEADER, 'remove');
        if (!removeValue || !removeValue.hasOwnProperty('headers')) {
          this.setPluginConfigValue(PLUGIN_NAMES.GAIUS_CUSTOM_HEADER, 'remove', {headers: []});
          removeValue = getPluginConfigValue(this.plugins, PLUGIN_NAMES.GAIUS_CUSTOM_HEADER, 'remove');
        }

        return [...removeValue.headers];
      },
      set(values) {
        const plugin = getPlugin(this.plugins, PLUGIN_NAMES.GAIUS_CUSTOM_HEADER);
        if (!plugin) {
          return;
        }

        this.setPluginConfigValue(PLUGIN_NAMES.GAIUS_CUSTOM_HEADER, 'remove', {headers: values});
      }
    },
    customHeaders: {
      get() {
        const plugin = getPlugin(this.plugins, PLUGIN_NAMES.RESPONSE_TRANSFORMER);
        if (!plugin) {
          return [];
        }
        const headers = getPluginConfigValue(this.plugins, PLUGIN_NAMES.RESPONSE_TRANSFORMER, 'add').headers;
        if (headers === undefined) {
          return []
        }
        return [...headers]
      },
      set(values) {
        const plugin = getPlugin(this.plugins, PLUGIN_NAMES.RESPONSE_TRANSFORMER);
        if (!plugin) {
          return;
        }

        this.setPluginConfigValue(PLUGIN_NAMES.RESPONSE_TRANSFORMER, 'add', {headers: values});
      }
    },
    removeHeaders: {
      get() {
        const plugin = getPlugin(this.plugins, PLUGIN_NAMES.RESPONSE_TRANSFORMER);
        if (!plugin) {
          return [];
        }
        let removeValue = getPluginConfigValue(this.plugins, PLUGIN_NAMES.RESPONSE_TRANSFORMER, 'remove');
        if (!removeValue || !removeValue.hasOwnProperty('headers')) {
          this.setPluginConfigValue(PLUGIN_NAMES.RESPONSE_TRANSFORMER, 'remove', {headers: []});
          removeValue = getPluginConfigValue(this.plugins, PLUGIN_NAMES.RESPONSE_TRANSFORMER, 'remove');
        }
        return [...removeValue.headers];
      },
      set(values) {
        const plugin = getPlugin(this.plugins, PLUGIN_NAMES.RESPONSE_TRANSFORMER);
        if (!plugin) {
          return;
        }

        this.setPluginConfigValue(PLUGIN_NAMES.RESPONSE_TRANSFORMER, 'remove', {headers: values});
      }
    },
    proxyHostDomain: {
      get() {
        const plugin = getPlugin(this.plugins, PLUGIN_NAMES.GAIUS_UPSTREAM_HOST);
        if (!plugin) {
          return;
        }
        return plugin.config.hostname;
      },
      set(values) {
        const plugin = getPlugin(this.plugins, PLUGIN_NAMES.GAIUS_UPSTREAM_HOST);
        if (!plugin) {
          return;
        }
        this.setPluginConfigValue(PLUGIN_NAMES.GAIUS_PROXY_CACHE, 'hostname', values);
      }
    },
    cookieBypassRules: {
      get: function () {
        return this.getBypassRule(COOKIE_BYPASS_RULE_KEY);
      },
      set: function (values) {
        this.setBypassRule(COOKIE_BYPASS_RULE_KEY, values);
      },
    },
    pathBypassRules: {
      get: function () {
        return this.getBypassRule(PATH_BYPASS_RULE_KEY);
      },
      set: function (values) {
        this.setBypassRule(PATH_BYPASS_RULE_KEY, values);
      },
    },
    queryBypassRules: {
      get: function () {
        return this.getBypassRule(QUERY_BYPASS_RULE_KEY);
      },
      set: function (values) {
        this.setBypassRule(QUERY_BYPASS_RULE_KEY, values);
      },
    },
    requestMethods: {
      get: function () {
        return this.getPluginConfigValue(PLUGIN_NAMES.GAIUS_PROXY_CACHE, 'request_method');
      },
      set: function (values) {
        const validRequestMethods = {
          HEAD: 1,
          GET: 1,
          POST: 1,
          PATCH: 1,
          PUT: 1,
          OPTIONS: 1,
        };
        values = values.map(v => v.toUpperCase());
        const invalid = values.find((v) => !validRequestMethods[v]);
        if (invalid) {
          this.$notification.error({message: this.$t('message.RequestMethodMustBe', [Object.keys(validRequestMethods).join(', ')])});
          return;
        }
        this.setPluginConfigValue(PLUGIN_NAMES.GAIUS_PROXY_CACHE, 'request_method', ...(new Set([values])).values());
      },
    },
    isSmartFullSiteCache: {
      get () {
        return this.getPluginConfigValue(PLUGIN_NAMES.GAIUS_PROXY_CACHE, 'enable_cache_fullsize');
      }, 
      set(value) {
        this.setPluginConfigValue(PLUGIN_NAMES.GAIUS_PROXY_CACHE, 'enable_cache_fullsize', value);
    },},
    responseCode: {
      get: function () {
        return this.getPluginConfigValue(PLUGIN_NAMES.GAIUS_PROXY_CACHE, 'response_code');
      },
      set: function (values) {
        let converted = new Set();
        for (let v of values) {
          const c = parseInt(v);
          if (!isNaN(c)) {
            converted.add(c);
          }
        }
        converted = [...converted.values()];
        const invalid = converted.find((v) => {
          return (v < 100 || v > 900);
        });
        if (invalid) {
          this.$notification.error({message: this.$t('message.ResponseCodeMustBe')});
          return;
        }
        this.setPluginConfigValue(PLUGIN_NAMES.GAIUS_PROXY_CACHE, 'response_code', converted);
      },
    },
    contentType: {
      get: function () {
        const cache_postfixes = this.getPluginConfigValue(PLUGIN_NAMES.GAIUS_PROXY_CACHE, 'cache_postfixes')
        if (cache_postfixes.length == 0) {
          return DEFAULT_CACHE_POST_FIXES
        } else {
          return cache_postfixes
        }
      },
      set: function (values) {
        this.setPluginConfigValue(PLUGIN_NAMES.GAIUS_PROXY_CACHE, 'cache_postfixes', values);
      },
    },
    cachePathlist: {
      get() {
        return this.getCachePaths();
      },
      set(value) {
        this.setCachePaths(value);
      },
    },
    HonourOriginCacheControl: {
      get() {
        return this.getPluginConfigValue(PLUGIN_NAMES.GAIUS_ADVANCE_PROXY_CACHE, 'enable_cache_control');
      },
      set(value) {
        this.setPluginConfigValue(PLUGIN_NAMES.GAIUS_ADVANCE_PROXY_CACHE, 'enable_cache_control', value);
      }
    },
    DefaultCaching: {
      get() {
        return this.getPluginConfigValue(PLUGIN_NAMES.GAIUS_ADVANCE_PROXY_CACHE, 'global_default_caching');
      },
      set(value) {
        this.setPluginConfigValue(PLUGIN_NAMES.GAIUS_ADVANCE_PROXY_CACHE, 'global_default_caching', value);
        for (const key in this.default_caching_config) {
          this.setPluginConfigValue(PLUGIN_NAMES.GAIUS_ADVANCE_PROXY_CACHE, key, this.default_caching_config[key]);
        }
      }
    },
    DisableCacheCompletely: {
      get() {
        return this.getPluginConfigValue(PLUGIN_NAMES.GAIUS_ADVANCE_PROXY_CACHE, 'global_disable_cache_completely');
      },
      set(value) {
        this.setPluginConfigValue(PLUGIN_NAMES.GAIUS_ADVANCE_PROXY_CACHE, 'global_disable_cache_completely', value);
      }
    },
    CustomCaching: {
      get() {
        return this.getPluginConfigValue(PLUGIN_NAMES.GAIUS_ADVANCE_PROXY_CACHE, 'global_custom_caching');
      },
      set(value) {
        this.setPluginConfigValue(PLUGIN_NAMES.GAIUS_ADVANCE_PROXY_CACHE, 'global_custom_caching', value);
      }
    },
    CustomCachingTime: {
      get() {
        const value = this.getPluginConfigValue(PLUGIN_NAMES.GAIUS_ADVANCE_PROXY_CACHE, 'cache_ttl');
        return Math.floor(value / 3600);
      }, 
      set(value) {
        const check = parseInt(value);
        if (isNaN(check)) {
          return;
        }
        this.setPluginConfigValue(PLUGIN_NAMES.GAIUS_ADVANCE_PROXY_CACHE, 'cache_ttl', Math.floor(value * 3600));
      }
    },
    gaiusBanErrors: function () {
      const error = this.getPluginErrors(PLUGIN_NAMES.GAIUS_BAN);
      if (!error) {
        return null;
      }
      return error.config;
    },
    gaiusUpstreamHostErrors: function () {
      const error = this.getPluginErrors(PLUGIN_NAMES.GAIUS_UPSTREAM_HOST);
      if (this.syncProxyHost && !isValidDomain(this.syncProxyHost)) {
        this.clearPluginError(PLUGIN_NAMES.GAIUS_UPSTREAM_HOST);
        return this.$t('message.URLFormatInvalid');
      }
      if (!error) {
        return null;
      }
      return error.config;
    },
    showClearCacheButton: function () {
      return this.isProxyCacheEnabled && !this.isBatchEdit;
    },
  },
  methods: {
    handleSmartFullSiteCache(checkbox) {
      // You can also perform additional actions when the checkbox state changes here
      let enabled = checkbox.target.checked
      this.isSmartFullSiteCache = enabled;
    },
    handleChangedTab(activeTab) {
      this.activeTab = activeTab;
    },
    gaius_request_header_key_validator(val) {
        return /^[a-zA-Z0-9_-]*$/.test(val);
    },
    getCachePaths() {
      const plugin = this.getPlugin(PLUGIN_NAMES.GAIUS_PROXY_CACHE);
      if (!plugin) {
        return [];
      }
      if (!plugin.config.cache_paths) {
        return [];
      }
      return plugin.config.cache_paths;
    },
    setCachePaths(value) {
      let plugin = this.getPlugin(PLUGIN_NAMES.GAIUS_PROXY_CACHE);
      if (!plugin) {
        plugin = this.getPluginDefaults(PLUGIN_NAMES.GAIUS_PROXY_CACHE);
      }
      const config = {...plugin.config};
      config.cache_paths = value;
      this.updatePlugin({...plugin, config: config});
    },
    customHeaderHSTS() {
      this.customHeader.key = 'Strict-Transport-Security';
      this.customHeader.value = 'max-age=63072000; includeSubDomains; preload'
    },
    customHeaderNoCache() {
      this.customHeader.key = 'Cache-Control';
      this.customHeader.value = 'no-store';
    },
    customHeaderAllowCORS() {
      this.customHeader.key = 'Access-Control-Allow-Origin';
      this.customHeader.value = '*';
    },
    _removeRequestHeader() {
      const headers = this.removeRequestHeaders;
      if (!headers.includes(`${this.removeRequestHeader.key}`)) {
        if (!this.gaius_request_header_key_validator(this.removeRequestHeader.key)) {
            this.$notification.error({message: this.$t('message.AllowKeys')});
        } else {
            headers.push(`${this.removeRequestHeader.key.toUpperCase()}`);
        }
      this.removeRequestHeaders = headers;
      this.removeRequestHeader = {
        key: ''
      }} else {
        this.$notification.error({message: this.$t('message.custom_header')});
      }
    },
    addRequestHeader() {
      const headers = this.customRequestHeaders;
      const header_key = this.customRequestHeader.key.trim();
      const header_value = this.customRequestHeader.value.trim().toLowerCase();
      if (!headers.includes(`${header_key}: ${header_value}`)) {
        if (!this.gaius_request_header_key_validator(header_key)) {
            this.$notification.error({message: this.$t('message.AllowKeys')});
        } else {
            if(
              header_value === '$remote_addr' ||
              header_value === '$server_name' ||
              header_value ==='$status' ||
              header_value ==='$server_addr'
            ){
              headers.push(`${header_key.toUpperCase()}: ${header_value}`);
            } else {
              this.$notification.error({message: this.$t('message.AllowValues')});
            }
        }
      this.customRequestHeaders = headers;
      this.customRequestHeader = {
        key: '',
        value: ''
      }} else {
        this.$notification.error({message: this.$t('message.custom_header')});
      }
    },
    _removeHeader() {
      const headers = this.removeHeaders;
      if (!headers.includes(`${this.removeHeader.key}`)) {
        headers.push(`${this.removeHeader.key}`);
      } else {
        this.$notification.error({message: this.$t('message.custom_header')});
      }
      this.removeHeaders = headers;
      this.removeHeader = {
        key: '',
      }
    },
    addHeader() {
      const headers = this.customHeaders;
      if (!headers.includes(`${this.customHeader.key}: ${this.customHeader.value}`)) {
        headers.push(`${this.customHeader.key}: ${this.customHeader.value}`);
      } else {
        this.$notification.error({message: this.$t('message.custom_header')});
      }
      this.customHeaders = headers;
      this.customHeader = {
        key: '',
        value: ''
      }
      //this.clearPluginError(PLUGIN_NAMES.RESPONSE_TRANSFORMER);
    },
    __removeRequestHeader(header) {
      const headers = this.customRequestHeaders.filter((h) => h !== header);
      this.customRequestHeaders = headers;
    },
    ___removeRequestHeader(header) {
      const headers = this.removeRequestHeaders.filter((h) => h !== header);
      this.removeRequestHeaders = headers;
    },
    __removeHeader(header) {
      const headers = this.customHeaders.filter((h) => h !== header);
      this.customHeaders = headers;
    },
    ___removeHeader(header) {
      const headers = this.removeHeaders.filter((h) => h !== header);
      this.removeHeaders = headers;
    },
    handleDurationChange(value, fieldkey) {
      this.setPluginConfigValue(PLUGIN_NAMES.GAIUS_BAN, fieldkey, value);
    },
    returnStringIfFailedUrlValidate: function (value, returnValue) {
      if (!value || value === '')
        return '';
      if (!isValidDomain(value)) {
        return returnValue;
      }
      return '';
    },
    getBypassRule(key) {
      const bypassRule = this.getPluginConfigValue(PLUGIN_NAMES.GAIUS_PROXY_CACHE, 'bypass_rules');
      const values = bypassRule.filter((b) => b.indexOf(key) === 0);
      return values.map((c) => {
        return c.replace(key, '');
      });
    },
    setBypassRule(key, values) {
      const bypassRules = this.getPluginConfigValue(PLUGIN_NAMES.GAIUS_PROXY_CACHE, 'bypass_rules');
      // this basically removes the existing values with prefix "key" in bypass rules, so the other rules remain
      const otherRules = [...bypassRules].filter((b) => b.indexOf(key) !== 0);
      // we add the prefix "key" with the value in values (values does not have the prefix yet)
      // example:
      // key = PATH:
      // values = [one, two]
      // appended = [PATH:one, PATH:two]
      const appended = new Set();
      for (let value of values) {
        appended.add(`${key}${value}`);
      }
      this.setPluginConfigValue(PLUGIN_NAMES.GAIUS_PROXY_CACHE, 'bypass_rules', [...otherRules, ...appended.values()]);
    },
    handleToggleCacheStrategy(enabled) {
     if (this.isAdvanceProxyCacheEnabled || this.isAdvanceProxyCacheMixed) {
        this.togglePlugin(PLUGIN_NAMES.GAIUS_ADVANCE_PROXY_CACHE, false);
      }
      this.togglePlugin(PLUGIN_NAMES.GAIUS_PROXY_CACHE, enabled);
    },
    handleToggleAdvanceCacheStrategy(enabled) {
      if (this.isProxyCacheEnabled || this.isProxyCacheMixed) {
        this.togglePlugin(PLUGIN_NAMES.GAIUS_PROXY_CACHE, false);
      }
      this.togglePlugin(PLUGIN_NAMES.GAIUS_ADVANCE_PROXY_CACHE, enabled);
    },
    handleToggleCustomRequestHeader(enabled) {
      if (this.isFreeCname) {
        this.notifyError(this.$t('message.FreeAccountCanNotEnableCustomHeader', {on: this.$t('domain.DomainName')}));
        EventBus.$emit('show-contact');
        return;
      }
      this.togglePlugin(PLUGIN_NAMES.GAIUS_CUSTOM_HEADER, enabled);
    },
    handleToggleCustomHeader(enabled) {
      if (this.isFreeCname) {
        this.notifyError(this.$t('message.FreeAccountCanNotEnableCustomHeader', {on: this.$t('domain.DomainName')}));
        EventBus.$emit('show-contact');
        return;
      }
      this.togglePlugin(PLUGIN_NAMES.RESPONSE_TRANSFORMER, enabled);
    },
    handleToggleProxyHost(enabled) {
      if (this.isFreeCname) {
        this.notifyError(this.$t('message.FreeAccountCanNotEnableProxyHost', {on: this.$t('domain.DomainName')}));
        EventBus.$emit('show-contact');
        return;
      }
      this.togglePlugin(PLUGIN_NAMES.GAIUS_UPSTREAM_HOST, enabled);
    },
    handleToggleHonourOriginCacheControl: debounce(function(enabled) {
      this.HonourOriginCacheControl = enabled;

      this.DefaultCaching = false;
      this.DisableCacheCompletely = false;
      this.CustomCaching = false; 
    }, 120),
    
    handleToggleDefaultCaching: debounce(function(enabled) {
      this.DefaultCaching = enabled;

      this.HonourOriginCacheControl = false;
      this.DisableCacheCompletely = false;
      this.CustomCaching = false
    }, 120),
    handleToggleDisableCacheCompletely: debounce(function(enabled) {
      this.DisableCacheCompletely = enabled;

      this.HonourOriginCacheControl = false;
      this.DefaultCaching = false;
      this.CustomCaching = false;
    }, 120),
    handleToggleCustomCaching: debounce(function(enabled) {
      this.CustomCaching = enabled;

      this.HonourOriginCacheControl = false;
      this.DefaultCaching = false;
      this.DisableCacheCompletely = false;
    }, 120),
    handleToggleGaiusBan(enabled) {
      this.togglePlugin(PLUGIN_NAMES.GAIUS_BAN, enabled);
    },
    showContact() {
      EventBus.$emit('show-contact');
    },
    getPluginDefaultMessage(pluginName, field) {
      const fieldDefaultMessages = {
        'default_timeout': this.$t('message.DurationBan')
      }
      return fieldDefaultMessages[field];
    },
    getPluginErrors(pluginName) {
      const pluginError = this.pluginErrors.find((p) => {
        if (has(p, pluginName)) {
          return p;
        }
      });
      if (!pluginError)
        return null;
      return pluginError[pluginName];
    },
    clearPluginError(pluginName) {
      const pluginError = this.pluginErrors.find((p) => {
        if (has(p, pluginName)) {
          return p;
        }
      });
      if (pluginError)
        pluginError[pluginName] = {};
      return null;
    },
    getFieldHelpMessage(errors, field, pluginName = null) {
      if (!errors) {
        if (pluginName) {
          return this.getPluginDefaultMessage(pluginName, field);
        }
        return '';
      }
      if (errors.hasOwnProperty('config')) {
        return errors.config[field];
      }
      if (errors.constructor == String) {
        return errors;
      }
      return errors[field];
    },
    getFieldValidationStatus(errors, field) {
      if (!errors)
        return '';
      if (errors.hasOwnProperty('config')) {
        const errorMsg = errors.config[field];
        if (!errorMsg)
          return '';
        if (errorMsg.constructor === Array) {
          if (errorMsg.length > 0) {
            return 'error';
          } else {
            return '';
          }
        } else {
          return 'error';
        }
      }
      if (errors.constructor == String) {
        return 'error';
      }
      if (errors[field])
        return 'error';
      return '';
    },
    processCachePathEntry(input, baseEntries, suppressErrorMessage) {
      const cachePaths = [...baseEntries];
      const rawInputValues = input.split(MULTIPLE_VALUES_SPLITTER);
      for (let r of rawInputValues) {
        const duplicate = cachePaths.find((_t) => _t === r);
        if (duplicate) {
          !suppressErrorMessage && this.notifyError(this.$t('message.DuplicateEntryFor', [r]));
          continue;
        }
        if (!r.startsWith('/')) {
          !suppressErrorMessage && this.notifyError(this.$t('message.pathsStartWithSlashFor', [r]));
          continue;
        }
        cachePaths.push(r);
      }
      return cachePaths;
    },
    addNewCachePath(suppressErrorMessage = false) {
      this.newCachePathlist = this.newCachePathlist.trim()
      if (!this.newCachePathlist.length) {
        return;
      }
      this.cachePathlist = this.processCachePathEntry(this.newCachePathlist, this.cachePathlist, suppressErrorMessage);
      this.newCachePathlist = '';
    },
    removeCachePathlist(cachePaths) {
      this.cachePathlist = this.cachePathlist.filter((i) => i !== cachePaths);
    },
    handleClearCacheEvent() {
      EventBus.$emit(Events.CLEAR_CACHE_DOMAIN, {domain: this.domain})
    },
    handleServicePathChange(event) {
      this.rawServicePath = event.target.value;
      this.syncServicePath = event.target.value;
    },
    isServicePathValid(servicePath) {
      if ((servicePath || '').match(/^(\/([a-zA-Z0-9\-\.\_\~\!\$\&\'\(\)\*\+\,\;\=\:\@]|(%[a-f0-9]{2,2}))*)*$/)) {
        return true;
      }
      
      return false;
    },
  }
}
</script>

<style>
.customRequestHeaderText {
    text-transform: uppercase;
}
.customRequestHeaderValueText {
  text-transform: lowercase;
}
</style>
