<template>
  <CCard>
    <CCardBody>
      <CRow>
        <CCol sm="12" lg="4">
          {{ $t('domain.ShadowDomain') }}：
          <CAlert color="info">{{ $t('domain.ShadowDomainHelp') }}</CAlert>
        </CCol>
        <CCol sm="12" lg="8">
          <div v-if="shadows.length > 0"
               style="display: flex; align-items: flex-end; gap: 12px">
            <div style="flex: 1">
              <CInput
                  type="text"
                  :label="$t('Search')"
                  :placeholder="$t('message.SearchShadowDomain')"
                  v-model="shadowSearch"
                  @keypress="handleShadowSearch"
              >
                <template #append>
                  <CButton type="submit" color="primary" @click="searchShadowDomains">{{
                      $t('Search')
                    }}
                  </CButton>
                </template>
              </CInput>
            </div>
          </div>
          <!-- <a-alert v-if="fieldErrors && fieldErrors.hasOwnProperty('hosts')" :message="domainError" type="error" :banner="true"
            style="margin-bottom: 10px"/> -->
          <CListGroup>
            <CListGroupItem id="foobar" v-for="(shadow) in shadowDomainsList" v-bind:key="shadow.name"
                            :class="{ 'subdomain-shadow-domain': shadow.is_subdomain }">
              <div style="display: flex; align-items: center;">
                <div style="flex: 1">
                  <span v-if="shadow.is_subdomain" style="padding-left: 20px"></span>
                  <span class="has-icon">
                    {{ shadow.name }}
                    <ExclusiveCnamePopover v-if="isPaidCname && generateExclusiveCname(shadow.name)"
                                           :value="generateExclusiveCname(shadow.name)"/>
                  </span>
                </div>
                <div class="shadow-list-action-btns">
                  <a-popconfirm
                      v-if="isDomainSaved"
                      :title="$t('message.promote_shadow_message')"
                      ok-text="Yes"
                      cancel-text="No"
                      @confirm="promoteShadow(shadow.name)"
                      @cancel="() => {}"
                  >
                    <a-button type="link">
                      <a-icon type="up-circle"/>
                    </a-button>
                  </a-popconfirm>
                  <a-popconfirm
                      :title="$t('message.delete_shadow_message')"
                      ok-text="Yes"
                      cancel-text="No"
                      @confirm="removeShadow(shadow.name)"
                      @cancel="() => {}"
                  >
                    <a-button type="link">
                      <a-icon type="delete"/>
                    </a-button>
                  </a-popconfirm>
                </div>
              </div>
            </CListGroupItem>
            <CListGroupItem>
                <CTextarea
                    name="shadow_domain"
                    v-model="newShadowName"
                    style="width: 98%"
                    :is-valid="shadowDomainValid"
                    @keyup="handleShadowDomainInputKeyup"
                    :description="$t('InputFormat') + ': www.domain.com'"
                    :placeholder="$t('domain.PleaseEnterTheDomainName')"
                >
                </CTextarea>
                <div class="d-flex justify-content-end mt-n4">
                <a-button @click="addShadow" :disabled="!newShadowName.length">
                  <a-icon type="plus"/>
                  {{ $t('Add') }}
                </a-button>
                </div>
            </CListGroupItem>
          </CListGroup>
        </CCol>
      </CRow>

      <!-- <CRow>
        <CCol sm="3">
          {{ $t('domain.AliasJump') }}
          <CBadge size="sm" color="info"
            v-c-tooltip="{content: $t('message.alias_redirect')}">i</CBadge>
        </CCol>
        <CCol sm="9">
          <CSwitch class="mx-1" :checked.sync="domain.alias_redirect" color="success" />
        </CCol>
      </CRow> -->
    </CCardBody>
  </CCard>
</template>

<script>
import {EventBus} from '@/plugins/event-bus';
import psl from 'psl';
import {MULTIPLE_VALUES_SPLITTER} from '@/utilities/constants';
import DomainValidationMixin from '@/utilities/DomainValidationMixin';
import ExclusiveCnamePopover from '@/views/domain/ExclusiveCnamePopover';
import {domainConfig} from '@/utilities/api';

const DOMAIN_STAT_CACHE_KEY = 'domain_stat';
const QUOTAS_CACHE_KEY = 'quotas';
const SHADOW_CACHE_KEY = 'shadow';

export default {
  name: 'ShadowDomainConfiguration',
  components: {ExclusiveCnamePopover},
  mixins: [DomainValidationMixin],
  props: ['shadows', 'isDomainSaved', 'fieldErrors', 'mainDomain', 'sourceDomain'],
  data() {
    return {
      shadowSearch: '',
      newShadowName: '',
      shadowSearchResults: null,
      formValidStates: {},
      blackRulExtensions: [],
      shadowDomainValid: null
    }
  },
  computed: {
    isPaidCname: function () {
      return !this.isFreeCname;
    },
    isFreeCname: function () {
      const subscriptions = this.$store.state.subscriptions;
      for (let s of subscriptions) {
        if (!s.plan || s.plan.name == this.$t('widget.FreeMembership'))
          return true;
      }

      return false;
    },
    shadowDomainsMap: function () {
      const shadowDomainMap = {};
      for (let s of this.shadows) {
        shadowDomainMap[s] = s;
      }

      return shadowDomainMap;
    },
    domainError: function () {
      if (this.fieldErrors.hasOwnProperty('hosts')) {
        return this.fieldErrors.hosts[0];
      }
      return false;
    },
    useSearchResults() {
      return Boolean(this.shadowSearchResults);
    },
    shadowDomainsList: function () {
      const shadows = this.useSearchResults ? this.shadowSearchResults : this.shadows;

      const shadowDomainsByDomain = this.groupShadowDomainsByDomain(shadows);
      const shadowDomains = [];
      const domainsSorted = Object.keys(shadowDomainsByDomain).sort();

      for (let d of domainsSorted) {
        shadowDomains.push({name: this.shadowDomainsMap[d], is_subdomain: false});

        for (let dd of shadowDomainsByDomain[d]) {
          shadowDomains.push({name: this.shadowDomainsMap[dd], is_subdomain: true});
        }
      }

      return shadowDomains;
    },
    cname: function () {
      return this.$store.getters.cname;
    },
  },
  methods: {
    generateExclusiveCname(domain) {
      const safeHosts = this.sourceDomain.getSafeHosts();
      const safeHost = safeHosts.find((sf) => sf.host === domain);
      if (!safeHost) {
        return null;
      }
      const exclusiveCnameDomain = safeHost.safe_host;

      return `${exclusiveCnameDomain}.${this.cname}`;
    },
    searchShadowDomains(e) {
      // need to preventDefault so form does not submit
      e && e.preventDefault();

      if (!this.shadowSearch.length) {
        this.shadowSearchResults = null;
        return;
      }

      this.shadowSearchResults = this.shadows.filter(item => item.includes(this.shadowSearch));
    },
    handleShadowSearch(e) {
      if (e.key !== "Enter") {
        return;
      }

      this.searchShadowDomains(e);
    },
    groupShadowDomainsByDomain(shadows) {
      const domainToSubdomains = {};
      const subDomains = [];

      // create all parent keys
      for (let s of shadows) {
        const parsed = psl.parse(s);
        const isSubdomain = parsed.input !== parsed.domain;
        if (isSubdomain) {
          subDomains.push(s);
          continue;
        }

        domainToSubdomains[parsed.domain] = [];
      }

      // assign subdomains to parent keys, if there is no parent key assign the subdomain as top-level
      for (let s of subDomains) {
        const parsed = psl.parse(s);

        if (domainToSubdomains[parsed.domain]) {
          domainToSubdomains[parsed.domain].push(s)
          domainToSubdomains[parsed.domain].sort();
        } else {
          domainToSubdomains[s] = [];
        }
      }

      return domainToSubdomains;
    },
    addShadow: function () {
      const d = this.newShadowName.toLowerCase().trim();

      if (!d.length)
        return true;

      const domains = d.split(MULTIPLE_VALUES_SPLITTER);
      const isSingleEntry = domains.length === 1;

      const success = [];
      const failed = [];
      let shdow_cache = 0;
      let isMainDomain = false;

      if (window.localStorage) {
        if (localStorage[DOMAIN_STAT_CACHE_KEY]) {
          shdow_cache = Number(localStorage[SHADOW_CACHE_KEY]);
        }
      }

      if (this.isFreeCname && d.includes("*.")) {
        this.notifyError(this.$t('message.FreeAccountCanNotAddWildDomain', {on: this.$t('domain.DomainName')}));
        EventBus.$emit('show-contact');
        return false;
      }

      this.formValidStates = {};
      this.formValidStates.shadow_domain = null;
      this.shadowDomainValid = null;

      for (const domain of domains) {
        const isDuplicate = this.shadows.includes(domain);
        if (isDuplicate) {
          // only show flash if we are processing a single domain because the messaging only makes
          // sense for a single domain input
          isSingleEntry && this.flashError(this.$t('message.ShadowDuplicate'));
          continue;
        }

        if (typeof (this.blackRulExtensions) == 'string') {
          this.blackRulExtensions = JSON.parse(this.blackRulExtensions);
        }

        let blackruleError = false;
        for (const blackrule of this.blackRulExtensions) {
          if (domain.toLowerCase().includes(blackrule)) {
            blackruleError = true;
            break;
          }
        }

        if (domain == 'googleusercontent.com' || blackruleError) {
          isSingleEntry && this.notifyError(this.$t('message.InvalidDomainExtension', {on: this.$t('domain.DomainName')}));
          this.formValidStates.shadow_domain = false;
          this.shadowDomainValid = false;
          failed.push(domain);
          continue;
        }

        if (!this.shadow_domain_validator(domain)) {
          // only show flash if we are processing a single domain because the messaging only makes
          // sense for a single domain input
          isSingleEntry && this.notifyError(this.$t('message.ShadowDomainValidationError'));
          this.formValidStates.shadow_domain = false;
          this.shadowDomainValid = false;
          failed.push(domain);
          continue;
        }

        if (domain === this.mainDomain) {
          isMainDomain = true;
          // only show flash if we are processing a single domain because the messaging only makes
          // sense for a single domain input
          isSingleEntry && this.notifyError(this.$t('message.MainDomainCannotBeAddedAsShadowDomain', {on: this.$t('domain.DomainName')}));
          this.formValidStates.shadow_domain = false;
          this.shadowDomainValid = false;
          failed.push(domain);
          continue;
        }

        this.formValidStates.shadow_domain = null;
        this.shadowDomainValid = null;


        success.push(domain);
      }

      const allPassed = success.length && !failed.length;
      if (success.length) {
        this.$emit('update:shadows', [...this.shadows, ...success]);
      }

      if (allPassed) {
        this.newShadowName = '';
      } else {
        this.newShadowName = failed.join('\n');
        if (isMainDomain) {
          !isSingleEntry && this.notifyError(this.$t('message.MainDomainCannotBeAddedAsShadowDomain', {on: this.$t('domain.DomainName')}));
          isMainDomain = false;
        } else {
          !isSingleEntry && this.notifyError(this.$t('message.input_parse_success_with_errors', {on: this.$t('domain.DomainName')}));
        }
        // this is our message when there are multiple entry
      }
      if (window.localStorage) {
        if (localStorage[DOMAIN_STAT_CACHE_KEY]) {
          shdow_cache = Number(localStorage[SHADOW_CACHE_KEY]);
        }
        shdow_cache--;
        // pre-populate widget data with cache values - let's not wait for the ajax to display info
        localStorage.setItem(SHADOW_CACHE_KEY, shdow_cache)
      }
      console.log('this.shdow_cache', shdow_cache);

      return true;
    },
    removeShadow(name) {
      const newShadows = [...this.shadows].filter((s) => s !== name);

      this.$emit('update:shadows', newShadows);

      // remove it from the search results
      if (this.useSearchResults) {
        this.shadowSearchResults = [...this.shadowSearchResults].filter((s) => s !== name);
      }
      let shdow_cache = 0;
      if (window.localStorage) {
        if (localStorage[DOMAIN_STAT_CACHE_KEY]) {
          shdow_cache = Number(localStorage[SHADOW_CACHE_KEY]);
        }
        shdow_cache++;
        // pre-populate widget data with cache values - let's not wait for the ajax to display info
        localStorage.setItem(SHADOW_CACHE_KEY, shdow_cache)
      }
      console.log('this.shdow_cache', shdow_cache)
    },
    promoteShadow(shadowDomain) {
      if (shadowDomain){
        if (shadowDomain.startsWith("*.")) {
          this.notifyError(this.$t('message.wildcardshandowdomain'));
            return;
        }
      }
      this.$emit('promote:shadow', shadowDomain);
    },
    handleShadowDomainInputKeyup(e) {
      const field = e.target.name;
      let value = e.target.value;

      const shadowDomains = value.split(MULTIPLE_VALUES_SPLITTER);

      if (shadowDomains.length > 1) {
        this.formValidStates[field] = null;
        this.shadowDomainValid = null;
      } else {
        value = value.toLowerCase().trim();
        this.formValidStates[field] = this.shadow_domain_validator(value);
        this.shadowDomainValid = this.shadow_domain_validator(value);
      }
    },
  },
  async mounted() {
    if (window.localStorage) {
      // pre-populate widget data with cache values - let's not wait for the ajax to display info
      localStorage.setItem(SHADOW_CACHE_KEY, 0)
    }

    try {
      let response = await domainConfig();
      if ("BLACKRULE_SUBDOMAINS" in response.data) {
        this.blackRulExtensions = response.data["BLACKRULE_SUBDOMAINS"];
      }
    } catch (errors) {
      errors.forEach((message) => {
        console.log("================blackRulExtensions Erro:", message);
      });
      this.blackRulExtensions = [];
    }

  }
}
</script>

<style scoped>
.subdomain-shadow-domain {
  background-color: #fbfbfb;
}
</style>
