import {MIXED_VALUE, PLUGIN_DEFAULTS} from '@/utilities/constants';
import {getPlugin, getPluginConfigValue, isPluginEnabled, setPluginConfigValue} from '@/utilities/plugin-actions';

/**
 * @deprecated let's use plugin-actions.js instead
 */
export default {
  methods: {
    getPluginDefaults(pluginName) {
      return PLUGIN_DEFAULTS[pluginName];
    },
    /**
     * @deprecated use `getPlugin` in `plugin-actions.js` instead
     */
    getPlugin(pluginName) {
      return getPlugin(this.plugins, pluginName);
    },
    togglePlugin(pluginName, enabled) {
      let plugin = getPlugin(this.plugins, pluginName);
      if (!plugin) {
        plugin = {...PLUGIN_DEFAULTS[pluginName]};
      }

      if (plugin.config === MIXED_VALUE) {
        plugin.config = {...PLUGIN_DEFAULTS[pluginName].config};
      }
      plugin.enabled = enabled;

      this.updatePlugin(plugin);
    },
    updatePlugin(plugin) {
      let found = false;

      const plugins = this.plugins.map((p) => {
        if (p.name === plugin.name) {
          found = true;
          return plugin;
        }

        return p;
      });

      // if we have not found it. the plugin does not exist yet.
      // so add it the plugin list.
      if (!found) {
        plugins.push(plugin);
      }

      this.updatePlugins(plugins);
    },
    updatePlugins(plugins) {
      this.$emit('update:plugins', plugins)
    },
    /**
     * @deprecated use `isPluginEnabled` in `plugin-actions.js` instead
     */
    isPluginEnabled(pluginName) {
      return isPluginEnabled(this.plugins, pluginName);
    },
    setPluginConfigValue(pluginName, configKey, value) {
      const plugin = setPluginConfigValue(this.plugins, pluginName, configKey, value);

      this.updatePlugin({...plugin});
    },
    /**
     * @deprecated use `getPluginConfigValue` in `plugin-actions.js` instead
     */
    getPluginConfigValue(pluginName, key) {
      return getPluginConfigValue(this.plugins, pluginName, key);
    },
  }
};
