<template>
  <div>
    <div v-if="viewMode === viewModes.OVERVIEW">
      <div>
        <h5>{{ $t('message.DomainDiagnoseOverview') }}</h5>
        
        <CAlert color="info" v-if="inProcess(this.domainList)" class="loading-message">
            <CSpinner/>
            <div style="margin-left: 10px;">{{ $t('message.batch_diagnose_in_progress') }}</div>
        </CAlert>

        <p v-if="hasError(this.domainList) && !inProcess(this.domainList)">{{ $t('message.batch_diagnose_error_detected') }}</p>
        <p v-else-if="!inProcess(this.domainList)">{{ $t('message.everything_okay') }}</p>
        <CListGroup>
          <CListGroupItem
              class="domain-item d-flex justify-content-between align-items-center"
              v-for="(d, index) in domainList" :key="d.domain"
              @click="viewDomain(index)"
          >
          {{ d.name }}
          <CSpinner size="sm" v-if="d.status == 'processing'" />
          <CBadge v-if="d.status !== 'processing'" :color="d.status ? 'success' : 'danger'" shape="pill">{{ d.status ? 'OK' : 'X' }}            
          </CBadge>
        </CListGroupItem>
        </CListGroup>
      </div>      
    </div>

    <div v-if="viewMode === viewModes.DOMAIN">
      <div class="domain-mode-navigation">
        <div>
          <CButton @click="handleBackToOverview">{{ $t('message.back_to_overview') }}</CButton>
        </div>

        <nav>
          <ul class="pagination">
            <li :class="{ 'page-item': true, 'disabled': viewDomainIndex === 0 }" @click="prevDomain()">
              <span class="page-link">‹</span>
            </li>
            <li class="page-item">
              <span class="page-link">
                {{ selectedViewDomain._data.domain_name.split('-').join('.') }}
              </span>
            </li>
            <li :class="{ 'page-item': true, 'disabled': viewDomainIndex === (domainList.length - 1) }" @click="nextDomain()">
              <span class="page-link">›</span>
            </li>
          </ul>
        </nav>
      </div>
      <div class="diagnose-container">
        <h5>{{ $t('message.diagnose_results_domain', { domain: selectedViewDomain._data.domain_name.split('-').join('.') }) }}</h5>        
        <BatchDiagnoseDetail :dg="dg" :domain="selectedViewDomain" :isLoading="isLoading" :hideContactUs="true"/>
      </div>
    </div>

  </div>
</template>

<style>
.loading-message {
  display: flex;
  align-items: center;
}

.domain-mode-navigation {
  display: flex;
  align-items: center;
}

.domain-mode-navigation nav {
  flex: 1;
  display: flex;
  justify-content: flex-end;
}

.domain-mode-navigation .pagination {
  margin-bottom: 0;
}

.domain-mode-navigation .page-link {
  color: gray;
}

.diagnose-container {
  margin-top: 30px;
}

.diagnose-container h5 {
  margin-left: 5px;
}

.domain-item {
  cursor: pointer;
}

.domain-item:hover {
  background-color: #f5f5f5;
}
</style>

<script>

import { isEmpty } from 'lodash';
import { diagnoseDomain, getClientIP } from '@/utilities/api';
import { DomainAction } from '@/utilities/websocket';
import { Websocket } from "@/plugins/websocket";
import BatchDiagnoseDetail from "./BatchDiagnoseDetail.vue"

export default {
  name: 'BatchDiagnose',
  props: {
    domains: Array,
    diagnose: Boolean
  },
  components: {BatchDiagnoseDetail},
  computed: {
    domainList() {
      if (!this.diagnose)
        return [];

      const domains = [];
      for (let d of this.domains) {
        const domain = {name: d._data.domain_name.split('-').join('.')};
        if (Object.keys(this.domainStatusMap).includes(d._data.domain_name.split('-').join('.'))) {
            domain.status = this.isOkay(this.domainStatusMap[d._data.domain_name.split('-').join('.')]);
        } else {
            domain.status = "processing";
        }        
        domains.push(domain);
      }
      return domains;
    },
    selectedViewDomain() {
      return this.domains[this.viewDomainIndex];
    },
    selectedViewDomainStatus() {
      return this.domainStatusMap[this.selectedViewDomain.name];
    }
  },
  mounted() {
    this.openWs();
    this.diagnoseDomains();
  },
  data() {
    return {
      domainStatusMap: {},
      checking: false,
      viewMode: 'OVERVIEW',
      viewModes: {
        OVERVIEW: 'OVERVIEW',
        DOMAIN: 'DOMAIN'
      },
      viewDomainIndex: null,
      dg: {
        init: [null, null],
        cname_query: [null, null],
        cname_visit: [null, null],
        source_setup: [null, null],
        source_visit: [null, null],
        source_cname: [null, null],
        ssl_visit: [null, null],
        site_visit: [null, null],
        site_visit_v6: [null, null]
      },
      isLoading: false,
      domain_ws: null
    };
  },
  methods: {
    sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    },
    openWs() {
        this.domain_ws = new Websocket(`domain/`);
        if (!this.domain_ws.isOpen()) {
          this.domain_ws.onopen = function(event) {
              console.log("==========>>>>> Successfully connected to the domain websocket server...")
          }
        }  
        
        this.domain_ws.onMessage = function(event, result) {
            if (result.action === DomainAction.DOMAIN_CHECKER) {                 
                this.domainStatusMap = {...this.domainStatusMap, ...{[result.data.row._data.domain_name.split('-').join('.')]: result.data.data}};
                let index = this.domainList.findIndex((obj => obj.name == result.data.row._data.domain_name.split('-').join('.')));
                this.domainList[index].status = this.isOkay(result.data.data);
                this.dg = result.data.data;
                this.isLoading = false;
            }
        }.bind(this)        
    },
    beforeDestroy() {
      this.closeWs();
    },
    closeWs() {
      this.domain_ws.connection.close();
    },
    sendDeploy(action, domains) {
      this.domain_ws.sendMessage(action, domains);
    },
    hasError(domainList) {
      return domainList.some((d) => !d.status);
    },
    inProcess(domainList) {
        return domainList.some((d) => d.status == 'processing'); 
    },
    hasDomainStatusData() {
      return !isEmpty(this.domainStatusMap);
    },
    async diagnoseDomains() {     
      const clientIP = await getClientIP(); 
      for (let d of this.domains) {
        this.domain_ws.sendMessage(DomainAction.DOMAIN_CHECKER, {
            route_id: d._data.id,
            row: d,
            client_ip: clientIP?.data?.ip
        });
      }  
    },
    isOkay(statusData) {
      const keys = Object.keys(statusData);
      const isAllPassed = keys.every((k) => {
        const data = statusData[k];
        return data[0] || false;
      });
      return isAllPassed;
    },
    viewDomain(index) {
      this.viewDomainIndex = index;
      this.viewMode = this.viewModes.DOMAIN;
      this.refresh();
    },
    handleBackToOverview() {
      this.viewMode = this.viewModes.OVERVIEW;
      this.viewDomainIndex = null;
    },
    nextDomain() {
      const topIndex = this.domainList.length - 1;
      if (this.viewDomainIndex < topIndex) {
        this.viewDomainIndex++;
        this.refresh();
      }
    },
    prevDomain() {
      if (this.viewDomainIndex > 0) {
        this.viewDomainIndex--;
        this.refresh();
      }
    },
    async refresh() {      
      this.isLoading = true;
      const clientIP = await getClientIP();
      this.domain_ws.sendMessage(DomainAction.DOMAIN_CHECKER, {
            route_id: this.selectedViewDomain._data.id,
            row: this.selectedViewDomain,
            client_ip: clientIP?.data?.ip
        });           
    }
  }
}
</script>
