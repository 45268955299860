<template>
  <div class="border rounded px-2 pt-1 pb-0">
    <template v-for="(value, index) in values">
      <a-tooltip v-if="value.length > 20" :key="value" :title="value" class="has-icon">
        <a-tag :key="value" :closable="true" @close="() => handleClose(value)">
          {{ `${value.slice(0, 20)}...` }}
        </a-tag>
      </a-tooltip>
      <a-tag v-else :key="value" :closable="true" @close="() => handleClose(value)" class="has-icon mb-1">
        {{ value }}
      </a-tag>
    </template>
    <a-input
        v-if="inputVisible"
        ref="input"
        type="text"
        size="small"
        class="mb-1"
        :style="{ width: '78px' }"
        :value="inputValue"
        @change="handleInputChange"
        @blur="handleInputConfirm"
        @keyup.enter="handleInputConfirm"
    />
    <a-tag v-else style="background: #fff; borderStyle: dashed;" @click="showInput" class="has-icon mb-1">
      <a-icon type="plus"/>
      {{ $t('New') }}
    </a-tag>
  </div>
</template>

<script>
export default {
  name: 'valueInput',
  props: ['values'],
  data() {
    return {
      inputVisible: false,
      inputValue: '',
    };
  },
  methods: {
    handleClose(removed) {
       const values = this.values.filter(v => v !== removed);
       
       this.$emit('update:values', values);
    },

    showInput() {
      this.inputVisible = true;
      this.$nextTick(function () {
        this.$refs.input.focus();
      });
    },

    handleInputChange(e) {
      this.inputValue = e.target.value;
    },

    handleInputConfirm() {
      const inputValue = this.inputValue;
      let values = this.values;
      if (!values) {
        values = [];
      }
      if (inputValue) {
        if (/[^a-zA-Z0-9\s,]/.test(inputValue)) {
          this.$notification.error({message: this.$t('message.SpecialCharactersError')});
          return;
        }
        const trimmedInputValue = inputValue.replace(/^\s+/, '').replace(/\s+$/, '');
        const newValues = trimmedInputValue.split(/[\s,]+/).map(item => item.replace(/[^a-zA-Z0-9]/g, ''));
        const nonEmptyValues = newValues.filter(value => value !== ''); 
        values = [...new Set([...values, ...nonEmptyValues])];
      }
      
      this.inputVisible = false;
      this.inputValue = '';
      
      this.$emit('update:values', values);
    },
  },
};
</script>