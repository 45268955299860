<template>
  <div class="container">
    <a-tabs :animated="false" :tab-position="mode">
      <a-tab-pane key="acme-configure" :tab="$t('BatchDelete')">
        <header>
          <div class="flex-fill">
            <div class="d-flex align-items-center mb-1 mt-2 has-icon">
              <details>
                <summary><strong>Selected Domain Shadow Domains</strong></summary>
                <ul>
                  <li v-for="(domain, index) in selectedDomain" :key="index">
                    <ul>
                      <li v-for="(host, hostIndex) in domain._data.hosts.slice(1)" :key="hostIndex">
                        {{ host }}
                      </li>
                    </ul>
                  </li>
                </ul>
              </details>
            </div>
          </div>
        </header>

        <div class="left-half">
          <!-- Error Alerts -->
          <a-alert class="margin" :message="$t('ShadowDomainDeleteInfo')" type="info" />
          <a-alert class="margin" v-if="invalidDomains.length > 0 && invalidDomains != ''" :message="errorMessage"
            type="warning" closable @close="onClose" />
          <a-alert class="margin" v-if="duplicateDomains.length > 0" :message="duplicate" type="warning" closable
            @close="onClose" />

          <!-- Textarea for entering domains -->
          <a-textarea class="margin" v-model="domainsInput" placeholder="Enter Shadow domains line by line"
            autosize></a-textarea>
          <br><br>
        </div>
      </a-tab-pane>
    </a-tabs>
  </div>
</template>

<script>

import { EventBus } from '@/plugins/event-bus.js';

export default {
  name: 'ShadowDomainDelete',
  props: {
    selectedDomain: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      domainsInput: '',
      invalidDomains: [],
      duplicateDomains: [],
      mode: 'left'
    };
  },
  computed: {
    errorMessage() {
      let message = '';
      if (this.invalidDomains.length > 0) {
        message += `Shadow domains are not valid for selected domains: ${this.invalidDomains.join(', ')}`;
      }
      return message;
    },
    duplicate() {
      let message = '';
      if (this.duplicateDomains.length > 0) {
        message += `Duplicate domains found: ${this.duplicateDomains.join(', ')}`;
      }
      return message;
    }
  },
  watch: {
    domainsInput: function (newVal, oldVal) {
      // Validate domains when input changes
      this.validateDomains();
      // Emit event to notify parent component (patchdomain.vue)
      const enteredDomains = this.domainsInput.trim().split('\n').map(domain => domain.trim());
      let invalidDomains = this.invalidDomains.map(domain => domain.trim());
      let duplicateDomains = this.duplicateDomains.map(domain => domain.trim());
      if (invalidDomains.length === 1 && invalidDomains[0] === '') {
        invalidDomains = [];
      }else if (duplicateDomains.length === 1 && duplicateDomains[0] === ''){
        duplicateDomains=[]
      }
      this.$emit('domains-updated', {
        domainsInput: enteredDomains,
        invalidDomains: invalidDomains,
        duplicateDomains: duplicateDomains
      });
    }
  },
  created() {
    EventBus.$on('shadowdomainarea', () => {
      this.clearshadowdomain();
    });
  },
  methods: {
    validateDomains() {
      const enteredDomains = this.domainsInput.trim().split('\n').map(domain => domain.trim());
      this.invalidDomains = [];
      this.duplicateDomains = [];

      // Track entered domains to check for duplicates
      const enteredDomainSet = new Set();

      // Check each entered domain against selected domains
      for (const enteredDomain of enteredDomains) {
        if (enteredDomainSet.has(enteredDomain)) {
          this.duplicateDomains.push(enteredDomain);
          continue;
        }
        enteredDomainSet.add(enteredDomain);

        let isValid = false;
        for (const domain of this.selectedDomain) {
          for (const host of domain._data.hosts.slice(1)) { // Exclude the first host
            if (host === enteredDomain) {
              isValid = true;
              break;
            }
          }
          if (isValid) break;
        }
        if (!isValid) {
          this.invalidDomains.push(enteredDomain);
        }
      }
    },
    clearErrors() {
      this.invalidDomains = [];
      this.duplicateDomains = [];
    },
    clearshadowdomain(){
      this.domainsInput=''
    },
    onClose() {
      // Handle close event if needed
    }
  }
}
</script>

<style scoped>
.margin {
  margin-top: 10px;
}

li,
ul {
  text-decoration: none;
  list-style: none;
}
</style>
